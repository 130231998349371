import React, { ReactElement, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { ROUTES } from 'src/routing/routes'
import { useClient, useQuery } from 'urql'
import { Factsheet_Assessment, Query_Root } from 'src/@types/graphql'
import {
  getFactsheetAssessorForAssessment,
  getFactsheetRecommendationById,
} from 'src/screens/factsheets/application/assessment/factsheetAssessmentQueries'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { EditButton } from 'src/shared/button/Buttons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { Section } from 'src/shared/presentation/Section'
import { Card, CardContent } from '@mui/material'
import { ReadOnlySelection, ReadOnlyTextField } from 'src/shared/presentation/ReadOnly'
import { HtmlRenderer } from 'src/shared/presentation/HtmlRenderer'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { FactsheetExportMenu } from 'src/shared/menu/FactsheetExportMenu'
import { usePermissionsForFactsheetAssessmentDetails } from 'src/service/security/PermissionHook'

export const FactsheetAssessmentDetailsRecommendationPage = (): ReactElement => {
  const { getMessage } = useMessageSource()
  const navigate = useDelayedNavigate()
  const urqlClient = useClient()
  const notificationService = useNotificationService()

  const { factsheetId, assessmentId } = useParams()
  const assessmentIdNumber = parseInt(assessmentId as string)

  const { canEdit, loading } = usePermissionsForFactsheetAssessmentDetails(assessmentIdNumber)

  const [factsheetAssessment, setFactsheetAssessment] = useState<Factsheet_Assessment | undefined>()

  const [{ data: factsheetAssessmentBaseData }] = useQuery<{
    factsheet_assessment: Query_Root['factsheet_assessment']
  }>({
    query: getFactsheetAssessorForAssessment,
    variables: { assessmentId: assessmentIdNumber },
  })

  const firstName = factsheetAssessmentBaseData?.factsheet_assessment[0]?.user?.first_name
  const lastName = factsheetAssessmentBaseData?.factsheet_assessment[0]?.user?.last_name
  const email = factsheetAssessmentBaseData?.factsheet_assessment[0]?.user?.email
  const assessmentUserFullName = !firstName ? email : `${firstName} ${lastName}`

  useEffect(() => {
    const initFormValues = async () => {
      const { data: factsheetAssessmentRecommendationData } = await urqlClient
        .query<{ factsheet_assessment: Query_Root['factsheet_assessment'] }>(getFactsheetRecommendationById, {
          assessmentId,
        })
        .toPromise()

      if (factsheetAssessmentRecommendationData) {
        const factsheetAssessmentRes = factsheetAssessmentRecommendationData?.factsheet_assessment?.[0]

        setFactsheetAssessment(factsheetAssessmentRes)
      } else {
        notificationService.operationFailed()
      }
    }

    initFormValues()
  }, [assessmentId, urqlClient, factsheetId])

  const onBackHandler = () => {
    navigate(ROUTES.FactsheetApplicationAssessment.params({ factsheetId }))
  }

  const onEditHandler = () => {
    const recommendationEdit = ROUTES.FactsheetApplicationAssessmentDetailsRoot.nested.RecommendationEdit

    if (recommendationEdit?.params) {
      navigate(recommendationEdit.params({ factsheetId, assessmentId }))
    }
  }

  return (
    <ScreenLayout
      title={`${getMessage('label.navigation.application.assessment')}: ${assessmentUserFullName || ''}`}
      onBack={onBackHandler}
      actions={<FactsheetExportMenu />}
    >
      {!loading && (
        <PageLayout>
          <Section
            id="personal-assessment"
            title={getMessage('label.assessment.personal.recommendation')}
            helpAndInstructions={<HelpAndInstructions labelKey={`label.help.assessment.recommendation.factsheet`} />}
            actionButton={<EditButton onClick={onEditHandler} hidden={!canEdit} />}
          >
            <Card>
              <CardContent>
                <ReadOnlySelection text={getMessage('label.recommendation')}>
                  {factsheetAssessment?.recommendation &&
                    getMessage(`label.recommendation.${factsheetAssessment!.recommendation}`)}
                </ReadOnlySelection>
                <ReadOnlyTextField text={getMessage('label.pros')}>
                  {factsheetAssessment?.pros && <HtmlRenderer html={factsheetAssessment!.pros} />}
                </ReadOnlyTextField>
                <ReadOnlyTextField text={getMessage('label.cons')}>
                  {factsheetAssessment?.cons && <HtmlRenderer html={factsheetAssessment!.cons} />}
                </ReadOnlyTextField>
                <ReadOnlyTextField text={getMessage('label.assessment.recommendation.comments')} isLast>
                  {factsheetAssessment?.recommendation_comment && (
                    <HtmlRenderer html={factsheetAssessment!.recommendation_comment} />
                  )}
                </ReadOnlyTextField>
              </CardContent>
            </Card>
          </Section>
        </PageLayout>
      )}
    </ScreenLayout>
  )
}
