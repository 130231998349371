import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { LEVEL, LEVEL_TYPE, TEXT_LENGTH } from 'src/shared/constants/constants'
import { useParams } from 'react-router'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useClient } from 'urql'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { ROUTES } from 'src/routing/routes'
import { Factsheet_Assessment_Set_Input, Mutation_Root, Query_Root } from 'src/@types/graphql'
import {
  getFactsheetAssessmentLevelCommentsQuery,
  updateFactsheetAssessmentLevelComments,
} from 'src/screens/factsheets/application/assessment/factsheetAssessmentQueries'
import { HtmlSanitizer } from 'src/shared/utils/HtmlSanitizer'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { Box } from '@mui/system'
import { Form } from 'react-final-form'
import { HtmlEditorField } from 'src/shared/form/control/HtmlEditorField'
import { maxChar } from 'src/shared/form/validation/validators'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { usePermissionsForFactsheetAssessmentDetails } from 'src/service/security/PermissionHook'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'

interface Props {
  level: LEVEL_TYPE
}

type originType = 'SAVE' | 'SAVE_AND_BACK'

export const FactsheetAssessmentLevelsCommentEditPage = ({ level }: Props): ReactElement => {
  const { factsheetId, assessmentId } = useParams()
  const assessmentIdNumber = parseInt(assessmentId as string)

  const navigate = useDelayedNavigate()
  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const { getMessage } = useMessageSource()

  const originRef = useRef<originType>('SAVE')

  const { canEdit, loading } = usePermissionsForFactsheetAssessmentDetails(assessmentIdNumber)

  const [comment, setComment] = useState<string | null>(null)

  useEffect(() => {
    const initData = async () => {
      const { data: factsheetLevelCommentData } = await urqlClient
        .query<
          { factsheet_assessment_by_pk: Query_Root['factsheet_assessment_by_pk'] },
          { id: number; isIntervention: boolean; isCommunication: boolean; isNetwork: boolean; isPolicy: boolean }
        >(getFactsheetAssessmentLevelCommentsQuery, {
          id: parseInt(assessmentId as string),
          isIntervention: level === LEVEL.INTERVENTION,
          isCommunication: level === LEVEL.COMMUNICATION,
          isNetwork: level === LEVEL.NETWORK,
          isPolicy: level === LEVEL.POLICY,
        })
        .toPromise()

      if (factsheetLevelCommentData?.factsheet_assessment_by_pk) {
        let comment: string | null | undefined
        switch (level) {
          case LEVEL.INTERVENTION:
            comment = factsheetLevelCommentData.factsheet_assessment_by_pk.intervention_comment
            break
          case LEVEL.COMMUNICATION:
            comment = factsheetLevelCommentData.factsheet_assessment_by_pk.communication_comment
            break
          case LEVEL.NETWORK:
            comment = factsheetLevelCommentData.factsheet_assessment_by_pk.network_comment
            break
          case LEVEL.POLICY:
            comment = factsheetLevelCommentData.factsheet_assessment_by_pk.policy_comment
            break
          default:
            comment = null
        }
        setComment(comment || null)
      } else {
        notificationService.operationFailed()
      }
    }

    initData()
  }, [urqlClient, assessmentId])

  const onSave = async ({ comment }: { comment: string | null }) => {
    const object = {
      ...(level === LEVEL.INTERVENTION && { intervention_comment: HtmlSanitizer.sanitize(comment ?? '') }),
      ...(level === LEVEL.COMMUNICATION && { communication_comment: HtmlSanitizer.sanitize(comment ?? '') }),
      ...(level === LEVEL.NETWORK && { network_comment: HtmlSanitizer.sanitize(comment ?? '') }),
      ...(level === LEVEL.POLICY && { policy_comment: HtmlSanitizer.sanitize(comment ?? '') }),
    }

    const { data } = await urqlClient
      .mutation<
        { update_factsheet_assessment: Mutation_Root['update_factsheet_assessment'] },
        { id: number; object: Factsheet_Assessment_Set_Input }
      >(updateFactsheetAssessmentLevelComments, {
        id: assessmentIdNumber,
        object,
      })
      .toPromise()

    if (data && data.update_factsheet_assessment?.affected_rows) {
      setComment(comment)
      notificationService.changesSaved()
      if (originRef.current === 'SAVE_AND_BACK') {
        onBack()
      }
    } else {
      notificationService.operationFailed()
    }
  }

  const onSubmit = (origin: originType) => (_: any) => {
    // trigger submit event
    if (originRef) {
      originRef.current = origin
    }
    document.getElementById('edit-form')?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const onBack = () => {
    const levelRoute = ROUTES.FactsheetApplicationAssessmentDetailsRoot.nested[level]

    if (levelRoute?.params) {
      navigate(levelRoute.params({ factsheetId, assessmentId }))
    }
  }

  if (!loading && !canEdit) {
    return <NotAuthorized inEditPage />
  }

  return (
    <ScreenLayout
      title={getMessage('label.title.edit.comments')}
      onBack={onBack}
      hasSecondLevelNavigation={false}
      actions={
        <>
          <SaveAndBackButton origin="header" onClick={onSubmit('SAVE_AND_BACK')} />
          <SaveButton origin="header" onClick={onSubmit('SAVE')} />
        </>
      }
    >
      {!loading && (
        <PageLayout>
          <>
            <HelpAndInstructions labelKey={`label.help.assessment.${level.toLowerCase()}.comment`} defaultExpansion />
            <Box>
              <Form<{ comment: string | null }>
                initialValues={{ comment }}
                onSubmit={onSave}
                render={({ handleSubmit }) => {
                  return (
                    <form id="edit-form" onSubmit={handleSubmit}>
                      <HtmlEditorField
                        name="comment"
                        validate={maxChar(TEXT_LENGTH.XL)}
                        label={getMessage('label.summary.comments')}
                      />
                      <DirtyFormSpy />
                    </form>
                  )
                }}
              />
            </Box>
          </>
        </PageLayout>
      )}
    </ScreenLayout>
  )
}
