import { get } from 'lodash'
import { useParams } from 'react-router-dom'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import {
  usePermissionsForFactsheetDetailsRelatedProjects,
  usePermissionsForFactsheetDetailsSidebar,
  usePermissionsForFactsheetUsers,
} from 'src/service/security/PermissionHook'
import { BASE_URL, PROCESS } from 'src/shared/constants/constants'
import { Sidebar, SidebarGroupListObject, SidebarGroupObject } from 'src/shared/sidebar/Sidebar'
import { useSidebarContext } from 'src/shared/sidebar/SidebarAwareContext'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useUserLocale } from 'src/user/UserContext'

export const FactsheetsSidebar = () => {
  const { factsheetId } = useParams()
  const factsheetIdNumber = parseInt(factsheetId as string)

  const notificationService = useNotificationService()
  const language = useUserLocale()
  const { getMessage } = useMessageSource()

  const {
    loading,
    metadata: { workflowStatus, title, fundingRound },
    refetch,
  } = usePermissionsForFactsheetDetailsSidebar(factsheetIdNumber)

  // permissions for routes in configuration group
  const { canView: canViewFactsheetUsers } = usePermissionsForFactsheetUsers(factsheetIdNumber)
  const { canViewLinkedProjects, canViewLinkedMeasures } =
    usePermissionsForFactsheetDetailsRelatedProjects(factsheetIdNumber)

  useSidebarContext(refetch)

  if (!loading && workflowStatus == null && title == null) {
    notificationService.operationFailed()
  }

  const factsheetStatus = workflowStatus ?? undefined
  const factsheetTitle = title ? get(title, language, '') : undefined

  const getFactsheetConfigGroupWithRoutes = (): SidebarGroupObject => {
    // group
    const factsheetConfigGroup: SidebarGroupObject = {
      groupMessageKey: 'label.factsheet.configuration',
      list: [
        {
          listMessageKey: 'label.navigation.factsheets.factsheet.description',
          href: ROUTES.FactsheetDescriptionRoot.params({ factsheetId }),
          routePath: ROUTES.FactsheetDescriptionRoot.path,
        },
      ],
    }

    if (canViewFactsheetUsers) {
      const factsheetPermissionsRoute: SidebarGroupListObject = {
        listMessageKey: 'label.navigation.factsheets.permissions',
        href: ROUTES.FactsheetPermissions.params({ factsheetId }),
        routePath: ROUTES.FactsheetPermissions.path,
      }

      factsheetConfigGroup.list.push(factsheetPermissionsRoute)
    }

    if (canViewLinkedProjects || canViewLinkedMeasures) {
      const factsheetRelatedProjectRoute: SidebarGroupListObject = {
        listMessageKey: 'label.navigation.factsheets.related.projects.measures',
        href: ROUTES.FactsheetRelatedProjects.params({ factsheetId }),
        routePath: ROUTES.FactsheetRelatedProjects.path,
      }

      factsheetConfigGroup.list.push(factsheetRelatedProjectRoute)
    }

    return factsheetConfigGroup
  }

  const getFactsheetApplicationGroupWithRoutes = (): SidebarGroupObject => {
    const factsheetApplicationGroup: SidebarGroupObject = {
      groupMessageKey: 'label.application',
      list: [
        {
          listMessageKey: 'label.navigation.factsheets.application.details',
          href: ROUTES.FactsheetApplicationDetailsRoot.params({ factsheetId }),
          routePath: ROUTES.FactsheetApplicationDetailsRoot.path,
        },
        {
          listMessageKey: 'label.navigation.factsheets.application.committees',
          href: ROUTES.FactsheetApplicationCommittee.params({ factsheetId }),
          routePath: ROUTES.FactsheetApplicationCommittee.path,
        },
        {
          listMessageKey: 'label.navigation.factsheets.application.assessment',
          href: ROUTES.FactsheetApplicationAssessment.params({ factsheetId }),
          routePath: ROUTES.FactsheetApplicationAssessment.path,
        },
      ],
    }

    return factsheetApplicationGroup
  }

  const routeGroups: SidebarGroupObject[] = [
    // Factsheet configuration group of routes
    getFactsheetConfigGroupWithRoutes(),

    // Factsheet application group of routes
    getFactsheetApplicationGroupWithRoutes(),
  ]

  return (
    <Sidebar
      routes={routeGroups}
      title={factsheetTitle}
      status={factsheetStatus}
      entityId={factsheetId}
      process={PROCESS.FACTSHEET}
      hideDetailedInformation={false}
      fundingRoundName={fundingRound?.name}
      home={{ url: BASE_URL.FACTSHEETS, label: getMessage('label.navigation.factsheets') }}
    />
  )
}
