export const TASK = {
  FILL_OUT_PROJECT_DESCRIPTION: 'FILL_OUT_PROJECT_DESCRIPTION',
  REWORK_PROJECT_DESCRIPTION: 'REWORK_PROJECT_DESCRIPTION',
  REVIEW_PROJECT_APPLICATION: 'REVIEW_PROJECT_APPLICATION',
  FILL_OUT_ASSESSMENT: 'FILL_OUT_ASSESSMENT',
  FILL_OUT_MILESTONE: 'FILL_OUT_MILESTONE',
  REVIEW_MILESTONE: 'REVIEW_MILESTONE',
  REWORK_MILESTONE: 'REWORK_MILESTONE',
  FILL_OUT_PROGRAM_DESCRIPTION: 'FILL_OUT_PROGRAM_DESCRIPTION',
  REVIEW_PROGRAM_DESCRIPTION: 'REVIEW_PROGRAM_DESCRIPTION',
  REWORK_PROGRAM_DESCRIPTION: 'REWORK_PROGRAM_DESCRIPTION',
  FINALIZE_PROGRAM_APPLICATION: 'FINALIZE_PROGRAM_APPLICATION',
  REVIEW_FACTSHEET_DESCRIPTION: 'REVIEW_FACTSHEET_DESCRIPTION',
  REWORK_FACTSHEET_DESCRIPTION: 'REWORK_FACTSHEET_DESCRIPTION',
} as const

export type TASK_TYPE = keyof typeof TASK

export const ALL_TASK_TYPES: TASK_TYPE[] = [
  'FILL_OUT_PROJECT_DESCRIPTION',
  'REWORK_PROJECT_DESCRIPTION',
  'REVIEW_PROJECT_APPLICATION',
  'FILL_OUT_ASSESSMENT',
  'FILL_OUT_MILESTONE',
  'REVIEW_MILESTONE',
  'REWORK_MILESTONE',
  'FILL_OUT_PROGRAM_DESCRIPTION',
  'REVIEW_PROGRAM_DESCRIPTION',
  'REWORK_PROGRAM_DESCRIPTION',
  'FINALIZE_PROGRAM_APPLICATION',
  'REWORK_FACTSHEET_DESCRIPTION',
  'REVIEW_FACTSHEET_DESCRIPTION',
]

export const MILESTONE_TASKS = ['FILL_OUT_MILESTONE', 'REVIEW_MILESTONE', 'REWORK_MILESTONE']

export const FACTSHEET_TASKS = ['REWORK_FACTSHEET_DESCRIPTION', 'REVIEW_FACTSHEET_DESCRIPTION']

export const PROGRAM_DESCRIPTION_TASKS = [
  'FILL_OUT_PROGRAM_DESCRIPTION',
  'REVIEW_PROGRAM_DESCRIPTION',
  'REWORK_PROGRAM_DESCRIPTION',
]

export const PROJECT_DESCRIPTION_TASKS = ['FILL_OUT_PROJECT_DESCRIPTION', 'REVIEW_PROJECT_APPLICATION']
