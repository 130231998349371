import { ReactElement, useCallback, useEffect, useState } from 'react'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { useParams } from 'react-router'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useClient, useQuery } from 'urql'
import { Factsheet_Assessment, Mutation_Root, Query_Root } from 'src/@types/graphql'
import {
  getFactsheetAssessorForAssessment,
  queryFactsheetAssessmentSummaryById,
  updateFactsheetAssessmentStatusById,
} from 'src/screens/factsheets/application/assessment/factsheetAssessmentQueries'
import { ROUTES } from 'src/routing/routes'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { EditButton } from 'src/shared/button/Buttons'
import { SummarySection } from 'src/screens/shared/application/common/SummarySection'
import { ValidationListItemProps } from 'src/screens/shared/common/SummaryValidationUtils'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { ASSESSMENT_STATUS } from 'src/shared/constants/assessment-constants'
import { Button } from '@mui/material'
import { SecondaryConfirmationModalDialog } from 'src/shared/modal-dialog/SecondaryConfirmationModalDialog'
import { FactsheetExportMenu } from 'src/shared/menu/FactsheetExportMenu'
import { usePermissionsForFactsheetAssessmentDetails } from 'src/service/security/PermissionHook'

export const FactsheetAssessmentDetailsSummaryPage = (): ReactElement => {
  const { factsheetId, assessmentId } = useParams()
  const assessmentIdNumber = parseInt(assessmentId as string)

  const { getMessage } = useMessageSource()
  const urqlClient = useClient()
  const navigate = useDelayedNavigate()
  const notificationService = useNotificationService()

  const { canEdit, loading } = usePermissionsForFactsheetAssessmentDetails(assessmentIdNumber)

  const [factsheetAssessment, setFactsheetAssessment] = useState<Factsheet_Assessment>()
  const [validationViolations, setValidationViolations] = useState<ValidationListItemProps[]>([])
  const [assessmentFinalizeConfirmationOpen, setAssessmentFinalizeConfirmationOpen] = useState(false)

  const [{ data: factsheetAssessmentBaseData }] = useQuery<{
    factsheet_assessment: Query_Root['factsheet_assessment']
  }>({
    query: getFactsheetAssessorForAssessment,
    variables: { assessmentId: assessmentIdNumber },
  })

  const firstName = factsheetAssessmentBaseData?.factsheet_assessment[0]?.user?.first_name
  const lastName = factsheetAssessmentBaseData?.factsheet_assessment[0]?.user?.last_name
  const email = factsheetAssessmentBaseData?.factsheet_assessment[0]?.user?.email
  const assessmentUserFullName = !firstName ? email : `${firstName} ${lastName}`

  const validateAssessment = useCallback(
    (factsheetAssessment: Factsheet_Assessment): ValidationListItemProps[] => {
      const validationViolations: ValidationListItemProps[] = []
      if (factsheetAssessment.recommendation === null) {
        const violation = {
          message: getMessage('validation.fill.out.section', [
            getMessage('label.navigation.assessment.recommendation'),
            getMessage('label.assessment.personal.recommendation'),
          ]),
          tab: 'recommendation',
          section: 'personal-recommendation',
        }
        validationViolations.push(violation)
      }
      return validationViolations
    },
    [getMessage],
  )

  useEffect(() => {
    const initFormValues = async () => {
      const { data } = await urqlClient
        .query<{ factsheet_assessment: Query_Root['factsheet_assessment'] }>(queryFactsheetAssessmentSummaryById, {
          assessmentId,
        })
        .toPromise()

      if (data && data.factsheet_assessment[0]) {
        const factsheetAssessment = data.factsheet_assessment[0]
        setFactsheetAssessment(factsheetAssessment)

        const validationViolations = validateAssessment(factsheetAssessment)
        setValidationViolations(validationViolations)
      } else {
        notificationService.operationFailed()
      }
    }

    initFormValues()
  }, [assessmentId, urqlClient, factsheetId])

  const onBackHandler = () => {
    navigate(ROUTES.FactsheetApplicationAssessment.params({ factsheetId }))
  }

  const summaryCommentsEditHandler = () => {
    const recommendationEdit = ROUTES.FactsheetApplicationAssessmentDetailsRoot.nested.SummaryCommentEdit

    if (recommendationEdit?.params) {
      navigate(recommendationEdit.params({ factsheetId, assessmentId }))
    }
  }

  const handleViolationClick = (validationItem: ValidationListItemProps) => {
    const route = validationItem.root
      ? validationItem.root
      : ROUTES.FactsheetApplicationAssessmentDetailsRoot.params({ factsheetId, assessmentId })

    navigate(
      `${route}${validationItem.tab ? '/' + validationItem.tab : ''}${
        validationItem.section ? `#${validationItem.section}` : ''
      }`,
    )
  }

  const confirmAssessmentFinalization = async () => {
    const { data, error } = await urqlClient
      .mutation<{
        update_factsheet_assessment: Mutation_Root['update_factsheet_assessment']
      }>(updateFactsheetAssessmentStatusById, {
        assessmentId,
        status: ASSESSMENT_STATUS.DONE,
      })
      .toPromise()

    if (error || data?.update_factsheet_assessment?.affected_rows !== 1) {
      notificationService.operationFailed()
    } else {
      const newStatus = data?.update_factsheet_assessment.returning[0]?.status

      if (factsheetAssessment) {
        const finalizedFactsheetAssessment: Factsheet_Assessment = {
          ...factsheetAssessment,
          status: newStatus,
        }
        setFactsheetAssessment(finalizedFactsheetAssessment)
        setAssessmentFinalizeConfirmationOpen(false)
        notificationService.changesSaved()
      }
    }
  }

  const openConfirmationModalDialog = () => {
    setAssessmentFinalizeConfirmationOpen(true)
  }

  const cancelAssessmentFinalization = () => {
    setAssessmentFinalizeConfirmationOpen(false)
  }

  return (
    <ScreenLayout
      title={`${getMessage('label.navigation.application.assessment')}: ${assessmentUserFullName || ''}`}
      onBack={onBackHandler}
      actions={<FactsheetExportMenu />}
    >
      <PageLayout>
        {!loading && factsheetAssessment != undefined && (
          <SummarySection
            title={getMessage('label.assessment.summary.title')}
            actionsHelpLabel={`label.help.assessment.summary.actions.factsheet`}
            status={
              <span>
                {getMessage('label.status')}: {getMessage(`label.assessment.status.${factsheetAssessment.status}`)}
              </span>
            }
            violations={validationViolations}
            violationClickHandler={handleViolationClick}
            actionsCompleteMessage={getMessage('label.assessment.summary.check.complete')}
            statusActions={
              factsheetAssessment.status === ASSESSMENT_STATUS.OPEN &&
              canEdit && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={openConfirmationModalDialog}
                  disabled={validationViolations.length > 0}
                >
                  {getMessage('button.set.to.done')}
                </Button>
              )
            }
            commentsHelpLabel={`label.help.assessment.summary.comments.factsheet`}
            commentActions={<EditButton onClick={summaryCommentsEditHandler} hidden={!canEdit} />}
            comment={factsheetAssessment.summary_comment ?? null}
          />
        )}

        <SecondaryConfirmationModalDialog
          open={assessmentFinalizeConfirmationOpen}
          onCancel={cancelAssessmentFinalization}
          onConfirm={confirmAssessmentFinalization}
          titleKey="label.assessment.set.to.done.confirm.title"
          confirmButtonKey="button.set.to.done"
        >
          {getMessage('label.assessment.set.to.done.confirm.body')}
        </SecondaryConfirmationModalDialog>
      </PageLayout>
    </ScreenLayout>
  )
}
