import { gql } from 'urql'

export const fetchFactsheetByIdQuery = gql`
  query fetchFactsheetQuery($id: Int!) {
    factsheet_by_pk(id: $id) {
      id
      title
      description
      modules
      application_type_id
      application_type {
        code
      }
      level
      project_leader_id
      published
      organization
      contact
      important_insights
      strength
      further_information
      evidence_of_effectiveness
      feature_update_version
      workflow_status
      version
      factsheet_workflow_id
      project_leader_user {
        first_name
        last_name
        email
        status
      }
      original_factsheet {
        id
        title
      }
    }
  }
`

export const fetchFactsheetBasicInfoEdit = gql`
  query fetchFactsheetBasicInfoEditQuery($id: Int!) {
    factsheet_by_pk(id: $id) {
      id
      title
      description
      modules
      application_type_id
      application_type {
        code
      }
      level
      organization
      contact
      important_insights
      workflow_status
      strength
      further_information
      evidence_of_effectiveness
      feature_update_version
      project_leader_id
      published
      version
      factsheet_users {
        user_id
        user {
          first_name
          last_name
          email
          status
        }
        type
      }
    }
  }
`

export const updateFactsheetProjectLeaderMutation = gql`
  mutation ($newProjectLeaderId: Int!, $projectLeaderRole: String!, $newFactsheetId: Int!) {
    set_current_factsheet_admin: update_factsheet_user(
      where: { _and: [{ user_id: { _eq: $newProjectLeaderId } }, { factsheet_id: { _eq: $newFactsheetId } }] }
      _set: { type: $projectLeaderRole }
    ) {
      affected_rows
    }
  }
`

export const updatePreviousFactsheetLeaderRole = gql`
  mutation ($previousProjectLeaderId: Int!, $projectContributorRole: String!, $prevFactsheetId: Int!) {
    set_previous_factsheet_admin_as_contributor: update_factsheet_user(
      where: { _and: [{ user_id: { _eq: $previousProjectLeaderId } }, { factsheet_id: { _eq: $prevFactsheetId } }] }
      _set: { type: $projectContributorRole }
    ) {
      affected_rows
    }
  }
`

export const updateFactsheetMutation = gql`
  mutation updateFactsheet($id: Int, $object: factsheet_set_input) {
    update_factsheet(where: { id: { _eq: $id } }, _set: $object) {
      affected_rows
    }
  }
`

export const relatedPfKapProjectsAndKapMeasuresQuery = gql`
  query ($factsheetId: Int!) {
    project_base(where: { factsheet_id: { _eq: $factsheetId } }) {
      dossier {
        short_title
        status
      }
      pf_kap_projects {
        application_type {
          key
        }
        modules
        id
      }
    }
    kap_measure(where: { factsheet_id: { _eq: $factsheetId } }) {
      id
      level
      short_title
      modules
      kap_program {
        id
        dossier {
          short_title
          status
        }
      }
    }
  }
`
/**
 * feature_base: { data: {} } creates new feature base (with id) in the table
 */
export const createFactsheetMutation = gql`
  mutation (
    $title: jsonb
    $published: Boolean!
    $application_type_id: Int!
    $factsheetLevel: String!
    $modules: jsonb
    $workflow_status: String!
    $version: String!
  ) {
    insert_factsheet_one(
      object: {
        title: $title
        published: $published
        application_type_id: $application_type_id
        level: $factsheetLevel
        modules: $modules
        feature_base: { data: {} }
        workflow_status: $workflow_status
        version: $version
      }
    ) {
      id
    }
  }
`

export const copyFactsheet = gql`
  mutation (
    $factsheetId: Int!
    $title: String!
    $level: String!
    $version: String!
    $language: String!
    $modules: [String!]!
  ) {
    copyFactsheet(
      factsheetId: $factsheetId
      title: $title
      level: $level
      version: $version
      language: $language
      modules: $modules
    ) {
      failureKey
      status
      factsheetId
    }
  }
`

export const queryAllFactsheets = gql`
  query ($locale: String!, $where: factsheet_bool_exp) {
    factsheet(where: $where, order_by: { id: desc }) {
      id
      title(path: $locale)
      modules
      level
      published
      version
    }
  }
`

export const fetchFactsheetSearchDataMutation = gql`
  mutation fetchFactsheetSearchData($input: FactsheetSearchInput!) {
    fetchFactsheetSearchData(search: $input) {
      data {
        id
        title
        description
        published
        modules
        strength
        topics
      }
    }
  }
`

export const fetchFactsheetRelatedPfKapProjects = gql`
  mutation fetchFactsheetRelatedPfKapProjectData($pfFactsheetId: Int!) {
    fetchFactsheetRelatedPfKapProjectData(factsheetId: $pfFactsheetId) {
      data {
        id
        applicationTypeKey
        modules
        shortTitle
        state
      }
    }
  }
`

export const fetchFactsheetRelatedMeasure = gql`
  mutation fetchFactsheetRelatedMeasureData($factsheetId: Int!) {
    fetchFactsheetRelatedMeasureData(factsheetId: $factsheetId) {
      data {
        id
        level
        programShortTitle
        programId
        modules
        shortTitle
        state
      }
    }
  }
`
