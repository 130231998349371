import { Box } from '@mui/material'
import { get } from 'lodash'
import { ReactElement, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { FactsheetRelatedMeasureData, FactsheetRelatedPfKapProjectData, Mutation_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import {
  fetchFactsheetRelatedMeasure,
  fetchFactsheetRelatedPfKapProjects,
} from 'src/screens/factsheets/factsheet/factsheetQueries'
import { FactsheetLinkedKapMeasuresGrid } from 'src/screens/factsheets/factsheet/related-projects-and-measures/FactsheetLinkedKapMeasuresGrid'
import { FactsheetLinkedPfKapProjectsGrid } from 'src/screens/factsheets/factsheet/related-projects-and-measures/FactsheetLinkedPfKapProjectsGrid'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { FactsheetExportMenu } from 'src/shared/menu/FactsheetExportMenu'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { Section } from 'src/shared/presentation/Section'
import { useError } from 'src/shared/utils/hooks/page-loading-error-hook'
import { useUserLocale } from 'src/user/UserContext'
import { useClient } from 'urql'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { usePermissionsForFactsheetDetailsRelatedProjects } from 'src/service/security/PermissionHook'
import combinedQuery from 'graphql-combine-query'

export const FactsheetRelatedProjectsAndMeasuresPage = (): ReactElement => {
  const { getMessage } = useMessageSource()
  const language = useUserLocale()
  const setError = useError()
  const urqlClient = useClient()

  const { factsheetId } = useParams()
  const factsheet_id = parseInt(factsheetId as string)
  const {
    loading,
    canViewLinkedMeasures,
    canClickOnLinkedMeasures,
    canViewLinkedProjects,
    canClickOnLinkedProjects,
    metadata: { title },
  } = usePermissionsForFactsheetDetailsRelatedProjects(parseInt(factsheetId as string))

  const [relatedProjects, setRelatedProjects] = useState<Array<FactsheetRelatedPfKapProjectData>>()
  const [relatedMeasures, setRelatedMeasures] = useState<Array<FactsheetRelatedMeasureData>>()

  useEffect(() => {
    const initData = async () => {
      const combinedMutation = combinedQuery('FetchFactsheetRelatedPfKapProjectAndMeasureData')
        .add<{
          fetchFactsheetRelatedPfKapProjectData: Mutation_Root['fetchFactsheetRelatedPfKapProjectData']
        }>(fetchFactsheetRelatedPfKapProjects, {
          pfFactsheetId: factsheet_id,
        })
        .add<{
          fetchFactsheetRelatedMeasureData: Mutation_Root['fetchFactsheetRelatedMeasureData']
        }>(fetchFactsheetRelatedMeasure, {
          factsheetId: factsheet_id,
        })

      const { document, variables: vars } = combinedMutation
      const { error, data } = await urqlClient
        .mutation<{
          fetchFactsheetRelatedPfKapProjectData: Mutation_Root['fetchFactsheetRelatedPfKapProjectData']
          fetchFactsheetRelatedMeasureData: Mutation_Root['fetchFactsheetRelatedMeasureData']
        }>(document, vars)
        .toPromise()

      if (error) {
        setError()
      } else {
        setRelatedProjects(data?.fetchFactsheetRelatedPfKapProjectData?.data ?? [])
        setRelatedMeasures(data?.fetchFactsheetRelatedMeasureData?.data ?? [])
      }
    }

    initData()
  }, [urqlClient, setError, factsheetId])

  if (!loading && !canViewLinkedMeasures && !canViewLinkedProjects) {
    return <NotAuthorized />
  }

  return (
    <ScreenLayout title={get(title, language, '')} hasSecondLevelNavigation={false} actions={<FactsheetExportMenu />}>
      {!loading && (
        <PageLayout>
          {canViewLinkedProjects && (
            <Section
              id="linked-pf-kap-projects"
              title={getMessage('label.factsheet.linked.pf_kap.projects')}
              helpAndInstructions={<HelpAndInstructions labelKey="label.help.factsheet.linked.pf_kap.projects" />}
            >
              {relatedProjects !== undefined && (
                <Box>
                  <FactsheetLinkedPfKapProjectsGrid
                    projects={relatedProjects}
                    canClickOnRow={canClickOnLinkedProjects}
                  />
                </Box>
              )}
            </Section>
          )}

          {canViewLinkedMeasures && (
            <Section
              id="linked-kap-measures"
              title={getMessage('label.factsheet.linked.kap.measures')}
              helpAndInstructions={<HelpAndInstructions labelKey="label.help.factsheet.linked.kap.measures" />}
            >
              {relatedMeasures !== undefined && (
                <Box>
                  <FactsheetLinkedKapMeasuresGrid measures={relatedMeasures} canClickOnRow={canClickOnLinkedMeasures} />
                </Box>
              )}
            </Section>
          )}
        </PageLayout>
      )}
    </ScreenLayout>
  )
}
