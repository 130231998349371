import { Card, CardContent } from '@mui/material'
import { ReactElement, useMemo, useState } from 'react'
import { Milestone } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { getMilestoneTitleLabel } from 'src/screens/shared/implementation/details/milestone/utils/MilestoneUtils'
import { EditButton } from 'src/shared/button/Buttons'
import { ENTITY_TYPE, FILE_TYPE, PROJECT_TYPE } from 'src/shared/constants/constants'
import { MILESTONE_TYPE, MILESTONE_TYPE_TYPE } from 'src/shared/constants/milestone-constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { FinalProjectReportExportMenu } from 'src/shared/menu/FinalProjectReportExportMenu'
import { ProjectMilestoneExportMenu } from 'src/shared/menu/ProjectMilestoneExportMenu'
import { FileSection } from 'src/shared/presentation/FileSection'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { HtmlRenderer } from 'src/shared/presentation/HtmlRenderer'
import { Section } from 'src/shared/presentation/Section'
import { Utils } from 'src/shared/utils/Utils'
import { MilestoneFinalReportChart } from 'src/shared/charts/milestone-final-report/MilestoneFinalReportChart'

interface Props {
  milestone: Milestone
  milestoneId: number
  milestoneType: MILESTONE_TYPE_TYPE
  process: PROJECT_TYPE
  canEdit: boolean
  onBack: () => void
  onEdit: () => void
  sectionTitle?: string
  helpLabel?: string
  canUploadFiles?: boolean
}

export const MilestoneDetailsView = ({
  milestone,
  milestoneId,
  milestoneType,
  process,
  canEdit,
  onBack,
  onEdit,
  sectionTitle = 'label.notes',
  helpLabel = `label.help.milestone.notes.${milestoneType}.${process}`,
  canUploadFiles = canEdit,
}: Props): ReactElement => {
  const { getMessage } = useMessageSource()

  const { requiredNotes, requiredDocuments } = Utils.resolveDefaultMilestoneValidationFields(milestoneType, process)

  const [chartBase64Image, setChartBase64Image] = useState<string>()

  const hasExportButton = milestoneType === MILESTONE_TYPE.ANNUAL_REPORT

  const title = useMemo(() => {
    const milestoneTitleLabel = getMilestoneTitleLabel(process, milestone.type)
    return `${getMessage(milestoneTitleLabel)} ${milestone.year_in_focus ?? ''}`
  }, [getMessage, milestone, process])

  return (
    <>
      <ScreenLayout
        title={title}
        onBack={onBack}
        actions={
          <>
            {hasExportButton && (
              <ProjectMilestoneExportMenu process={process} year={milestone?.year_in_focus as number} />
            )}
            {milestoneType === MILESTONE_TYPE.FINAL_REPORT && (
              <FinalProjectReportExportMenu process={process} chartBase64Image={chartBase64Image} />
            )}
          </>
        }
      >
        <PageLayout>
          <>
            <Section
              title={getMessage(sectionTitle)}
              helpAndInstructions={<HelpAndInstructions labelKey={helpLabel} />}
              actionButton={<EditButton onClick={onEdit} hidden={!canEdit} />}
              optional={!requiredNotes}
            >
              {milestone?.notes && (
                <Card>
                  <CardContent>
                    <HtmlRenderer html={milestone?.notes} />
                  </CardContent>
                </Card>
              )}
            </Section>
            <FileSection
              id="documents"
              title={getMessage(`label.${milestoneType}.documents`)}
              fileType={FILE_TYPE.MILESTONE_DETAILS_DOCUMENT}
              entityType={ENTITY_TYPE.MILESTONE}
              entityId={milestoneId}
              canEdit={canUploadFiles}
              helpAndInstructions={
                <HelpAndInstructions labelKey={`label.help.milestone.documents.${milestoneType}.${process}`} />
              }
              optional={!requiredDocuments}
            />

            {milestoneType === MILESTONE_TYPE.FINAL_REPORT && (
              <MilestoneFinalReportChart
                setChartBase64Image={setChartBase64Image}
                style={{ visibility: 'hidden', display: 'none' }}
              />
            )}
          </>
        </PageLayout>
      </ScreenLayout>
    </>
  )
}
