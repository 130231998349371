import { gql } from 'urql'

/**
 * Factsheet assessment grid listing.
 */

export const getFactsheetAssessmentsQuery = gql`
  query fetchAssessmentsQuery($factsheetId: Int!) {
    factsheet_assessment(where: { factsheet_id: { _eq: $factsheetId } }) {
      id
      recommendation
      assessor_id
      instance_id
      user {
        first_name
        last_name
        email
      }
      assessment_instance_type {
        key
      }
    }
  }
`

export const insertMultipleFactsheetAssessmentsMutation = gql`
  mutation insert_factsheet_assessments($objects: [factsheet_assessment_insert_input!]!) {
    insert_factsheet_assessment(
      objects: $objects
      on_conflict: { constraint: factsheet_assessment_factsheet_id_assessor_id_instance_id_uq, update_columns: [] }
    ) {
      affected_rows
      returning {
        id
        user {
          first_name
          last_name
          email
          language
        }
      }
    }
  }
`

export const deleteFactsheetAssessmentMutationQuery = gql`
  mutation ($assessment_id: Int!) {
    delete_factsheet_assessment(where: { id: { _eq: $assessment_id } }) {
      affected_rows
    }
  }
`

export const getFactsheetAssessmentTypesQuery = gql`
  query fetchAssessmentTypesByProcess($process: String!) {
    assessment_instance_type(where: { process: { _eq: $process } }) {
      id
      code
      key
      sort_number
      process
    }
  }
`

export const getUsersForFactsheetAssessment = gql`
  query fetchUsers($role: String!, $status: String!) {
    internal_users: user_roles(where: { role: { _eq: $role }, user: { status: { _eq: $status } } }) {
      user {
        first_name
        last_name
        email
        id
      }
    }
  }
`

/**
 * Factsheet assessment all details tabs.
 */

export const getFactsheetAssessorForAssessment = gql`
  query ($assessmentId: Int!) {
    factsheet_assessment(where: { id: { _eq: $assessmentId } }) {
      id
      user {
        first_name
        last_name
        email
      }
    }
  }
`

/**
 * Factsheet assessment queries for Level tabs.
 */

export const getCriteriaConfigByLevelQuery = gql`
  query fetchCriteriaGroupByLevel($level: jsonb!, $assessmentId: Int!) {
    factsheet_assessment_criteria_selection(where: { factsheet_assessment_id: { _eq: $assessmentId } }) {
      id
      criteria_config_id
      rating
      criteria_config {
        criteria_id
        criteria_type
      }
    }

    criteria_group_config(where: { criteria_configs: { levels: { _contains: $level } } }, order_by: { code: asc }) {
      id
      names
      code
      criteria_configs(where: { levels: { _contains: $level } }, order_by: { criteria_type: asc, sort_number: asc }) {
        id
        criteria_id
        criteria_type
        description
        code
        active
        sort_number
      }
    }
  }
`

export const saveFactsheetAssessmentCriteriaSelection = gql`
  mutation insert_factsheet_selection(
    $assessmentId: Int!
    $selections: [factsheet_assessment_criteria_selection_insert_input!]!
  ) {
    delete_factsheet_assessment_criteria_selection(where: { factsheet_assessment_id: { _eq: $assessmentId } }) {
      affected_rows
    }

    insert_factsheet_assessment_criteria_selection(
      objects: $selections
      on_conflict: { constraint: criteria_selection_factsheet_assessment_id_criteria_config_id_u, update_columns: [] }
    ) {
      affected_rows
    }
  }
`

export const getFactsheetAssessmentLevelCommentsQuery = gql`
  query fetchFactsheetAssessmentComments(
    $id: Int!
    $isIntervention: Boolean!
    $isCommunication: Boolean!
    $isNetwork: Boolean!
    $isPolicy: Boolean!
  ) {
    factsheet_assessment_by_pk(id: $id) {
      intervention_comment @include(if: $isIntervention)
      communication_comment @include(if: $isCommunication)
      network_comment @include(if: $isNetwork)
      policy_comment @include(if: $isPolicy)
    }
  }
`

export const updateFactsheetAssessmentLevelComments = gql`
  mutation updateFactsheetAssessmentComments($id: Int!, $object: factsheet_assessment_set_input!) {
    update_factsheet_assessment(where: { id: { _eq: $id } }, _set: $object) {
      affected_rows
    }
  }
`

/**
 * Factsheet assessment queries for Summary tab.
 */

export const getFactsheetAssessmentSummaryCommentById = gql`
  query ($assessmentId: Int!) {
    factsheet_assessment(where: { id: { _eq: $assessmentId } }) {
      summary_comment
    }
  }
`

export const queryFactsheetAssessmentSummaryById = gql`
  query ($assessmentId: Int!) {
    factsheet_assessment(where: { id: { _eq: $assessmentId } }) {
      id
      summary_comment
      recommendation
      status
    }
  }
`

export const updateFactsheetAssessmentStatusById = gql`
  mutation ($assessmentId: Int!, $status: String!) {
    update_factsheet_assessment(where: { _and: { id: { _eq: $assessmentId } } }, _set: { status: $status }) {
      affected_rows
      returning {
        status
      }
    }
  }
`

export const updateFactsheetAssessmentSummaryCommentById = gql`
  mutation ($assessmentId: Int!, $comment: String!) {
    update_factsheet_assessment(where: { _and: { id: { _eq: $assessmentId } } }, _set: { summary_comment: $comment }) {
      affected_rows
      returning {
        summary_comment
      }
    }
  }
`

/**
 * Factsheet assessment queries for Recommendation tab.
 */

export const getFactsheetRecommendationById = gql`
  query ($assessmentId: Int!) {
    factsheet_assessment(where: { id: { _eq: $assessmentId } }) {
      id
      recommendation
      pros
      cons
      recommendation_comment
    }
  }
`

export const updateFactsheetAssessmentRecommendationById = gql`
  mutation ($assessmentId: Int!, $recommendation: String, $pros: String, $cons: String, $comment: String) {
    update_factsheet_assessment(
      where: { _and: { id: { _eq: $assessmentId } } }
      _set: { recommendation_comment: $comment, pros: $pros, cons: $cons, recommendation: $recommendation }
    ) {
      affected_rows
      returning {
        recommendation
      }
    }
  }
`
