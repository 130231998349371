import { Button, Card, CardActions, CardContent, CardHeader, Typography } from '@mui/material'
import Stack from '@mui/material/Stack'
import { ReactElement, ReactNode, useState } from 'react'
import { ProgramValidationResponse } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { ValidationListItemProps } from 'src/screens/shared/common/SummaryValidationUtils'
import { ProgramWorkflowService } from 'src/service/axios/ProgramWorkflowService'
import { DOSSIER_STATUS, DOSSIER_STATUS_TYPE, PROJECT, USER_ROLES_TYPE } from 'src/shared/constants/constants'
import { LIST_HEIGHT } from 'src/shared/constants/styling-constants'
import { NextState } from 'src/shared/constants/workflow-constants'
import { DeleteIcon } from 'src/shared/icons/Icons'
import { HandoverModalDialog } from 'src/shared/modal-dialog/HandoverModalDialog'
import { SecondaryConfirmationModalDialog } from 'src/shared/modal-dialog/SecondaryConfirmationModalDialog'
import { Section } from 'src/shared/presentation/Section'
import { useSidebarAPI } from 'src/shared/sidebar/SidebarAwareContext'
import { useModalCancel } from 'src/shared/utils/hooks/modal-hooks'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { NextStateIf } from 'src/shared/workflow/NextStateIf'
import { useUserLocale } from 'src/user/UserContext'
import styled from 'styled-components/macro'
import { ListItemComplete, ListItemIncomplete } from 'src/screens/shared/application/common/SummarySectionAlert'

interface ProgramSummarySectionProps {
  title: string
  status: DOSSIER_STATUS_TYPE
  helpAndInstructions: ReactNode
  actionsCompleteMessage: string
  violations: ProgramValidationResponse[]
  refetchPageData: () => void
  workflowId: string
  programId: number
  nextStates: NextState[]
  userGlobalRoles: Array<USER_ROLES_TYPE>
  canEdit: boolean
  canDeleteProgram: boolean
  violationClickHandler: (validationItem: ValidationListItemProps) => void
  noItemsToValidate: boolean
}

const ListTitle = styled(Typography)`
  height: ${LIST_HEIGHT};
  display: flex;
  align-items: center;
`

const SpanStyled = styled.span<{ $isCanceled: string }>`
  color: ${({ $isCanceled, theme }) =>
    $isCanceled === `${DOSSIER_STATUS.CANCELED}` ? theme.colors.error.dark : 'inherit'};
  line-height: 2.25rem;
`

export const ProgramSummarySection = ({
  title,
  status,
  helpAndInstructions,
  actionsCompleteMessage,
  violations,
  violationClickHandler,
  workflowId,
  programId,
  nextStates,
  userGlobalRoles,
  canEdit,
  canDeleteProgram,
  refetchPageData,
  noItemsToValidate,
}: ProgramSummarySectionProps): ReactElement => {
  const { getMessage } = useMessageSource()
  const language = useUserLocale()

  const [deleteProgramConfirmationOpen, setDeleteProgramConfirmationOpen] = useState(false)
  const [deleteProgramLoading, setDeleteProgramLoading] = useState(false)
  const [conceptReviewProgramConfirmationOpen, setConceptReviewProgramConfirmationOpen] = useState(false)
  const [conceptReworkProgramConfirmationOpen, setConceptReworkProgramConfirmationOpen] = useState(false)
  const [sendToGFCHConfirmationOpen, setSendToGFCHConfirmationOpen] = useState(false)
  const [withdrawProgramConfirmationOpen, setWithdrawProgramConfirmationOpen] = useState(false)

  const onCancel = useModalCancel(() => {
    setConceptReviewProgramConfirmationOpen(false)
    setConceptReworkProgramConfirmationOpen(false)
    setSendToGFCHConfirmationOpen(false)
  })

  const sidebarAPI = useSidebarAPI()
  const notificationService = useNotificationService()
  const navigate = useDelayedNavigate()
  const [sendingToConceptReview, setSendingToConceptReview] = useState(false)
  const [sendingToConceptRework, setSendingToConceptRework] = useState(false)
  const [sendingToGFCH, setSendingToGFCH] = useState(false)
  const [transitionToWithdraw, setTransitionToWithdraw] = useState(false)

  const onSendToGFCH = async () => {
    try {
      setSendingToGFCH(true)
      await ProgramWorkflowService.transition(workflowId, DOSSIER_STATUS.APPLICATION, language)
      refetchPageData()
      sidebarAPI.refetchSidebarData()
      navigate(ROUTES.KapApplicationDetailsRoot.params({ programId }))
      setSendToGFCHConfirmationOpen(false)
      notificationService.changesSaved()
    } catch (e) {
      notificationService.operationFailed()
    } finally {
      setSendingToGFCH(false)
    }
  }

  const onSendToConceptReview = async () => {
    try {
      setSendingToConceptReview(true)
      await ProgramWorkflowService.transition(workflowId, DOSSIER_STATUS.CONCEPT_REVIEW, language)
      refetchPageData()
      sidebarAPI.refetchSidebarData()
      setConceptReviewProgramConfirmationOpen(false)
      notificationService.changesSaved()
    } catch (e) {
      notificationService.operationFailed()
    } finally {
      setSendingToConceptReview(false)
    }
  }
  const onSendToConceptRework = async () => {
    try {
      setSendingToConceptRework(true)
      await ProgramWorkflowService.transition(workflowId, DOSSIER_STATUS.CONCEPT_REWORK, language)
      refetchPageData()
      sidebarAPI.refetchSidebarData()
      setConceptReworkProgramConfirmationOpen(false)
      notificationService.changesSaved()
    } catch (e) {
      notificationService.operationFailed()
    } finally {
      setSendingToConceptRework(false)
    }
  }

  const onConfirmWithdrawal = async () => {
    try {
      setTransitionToWithdraw(true)
      await ProgramWorkflowService.transition(workflowId, DOSSIER_STATUS.CANCELED, language)
      refetchPageData()
      sidebarAPI.refetchSidebarData()
      setWithdrawProgramConfirmationOpen(false)
      notificationService.changesSaved()
    } catch (e) {
      notificationService.operationFailed()
    } finally {
      setTransitionToWithdraw(false)
    }
  }

  const openHandoverModalDialog = (dossierStatus: DOSSIER_STATUS_TYPE) => {
    if (dossierStatus === 'CONCEPT_REVIEW') {
      setConceptReviewProgramConfirmationOpen(true)
    } else if (dossierStatus === 'CONCEPT_REWORK') {
      setConceptReworkProgramConfirmationOpen(true)
    } else if (dossierStatus === 'APPLICATION') {
      setSendToGFCHConfirmationOpen(true)
    }
  }

  const openWithdrawProgramConfirmationModalDialog = () => {
    setWithdrawProgramConfirmationOpen(true)
  }

  const onCancelWithdrawal = () => {
    setWithdrawProgramConfirmationOpen(false)
  }

  const openDeleteProgramConfirmationModalDialog = () => {
    setDeleteProgramConfirmationOpen(true)
  }

  const onCancelDelete = () => {
    setDeleteProgramConfirmationOpen(false)
  }

  const onConfirmDelete = () => {
    setDeleteProgramLoading(true)

    ProgramWorkflowService.deleteProgram(programId)
      .then(() => {
        notificationService.deleteSuccessful()
        navigate(`/${ROUTES.KapIndex.path}`)
      })
      .catch(() => {
        notificationService.operationFailed()
      })
      .finally(() => {
        setDeleteProgramLoading(false)
      })
  }

  return (
    <>
      <Section
        title={title}
        helpAndInstructions={helpAndInstructions}
        actionButton={
          canDeleteProgram && (
            <Button
              variant="contained"
              color="error"
              onClick={openDeleteProgramConfirmationModalDialog}
              startIcon={<DeleteIcon />}
            >
              {getMessage('button.kap.program.delete')}
            </Button>
          )
        }
      >
        <Card>
          <CardHeader
            title={
              <>
                {getMessage('label.project.status')}:{' '}
                <SpanStyled $isCanceled={status}>{getMessage(`label.project.status.${status}`)}</SpanStyled>
              </>
            }
            action={
              <CardActions sx={{ padding: 0 }}>
                <NextStateIf
                  forState={DOSSIER_STATUS.CANCELED}
                  nextStates={nextStates}
                  userGlobalRoles={userGlobalRoles}
                  canEditOwnCanton={canEdit}
                >
                  <Button variant="outlined" color="secondary" onClick={openWithdrawProgramConfirmationModalDialog}>
                    {getMessage('button.withdraw.program')}
                  </Button>
                </NextStateIf>
                <NextStateIf
                  forState={DOSSIER_STATUS.CONCEPT_REVIEW}
                  nextStates={nextStates}
                  userGlobalRoles={userGlobalRoles}
                  canEditOwnCanton={canEdit}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={violations.length > 0}
                    onClick={() => openHandoverModalDialog(DOSSIER_STATUS.CONCEPT_REVIEW)}
                  >
                    {getMessage('button.set.to.review')}
                  </Button>
                </NextStateIf>
                <NextStateIf
                  forState={DOSSIER_STATUS.CONCEPT_REWORK}
                  nextStates={nextStates}
                  userGlobalRoles={userGlobalRoles}
                  canEditOwnCanton={canEdit}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => openHandoverModalDialog(DOSSIER_STATUS.CONCEPT_REWORK)}
                  >
                    {getMessage('button.set.to.rework')}
                  </Button>
                </NextStateIf>
                <NextStateIf
                  forState={DOSSIER_STATUS.APPLICATION}
                  nextStates={nextStates}
                  userGlobalRoles={userGlobalRoles}
                  canEditOwnCanton={canEdit}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={violations.length > 0}
                    onClick={() => openHandoverModalDialog(DOSSIER_STATUS.APPLICATION)}
                  >
                    {getMessage('button.generate.application')}
                  </Button>
                </NextStateIf>
              </CardActions>
            }
          />
          <CardContent>
            <Stack spacing={1}>
              <ListTitle variant="h6" sx={{ ...{ display: 'none' } }}>
                {getMessage('label.project.summary.description.system.check.for.completion')}
              </ListTitle>

              {violations.length === 0 ? (
                <ListItemComplete message={actionsCompleteMessage} noItemsToValidate={noItemsToValidate} />
              ) : (
                violations?.map((violation) => {
                  const listItemsProps: ValidationListItemProps[] = []

                  const labelArguments = violation.labelArguments.map((labelArgument) => getMessage(labelArgument))
                  const message = getMessage(violation.validationViolationMessageKey, labelArguments)
                  listItemsProps.push({
                    message: message,
                    tab: violation.programDescriptionTab,
                    section: violation.section ?? undefined,
                  })

                  return listItemsProps.map((listItemProps) => {
                    return (
                      <ListItemIncomplete
                        message={listItemProps.message}
                        key={listItemProps.message}
                        onClick={() => violationClickHandler(listItemProps)}
                        onKeyPress={(event) => {
                          if (['Enter', ' '].includes(event.key)) {
                            violationClickHandler(listItemProps)
                          }
                        }}
                      />
                    )
                  })
                })
              )}
            </Stack>
          </CardContent>
        </Card>
      </Section>
      <HandoverModalDialog
        open={conceptReviewProgramConfirmationOpen}
        onSend={onSendToConceptReview}
        onCancel={onCancel}
        entityId={programId}
        loading={sendingToConceptReview}
        titleKey="label.program.send.to.concept.review.confirm.title"
        contentKey="label.program.concept.to.review.confirm.body"
        buttonLoadingKey="button.set.to.review"
        processType={PROJECT.KAP}
      />
      <HandoverModalDialog
        open={conceptReworkProgramConfirmationOpen}
        onSend={onSendToConceptRework}
        onCancel={onCancel}
        entityId={programId}
        loading={sendingToConceptRework}
        processType={PROJECT.KAP}
        buttonLoadingKey="button.set.to.rework"
        titleKey="label.program.send.to.concept.rework.confirm.title"
        contentKey="label.program.concept.to.rework.confirm.body"
      />
      <HandoverModalDialog
        open={sendToGFCHConfirmationOpen}
        onSend={onSendToGFCH}
        onCancel={onCancel}
        entityId={programId}
        loading={sendingToGFCH}
        processType={PROJECT.KAP}
        titleKey="label.program.application.confirm.title"
        contentKey="label.program.application.confirm.body"
        buttonLoadingKey="button.project.send.to.gfch"
        pdfDownloadVisible
      />
      <HandoverModalDialog
        open={withdrawProgramConfirmationOpen}
        onSend={onConfirmWithdrawal}
        onCancel={onCancelWithdrawal}
        entityId={programId}
        loading={transitionToWithdraw}
        processType={PROJECT.KAP}
        titleKey="label.program.withdraw.confirm.title"
        contentKey="label.program.withdraw.confirm.body"
        buttonLoadingKey="button.withdraw.program"
      />
      <SecondaryConfirmationModalDialog
        open={deleteProgramConfirmationOpen}
        onCancel={onCancelDelete}
        onConfirm={onConfirmDelete}
        titleKey={'label.delete.confirm.kap.program.title'}
        confirmButtonKey={'button.kap.program.delete'}
        buttonColor={'error'}
        loading={deleteProgramLoading}
      >
        {getMessage('label.delete.confirm.kap.program')}
      </SecondaryConfirmationModalDialog>
    </>
  )
}
