import { gql } from 'urql'

/**
 * Factsheet Application committee Queries.
 */
export const fetchCommitteeFactsheetDataQuery = gql`
  query fetchCommitteeData(
    $process: jsonb!
    $factsheetLevel: jsonb!
    $factsheetWhere: factsheet_bool_exp!
    $role: String
    $isEdit: Boolean! = false
    $userStatus: String!
  ) {
    committee_config(
      where: { processes: { _contains: $process }, active: { _eq: true } }
      order_by: { sort_number: asc }
    ) {
      active
      code
      descriptions
      id
      sort_number
    }

    factsheet_committee_recommendation(where: { factsheet: $factsheetWhere }) {
      id
      committee_config_id
      recommendation
      description
      committee_config {
        active
        code
        descriptions
        id
        sort_number
      }
      factsheet {
        id
      }
    }

    factsheet_application_decision(where: { factsheet: $factsheetWhere }) {
      id
      version
      pros
      cons
      final_decision
      quality_criteria
      exclusion_criteria
      decision_letter_text
      version
      factsheet {
        gfch_responsible {
          id
          email
          first_name
          last_name
        }
      }
    }

    criteria_config(where: { levels: { _contains: $factsheetLevel } }, order_by: { sort_number: asc }) {
      criteria_type
      criteria_id
      code
      active
      description
      sort_number
    }

    user(
      where: { user_roles: { _and: { role: { _eq: $role }, user: { status: { _eq: $userStatus } } } } }
      order_by: { first_name: asc, last_name: asc, email: asc }
    ) @include(if: $isEdit) {
      id
      first_name
      last_name
      email
    }
  }
`

export const saveFactsheetCommitteeDataMutationQuery = gql`
  mutation update_committee_recommendation(
    $factsheetId: Int!
    $factsheetCommitteeRecommendations: [factsheet_committee_recommendation_insert_input!]!
    $factsheetApplicationDecision: [factsheet_application_decision_insert_input!]!
    $gfchResponsibleId: Int
  ) {
    insert_factsheet_committee_recommendation(
      objects: $factsheetCommitteeRecommendations
      on_conflict: {
        constraint: factsheet_committee_recommendation_factsheet_id_committee_id_uq
        update_columns: [description, recommendation]
        where: { factsheet: { id: { _eq: $factsheetId } } }
      }
    ) {
      returning {
        id
      }
      affected_rows
    }

    update_factsheet(where: { id: { _eq: $factsheetId } }, _set: { gfch_responsible_id: $gfchResponsibleId }) {
      affected_rows
    }

    insert_factsheet_application_decision(
      objects: $factsheetApplicationDecision
      on_conflict: {
        constraint: factsheet_application_decision_factsheet_id_uq
        update_columns: [
          pros
          cons
          final_decision
          quality_criteria
          exclusion_criteria
          decision_letter_text
          version
        ]
        where: { factsheet: { id: { _eq: $factsheetId } } }
      }
    ) {
      returning {
        id
        version
      }
      affected_rows
    }
  }
`
