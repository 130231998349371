import React, { ReactElement } from 'react'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { useUserLocale } from 'src/user/UserContext'
import {
  Box,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { get } from 'lodash'
import { Query_Root } from 'src/@types/graphql'
import { CRITERIA_TYPE } from 'src/shared/constants/assessment-constants'
import { CheckIcon } from 'src/shared/icons/Icons'
import styled from 'styled-components/macro'
import {
  CRITERIA_CONFIG_PREFIX,
  CriteriaFormValues,
} from 'src/screens/factsheets/application/assessment/details/common/FactsheetAssessmentUtils'
import { CheckboxGroupField } from 'src/shared/form/control/CheckboxGroupField'
import { AutoCompleteField } from 'src/shared/form/control/AutoCompleteField'
import { Option } from 'src/shared/form/control'

const StyledChip = styled(Chip)`
  min-width: 50px;
`

interface Props {
  groupTitle: string
  mode: 'view' | 'edit'
  rows: Query_Root['criteria_config']
  values?: CriteriaFormValues | undefined
}

export const FactsheetAssessmentConfigTable = ({ groupTitle, rows, mode = 'view', values }: Props): ReactElement => {
  const { getMessage } = useMessageSource()
  const language = useUserLocale()

  const ratingOptions: Option[] = [
    { label: '++', value: '++' },
    { label: '+', value: '+' },
    { label: '-', value: '-' },
    { label: '--', value: '--' },
    { label: getMessage('label.field.not.available'), value: null },
  ]

  return (
    <Box>
      <Typography variant="h3" color="textSecondary" mb={1.5} sx={{ flexGrow: 1 }}>
        {groupTitle}
      </Typography>

      <TableContainer component={Paper}>
        <Table style={{ overflow: 'wrap' }}>
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ width: '10%' }}>
                {getMessage('label.criteria')}
              </TableCell>
              <TableCell sx={{ width: '75%' }}>{getMessage('label.question')}</TableCell>
              <TableCell align="center" sx={{ width: '15%' }}>
                {getMessage('label.rating')}
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.map((item) => (
              <TableRow key={item.id.toString()}>
                <TableCell align="center">
                  <Typography variant="subtitle2">{item.criteria_id}</Typography>
                </TableCell>

                <TableCell>{get(item.description, language, '')}</TableCell>

                {/* Actions for criteria configs ratings for EXCLUSION and QUALITY */}
                <TableCell align="center" sx={{ lineHeight: '100%' }}>
                  {mode === 'view' && values !== undefined && (
                    <>
                      {item.criteria_type === CRITERIA_TYPE.EXCLUSION &&
                        values[`${CRITERIA_CONFIG_PREFIX}${item.id}`] && <CheckIcon />}

                      {item.criteria_type === CRITERIA_TYPE.QUALITY && (
                        <StyledChip
                          label={
                            values[`${CRITERIA_CONFIG_PREFIX}${item.id}`]?.toString() ||
                            getMessage('label.field.not.available')
                          }
                        />
                      )}
                    </>
                  )}

                  {mode === 'edit' && (
                    <>
                      {item.criteria_type === CRITERIA_TYPE.EXCLUSION && (
                        <CheckboxGroupField
                          name={`${CRITERIA_CONFIG_PREFIX}${item.id}`}
                          data={{ value: `${CRITERIA_CONFIG_PREFIX}${item.id}` }}
                          $noLabel={true}
                        />
                      )}

                      {item.criteria_type === CRITERIA_TYPE.QUALITY && (
                        <AutoCompleteField
                          options={ratingOptions}
                          name={`${CRITERIA_CONFIG_PREFIX}${item.id}`}
                          label={getMessage('label.rating')}
                        />
                      )}
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
