import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Query_Root } from 'src/@types/graphql'
import { FactsheetCommitteeDecisionManagementPage } from 'src/screens/administration/round-management/details/round-information/committee-decision-management/FactsheetCommitteeDecisionManagmentPage'
import { ProjectCommitteeDecisionManagementPage } from 'src/screens/administration/round-management/details/round-information/committee-decision-management/ProjectCommitteeDecisionManagementPage'
import { getFundingRound } from 'src/screens/administration/round-management/round-management-queries'
import { PROCESS, PROCESS_TYPE } from 'src/shared/constants/constants'
import { useClient } from 'urql'

export const CommitteeDecisionManagementPage = () => {
  const { roundId } = useParams()
  const urqlClient = useClient()

  const [process, setProcess] = useState<PROCESS_TYPE>()

  useEffect(() => {
    const initData = async () => {
      const { data } = await urqlClient
        .query<{ funding_round_by_pk: Query_Root['funding_round_by_pk'] }>(getFundingRound, {
          roundId,
        })
        .toPromise()

      if (data) {
        setProcess(data.funding_round_by_pk?.process as PROCESS_TYPE)
      }
    }
    initData()
  }, [roundId, urqlClient])

  const renderComponent = (process: PROCESS_TYPE | undefined) => {
    switch (process) {
      case PROCESS.FACTSHEET:
        return <FactsheetCommitteeDecisionManagementPage />
      case PROCESS.PF_KAP:
      case PROCESS.PF_PGV:
        return <ProjectCommitteeDecisionManagementPage />
      default:
        return <></>
    }
  }
  return renderComponent(process)
}
