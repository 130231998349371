import { Box } from '@mui/material'
import { DataGridPro, GridColDef, GridRowModel, GridSortModel } from '@mui/x-data-grid-pro'
import { ReactElement, useMemo, useState } from 'react'
import { FactsheetRelatedPfKapProjectData } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { useGridTranslateHook } from 'src/shared/utils/hooks/grid-translate-hook'
import styled from 'styled-components/macro'

interface Props {
  projects: Array<FactsheetRelatedPfKapProjectData>
  canClickOnRow: boolean
}

const DataGridProStyled = styled(DataGridPro)<{ $isClickable?: boolean }>`
  & .MuiDataGrid-row {
    &:hover {
      cursor: ${({ $isClickable }) => ($isClickable ? 'pointer' : 'default')};
    }
  }
`

export const FactsheetLinkedPfKapProjectsGrid = ({ projects, canClickOnRow = true }: Props): ReactElement => {
  const { getMessage } = useMessageSource()
  const gridTranslations = useGridTranslateHook()
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'id',
      sort: 'desc',
    },
  ])

  const handleRowClick = (params: GridRowModel) => {
    if (canClickOnRow) {
      const projectId = params?.id
      // We don't use useDelayedNavigate here because the project needs to open in a new window
      window.open(ROUTES.PfKapDetailsRoot.nested.BasicInformation.params({ projectId }))
    }
  }

  const rows = useMemo(() => {
    return projects.map((projectBase) => {
      return {
        ...projectBase,
        id: projectBase.id,
        shortTitle: projectBase.shortTitle,
        applicationType: projectBase.applicationTypeKey,
        status: projectBase.state,
        modules: projectBase.modules,
      }
    })
  }, [projects])

  const columns = useMemo(() => {
    return [
      {
        field: 'id',
        headerName: getMessage('label.project.id'),
        flex: 0.1,
        renderCell: ({ row }) => `${getMessage('label.project.id.character.pf_kap')}${row.id}`,
      },
      {
        field: 'shortTitle',
        headerName: getMessage('label.short.title'),
        flex: 1,
      },
      {
        field: 'applicationType',
        headerName: getMessage('label.application_type'),
        flex: 0.3,
        renderCell: ({ value }) => `${getMessage(value)}`,
      },
      {
        field: 'status',
        headerName: getMessage('label.project.status'),
        flex: 0.2,
        renderCell: ({ value }) => `${getMessage(`label.project.status.${value}`)}`,
      },
      {
        field: 'modules',
        headerName: getMessage('label.modules'),
        renderCell: ({ row }) => {
          return row.modules?.map((m: string) => getMessage(`label.module.${m}`)).join(', ')
        },
        flex: 0.2,
      },
    ] as GridColDef[]
  }, [getMessage])

  return (
    <Box>
      <DataGridProStyled
        $isClickable={canClickOnRow}
        rows={rows}
        columns={columns}
        autoHeight
        localeText={gridTranslations}
        disableRowSelectionOnClick
        disableColumnReorder
        disableColumnFilter
        disableColumnPinning
        onRowClick={handleRowClick}
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
      />
    </Box>
  )
}
