import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useClient } from 'urql'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Mutation_Root, Query_Root } from 'src/@types/graphql'
import {
  getFactsheetAssessmentSummaryCommentById,
  updateFactsheetAssessmentSummaryCommentById,
} from 'src/screens/factsheets/application/assessment/factsheetAssessmentQueries'
import { SummaryCommentEditForm, SummaryCommentFormValues } from 'src/screens/shared/common/SummaryCommentEditForm'
import { ROUTES } from 'src/routing/routes'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { usePermissionsForFactsheetAssessmentDetails } from 'src/service/security/PermissionHook'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'

type originType = 'SAVE' | 'SAVE_AND_BACK'

export const FactsheetAssessmentSummaryCommentEditPage = (): ReactElement => {
  const { factsheetId, assessmentId } = useParams()
  const assessmentIdNumber = parseInt(assessmentId as string)

  const { getMessage } = useMessageSource()
  const navigate = useDelayedNavigate()

  const originRef = useRef<originType>('SAVE')
  const urqlClient = useClient()
  const notificationService = useNotificationService()

  const { canEdit, loading } = usePermissionsForFactsheetAssessmentDetails(assessmentIdNumber)

  const [assessmentSummaryComment, setAssessmentSummaryComment] = useState<string | null>(null)

  useEffect(() => {
    const initFormValues = async () => {
      const { data } = await urqlClient
        .query<{ factsheet_assessment: Query_Root['factsheet_assessment'] }>(getFactsheetAssessmentSummaryCommentById, {
          assessmentId,
        })
        .toPromise()

      if (data && data.factsheet_assessment[0]) {
        const assessment = data.factsheet_assessment[0]
        setAssessmentSummaryComment(assessment?.summary_comment || null)
      }
    }

    initFormValues()
  }, [assessmentId, urqlClient])

  const onSubmit = (origin: originType) => (_: any) => {
    // trigger submit event
    if (originRef) {
      originRef.current = origin
    }
    document.getElementById('edit-form')?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const onBack = () => {
    const recommendationEdit = ROUTES.FactsheetApplicationAssessmentDetailsRoot.nested.Summary

    if (recommendationEdit?.params) {
      navigate(recommendationEdit.params({ factsheetId, assessmentId }))
    }
  }

  const updateHandler = async (values: SummaryCommentFormValues) => {
    const updateObject = {
      assessmentId: assessmentId,
      comment: values.comment,
    }

    const { data, error } = await urqlClient
      .mutation<{ update_factsheet_assessment: Mutation_Root['update_factsheet_assessment'] }>(
        updateFactsheetAssessmentSummaryCommentById,
        updateObject,
      )
      .toPromise()

    if (error || data?.update_factsheet_assessment?.affected_rows !== 1) {
      notificationService.operationFailed()
    } else {
      const newComment = data.update_factsheet_assessment.returning[0].summary_comment
      setAssessmentSummaryComment(newComment || null)

      notificationService.changesSaved()
      if (originRef.current === 'SAVE_AND_BACK') {
        onBack()
      }
    }
  }

  if (!loading && !canEdit) {
    return <NotAuthorized inEditPage />
  }

  return (
    <ScreenLayout
      title={getMessage('label.title.edit.comments')}
      onBack={onBack}
      hasSecondLevelNavigation={false}
      actions={
        <>
          <SaveAndBackButton origin="header" onClick={onSubmit('SAVE_AND_BACK')} />
          <SaveButton origin="header" onClick={onSubmit('SAVE')} />
        </>
      }
    >
      {!loading && (
        <PageLayout>
          <>
            <HelpAndInstructions labelKey={`label.help.assessment.summary.comments.factsheet`} defaultExpansion />
            <SummaryCommentEditForm comment={assessmentSummaryComment} onSave={updateHandler} />
          </>
        </PageLayout>
      )}
    </ScreenLayout>
  )
}
