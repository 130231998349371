import { Box, Card, CardActions, CardContent, CardHeader, Typography } from '@mui/material'
import arrayMutators from 'final-form-arrays'
import { useRef } from 'react'
import { Form } from 'react-final-form'
import { useParams } from 'react-router'
import { Dossier } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import {
  CommitteeDecisionValues,
  useResolveCommitteeDecisionManagementData,
} from 'src/screens/administration/round-management/details/round-information/committee-decision-management/CommitteeDecisionManagementService'
import { CommitteeDecisions } from 'src/screens/shared/application/committee/common/CommitteeDecisions'
import { useUpdateCommitteeData } from 'src/screens/shared/application/committee/common/CommitteeService'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { PROJECT } from 'src/shared/constants/constants'
import { AutoCompleteField } from 'src/shared/form/control/AutoCompleteField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { createDecorators } from 'src/shared/form/utils/decorators'
import { ExternalIcon } from 'src/shared/icons/Icons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { S } from 'src/shared/styled/S'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useUserLocale } from 'src/user/UserContext'

type originType = 'SAVE' | 'SAVE_AND_BACK'
const decorator = createDecorators()

export const ProjectCommitteeDecisionManagementPage = () => {
  const navigate = useDelayedNavigate()
  const { roundId } = useParams()
  const { getMessage } = useMessageSource()
  const locale = useUserLocale()
  const originRef = useRef<originType>('SAVE')

  const {
    fundingRound,
    selectedProject,
    setSelectedProject,
    selectProjectOptions,
    initialFormValues,
    setInitialFormValues,
    optionValues,
  } = useResolveCommitteeDecisionManagementData(parseInt(roundId as string))

  const backHandler = () => {
    navigate(ROUTES.RoundManagementDetailsRoot.params({ roundId }))
  }

  const onSubmit = (origin: originType) => (_: any) => {
    // trigger submit event
    if (originRef) {
      originRef.current = origin
    }
    document
      .getElementById('committee-decision-form')
      ?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const resolveLink = (project: Dossier) => {
    const projectType = project.type
    if (projectType === PROJECT.PF_KAP) {
      const projectId = project.project_bases[0].pf_kap_projects[0].id
      return ROUTES.PfKapDetailsRoot.nested.BasicInformation.params({ projectId })
    } else if (projectType === PROJECT.PF_PGV) {
      const projectId = project.project_bases[0].pf_pgv_projects[0].id
      return ROUTES.PfPgvDetailsRoot.nested.BasicInformation.params({ projectId })
    } else {
      throw Error(`Unsupported project type ${projectType}`)
    }
  }

  const updateCommitteeData = useUpdateCommitteeData()

  const onUpdateHandler = async (values: CommitteeDecisionValues) => {
    await updateCommitteeData(
      values,
      selectedProject?.project_bases[0].id as number,
      setInitialFormValues,
      originRef,
      backHandler,
    )
  }

  const setNewlySelectedProjectById = (projectId: number) => {
    const newlySelectedProject = fundingRound?.dossiers.find((dossier) => {
      if (dossier.type === PROJECT.PF_KAP) {
        return dossier.project_bases[0].pf_kap_projects[0].id === projectId
      } else if (dossier.type === PROJECT.PF_PGV) {
        return dossier.project_bases[0].pf_pgv_projects[0].id === projectId
      } else {
        throw Error(`Unsupported type ${dossier.type}`)
      }
    })
    setSelectedProject(newlySelectedProject)
  }

  const handleSelectedProjectChange = (value: any, dirty: boolean) => {
    if (dirty) {
      if (window.confirm(getMessage('notification.form.unsaved.changes'))) {
        setNewlySelectedProjectById(parseInt(value))
        return true
      } else {
        return false
      }
    } else {
      setNewlySelectedProjectById(parseInt(value))
      return true
    }
  }

  return (
    <>
      {fundingRound && (
        <ScreenLayout
          title={`${fundingRound?.name} ${getMessage('label.decisions')}`}
          hasSecondLevelNavigation={false}
          onBack={backHandler}
          actions={
            selectedProject &&
            initialFormValues && (
              <>
                <SaveAndBackButton origin="header" onClick={onSubmit('SAVE_AND_BACK')} />
                <SaveButton origin="header" onClick={onSubmit('SAVE')} />
              </>
            )
          }
        >
          <PageLayout>
            <HelpAndInstructions labelKey="label.help.committee.decision.management" />
            <Box>
              <Form<CommitteeDecisionValues>
                initialValues={initialFormValues}
                onSubmit={onUpdateHandler}
                mutators={{ ...arrayMutators }}
                decorators={decorator}
                render={({ handleSubmit, values, dirty }) => {
                  return (
                    <form id="committee-decision-form" onSubmit={handleSubmit}>
                      <Card sx={{ marginBottom: 5 }}>
                        <CardHeader title={getMessage('label.select.project')} />
                        <CardContent sx={{ paddingBottom: 0 }}>
                          <AutoCompleteField
                            onCustomChange={(value: any) => {
                              return handleSelectedProjectChange(value, dirty)
                            }}
                            label={getMessage('label.project')}
                            name="project"
                            options={selectProjectOptions}
                            disableClearable={true}
                          />
                        </CardContent>
                        <CardActions sx={{ padding: 2 }}>
                          {selectedProject && (
                            <S.Link to={resolveLink(selectedProject)} target="_blank" $isLast>
                              <Typography variant="h5">{getMessage('label.project.link')}</Typography>
                              <ExternalIcon />
                            </S.Link>
                          )}
                        </CardActions>
                      </Card>
                      {selectedProject && initialFormValues && (
                        <CommitteeDecisions
                          locale={locale}
                          values={values}
                          qualityCriteriaOptions={optionValues.qualityCriteriaOptions}
                          exclusionCriteriaOptions={optionValues.exclusionCriteriaOptions}
                          gfchResponsibleOptions={optionValues.gfchResponsibleOptions}
                        />
                      )}
                      <DirtyFormSpy />
                    </form>
                  )
                }}
              />
            </Box>
          </PageLayout>
        </ScreenLayout>
      )}
    </>
  )
}
