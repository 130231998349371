import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import combinedQuery from 'graphql-combine-query'
import { get } from 'lodash'
import { ReactElement, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { FactsheetValidationResponse, Feature_Type_Config, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import {
  queryFactsheetComment,
  validateFactsheet,
} from 'src/screens/factsheets/factsheet/description/summary/factsheetSummaryQueries'
import { FactsheetSummarySection } from 'src/screens/factsheets/factsheet/description/summary/FactsheetSummarySection'
import { ValidationListItemProps } from 'src/screens/shared/common/SummaryValidationUtils'
import { queryFeatureTypes } from 'src/screens/shared/feature/featureBaseQueries'
import { FactsheetWorkflowService } from 'src/service/axios/FactsheetWorkflowService'
import { usePermissionsForFactsheetDetailsSummaryPage } from 'src/service/security/PermissionHook'
import { FACTSHEET_WORKFLOW_STATUS, FACTSHEET_WORKFLOW_STATUS_TYPE } from 'src/shared/constants/factsheet-constants'
import { NextStateFactsheet } from 'src/shared/constants/workflow-constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { FactsheetExportMenu } from 'src/shared/menu/FactsheetExportMenu'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { Section } from 'src/shared/presentation/Section'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useUserLocale } from 'src/user/UserContext'
import { useClient } from 'urql'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { EditButton } from 'src/shared/button/Buttons'
import { HtmlRenderer } from 'src/shared/presentation/HtmlRenderer'

export const FactsheetSummaryPage = (): ReactElement => {
  const { factsheetId } = useParams()
  const language = useUserLocale()
  const factsheet_id = parseInt(factsheetId as string)

  const { getMessage } = useMessageSource()
  const notificationService = useNotificationService()
  const urqlClient = useClient()
  const navigate = useDelayedNavigate()

  const [validationViolations, setValidationViolations] = useState<FactsheetValidationResponse[] | null>(null)
  const [factsheetFeatureTypes, setFactsheetFeatureTypes] = useState<Feature_Type_Config[]>([])
  const [nextStates, setNextStates] = useState<NextStateFactsheet[] | null>(null)
  const [factsheetSummaryComment, setFactsheetSummaryComment] = useState<string | undefined>(undefined)

  const {
    loading,
    canDelete,
    canView,
    canEdit,
    refetch,
    metadata: { factsheetLevel, factsheet_workflow_id, userFactsheetRoles, userGlobalRoles, workflowStatus, title },
  } = usePermissionsForFactsheetDetailsSummaryPage(factsheet_id)

  const fetchData = useCallback(async () => {
    const combinedQueryResult = combinedQuery('FactsheetDescriptionSummaryData')
      .add<
        { validateFactsheetDescription: Query_Root['validateFactsheetDescription'] },
        { factsheetId: number; factsheetLevel: string }
      >(validateFactsheet, {
        factsheetId: factsheet_id,
        factsheetLevel: factsheetLevel as string,
      })
      .add<{ feature_type_config: Query_Root['feature_type_config'] }>(queryFeatureTypes)
      .add<{ factsheet: Query_Root['factsheet'] }, { id: number }>(queryFactsheetComment, {
        id: factsheet_id,
      })

    const { document, variables } = combinedQueryResult

    const { data, error } = await urqlClient
      .query<{
        validateFactsheetDescription: Query_Root['validateFactsheetDescription']
        feature_type_config: Query_Root['feature_type_config']
        factsheet: Query_Root['factsheet']
      }>(document, variables)
      .toPromise()

    if (
      data &&
      data.validateFactsheetDescription &&
      factsheet_workflow_id !== null &&
      data.factsheet[0] !== undefined
    ) {
      const nextStates = await FactsheetWorkflowService.nextStates(factsheet_workflow_id)
      setNextStates(nextStates)

      const validationViolations =
        workflowStatus === FACTSHEET_WORKFLOW_STATUS.CANCELED ? [] : data.validateFactsheetDescription
      setValidationViolations(validationViolations)
      setFactsheetFeatureTypes(data.feature_type_config)
      setFactsheetSummaryComment(data.factsheet[0].comment || undefined)
    } else if (error) {
      notificationService.operationFailed()
    }
  }, [factsheet_id, urqlClient, notificationService, factsheet_workflow_id])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const getValidationCompleteMessage = () => {
    if (workflowStatus === FACTSHEET_WORKFLOW_STATUS.CANCELED) {
      return getMessage('label.factsheet.is.canceled')
    } else {
      return getMessage('label.factsheet.summary.description.completion.check.complete')
    }
  }

  const handleViolationClick = (validationItem: ValidationListItemProps) => {
    const route = validationItem.root ? validationItem.root : ROUTES.FactsheetDescriptionRoot.params({ factsheetId })

    navigate(
      `${route}${validationItem.tab ? '/' + validationItem.tab : ''}${
        validationItem.section ? `#${validationItem.section}` : ''
      }`,
    )
  }

  const handleEditComment = () => {
    navigate(ROUTES.FactsheetDescriptionRoot.nested.FactsheetSummaryCommentEdit.params({ factsheetId }))
  }

  if (!loading && !canView) {
    return <NotAuthorized inSecondLevelNavigationTab />
  }

  return (
    <ScreenLayout title={get(title, language, '')} actions={<FactsheetExportMenu />}>
      <PageLayout>
        {!loading && nextStates !== null && validationViolations !== null && (
          <>
            <FactsheetSummarySection
              factsheetId={factsheet_id}
              title={getMessage('label.factsheet.summary.title')}
              canDeleteFactsheet={canDelete}
              status={workflowStatus as FACTSHEET_WORKFLOW_STATUS_TYPE}
              nextStates={nextStates}
              helpAndInstructions={<HelpAndInstructions labelKey={`label.help.factsheet.summary`} />}
              refetchPageData={() => {
                refetch()
                fetchData()
              }}
              userGlobalRoles={userGlobalRoles}
              userFactsheetRoles={userFactsheetRoles}
              workflowId={factsheet_workflow_id as string}
              violations={validationViolations}
              noItemsToValidate={workflowStatus === FACTSHEET_WORKFLOW_STATUS.CANCELED}
              featureTypes={factsheetFeatureTypes}
              violationClickHandler={handleViolationClick}
              actionsCompleteMessage={getValidationCompleteMessage()}
            />

            <Section
              title={getMessage('label.summary.comments')}
              helpAndInstructions={<HelpAndInstructions labelKey="label.help.factsheet.summary.comments" />}
              actionButton={<EditButton onClick={handleEditComment} hidden={!canEdit} />}
            >
              {factsheetSummaryComment && (
                <Card>
                  <CardContent>
                    <HtmlRenderer html={factsheetSummaryComment} />
                  </CardContent>
                </Card>
              )}
            </Section>
          </>
        )}
      </PageLayout>
    </ScreenLayout>
  )
}
