import { Box, Card, CardContent, Chip, Typography } from '@mui/material'
import { get } from 'lodash'
import { ReactElement } from 'react'
import { useParams } from 'react-router'
import { valueof } from 'src/@types/global'
import { Factsheet, Maybe, Query_Root, User } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { fetchFactsheetByIdQuery } from 'src/screens/factsheets/factsheet/factsheetQueries'
import { usePermissionsForFactsheetDetailsDescriptionPage } from 'src/service/security/PermissionHook'
import { EditButton } from 'src/shared/button/Buttons'
import { FACTSHEET_USER_ROLE_TYPE, FACTSHEET_USER_ROLES, USER_STATUS } from 'src/shared/constants/constants'
import { FACTSHEET_DESCRIPTION_EDIT_OPTION, FACTSHEET_WORKFLOW_STATUS } from 'src/shared/constants/factsheet-constants'
import { TYPOGRAPHY_MIXIN } from 'src/shared/constants/styling-constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { FactsheetExportMenu } from 'src/shared/menu/FactsheetExportMenu'
import { DefaultSectionTypography } from 'src/shared/presentation/DefaultSectionTypography'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { HtmlRenderer } from 'src/shared/presentation/HtmlRenderer'
import { ReadOnlySelection, ReadOnlyTextField } from 'src/shared/presentation/ReadOnly'
import { Section } from 'src/shared/presentation/Section'
import { S } from 'src/shared/styled/S'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useUser, useUserLocale } from 'src/user/UserContext'
import styled from 'styled-components/macro'
import { useQuery } from 'urql'

const IdChip = styled(Chip)`
  cursor: pointer;
  min-width: 3rem;

  &.MuiChip-filledDefault {
    background-color: ${({ theme }) => theme.colors.action.hover};
    color: ${({ theme }) => theme.colors.text.disabled};
  }

  & .MuiChip-label {
    ${TYPOGRAPHY_MIXIN.subtitle2};
  }
`

export const FactsheetDescriptionPage = (): ReactElement => {
  const { factsheetId } = useParams()
  const { getMessage } = useMessageSource()
  const factsheet_id = parseInt(factsheetId as string)
  const { user } = useUser()
  const userRoles = user.roles

  const notificationService = useNotificationService()
  const language = useUserLocale()
  const navigate = useDelayedNavigate()

  const [{ data, error, fetching }] = useQuery<{
    factsheet_by_pk: Query_Root['factsheet_by_pk']
  }>({
    query: fetchFactsheetByIdQuery,
    variables: { id: parseInt(factsheetId as string) },
  })
  const {
    canEdit,
    loading,
    metadata: { userFactsheetRoles },
  } = usePermissionsForFactsheetDetailsDescriptionPage(factsheet_id)

  if (error) {
    notificationService.operationFailed()
  }

  const factsheet = data?.factsheet_by_pk

  const getFactsheetAttributeByLanguage = (attribute: any): any => {
    return attribute?.[language]
  }

  const handleEditDescription = (field: string) => {
    const route = ROUTES.FactsheetDescriptionRoot.params({ factsheetId })
    navigate(`${route}/${field}/edit`)
  }

  const relatedFactsheetClickHandler = (factsheetId: number) => () => {
    window.open(ROUTES.FactsheetDescriptionRoot.nested.FactsheetDescription.params({ factsheetId }))
  }

  const renderLanguageAttribute = (field: string) => {
    return (
      getFactsheetAttributeByLanguage(field) && (
        <Card>
          <CardContent>
            <HtmlRenderer html={getFactsheetAttributeByLanguage(field)} />
          </CardContent>
        </Card>
      )
    )
  }

  const getUserText = (user: Maybe<User> | undefined) => {
    if (!user) {
      return ''
    } else if (user.status === USER_STATUS.ACTIVE) {
      return `${user.first_name} ${user.last_name}, ${user.email}`
    } else {
      return `${getMessage('label.user.unknown')}, ${user.email}`
    }
  }

  const hasCollaboratorFactsheetRole = (factsheetUserRoles: Array<valueof<FACTSHEET_USER_ROLE_TYPE>>): boolean => {
    return [FACTSHEET_USER_ROLES.ADMIN, FACTSHEET_USER_ROLES.CONTRIBUTOR, FACTSHEET_USER_ROLES.READER].some((r) =>
      factsheetUserRoles.includes(r),
    )
  }

  const showProjectLead = (factsheet: Factsheet): boolean => {
    if (
      factsheet.workflow_status === FACTSHEET_WORKFLOW_STATUS.CONCEPT ||
      factsheet.workflow_status === FACTSHEET_WORKFLOW_STATUS.REVISION ||
      factsheet.workflow_status === FACTSHEET_WORKFLOW_STATUS.ACCEPTED
    ) {
      if (Utils.isExternalRole(userRoles) && !hasCollaboratorFactsheetRole(userFactsheetRoles)) {
        return false
      }

      if (Utils.isCantonalRole(user.roles)) {
        return false
      }
    }

    return true
  }

  return (
    <ScreenLayout
      title={get(factsheet?.title, language, '')}
      hasSecondLevelNavigation={true}
      actions={<FactsheetExportMenu />}
    >
      <PageLayout>
        <>
          {!loading && !fetching && factsheet && (
            <>
              {/* Basic information */}
              <Section
                id={FACTSHEET_DESCRIPTION_EDIT_OPTION.BASIC_INFO}
                title={getMessage('label.factsheet.basic.information')}
                actionButton={
                  <EditButton
                    origin="content"
                    color="primary"
                    onClick={() => handleEditDescription(FACTSHEET_DESCRIPTION_EDIT_OPTION.BASIC_INFO)}
                    hidden={!canEdit}
                  />
                }
                helpAndInstructions={<HelpAndInstructions labelKey="label.help.factsheet.basic.information" />}
              >
                <Card>
                  <CardContent>
                    <Box>
                      <ReadOnlyTextField text={getMessage('label.factsheet.id')}>
                        {`${getMessage('label.factsheet.id.character')}${factsheet.id}`}
                      </ReadOnlyTextField>
                      <ReadOnlySelection text={getMessage('label.factsheet.type')}>
                        {factsheet.application_type &&
                          getMessage(
                            `label.application_type.factsheet.${factsheet.application_type.code.toLowerCase()}`,
                          )}
                      </ReadOnlySelection>
                      <ReadOnlyTextField text={getMessage('label.level')}>
                        {getMessage(`label.level.${factsheet.level}`)}
                      </ReadOnlyTextField>
                      <ReadOnlySelection text={getMessage('label.modules')}>
                        {factsheet.modules &&
                          factsheet?.modules.map((module: any) => (
                            <Typography key={module}>{getMessage(`label.module.description.${module}`)}</Typography>
                          ))}
                      </ReadOnlySelection>
                      <ReadOnlyTextField text={getMessage('label.factsheet.published')}>
                        {getMessage(`label.factsheet.published.${factsheet.published}`)}
                      </ReadOnlyTextField>
                      <ReadOnlyTextField text={getMessage('label.factsheet.version')}>
                        {factsheet.version}
                      </ReadOnlyTextField>
                      <ReadOnlyTextField text={getMessage('label.organization')}>
                        {factsheet.organization}
                      </ReadOnlyTextField>
                      <ReadOnlyTextField text={getMessage('label.contact')}>
                        {factsheet?.contact && <HtmlRenderer html={factsheet?.contact} />}
                      </ReadOnlyTextField>
                      {showProjectLead(factsheet) && (
                        <ReadOnlyTextField text={getMessage('label.project.leader')} isLast>
                          {getUserText(factsheet.project_leader_user)}
                        </ReadOnlyTextField>
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </Section>

              {/* Title and description */}
              <Section
                id={FACTSHEET_DESCRIPTION_EDIT_OPTION.TITLE_AND_DESCRIPTION}
                title={getMessage('label.title.and.description')}
                actionButton={
                  <EditButton
                    origin="content"
                    color="primary"
                    onClick={() => handleEditDescription(FACTSHEET_DESCRIPTION_EDIT_OPTION.TITLE_AND_DESCRIPTION)}
                    hidden={!canEdit}
                  />
                }
                helpAndInstructions={<HelpAndInstructions labelKey="label.help.factsheet.title_and_description" />}
              >
                <Card>
                  <CardContent>
                    <ReadOnlyTextField text={getMessage('label.title')}>
                      {getFactsheetAttributeByLanguage(factsheet.title)}
                    </ReadOnlyTextField>
                    <ReadOnlyTextField text={getMessage('label.description')} isLast>
                      {getFactsheetAttributeByLanguage(factsheet.description) && (
                        <HtmlRenderer html={getFactsheetAttributeByLanguage(factsheet.description)} />
                      )}
                    </ReadOnlyTextField>
                  </CardContent>
                </Card>
              </Section>

              {/* Factsheet copied */}
              <Section
                title={getMessage('label.factsheet.original')}
                helpAndInstructions={<HelpAndInstructions labelKey="label.help.factsheet.original" />}
                actionButton={<></>}
              >
                {factsheet.original_factsheet ? (
                  <S.Card.Container>
                    <S.Card.Content
                      onClick={relatedFactsheetClickHandler(factsheet.original_factsheet.id)}
                      tabIndex={0}
                      onKeyDown={(event: { key: string }) => {
                        if (['Enter', ' '].includes(event.key)) {
                          relatedFactsheetClickHandler(factsheet.original_factsheet!.id)()
                        }
                      }}
                    >
                      <IdChip
                        size="small"
                        label={`${getMessage('label.factsheet.id.character')}${factsheet.original_factsheet.id}`}
                      />
                      <Typography variant="subtitle2">
                        {getFactsheetAttributeByLanguage(factsheet.original_factsheet.title)}
                      </Typography>
                    </S.Card.Content>
                  </S.Card.Container>
                ) : (
                  <S.Card.Container>
                    <S.Card.Content>
                      <DefaultSectionTypography
                        noEntriesMessageKey={getMessage('label.no.original.factsheets')}
                        $standAlone={true}
                      />
                    </S.Card.Content>
                  </S.Card.Container>
                )}
              </Section>

              <Section
                id={FACTSHEET_DESCRIPTION_EDIT_OPTION.IMPORTANT_INSIGHTS}
                title={getMessage('label.factsheet.important_insights')}
                actionButton={
                  <EditButton
                    origin="content"
                    color="primary"
                    onClick={() => handleEditDescription(FACTSHEET_DESCRIPTION_EDIT_OPTION.IMPORTANT_INSIGHTS)}
                    hidden={!canEdit}
                  />
                }
                helpAndInstructions={<HelpAndInstructions labelKey="label.help.factsheet.important_insights" />}
              >
                {renderLanguageAttribute(factsheet.important_insights)}
              </Section>

              <Section
                id={FACTSHEET_DESCRIPTION_EDIT_OPTION.STRENGTH}
                title={getMessage('label.factsheet.strength')}
                actionButton={
                  <EditButton
                    origin="content"
                    color="primary"
                    onClick={() => handleEditDescription(FACTSHEET_DESCRIPTION_EDIT_OPTION.STRENGTH)}
                    hidden={!canEdit}
                  />
                }
                helpAndInstructions={<HelpAndInstructions labelKey="label.help.factsheet.strength" />}
              >
                {renderLanguageAttribute(factsheet.strength)}
              </Section>

              <Section
                id={FACTSHEET_DESCRIPTION_EDIT_OPTION.FURTHER_INFORMATION}
                title={getMessage('label.factsheet.further_information')}
                actionButton={
                  <EditButton
                    origin="content"
                    color="primary"
                    onClick={() => handleEditDescription(FACTSHEET_DESCRIPTION_EDIT_OPTION.FURTHER_INFORMATION)}
                    hidden={!canEdit}
                  />
                }
                helpAndInstructions={<HelpAndInstructions labelKey="label.help.factsheet.further_information" />}
              >
                {renderLanguageAttribute(factsheet.further_information)}
              </Section>

              <Section
                id={FACTSHEET_DESCRIPTION_EDIT_OPTION.EVIDENCE_OF_EFFECTIVENESS}
                title={getMessage('label.factsheet.evidence_of_effectiveness')}
                actionButton={
                  <EditButton
                    origin="content"
                    color="primary"
                    onClick={() => handleEditDescription(FACTSHEET_DESCRIPTION_EDIT_OPTION.EVIDENCE_OF_EFFECTIVENESS)}
                    hidden={!canEdit}
                  />
                }
                helpAndInstructions={<HelpAndInstructions labelKey="label.help.factsheet.evidence_of_effectiveness" />}
              >
                {renderLanguageAttribute(factsheet.evidence_of_effectiveness)}
              </Section>
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
