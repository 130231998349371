import { Alert, AlertTitle, Box, DialogActions, DialogContent, Stack } from '@mui/material'
import { FormApi } from 'final-form'
import { ReactElement, useContext, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { Application_Type, Mutation_Root, Project_User_Arr_Rel_Insert_Input } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { PrimaryButton, SecondaryButton } from 'src/shared/button/Buttons'
import {
  DOSSIER_STATUS,
  DOSSIER_STATUS_TYPE,
  LIFE_PHASE_TYPES,
  PF_KAP_LIFE_PHASES,
  PROJECT,
  PROJECT_TYPE,
  PROJECT_USER_ROLES,
  TEXT_LENGTH,
} from 'src/shared/constants/constants'
import { AutoCompleteField } from 'src/shared/form/control/AutoCompleteField'
import { MultiSelectField } from 'src/shared/form/control/MultiSelectField'
import { TextField } from 'src/shared/form/control/TextField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { createDecorators } from 'src/shared/form/utils/decorators'
import { composeValidators, maxChar, required } from 'src/shared/form/validation/validators'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { UserContext } from 'src/user/UserContext'
import { gql, useClient } from 'urql'

const addDossier = gql`
  mutation (
    $title: String!
    $status: String!
    $type: String!
    $projectLeaderId: Int
    $shortTitle: String!
    $applicationTypeId: Int!
    $modules: jsonb
    $userAdmin: project_user_arr_rel_insert_input
  ) {
    insert_dossier(
      objects: {
        title: $title
        status: $status
        type: $type
        short_title: $shortTitle
        project_bases: {
          data: {
            feature_base: { data: {} }
            project_users: $userAdmin
            project_leader_id: $projectLeaderId
            pf_kap_projects: { data: { modules: $modules, application_type_id: $applicationTypeId } }
          }
        }
      }
    ) {
      affected_rows
      returning {
        id
        project_bases {
          pf_kap_projects {
            id
          }
        }
      }
    }
  }
`

interface AddDossierTypes {
  title: string
  status: DOSSIER_STATUS_TYPE
  type: PROJECT_TYPE
  projectLeaderId: number | null
  shortTitle: string
  applicationTypeId: number
  modules: LIFE_PHASE_TYPES[]
}

interface FormValues {
  projectLeaderId: number | null
  title: string
  type: PROJECT_TYPE
  status: DOSSIER_STATUS_TYPE
  shortTitle: string
  applicationTypeId: number
  modules: LIFE_PHASE_TYPES[]
}

const initialValuesFactory = (userId: number, isInternalRole: boolean): FormValues => {
  return {
    projectLeaderId: isInternalRole ? null : userId,
    type: PROJECT.PF_KAP,
    status: DOSSIER_STATUS.CONCEPT,
    title: '',
    shortTitle: '',
    applicationTypeId: 0,
    modules: [],
  }
}

interface PfKapCreateProjectModalDialogProps {
  onCancel: () => void
  onSuccess: (projectId: number) => void
  activeApplicationTypes: Application_Type[]
}

const decorators = createDecorators()

export const PfKapCreateProjectModalDialog = ({
  onCancel,
  onSuccess,
  activeApplicationTypes,
}: PfKapCreateProjectModalDialogProps): ReactElement => {
  const [initialValues, setInitialValues] = useState<FormValues | null>(null)
  const { user } = useContext(UserContext)
  const email = user.email
  const notificationService = useNotificationService()
  const urqlClient = useClient()
  const { getMessage } = useMessageSource()
  const type = PROJECT.PF_KAP
  const modulesArray = PF_KAP_LIFE_PHASES

  const [applicationTypeOptions, setApplicationTypeOptions] = useState<{ label: string; value: string | number }[]>([])
  const [moduleTypesOptions, setModuleTypesOptions] = useState<{ label: string; value: string | number }[]>([])

  useEffect(() => {
    const initializeFormValues = async () => {
      setApplicationTypeOptions(
        activeApplicationTypes.map((applicationType) => ({
          label: getMessage(applicationType.key),
          value: applicationType.id,
        })),
      )

      const moduleTypesOptions = modulesArray.map((module: any) => ({
        label: `${getMessage(`label.module.description.${module}`)}`,
        value: module,
      }))

      setModuleTypesOptions(moduleTypesOptions)

      const { id } = user
      const isInternalRole = Utils.isInternalRole(user.roles)
      const initialValues = initialValuesFactory(id, isInternalRole)
      setInitialValues(initialValues)
    }

    initializeFormValues()
  }, [email, urqlClient, getMessage, type, user, modulesArray, activeApplicationTypes])

  const handleSubmitLocal = async (values: FormValues, _: FormApi<FormValues>): Promise<any> => {
    values = {
      ...values,
      modules: Utils.sortModules(values.modules) as LIFE_PHASE_TYPES[],
    }

    const isInternal = Utils.isInternalRole(user.roles)

    const userAdmin: Project_User_Arr_Rel_Insert_Input = isInternal
      ? {
          data: [],
        }
      : {
          data: [{ type: PROJECT_USER_ROLES.ADMIN, user_id: user.id }],
        }

    const { data } = await urqlClient
      .mutation<
        { insert_dossier: Mutation_Root['insert_dossier'] },
        AddDossierTypes & { userAdmin?: Project_User_Arr_Rel_Insert_Input }
      >(addDossier, {
        ...values,
        userAdmin,
      })
      .toPromise()

    const projectId = data?.insert_dossier?.returning?.[0].project_bases?.[0].pf_kap_projects?.[0].id

    if (data && projectId) {
      setInitialValues(values)
      notificationService.changesSaved()
      onSuccess(projectId)
    } else {
      notificationService.operationFailed()
    }
  }

  const changeApplicationTypeIsDisabled = !Utils.isGFCHCoordinatorRoleForProcess(type, user.roles)

  return (
    <>
      <Box>
        {initialValues && (
          <Form<FormValues>
            initialValues={initialValues}
            onSubmit={handleSubmitLocal}
            decorators={decorators}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} noValidate>
                <DialogContent>
                  <Stack spacing={2}>
                    <TextField
                      required
                      label={getMessage('label.title')}
                      name="title"
                      validate={composeValidators(required(), maxChar(TEXT_LENGTH.M))}
                    />
                    <TextField
                      required
                      label={getMessage('label.short.title')}
                      name="shortTitle"
                      validate={composeValidators(required(), maxChar(TEXT_LENGTH.S))}
                    />
                    {changeApplicationTypeIsDisabled && (
                      <Alert severity="warning">
                        <AlertTitle>{getMessage('label.warning')}</AlertTitle>
                        {getMessage('label.warning.application.types.modules')}
                      </Alert>
                    )}
                    <MultiSelectField
                      options={moduleTypesOptions}
                      name="modules"
                      label={getMessage('label.modules')}
                      validate={required()}
                    />
                    <AutoCompleteField
                      required
                      label={getMessage('label.application_type.types')}
                      name="applicationTypeId"
                      options={applicationTypeOptions}
                      validate={required()}
                    />
                    <DirtyFormSpy />
                  </Stack>
                </DialogContent>
                <DialogActions>
                  <SecondaryButton messageKey="button.close" onClick={onCancel} />
                  <PrimaryButton messageKey="button.submit" type="submit" />
                </DialogActions>
              </form>
            )}
          />
        )}
      </Box>
    </>
  )
}
