import { useEffect, useState } from 'react'
import { Dossier, Factsheet, Funding_Round, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import {
  getFundingRoundByIdAndAssignedFactsheets,
  getFundingRoundByIdAndAssignedProjects,
} from 'src/screens/administration/round-management/round-management-queries'
import { useResolveFactsheetCommitteeData } from 'src/screens/factsheets/application/committee/common/FactsheetCommitteeService'
import {
  CommitteeConfigValues,
  ProjectApplicationDecisionValues,
} from 'src/screens/shared/application/committee/ApplicationCommiteeUtils'
import { useResolveCommitteeData } from 'src/screens/shared/application/committee/common/CommitteeService'
import { COMMITTEE_FORM_MODE, LEVEL_TYPE, PROCESS, PROJECT, PROJECT_TYPE } from 'src/shared/constants/constants'
import { Option } from 'src/shared/form/control'
import { useUserLocale } from 'src/user/UserContext'
import { useClient } from 'urql'

export interface CommitteeDecisionValues {
  project: number
  projectApplicationDecision: ProjectApplicationDecisionValues
  committees: Array<CommitteeConfigValues>
  projectBaseId: number | null
  version: number | null
}

export const useResolveCommitteeDecisionManagementData = (roundId: number) => {
  const urqlClient = useClient()
  const { getMessage } = useMessageSource()
  const [fundingRound, setFundingRound] = useState<Funding_Round | null>()
  const [selectedProject, setSelectedProject] = useState<Dossier>()
  const [selectProjectOptions, setSelectProjectOptions] = useState<Option[]>([])

  useEffect(() => {
    const initData = async () => {
      const { data } = await urqlClient
        .query<{ funding_round_by_pk: Query_Root['funding_round_by_pk'] }>(getFundingRoundByIdAndAssignedProjects, {
          roundId,
        })
        .toPromise()

      const selectProjectOptions: Option[] = []

      if (data) {
        const process = data.funding_round_by_pk?.process
        if (process === PROJECT.PF_KAP) {
          data.funding_round_by_pk?.dossiers.forEach((dossier) => {
            const project = dossier.project_bases[0].pf_kap_projects[0]
            selectProjectOptions.push({
              label: `${getMessage('label.project.id.character.pf_kap')}${project.id} ${dossier.title}`,
              value: project.id,
            })
          })
        } else if (process === PROJECT.PF_PGV) {
          data.funding_round_by_pk?.dossiers.forEach((dossier) => {
            const project = dossier.project_bases[0].pf_pgv_projects[0]
            selectProjectOptions.push({
              label: `${getMessage('label.project.id.character.pf_pgv')}${project.id} ${dossier.title}`,
              value: project.id,
            })
          })
        } else {
          throw Error(`Unsupported process ${process}`)
        }

        setSelectProjectOptions(selectProjectOptions)
        setSelectedProject(data.funding_round_by_pk?.dossiers[0])
        setFundingRound(data?.funding_round_by_pk)
      }
    }
    initData()
  }, [roundId, urqlClient, getMessage])

  const { initialFormValues, optionValues, setInitialFormValues } = useResolveCommitteeData(
    selectedProject?.type === PROJECT.PF_KAP
      ? selectedProject?.project_bases[0]?.pf_kap_projects?.[0].id
      : selectedProject?.type === PROJECT.PF_PGV
      ? selectedProject?.project_bases[0]?.pf_pgv_projects?.[0].id
      : undefined,
    selectedProject?.project_bases[0].id ?? null,
    selectedProject?.type as PROJECT_TYPE,
    true,
    COMMITTEE_FORM_MODE.ROUND_MANAGEMENT_COMMITTEE,
  )

  return {
    fundingRound,
    selectedProject,
    setSelectedProject,
    selectProjectOptions,
    initialFormValues,
    setInitialFormValues,
    optionValues,
  }
}

export const useResolveCommitteeDecisionManagementDataForFactsheet = (roundId: number) => {
  const urqlClient = useClient()
  const { getMessage } = useMessageSource()
  const locale = useUserLocale()

  const [fundingRound, setFundingRound] = useState<Funding_Round | null>()
  const [selectedFactsheet, setSelectedFactsheet] = useState<Factsheet>()
  const [selectedFactsheetOptions, setSelectedFactsheetOptions] = useState<Option[]>([])

  useEffect(() => {
    const initData = async () => {
      const { data } = await urqlClient
        .query<{ funding_round_by_pk: Query_Root['funding_round_by_pk'] }>(getFundingRoundByIdAndAssignedFactsheets, {
          roundId,
          locale,
        })
        .toPromise()

      const selectFactsheetOptions: Option[] = []

      if (data) {
        data.funding_round_by_pk?.factsheets.forEach((factsheet) => {
          selectFactsheetOptions.push({
            label: `${getMessage('label.factsheet.id.character')}${factsheet.id} ${factsheet.title}`,
            value: factsheet.id,
          })
        })

        setSelectedFactsheetOptions(selectFactsheetOptions)
        setSelectedFactsheet(data.funding_round_by_pk?.factsheets[0])
        setFundingRound(data?.funding_round_by_pk)
      }
    }
    initData()
  }, [roundId, urqlClient, getMessage, locale])

  const { initialFormValues, optionValues, setInitialFormValues } = useResolveFactsheetCommitteeData(
    selectedFactsheet?.id,
    PROCESS.FACTSHEET,
    (selectedFactsheet?.level as LEVEL_TYPE) ?? undefined,
    true,
  )

  return {
    fundingRound,
    selectedFactsheet,
    setSelectedFactsheet,
    selectedFactsheetOptions,
    initialFormValues,
    setInitialFormValues,
    optionValues,
  }
}
