import { ComposePaths, ExtractRouteParams, withParams } from 'src/routing/routing-utils'
import { PfKapAssessmentCriteriaPage } from 'src/screens/pf-kap/application/assessment/details/assessment-criteria/PfKapAssessmentCriteriaPage'
import { PfKapExclusionPage } from 'src/screens/pf-kap/application/assessment/details/exclusion/PfKapExclusionPage'
import { PfKapAssessmentDetailsIndexPage } from 'src/screens/pf-kap/application/assessment/details/PfKapAssessmentDetailsIndexPage'
import { PfKapRecommendationPage } from 'src/screens/pf-kap/application/assessment/details/recommendation/PfKapRecommendationPage'
import { PfKapAssessmentDetailsSummaryPage } from 'src/screens/pf-kap/application/assessment/details/summary/PfKapAssessmentDetailsSummaryPage'
import { PfKapApplicationAssessmentIndexPage } from 'src/screens/pf-kap/application/assessment/PfKapApplicationAssessmentIndexPage'
import { PfKapApplicationCommitteePage } from 'src/screens/pf-kap/application/committee/PfKapApplicationCommitteePage'
import { PfKapApplicationInformationPage } from 'src/screens/pf-kap/application/details/application-information/PfKapApplicationInformationPage'
import { PfKapApplicationDetailsIndexPage } from 'src/screens/pf-kap/application/details/PfKapApplicationDetailsIndexPage'
import { PfKapApplicationSummaryPage } from 'src/screens/pf-kap/application/details/summary/PfKapApplicationSummaryPage'
import { PfKapImplementationMilestonePage } from 'src/screens/pf-kap/implementation/details/milestone/PfKapImplementationMilestonePage'
import { PfKapImplementationDetailsIndexPage } from 'src/screens/pf-kap/implementation/details/PfKapImplementationDetailsIndexPage'
import { PfKapImplementationDetailsSummaryPage } from 'src/screens/pf-kap/implementation/details/summary/PfKapImplementationDetailsSummaryPage'
import { PfKapIndexPage } from 'src/screens/pf-kap/index/PfKapIndexPage'
import { PfKapBackgroundInformationPage } from 'src/screens/pf-kap/project/details/background-information/PfKapBackgroundInformationPage'
import { PfKapBasicInformationEditPage } from 'src/screens/pf-kap/project/details/basic-information/edit/PfKapBasicInformationEditPage'
import { PfKapBasicInformationPage } from 'src/screens/pf-kap/project/details/basic-information/PfKapBasicInformationPage'
import { PfKapEvaluationEditPage } from 'src/screens/pf-kap/project/details/effectivity-management/edit/PfKapEvaluationEditPage'
import { PfKapEvaluationMilestoneCreatePage } from 'src/screens/pf-kap/project/details/effectivity-management/edit/PfKapEvaluationMilestoneCreatePage'
import { PfKapEvaluationMilestoneEditPage } from 'src/screens/pf-kap/project/details/effectivity-management/edit/PfKapEvaluationMilestoneEditPage'
import { PfKapEffectivityManagementPage } from 'src/screens/pf-kap/project/details/effectivity-management/PfKapEffectivityManagementPage'
import { PfKapGoalsAndMeasuresPage } from 'src/screens/pf-kap/project/details/goals-and-measures/PfKapGoalsAndMeasuresPage'
import { PfKapProjectDetailsIndexPage } from 'src/screens/pf-kap/project/details/PfKapProjectDetailsIndexPage'
import { PfKapProjectFeaturesPage } from 'src/screens/pf-kap/project/details/project-features/PfKapProjectFeaturesPage'
import { PfKapStartingPointAndVisionPage } from 'src/screens/pf-kap/project/details/starting-point-and-vision/PfKapStartingPointAndVisionPage'
import { PfKapProjectJournalCreatePage } from 'src/screens/pf-kap/project/journal/edit/PfKapProjectJournalCreatePage'
import { PfKapProjectJournalEditPage } from 'src/screens/pf-kap/project/journal/edit/PfKapProjectJournalEditPage'
import { PfKapProjectJournalPage } from 'src/screens/pf-kap/project/journal/PfKapProjectJournalPage'
import { PfKapPermissionsPage } from 'src/screens/pf-kap/project/permissions/PfKapPermissionsPage'
import { ApplicationAssessmentCriteriaEditPage } from 'src/screens/shared/application/assessment/details/assessment-criteria/edit/ApplicationAssessmentCriteriaEditPage'
import { ApplicationAssessmentCommentEditPage } from 'src/screens/shared/application/assessment/details/comment/ApplicationAssessmentCommentEditPage'
import { ApplicationAssessmentExclusionEditPage } from 'src/screens/shared/application/assessment/details/exclusion/edit/ApplicationAssessmentExclusionEditPage'
import { ApplicationAssessmentRecommendationEditPage } from 'src/screens/shared/application/assessment/details/recommendation/edit/ApplicationAssessmentRecommendationEditPage'
import { ApplicationAssessmentSummaryCommentEditPage } from 'src/screens/shared/application/assessment/details/summary/edit/ApplicationAssessmentSummaryCommentEditPage'
import { ApplicationCommitteeEditPage } from 'src/screens/shared/application/committee/edit-committee/ApplicationCommitteeEditPage'
import { ApplicationInformationEditPage } from 'src/screens/shared/application/details/application-information/edit/ApplicationInformationEditPage'
import { ApplicationSummaryEditPage } from 'src/screens/shared/application/details/summary/edit/ApplicationSummaryEditPage'
import { MilestoneGoalEditPage } from 'src/screens/shared/implementation/details/milestone/details/annual/goal/edit/MilestoneGoalEditPage'
import { MilestoneFeatureKpiEditPage } from 'src/screens/shared/implementation/details/milestone/details/annual/kpi/edit/MilestoneFeatureKpiEditPage'
import { MilestoneFeatureKpiPage } from 'src/screens/shared/implementation/details/milestone/details/annual/kpi/MilestoneFeatureKpiPage'
import { MilestoneMeasureEditPage } from 'src/screens/shared/implementation/details/milestone/details/annual/measure/edit/MilestoneMeasureEditPage'
import { MilestoneMeasureCreatePage } from 'src/screens/shared/implementation/details/milestone/details/annual/measure/MilestoneMeasureCreatePage'
import { MilestonesGoalsAndMeasuresPage } from 'src/screens/shared/implementation/details/milestone/details/annual/MilestoneGoalsAndMeasuresPage'
import { MilestoneConditionsEditPage } from 'src/screens/shared/implementation/details/milestone/details/conditions/edit/MilestoneConditionsEditPage'
import { MilestoneConditionsPage } from 'src/screens/shared/implementation/details/milestone/details/conditions/MilestoneConditionsPage'
import { MilestoneDetailsEditPage } from 'src/screens/shared/implementation/details/milestone/details/default-milestone/edit/MilestoneDetailsEditPage'
import { MilestoneDetailsPage } from 'src/screens/shared/implementation/details/milestone/details/default-milestone/MilestoneDetailsPage'
import { DefaultMilestoneIndex } from 'src/screens/shared/implementation/details/milestone/details/DefaultMilestoneIndex'
import { MilestoneEvaluationEditPage } from 'src/screens/shared/implementation/details/milestone/details/evaluation/edit/MilestoneEvaluationEditPage'
import { MilestoneEvaluationPage } from 'src/screens/shared/implementation/details/milestone/details/evaluation/MilestoneEvaluationPage'
import { MilestoneAssessmentCriteriaEditPage } from 'src/screens/shared/implementation/details/milestone/details/final/assessment-criteria/edit/MilestoneAssessmentCriteriaEditPage'
import { MilestoneAssessmentCriteriaPage } from 'src/screens/shared/implementation/details/milestone/details/final/assessment-criteria/MilestoneAssessmentCriteriaPage'
import { MilestoneAssessmentCommentEditPage } from 'src/screens/shared/implementation/details/milestone/details/final/comment/MilestoneAssessmentCommentEditPage'
import { QualitativeEvaluationEditPage } from 'src/screens/shared/implementation/details/milestone/details/final/qualitative-evaluation/edit/QualitativeEvaluationEditPage'
import { QualitativeEvaluationSWOTAnalysisEditPage } from 'src/screens/shared/implementation/details/milestone/details/final/qualitative-evaluation/edit/QualitativeEvaluationSWOTAnalysisEditPage'
import { FinalReportQualitativeEvaluationPage } from 'src/screens/shared/implementation/details/milestone/details/final/qualitative-evaluation/FinalReportQualitativeEvaluationPage'
import { MilestoneFinancialsEditPage } from 'src/screens/shared/implementation/details/milestone/details/financial/edit/MilestoneFinancialsEditPage'
import { MilestoneFinancialsPage } from 'src/screens/shared/implementation/details/milestone/details/financial/MilestoneFinancialsPage'
import { MilestoneSummaryCommentEditPage } from 'src/screens/shared/implementation/details/milestone/details/summary/edit/MilestoneSummaryCommentEditPage'
import { MilestoneSummaryPage } from 'src/screens/shared/implementation/details/milestone/details/summary/MilestoneSummaryPage'
import { ImplementationSummaryCommentEditPage } from 'src/screens/shared/implementation/details/summary/edit/ImplementationSummaryCommentEditPage'
import { BackgroundInformationEditPage } from 'src/screens/shared/project/details/background-information/edit/BackgroundInformationEditPage'
import { ProjectOrganizationCreatePage } from 'src/screens/shared/project/details/basic-information/organization/edit/ProjectOrganizationCreatePage'
import { ProjectOrganizationEditPage } from 'src/screens/shared/project/details/basic-information/organization/edit/ProjectOrganizationEditPage'
import { ProjectOrganizationViewPage } from 'src/screens/shared/project/details/basic-information/organization/view/ProjectOrganizationViewPage'
import { ProjectBudgetEditPage } from 'src/screens/shared/project/details/financials/edit/ProjectBudgetEditPage'
import { ProjectFinancials } from 'src/screens/shared/project/details/financials/ProjectFinancials'
import { ProjectGoalCreatePage } from 'src/screens/shared/project/details/goals-and-measures/goal/edit/ProjectGoalCreatePage'
import { ProjectGoalEditPage } from 'src/screens/shared/project/details/goals-and-measures/goal/edit/ProjectGoalEditPage'
import { ProjectMeasureCreatePage } from 'src/screens/shared/project/details/goals-and-measures/measure/edit/ProjectMeasureCreatePage'
import { ProjectMeasureEditPage } from 'src/screens/shared/project/details/goals-and-measures/measure/edit/ProjectMeasureEditPage'
import { ProjectFeaturesEditPage } from 'src/screens/shared/project/details/project-features/edit/ProjectFeaturesEditPage'
import { ProjectFeaturesKpiEditPage } from 'src/screens/shared/project/details/project-features/edit/ProjectFeaturesKpiEditPage'
import { InitialSituationEditPage } from 'src/screens/shared/project/details/starting-point-and-vision/initial-situation/InitialSituationEditPage'
import { ProjectJustificationEditPage } from 'src/screens/shared/project/details/starting-point-and-vision/project-justification/ProjectJustificationEditPage'
import { SourcesEditPage } from 'src/screens/shared/project/details/starting-point-and-vision/sources/SourcesEditPage'
import { VisionEditPage } from 'src/screens/shared/project/details/starting-point-and-vision/vision/VisionEditPage'
import { ProjectSummaryCommentEditPage } from 'src/screens/shared/project/details/summary/edit/ProjectSummaryCommentEditPage'
import { ProjectSummaryPage } from 'src/screens/shared/project/details/summary/ProjectSummaryPage'
import { ASSESSMENT_TYPE, CRITERIA_TYPE } from 'src/shared/constants/assessment-constants'
import { FinalReportResultsPage } from 'src/screens/shared/implementation/details/milestone/details/final/result/FinalReportResultsPage'

const PfKapIndex = {
  path: 'pf-kap',
  Component: PfKapIndexPage,
  roles: [
    'PD-EXT_USER',
    'PD-EXT_EXPERT',
    'PD-GFCH_PF-KAP_CONTRIBUTOR',
    'PD-GFCH_PF-KAP_COORDINATOR',
    'PD-GFCH_PF-KAP_READER',
    'PD-CANTON_KAP_READER',
    'PD-CANTON_KAP_CONTRIBUTOR',
  ],
} as const

const PfKapDetailsRoot = {
  path: 'pf-kap/project/:projectId/details',
  Component: PfKapProjectDetailsIndexPage,
  roles: [
    'PD-EXT_USER',
    'PD-EXT_EXPERT',
    'PD-GFCH_PF-KAP_CONTRIBUTOR',
    'PD-GFCH_PF-KAP_COORDINATOR',
    'PD-GFCH_PF-KAP_READER',
    'PD-CANTON_KAP_READER',
    'PD-CANTON_KAP_CONTRIBUTOR',
  ],
} as const

const PfKapBasicInformation = {
  path: 'basic-information',
  Component: PfKapBasicInformationPage,
  navigationKey: 'label.navigation.project.basic.information',
  primary: true,
} as const

const PfKapBasicInformationEdit = {
  path: 'basic-information/edit',
  Component: PfKapBasicInformationEditPage,
  hideNavigation: true,
} as const

const PfKapOrganization = {
  path: 'organization/:organizationId/:organizationRelationType',
  Component: () => <ProjectOrganizationViewPage baseUrl="/pf-kap" />,
  hideNavigation: true,
} as const

const PfKapOrganizationNew = {
  path: 'organization/new/:organizationRelationType',
  Component: () => <ProjectOrganizationCreatePage baseUrl="/pf-kap" />,
  hideNavigation: true,
} as const

const PfKapOrganizationEdit = {
  path: 'organization/:organizationId/edit/:organizationRelationType',
  Component: () => <ProjectOrganizationEditPage baseUrl="/pf-kap" />,
  hideNavigation: true,
} as const

const PfKapStartingPointAndVision = {
  path: 'starting-point-and-vision',
  Component: PfKapStartingPointAndVisionPage,
  navigationKey: 'label.navigation.project.starting.point.and.vision',
} as const

const PfKapStartingPointAndVisionInitialSituationEdit = {
  path: 'starting-point-and-vision/initial-situation/edit',
  Component: () => <InitialSituationEditPage baseUrl="/pf-kap" />,
  hideNavigation: true,
} as const

const PfKapStartingPointAndVisionProjectJustificationEdit = {
  path: 'starting-point-and-vision/project-justification/edit',
  Component: () => <ProjectJustificationEditPage baseUrl="/pf-kap" />,
  hideNavigation: true,
} as const

const PfKapStartingPointAndVisionSourcesEdit = {
  path: 'starting-point-and-vision/sources/edit',
  Component: () => <SourcesEditPage baseUrl="/pf-kap" />,
  hideNavigation: true,
} as const

const PfKapStartingPointAndVisionVisionEdit = {
  path: 'starting-point-and-vision/vision/edit',
  Component: () => <VisionEditPage baseUrl="/pf-kap" />,
  hideNavigation: true,
} as const

const PfKapProjectFeatures = {
  path: 'project-features',
  Component: PfKapProjectFeaturesPage,
  navigationKey: 'label.navigation.project.features',
} as const

const PfKapProjectFeaturesEdit = {
  path: 'project-features/:featureTypeId/edit',
  Component: () => <ProjectFeaturesEditPage baseUrl="/pf-kap" />,
  hideNavigation: true,
} as const

const PfKapProjectFeaturesKpiEdit = {
  path: 'project-features/kpi/edit',
  Component: () => <ProjectFeaturesKpiEditPage baseUrl="/pf-kap" />,
  hideNavigation: true,
} as const

const PfKapGoalsAndMeasures = {
  path: 'goals-and-measures',
  Component: PfKapGoalsAndMeasuresPage,
  navigationKey: 'label.navigation.project.goals.and.measures',
} as const

const PfKapProjectGoalNew = {
  path: 'goals-and-measures/goal/new',
  Component: () => <ProjectGoalCreatePage baseUrl="/pf-kap" />,
  hideNavigation: true,
} as const

const PfKapProjectGoalEdit = {
  path: 'goals-and-measures/goal/:goalId/edit',
  Component: () => <ProjectGoalEditPage baseUrl="/pf-kap" />,
  hideNavigation: true,
} as const

const PfKapProjectMeasureNew = {
  path: 'goals-and-measures/measure/new',
  Component: () => <ProjectMeasureCreatePage baseUrl="/pf-kap" />,
  hideNavigation: true,
} as const

const PfKapProjectMeasureEdit = {
  path: 'goals-and-measures/measure/:measureId/edit',
  Component: () => <ProjectMeasureEditPage baseUrl="/pf-kap" />,
  hideNavigation: true,
} as const

const PfKapFinancials = {
  path: 'financials',
  Component: () => <ProjectFinancials baseUrl="/pf-kap" />,
  navigationKey: 'label.navigation.project.financials',
} as const

const PfKapFinancialsProjectBudgetEdit = {
  path: 'financials/project-budget/edit',
  Component: () => <ProjectBudgetEditPage baseUrl="/pf-kap" />,
  hideNavigation: true,
} as const

const PfKapBackgroundInformation = {
  path: 'background-information',
  Component: PfKapBackgroundInformationPage,
  navigationKey: 'label.navigation.project.background.information',
} as const

const PfKapBackgroundInformationSustainabilityEdit = {
  path: 'background-information/sustainability/edit',
  Component: () => <BackgroundInformationEditPage baseUrl="/pf-kap" field="sustainability" />,
  hideNavigation: true,
} as const

const PfKapBackgroundInformationParticipationEdit = {
  path: 'background-information/participation/edit',
  Component: () => <BackgroundInformationEditPage baseUrl="/pf-kap" field="participation" />,
  hideNavigation: true,
} as const

const PfKapBackgroundInformationEqualOpportunityEdit = {
  path: 'background-information/equal_opportunity/edit',
  Component: () => <BackgroundInformationEditPage baseUrl="/pf-kap" field="equal_opportunity" />,
  hideNavigation: true,
} as const

const PfKapBackgroundInformationMultiplicationPotentialEdit = {
  path: 'background-information/multiplication_potential/edit',
  Component: () => <BackgroundInformationEditPage baseUrl="/pf-kap" field="multiplication_potential" />,
  hideNavigation: true,
} as const

const PfKapBackgroundInformationAdditionalInformationEdit = {
  path: 'background-information/additional_information/edit',
  Component: () => <BackgroundInformationEditPage baseUrl="/pf-kap" field="additional_information" />,
  hideNavigation: true,
} as const

const PfKapEffectivityManagement = {
  path: 'effectivity',
  Component: PfKapEffectivityManagementPage,
  navigationKey: 'label.navigation.project.effectivity',
} as const

const PfKapEffectivityManagementEvaluationEdit = {
  path: 'effectivity/evaluation/edit',
  Component: () => <PfKapEvaluationEditPage />,
  hideNavigation: true,
} as const

const PfKapEffectivityManagementEvaluationMilestoneNew = {
  path: 'effectivity/evaluation-milestone/new',
  Component: () => <PfKapEvaluationMilestoneCreatePage />,
  hideNavigation: true,
} as const

const PfKapEffectivityManagementEvaluationMilestoneEdit = {
  path: 'effectivity/evaluation-milestone/:evaluationMilestoneId/edit',
  Component: () => <PfKapEvaluationMilestoneEditPage />,
  hideNavigation: true,
} as const

const PfKapSummary = {
  path: 'summary',
  Component: () => <ProjectSummaryPage baseUrl="/pf-kap" />,
  navigationKey: 'label.navigation.project.summary',
} as const

const PfKapSummaryCommentEdit = {
  path: 'summary/comment/edit',
  Component: () => <ProjectSummaryCommentEditPage baseUrl="/pf-kap" />,
  hideNavigation: true,
} as const

const PfKapProjectPermissions = {
  path: 'pf-kap/project/:projectId/permissions',
  Component: PfKapPermissionsPage,
  roles: [
    'PD-EXT_USER',
    'PD-EXT_EXPERT',
    'PD-GFCH_PF-KAP_CONTRIBUTOR',
    'PD-GFCH_PF-KAP_COORDINATOR',
    'PD-GFCH_PF-KAP_READER',
    'PD-CANTON_KAP_READER',
    'PD-CANTON_KAP_CONTRIBUTOR',
  ],
} as const

const PfKapProjectJournal = {
  path: 'pf-kap/project/:projectId/project-journal',
  Component: PfKapProjectJournalPage,
  roles: ['PD-GFCH_PF-KAP_CONTRIBUTOR', 'PD-GFCH_PF-KAP_COORDINATOR', 'PD-GFCH_PF-KAP_READER'],
} as const

const PfKapProjectJournalNew = {
  path: 'pf-kap/project/:projectId/project-journal/new',
  Component: PfKapProjectJournalCreatePage,
  roles: ['PD-GFCH_PF-KAP_CONTRIBUTOR', 'PD-GFCH_PF-KAP_COORDINATOR'],
} as const

const PfKapProjectJournalEdit = {
  path: 'pf-kap/project/:projectId/project-journal/:journalId/edit',
  Component: PfKapProjectJournalEditPage,
  roles: ['PD-GFCH_PF-KAP_CONTRIBUTOR', 'PD-GFCH_PF-KAP_COORDINATOR'],
} as const

const PfKapApplicationDetailsRoot = {
  path: 'pf-kap/project/:projectId/application/details',
  Component: PfKapApplicationDetailsIndexPage,
  roles: [
    'PD-EXT_USER',
    'PD-EXT_EXPERT',
    'PD-GFCH_PF-KAP_CONTRIBUTOR',
    'PD-GFCH_PF-KAP_COORDINATOR',
    'PD-GFCH_PF-KAP_READER',
    'PD-CANTON_KAP_CONTRIBUTOR',
    'PD-CANTON_KAP_READER',
  ],
} as const

const PfKapApplicationInformation = {
  path: 'information',
  Component: PfKapApplicationInformationPage,
  navigationKey: 'label.navigation.application.information',
  primary: true,
} as const

const PfKapApplicationInformationEdit = {
  path: 'information/edit',
  Component: () => <ApplicationInformationEditPage baseUrl="/pf-kap" />,
  hideNavigation: true,
} as const

const PfKapApplicationSummary = {
  path: 'summary',
  Component: PfKapApplicationSummaryPage,
  navigationKey: 'label.navigation.project.summary',
} as const

const PfKapApplicationSummaryCommentEdit = {
  path: 'summary/edit',
  Component: () => <ApplicationSummaryEditPage baseUrl="/pf-kap" />,
  hideNavigation: true,
} as const

const PfKapApplicationAssessment = {
  path: 'pf-kap/project/:projectId/application/assessment',
  Component: PfKapApplicationAssessmentIndexPage,
  roles: ['PD-EXT_EXPERT', 'PD-GFCH_PF-KAP_CONTRIBUTOR', 'PD-GFCH_PF-KAP_COORDINATOR', 'PD-GFCH_PF-KAP_READER'],
} as const

const PfKapApplicationCommittee = {
  path: 'pf-kap/project/:projectId/application/committee',
  Component: PfKapApplicationCommitteePage,
  roles: ['PD-GFCH_PF-KAP_CONTRIBUTOR', 'PD-GFCH_PF-KAP_COORDINATOR', 'PD-GFCH_PF-KAP_READER'],
} as const

const PfKapApplicationCommitteeEdit = {
  path: 'pf-kap/project/:projectId/application/committee/edit',
  Component: () => <ApplicationCommitteeEditPage baseUrl="/pf-kap" />,
  roles: ['PD-GFCH_PF-KAP_CONTRIBUTOR', 'PD-GFCH_PF-KAP_COORDINATOR'],
} as const

const PfKapApplicationAssessmentDetailsRoot = {
  path: 'pf-kap/project/:projectId/application/assessment/:assessmentId/details',
  Component: PfKapAssessmentDetailsIndexPage,
  roles: ['PD-EXT_EXPERT', 'PD-GFCH_PF-KAP_CONTRIBUTOR', 'PD-GFCH_PF-KAP_COORDINATOR', 'PD-GFCH_PF-KAP_READER'],
} as const

const PfKapApplicationAssessmentExclusion = {
  path: 'exclusion',
  Component: PfKapExclusionPage,
  navigationKey: 'label.navigation.assessment.exclusion',
  primary: true,
} as const

const PfKapApplicationAssessmentExclusionEdit = {
  path: 'exclusion/edit',
  Component: () => <ApplicationAssessmentExclusionEditPage baseUrl="/pf-kap" />,
  hideNavigation: true,
} as const

const PfKapApplicationAssessmentExclusionCommentEdit = {
  path: 'exclusion/comment/edit',
  Component: () => <ApplicationAssessmentCommentEditPage baseUrl="/pf-kap" type={CRITERIA_TYPE.EXCLUSION} />,
  hideNavigation: true,
} as const

const PfKapApplicationAssessmentCriteria = {
  path: 'criteria',
  Component: PfKapAssessmentCriteriaPage,
  navigationKey: 'label.navigation.assessment',
} as const

const PfKapApplicationAssessmentCriteriaEdit = {
  path: 'criteria/edit',
  Component: () => <ApplicationAssessmentCriteriaEditPage baseUrl="/pf-kap" />,
  hideNavigation: true,
} as const

const PfKapApplicationAssessmentCriteriaCommentEdit = {
  path: 'criteria/comment/edit',
  Component: () => <ApplicationAssessmentCommentEditPage baseUrl="/pf-kap" type={CRITERIA_TYPE.QUALITY} />,
  hideNavigation: true,
} as const

const PfKapApplicationAssessmentRecommendation = {
  path: 'recommendation',
  Component: PfKapRecommendationPage,
  navigationKey: 'label.navigation.assessment.recommendation',
} as const

const PfKapApplicationAssessmentRecommendationEdit = {
  path: 'recommendation/edit',
  Component: () => <ApplicationAssessmentRecommendationEditPage baseUrl="/pf-kap" />,
  hideNavigation: true,
} as const

const PfKapApplicationAssessmentSummary = {
  path: 'summary',
  Component: PfKapAssessmentDetailsSummaryPage,
  navigationKey: 'label.navigation.project.summary',
} as const

const PfKapApplicationAssessmentSummaryCommentEdit = {
  path: 'summary/comment/edit',
  Component: () => <ApplicationAssessmentSummaryCommentEditPage baseUrl="/pf-kap" />,
  hideNavigation: true,
} as const

const PfKapImplementationDetailsRoot = {
  path: 'pf-kap/project/:projectId/implementation/details',
  Component: PfKapImplementationDetailsIndexPage,
  roles: [
    'PD-EXT_USER',
    'PD-GFCH_PF-KAP_CONTRIBUTOR',
    'PD-GFCH_PF-KAP_COORDINATOR',
    'PD-GFCH_PF-KAP_READER',
    'PD-CANTON_KAP_READER',
    'PD-CANTON_KAP_CONTRIBUTOR',
  ],
} as const

const PfKapImplementationMilestone = {
  path: 'milestones',
  Component: PfKapImplementationMilestonePage,
  navigationKey: 'label.navigation.implementation.milestones',
  primary: true,
} as const

const PfKapImplementationSummary = {
  path: 'summary',
  Component: PfKapImplementationDetailsSummaryPage,
  navigationKey: 'label.navigation.implementation.summary',
} as const

const PfKapImplementationSummaryCommentEdit = {
  path: 'summary/edit',
  Component: () => <ImplementationSummaryCommentEditPage baseUrl="/pf-kap" />,
  hideNavigation: true,
} as const

const PfKapMilestoneDetailsRoot = {
  path: 'pf-kap/project/:projectId/implementation/details/milestone/:milestoneId/:milestonePath',
  Component: () => <DefaultMilestoneIndex baseUrl="/pf-kap" />,
  roles: [
    'PD-EXT_USER',
    'PD-GFCH_PF-KAP_CONTRIBUTOR',
    'PD-GFCH_PF-KAP_COORDINATOR',
    'PD-GFCH_PF-KAP_READER',
    'PD-CANTON_KAP_READER',
    'PD-CANTON_KAP_CONTRIBUTOR',
  ],
} as const

const PfKapDefaultMilestoneDetails = {
  path: 'details',
  Component: () => <MilestoneDetailsPage baseUrl="/pf-kap" />,
  navigationKey: 'label.navigation.implementation.details',
  primary: true,
} as const

const PfKapDefaultMilestoneDetailsEdit = {
  path: 'details/edit',
  Component: () => <MilestoneDetailsEditPage baseUrl="/pf-kap" />,
  hideNavigation: true,
} as const

const PfKapIntermediateDiscussionMilestoneEvaluation = {
  path: 'evaluation',
  Component: () => <MilestoneEvaluationPage baseUrl="/pf-kap" />,
  navigationKey: 'label.navigation.implementation.evaluation',
} as const

const PfKapIntermediateDiscussionMilestoneEvaluationEdit = {
  path: 'evaluation/edit',
  Component: () => <MilestoneEvaluationEditPage baseUrl="/pf-kap" />,
  hideNavigation: true,
} as const

const PfKapMilestoneFinancials = {
  path: 'financials',
  Component: () => <MilestoneFinancialsPage baseUrl="/pf-kap" />,
  navigationKey: 'label.navigation.project.financials',
  primary: true,
} as const

const PfKapMilestoneSummary = {
  path: 'summary',
  Component: () => <MilestoneSummaryPage baseUrl="/pf-kap" />,
  navigationKey: 'label.navigation.implementation.summary',
} as const

const PfKapMilestoneSummaryCommentEdit = {
  path: 'summary/comment/edit',
  Component: () => <MilestoneSummaryCommentEditPage baseUrl="/pf-kap" />,
  hideNavigation: true,
} as const

const PfKapFinalReportMilestoneExternalAssessment = {
  path: 'external-assessment',
  Component: () => <MilestoneAssessmentCriteriaPage baseUrl="/pf-kap" assessorType={ASSESSMENT_TYPE.EXTERNAL} />,
  navigationKey: 'label.assessment.type.external_assessment',
  primary: true,
} as const

const PfKapFinalReportMilestoneInternalAssessment = {
  path: 'internal-assessment',
  Component: () => <MilestoneAssessmentCriteriaPage baseUrl="/pf-kap" assessorType={ASSESSMENT_TYPE.INTERNAL} />,
  navigationKey: 'label.assessment.type.internal_assessment',
} as const

const PfKapFinalReportMilestoneExternalAssessmentEdit = {
  path: 'external-assessment/edit',
  Component: () => <MilestoneAssessmentCriteriaEditPage baseUrl="/pf-kap" assessorType={ASSESSMENT_TYPE.EXTERNAL} />,
  hideNavigation: true,
} as const

const PfKapFinalReportMilestoneExternalAssessmentCommentEdit = {
  path: 'external-assessment/comment/edit',
  Component: () => <MilestoneAssessmentCommentEditPage baseUrl="/pf-kap" type={ASSESSMENT_TYPE.EXTERNAL} />,
  hideNavigation: true,
} as const

const PfKapFinalReportMilestoneInternalAssessmentEdit = {
  path: 'internal-assessment/edit',
  Component: () => <MilestoneAssessmentCriteriaEditPage baseUrl="/pf-kap" assessorType={ASSESSMENT_TYPE.INTERNAL} />,
  hideNavigation: true,
} as const

const PfKapFinalReportMilestoneInternalAssessmentCommentEdit = {
  path: 'internal-assessment/comment/edit',
  Component: () => <MilestoneAssessmentCommentEditPage baseUrl="/pf-kap" type={ASSESSMENT_TYPE.INTERNAL} />,
  hideNavigation: true,
} as const

const PfKapFinalReportResults = {
  path: 'results',
  Component: () => <FinalReportResultsPage baseUrl="/pf-kap" />,
  navigationKey: 'label.navigation.milestone.final.report.results',
} as const

const PfKapFinalReportQualitativeEvaluation = {
  path: 'qualitative-evaluation',
  Component: () => <FinalReportQualitativeEvaluationPage baseUrl={'/pf-kap'} />,
  navigationKey: 'label.navigation.milestone.qualitative.evaluation',
} as const

const PfKapFinalReportQualitativeEvaluationSWOTAnalysisEdit = {
  path: 'qualitative-evaluation/swot-analysis/edit',
  Component: () => <QualitativeEvaluationSWOTAnalysisEditPage baseUrl={'/pf-kap'} />,
  hideNavigation: true,
} as const

const PfKapFinalReportQualitativeEvaluationEqualOpportunityEdit = {
  path: 'qualitative-evaluation/equal-opportunity/edit',
  Component: () => <QualitativeEvaluationEditPage baseUrl={'/pf-kap'} field={'equal_opportunity'} />,
  hideNavigation: true,
} as const

const PfKapFinalReportQualitativeEvaluationSustainabilityEdit = {
  path: 'qualitative-evaluation/sustainability/edit',
  Component: () => <QualitativeEvaluationEditPage baseUrl={'/pf-kap'} field={'sustainability'} />,
  hideNavigation: true,
} as const

const PfKapFinalReportQualitativeEvaluationMultiplicationPotentialEdit = {
  path: 'qualitative-evaluation/multiplication-potential/edit',
  Component: () => <QualitativeEvaluationEditPage baseUrl={'/pf-kap'} field={'multiplication_potential'} />,
  hideNavigation: true,
} as const

const PfKapMilestoneGoalsAndMeasures = {
  path: 'goals-and-measures',
  Component: () => <MilestonesGoalsAndMeasuresPage baseUrl="/pf-kap" />,
  navigationKey: 'label.navigation.project.goals.and.measures',
} as const

const PfKapMilestoneGoalEdit = {
  path: 'goals-and-measures/goal/:goalId/edit',
  Component: () => <MilestoneGoalEditPage baseUrl="/pf-kap" />,
  hideNavigation: true,
} as const

const PfKapMilestoneMeasureEdit = {
  path: 'goals-and-measures/measure/:measureId/edit',
  Component: () => <MilestoneMeasureEditPage baseUrl="/pf-kap" />,
  hideNavigation: true,
} as const

const PfKapMilestoneMeasureNew = {
  path: 'goals-and-measures/measure/new',
  Component: () => <MilestoneMeasureCreatePage baseUrl="/pf-kap" />,
  hideNavigation: true,
} as const

const PfKapMilestoneFeatureKpi = {
  path: 'kpi',
  Component: () => <MilestoneFeatureKpiPage baseUrl="/pf-kap" />,
  navigationKey: 'label.navigation.milestone.feature.kpi',
} as const

const PfKapMilestoneFeatureKpiEdit = {
  path: 'kpi/edit',
  Component: () => <MilestoneFeatureKpiEditPage baseUrl="/pf-kap" />,
  hideNavigation: true,
} as const

const PfKapMilestoneFinancialsEdit = {
  path: 'financials/edit',
  Component: () => <MilestoneFinancialsEditPage baseUrl="/pf-kap" />,
  hideNavigation: true,
} as const

const PfKapMilestoneConditions = {
  path: 'conditions',
  Component: () => <MilestoneConditionsPage baseUrl="/pf-kap" />,
  navigationKey: 'label.navigation.milestone.conditions',
  primary: true,
} as const

const PfKapMilestoneConditionsEdit = {
  path: 'conditions/edit',
  Component: () => <MilestoneConditionsEditPage baseUrl="/pf-kap" />,
  hideNavigation: true,
} as const

export const PfKapRoutes = {
  PfKapIndex: PfKapIndex,
  PfKapDetailsRoot: {
    ...PfKapDetailsRoot,
    params: (props: ExtractRouteParams<typeof PfKapDetailsRoot.path>) => withParams(`/${PfKapDetailsRoot.path}`)(props),
    nested: {
      BasicInformation: {
        ...PfKapBasicInformation,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof PfKapDetailsRoot.path, typeof PfKapBasicInformation.path>>,
        ) => withParams(`/${PfKapDetailsRoot.path}/${PfKapBasicInformation.path}`)(props),
      },
      BasicInformationEdit: {
        ...PfKapBasicInformationEdit,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof PfKapDetailsRoot.path, typeof PfKapBasicInformationEdit.path>>,
        ) => withParams(`/${PfKapDetailsRoot.path}/${PfKapBasicInformationEdit.path}`)(props),
      },
      Organization: {
        ...PfKapOrganization,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof PfKapDetailsRoot.path, typeof PfKapOrganization.path>>,
        ) => withParams(`/${PfKapDetailsRoot.path}/${PfKapOrganization.path}`)(props),
      },
      OrganizationNew: {
        ...PfKapOrganizationNew,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof PfKapDetailsRoot.path, typeof PfKapOrganizationNew.path>>,
        ) => withParams(`/${PfKapDetailsRoot.path}/${PfKapOrganizationNew.path}`)(props),
      },
      OrganizationEdit: {
        ...PfKapOrganizationEdit,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof PfKapDetailsRoot.path, typeof PfKapOrganizationEdit.path>>,
        ) => withParams(`/${PfKapDetailsRoot.path}/${PfKapOrganizationEdit.path}`)(props),
      },
      StartingPointAndVision: {
        ...PfKapStartingPointAndVision,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapDetailsRoot.path, typeof PfKapStartingPointAndVision.path>
          >,
        ) => withParams(`/${PfKapDetailsRoot.path}/${PfKapStartingPointAndVision.path}`)(props),
      },
      StartingPointAndVisionInitialSituationEdit: {
        ...PfKapStartingPointAndVisionInitialSituationEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapDetailsRoot.path, typeof PfKapStartingPointAndVisionInitialSituationEdit.path>
          >,
        ) => withParams(`/${PfKapDetailsRoot.path}/${PfKapStartingPointAndVisionInitialSituationEdit.path}`)(props),
      },
      StartingPointAndVisionProjectJustificationEdit: {
        ...PfKapStartingPointAndVisionProjectJustificationEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapDetailsRoot.path, typeof PfKapStartingPointAndVisionProjectJustificationEdit.path>
          >,
        ) => withParams(`/${PfKapDetailsRoot.path}/${PfKapStartingPointAndVisionProjectJustificationEdit.path}`)(props),
      },
      StartingPointAndVisionSourcesEdit: {
        ...PfKapStartingPointAndVisionSourcesEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapDetailsRoot.path, typeof PfKapStartingPointAndVisionSourcesEdit.path>
          >,
        ) => withParams(`/${PfKapDetailsRoot.path}/${PfKapStartingPointAndVisionSourcesEdit.path}`)(props),
      },
      StartingPointAndVisionVisionEdit: {
        ...PfKapStartingPointAndVisionVisionEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapDetailsRoot.path, typeof PfKapStartingPointAndVisionVisionEdit.path>
          >,
        ) => withParams(`/${PfKapDetailsRoot.path}/${PfKapStartingPointAndVisionVisionEdit.path}`)(props),
      },
      ProjectFeatures: {
        ...PfKapProjectFeatures,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof PfKapDetailsRoot.path, typeof PfKapProjectFeatures.path>>,
        ) => withParams(`/${PfKapDetailsRoot.path}/${PfKapProjectFeatures.path}`)(props),
      },
      ProjectFeaturesEdit: {
        ...PfKapProjectFeaturesEdit,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof PfKapDetailsRoot.path, typeof PfKapProjectFeaturesEdit.path>>,
        ) => withParams(`/${PfKapDetailsRoot.path}/${PfKapProjectFeaturesEdit.path}`)(props),
      },
      ProjectFeaturesKpiEdit: {
        ...PfKapProjectFeaturesKpiEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapDetailsRoot.path, typeof PfKapProjectFeaturesKpiEdit.path>
          >,
        ) => withParams(`/${PfKapDetailsRoot.path}/${PfKapProjectFeaturesKpiEdit.path}`)(props),
      },
      GoalsAndMeasures: {
        ...PfKapGoalsAndMeasures,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof PfKapDetailsRoot.path, typeof PfKapGoalsAndMeasures.path>>,
        ) => withParams(`/${PfKapDetailsRoot.path}/${PfKapGoalsAndMeasures.path}`)(props),
      },
      ProjectGoalNew: {
        ...PfKapProjectGoalNew,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof PfKapDetailsRoot.path, typeof PfKapProjectGoalNew.path>>,
        ) => withParams(`/${PfKapDetailsRoot.path}/${PfKapProjectGoalNew.path}`)(props),
      },
      ProjectGoalEdit: {
        ...PfKapProjectGoalEdit,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof PfKapDetailsRoot.path, typeof PfKapProjectGoalEdit.path>>,
        ) => withParams(`/${PfKapDetailsRoot.path}/${PfKapProjectGoalEdit.path}`)(props),
      },
      ProjectMeasureNew: {
        ...PfKapProjectMeasureNew,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof PfKapDetailsRoot.path, typeof PfKapProjectMeasureNew.path>>,
        ) => withParams(`/${PfKapDetailsRoot.path}/${PfKapProjectMeasureNew.path}`)(props),
      },
      ProjectMeasureEdit: {
        ...PfKapProjectMeasureEdit,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof PfKapDetailsRoot.path, typeof PfKapProjectMeasureEdit.path>>,
        ) => withParams(`/${PfKapDetailsRoot.path}/${PfKapProjectMeasureEdit.path}`)(props),
      },
      Financials: {
        ...PfKapFinancials,
        params: (props: ExtractRouteParams<ComposePaths<typeof PfKapDetailsRoot.path, typeof PfKapFinancials.path>>) =>
          withParams(`/${PfKapDetailsRoot.path}/${PfKapFinancials.path}`)(props),
      },
      FinancialsProjectBudgetEdit: {
        ...PfKapFinancialsProjectBudgetEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapDetailsRoot.path, typeof PfKapFinancialsProjectBudgetEdit.path>
          >,
        ) => withParams(`/${PfKapDetailsRoot.path}/${PfKapFinancialsProjectBudgetEdit.path}`)(props),
      },
      EffectivityManagement: {
        ...PfKapEffectivityManagement,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof PfKapDetailsRoot.path, typeof PfKapEffectivityManagement.path>>,
        ) => withParams(`/${PfKapDetailsRoot.path}/${PfKapEffectivityManagement.path}`)(props),
      },
      EffectivityManagementEvaluationEdit: {
        ...PfKapEffectivityManagementEvaluationEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapDetailsRoot.path, typeof PfKapEffectivityManagementEvaluationEdit.path>
          >,
        ) => withParams(`/${PfKapDetailsRoot.path}/${PfKapEffectivityManagementEvaluationEdit.path}`)(props),
      },
      EffectivityManagementEvaluationMilestoneNew: {
        ...PfKapEffectivityManagementEvaluationMilestoneNew,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapDetailsRoot.path, typeof PfKapEffectivityManagementEvaluationMilestoneNew.path>
          >,
        ) => withParams(`/${PfKapDetailsRoot.path}/${PfKapEffectivityManagementEvaluationMilestoneNew.path}`)(props),
      },
      EffectivityManagementEvaluationMilestoneEdit: {
        ...PfKapEffectivityManagementEvaluationMilestoneEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapDetailsRoot.path, typeof PfKapEffectivityManagementEvaluationMilestoneEdit.path>
          >,
        ) => withParams(`/${PfKapDetailsRoot.path}/${PfKapEffectivityManagementEvaluationMilestoneEdit.path}`)(props),
      },
      BackgroundInformation: {
        ...PfKapBackgroundInformation,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof PfKapDetailsRoot.path, typeof PfKapBackgroundInformation.path>>,
        ) => withParams(`/${PfKapDetailsRoot.path}/${PfKapBackgroundInformation.path}`)(props),
      },
      BackgroundInformationSustainabilityEdit: {
        ...PfKapBackgroundInformationSustainabilityEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapDetailsRoot.path, typeof PfKapBackgroundInformationSustainabilityEdit.path>
          >,
        ) => withParams(`/${PfKapDetailsRoot.path}/${PfKapBackgroundInformationSustainabilityEdit.path}`)(props),
      },
      BackgroundInformationParticipationEdit: {
        ...PfKapBackgroundInformationParticipationEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapDetailsRoot.path, typeof PfKapBackgroundInformationParticipationEdit.path>
          >,
        ) => withParams(`/${PfKapDetailsRoot.path}/${PfKapBackgroundInformationParticipationEdit.path}`)(props),
      },
      BackgroundInformationEqualOpportunintyEdit: {
        ...PfKapBackgroundInformationEqualOpportunityEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapDetailsRoot.path, typeof PfKapBackgroundInformationEqualOpportunityEdit.path>
          >,
        ) => withParams(`/${PfKapDetailsRoot.path}/${PfKapBackgroundInformationEqualOpportunityEdit.path}`)(props),
      },
      BackgroundInformationMultiplicationPotentialEdit: {
        ...PfKapBackgroundInformationMultiplicationPotentialEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof PfKapDetailsRoot.path,
              typeof PfKapBackgroundInformationMultiplicationPotentialEdit.path
            >
          >,
        ) =>
          withParams(`/${PfKapDetailsRoot.path}/${PfKapBackgroundInformationMultiplicationPotentialEdit.path}`)(props),
      },
      BackgroundInformationAdditionalInformationEdit: {
        ...PfKapBackgroundInformationAdditionalInformationEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapDetailsRoot.path, typeof PfKapBackgroundInformationAdditionalInformationEdit.path>
          >,
        ) => withParams(`/${PfKapDetailsRoot.path}/${PfKapBackgroundInformationAdditionalInformationEdit.path}`)(props),
      },
      ProjectSummary: {
        ...PfKapSummary,
        params: (props: ExtractRouteParams<ComposePaths<typeof PfKapDetailsRoot.path, typeof PfKapSummary.path>>) =>
          withParams(`/${PfKapDetailsRoot.path}/${PfKapSummary.path}`)(props),
      },
      ProjectSummaryCommentEdit: {
        ...PfKapSummaryCommentEdit,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof PfKapDetailsRoot.path, typeof PfKapSummaryCommentEdit.path>>,
        ) => withParams(`/${PfKapDetailsRoot.path}/${PfKapSummaryCommentEdit.path}`)(props),
      },
    },
  },
  PfKapPermissions: {
    ...PfKapProjectPermissions,
    params: (props: ExtractRouteParams<typeof PfKapProjectPermissions.path>) =>
      withParams(`/${PfKapProjectPermissions.path}`)(props),
  },
  PfKapProjectJournal: {
    ...PfKapProjectJournal,
    params: (props: ExtractRouteParams<typeof PfKapProjectJournal.path>) =>
      withParams(`/${PfKapProjectJournal.path}`)(props),
  },
  PfKapProjectJournalNew: {
    ...PfKapProjectJournalNew,
    params: (props: ExtractRouteParams<typeof PfKapProjectJournalNew.path>) =>
      withParams(`/${PfKapProjectJournalNew.path}`)(props),
  },
  PfKapProjectJournalEdit: {
    ...PfKapProjectJournalEdit,
    params: (props: ExtractRouteParams<typeof PfKapProjectJournalEdit.path>) =>
      withParams(`/${PfKapProjectJournalEdit.path}`)(props),
  },
  PfKapApplicationDetailsRoot: {
    ...PfKapApplicationDetailsRoot,
    params: (props: ExtractRouteParams<typeof PfKapApplicationDetailsRoot.path>) =>
      withParams(`/${PfKapApplicationDetailsRoot.path}`)(props),
    nested: {
      Information: {
        ...PfKapApplicationInformation,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapApplicationDetailsRoot.path, typeof PfKapApplicationInformation.path>
          >,
        ) => withParams(`/${PfKapApplicationDetailsRoot.path}/${PfKapApplicationInformation.path}`)(props),
      },
      InformationEdit: {
        ...PfKapApplicationInformationEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapApplicationDetailsRoot.path, typeof PfKapApplicationInformationEdit.path>
          >,
        ) => withParams(`/${PfKapApplicationDetailsRoot.path}/${PfKapApplicationInformationEdit.path}`)(props),
      },
      Summary: {
        ...PfKapApplicationSummary,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapApplicationDetailsRoot.path, typeof PfKapApplicationSummary.path>
          >,
        ) => withParams(`/${PfKapApplicationDetailsRoot.path}/${PfKapApplicationSummary.path}`)(props),
      },
      SummaryCommentEdit: {
        ...PfKapApplicationSummaryCommentEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapApplicationDetailsRoot.path, typeof PfKapApplicationSummaryCommentEdit.path>
          >,
        ) => withParams(`/${PfKapApplicationDetailsRoot.path}/${PfKapApplicationSummaryCommentEdit.path}`)(props),
      },
    },
  },
  PfKapApplicationCommittee: {
    ...PfKapApplicationCommittee,
    params: (props: ExtractRouteParams<typeof PfKapApplicationCommittee.path>) =>
      withParams(`/${PfKapApplicationCommittee.path}`)(props),
  },
  PfKapApplicationCommitteeEdit: {
    ...PfKapApplicationCommitteeEdit,
    params: (props: ExtractRouteParams<typeof PfKapApplicationCommitteeEdit.path>) =>
      withParams(`/${PfKapApplicationCommitteeEdit.path}`)(props),
  },
  PfKapApplicationAssessment: {
    ...PfKapApplicationAssessment,
    params: (props: ExtractRouteParams<typeof PfKapApplicationAssessment.path>) =>
      withParams(`/${PfKapApplicationAssessment.path}`)(props),
  },
  PfKapApplicationAssessmentDetailsRoot: {
    ...PfKapApplicationAssessmentDetailsRoot,
    params: (props: ExtractRouteParams<typeof PfKapApplicationAssessmentDetailsRoot.path>) =>
      withParams(`/${PfKapApplicationAssessmentDetailsRoot.path}`)(props),
    nested: {
      Exclusion: {
        ...PfKapApplicationAssessmentExclusion,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof PfKapApplicationAssessmentDetailsRoot.path,
              typeof PfKapApplicationAssessmentExclusion.path
            >
          >,
        ) =>
          withParams(`/${PfKapApplicationAssessmentDetailsRoot.path}/${PfKapApplicationAssessmentExclusion.path}`)(
            props,
          ),
      },
      ExclusionEdit: {
        ...PfKapApplicationAssessmentExclusionEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof PfKapApplicationAssessmentDetailsRoot.path,
              typeof PfKapApplicationAssessmentExclusionEdit.path
            >
          >,
        ) =>
          withParams(`/${PfKapApplicationAssessmentDetailsRoot.path}/${PfKapApplicationAssessmentExclusionEdit.path}`)(
            props,
          ),
      },
      ExclusionCommentEdit: {
        ...PfKapApplicationAssessmentExclusionCommentEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof PfKapApplicationAssessmentDetailsRoot.path,
              typeof PfKapApplicationAssessmentExclusionCommentEdit.path
            >
          >,
        ) =>
          withParams(
            `/${PfKapApplicationAssessmentDetailsRoot.path}/${PfKapApplicationAssessmentExclusionCommentEdit.path}`,
          )(props),
      },
      Criteria: {
        ...PfKapApplicationAssessmentCriteria,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof PfKapApplicationAssessmentDetailsRoot.path,
              typeof PfKapApplicationAssessmentCriteria.path
            >
          >,
        ) =>
          withParams(`/${PfKapApplicationAssessmentDetailsRoot.path}/${PfKapApplicationAssessmentCriteria.path}`)(
            props,
          ),
      },
      CriteriaEdit: {
        ...PfKapApplicationAssessmentCriteriaEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof PfKapApplicationAssessmentDetailsRoot.path,
              typeof PfKapApplicationAssessmentCriteriaEdit.path
            >
          >,
        ) =>
          withParams(`/${PfKapApplicationAssessmentDetailsRoot.path}/${PfKapApplicationAssessmentCriteriaEdit.path}`)(
            props,
          ),
      },
      CriteriaCommentEdit: {
        ...PfKapApplicationAssessmentCriteriaCommentEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof PfKapApplicationAssessmentDetailsRoot.path,
              typeof PfKapApplicationAssessmentCriteriaCommentEdit.path
            >
          >,
        ) =>
          withParams(
            `/${PfKapApplicationAssessmentDetailsRoot.path}/${PfKapApplicationAssessmentCriteriaCommentEdit.path}`,
          )(props),
      },
      Recommendation: {
        ...PfKapApplicationAssessmentRecommendation,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof PfKapApplicationAssessmentDetailsRoot.path,
              typeof PfKapApplicationAssessmentRecommendation.path
            >
          >,
        ) =>
          withParams(`/${PfKapApplicationAssessmentDetailsRoot.path}/${PfKapApplicationAssessmentRecommendation.path}`)(
            props,
          ),
      },
      RecommendationEdit: {
        ...PfKapApplicationAssessmentRecommendationEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof PfKapApplicationAssessmentDetailsRoot.path,
              typeof PfKapApplicationAssessmentRecommendationEdit.path
            >
          >,
        ) =>
          withParams(
            `/${PfKapApplicationAssessmentDetailsRoot.path}/${PfKapApplicationAssessmentRecommendationEdit.path}`,
          )(props),
      },
      Summary: {
        ...PfKapApplicationAssessmentSummary,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof PfKapApplicationAssessmentDetailsRoot.path,
              typeof PfKapApplicationAssessmentSummary.path
            >
          >,
        ) =>
          withParams(`/${PfKapApplicationAssessmentDetailsRoot.path}/${PfKapApplicationAssessmentSummary.path}`)(props),
      },
      SummaryCommentEdit: {
        ...PfKapApplicationAssessmentSummaryCommentEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof PfKapApplicationAssessmentDetailsRoot.path,
              typeof PfKapApplicationAssessmentSummaryCommentEdit.path
            >
          >,
        ) =>
          withParams(
            `/${PfKapApplicationAssessmentDetailsRoot.path}/${PfKapApplicationAssessmentSummaryCommentEdit.path}`,
          )(props),
      },
    },
  },
  PfKapImplementationDetailsRoot: {
    ...PfKapImplementationDetailsRoot,
    params: (props: ExtractRouteParams<typeof PfKapImplementationDetailsRoot.path>) =>
      withParams(`/${PfKapImplementationDetailsRoot.path}`)(props),
    nested: {
      Milestone: {
        ...PfKapImplementationMilestone,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapImplementationDetailsRoot.path, typeof PfKapImplementationMilestone.path>
          >,
        ) => withParams(`/${PfKapImplementationDetailsRoot.path}/${PfKapImplementationMilestone.path}`)(props),
      },
      Summary: {
        ...PfKapImplementationSummary,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapImplementationDetailsRoot.path, typeof PfKapImplementationSummary.path>
          >,
        ) => withParams(`/${PfKapImplementationDetailsRoot.path}/${PfKapImplementationSummary.path}`)(props),
      },
      SummaryCommentEdit: {
        ...PfKapImplementationSummaryCommentEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapImplementationDetailsRoot.path, typeof PfKapImplementationSummaryCommentEdit.path>
          >,
        ) => withParams(`/${PfKapImplementationDetailsRoot.path}/${PfKapImplementationSummaryCommentEdit.path}`)(props),
      },
    },
  },
  PfKapDefaultMilestoneRoot: {
    ...PfKapMilestoneDetailsRoot,
    params: (props: ExtractRouteParams<typeof PfKapMilestoneDetailsRoot.path>) =>
      withParams(`/${PfKapMilestoneDetailsRoot.path}`)(props),
    nested: {
      Details: {
        ...PfKapDefaultMilestoneDetails,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapMilestoneDetailsRoot.path, typeof PfKapDefaultMilestoneDetails.path>
          >,
        ) => withParams(`/${PfKapMilestoneDetailsRoot.path}/${PfKapDefaultMilestoneDetails.path}`)(props),
      },
      DetailsEdit: {
        ...PfKapDefaultMilestoneDetailsEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapMilestoneDetailsRoot.path, typeof PfKapDefaultMilestoneDetailsEdit.path>
          >,
        ) => withParams(`/${PfKapMilestoneDetailsRoot.path}/${PfKapDefaultMilestoneDetailsEdit.path}`)(props),
      },
      Summary: {
        ...PfKapMilestoneSummary,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapMilestoneDetailsRoot.path, typeof PfKapMilestoneSummary.path>
          >,
        ) => withParams(`/${PfKapMilestoneDetailsRoot.path}/${PfKapMilestoneSummary.path}`)(props),
      },
      SummaryCommentEdit: {
        ...PfKapMilestoneSummaryCommentEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapMilestoneDetailsRoot.path, typeof PfKapMilestoneSummaryCommentEdit.path>
          >,
        ) => withParams(`/${PfKapMilestoneDetailsRoot.path}/${PfKapMilestoneSummaryCommentEdit.path}`)(props),
      },
    },
  },
  PfKapIntermediateMilestoneRoot: {
    ...PfKapMilestoneDetailsRoot,
    params: (props: ExtractRouteParams<typeof PfKapMilestoneDetailsRoot.path>) =>
      withParams(`/${PfKapMilestoneDetailsRoot.path}`)(props),
    nested: {
      Details: {
        ...PfKapDefaultMilestoneDetails,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapMilestoneDetailsRoot.path, typeof PfKapDefaultMilestoneDetails.path>
          >,
        ) => withParams(`/${PfKapMilestoneDetailsRoot.path}/${PfKapDefaultMilestoneDetails.path}`)(props),
      },
      DetailsEdit: {
        ...PfKapDefaultMilestoneDetailsEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapMilestoneDetailsRoot.path, typeof PfKapDefaultMilestoneDetailsEdit.path>
          >,
        ) => withParams(`/${PfKapMilestoneDetailsRoot.path}/${PfKapDefaultMilestoneDetailsEdit.path}`)(props),
      },
      Evaluation: {
        ...PfKapIntermediateDiscussionMilestoneEvaluation,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof PfKapMilestoneDetailsRoot.path,
              typeof PfKapIntermediateDiscussionMilestoneEvaluation.path
            >
          >,
        ) =>
          withParams(`/${PfKapMilestoneDetailsRoot.path}/${PfKapIntermediateDiscussionMilestoneEvaluation.path}`)(
            props,
          ),
      },
      EvaluationEdit: {
        ...PfKapIntermediateDiscussionMilestoneEvaluationEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof PfKapMilestoneDetailsRoot.path,
              typeof PfKapIntermediateDiscussionMilestoneEvaluationEdit.path
            >
          >,
        ) =>
          withParams(`/${PfKapMilestoneDetailsRoot.path}/${PfKapIntermediateDiscussionMilestoneEvaluationEdit.path}`)(
            props,
          ),
      },
      Summary: {
        ...PfKapMilestoneSummary,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapMilestoneDetailsRoot.path, typeof PfKapMilestoneSummary.path>
          >,
        ) => withParams(`/${PfKapMilestoneDetailsRoot.path}/${PfKapMilestoneSummary.path}`)(props),
      },
      SummaryCommentEdit: {
        ...PfKapMilestoneSummaryCommentEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapMilestoneDetailsRoot.path, typeof PfKapMilestoneSummaryCommentEdit.path>
          >,
        ) => withParams(`/${PfKapMilestoneDetailsRoot.path}/${PfKapMilestoneSummaryCommentEdit.path}`)(props),
      },
    },
  },
  PfKapFinancialReportMilestoneRoot: {
    ...PfKapMilestoneDetailsRoot,
    params: (props: ExtractRouteParams<typeof PfKapMilestoneDetailsRoot.path>) =>
      withParams(`/${PfKapMilestoneDetailsRoot.path}`)(props),
    nested: {
      Financials: {
        ...PfKapMilestoneFinancials,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapMilestoneDetailsRoot.path, typeof PfKapMilestoneFinancials.path>
          >,
        ) => withParams(`/${PfKapMilestoneDetailsRoot.path}/${PfKapMilestoneFinancials.path}`)(props),
      },
      FinancialsEdit: {
        ...PfKapMilestoneFinancialsEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapMilestoneDetailsRoot.path, typeof PfKapMilestoneFinancialsEdit.path>
          >,
        ) => withParams(`/${PfKapMilestoneDetailsRoot.path}/${PfKapMilestoneFinancialsEdit.path}`)(props),
      },
      Summary: {
        ...PfKapMilestoneSummary,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapMilestoneDetailsRoot.path, typeof PfKapMilestoneSummary.path>
          >,
        ) => withParams(`/${PfKapMilestoneDetailsRoot.path}/${PfKapMilestoneSummary.path}`)(props),
      },
      SummaryCommentEdit: {
        ...PfKapMilestoneSummaryCommentEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapMilestoneDetailsRoot.path, typeof PfKapMilestoneSummaryCommentEdit.path>
          >,
        ) => withParams(`/${PfKapMilestoneDetailsRoot.path}/${PfKapMilestoneSummaryCommentEdit.path}`)(props),
      },
    },
  },
  PfKapFinalReportMilestoneRoot: {
    ...PfKapMilestoneDetailsRoot,
    params: (props: ExtractRouteParams<typeof PfKapMilestoneDetailsRoot.path>) =>
      withParams(`/${PfKapMilestoneDetailsRoot.path}`)(props),
    nested: {
      Conditions: {
        ...PfKapMilestoneConditions,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapMilestoneDetailsRoot.path, typeof PfKapMilestoneConditions.path>
          >,
        ) => withParams(`/${PfKapMilestoneDetailsRoot.path}/${PfKapMilestoneConditions.path}`)(props),
      },
      ConditionsEdit: {
        ...PfKapMilestoneConditionsEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapMilestoneDetailsRoot.path, typeof PfKapMilestoneConditionsEdit.path>
          >,
        ) => withParams(`/${PfKapMilestoneDetailsRoot.path}/${PfKapMilestoneConditionsEdit.path}`)(props),
      },
      ExternalAssessment: {
        ...PfKapFinalReportMilestoneExternalAssessment,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapMilestoneDetailsRoot.path, typeof PfKapFinalReportMilestoneExternalAssessment.path>
          >,
        ) =>
          withParams(`/${PfKapMilestoneDetailsRoot.path}/${PfKapFinalReportMilestoneExternalAssessment.path}`)(props),
      },
      ExternalAssessmentEdit: {
        ...PfKapFinalReportMilestoneExternalAssessmentEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof PfKapMilestoneDetailsRoot.path,
              typeof PfKapFinalReportMilestoneExternalAssessmentEdit.path
            >
          >,
        ) =>
          withParams(`/${PfKapMilestoneDetailsRoot.path}/${PfKapFinalReportMilestoneExternalAssessmentEdit.path}`)(
            props,
          ),
      },
      ExternalAssessmentCommentEdit: {
        ...PfKapFinalReportMilestoneExternalAssessmentCommentEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof PfKapMilestoneDetailsRoot.path,
              typeof PfKapFinalReportMilestoneExternalAssessmentCommentEdit.path
            >
          >,
        ) =>
          withParams(
            `/${PfKapMilestoneDetailsRoot.path}/${PfKapFinalReportMilestoneExternalAssessmentCommentEdit.path}`,
          )(props),
      },
      InternalAssessment: {
        ...PfKapFinalReportMilestoneInternalAssessment,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapMilestoneDetailsRoot.path, typeof PfKapFinalReportMilestoneInternalAssessment.path>
          >,
        ) =>
          withParams(`/${PfKapMilestoneDetailsRoot.path}/${PfKapFinalReportMilestoneInternalAssessment.path}`)(props),
      },
      InternalAssessmentEdit: {
        ...PfKapFinalReportMilestoneInternalAssessmentEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof PfKapMilestoneDetailsRoot.path,
              typeof PfKapFinalReportMilestoneInternalAssessmentEdit.path
            >
          >,
        ) =>
          withParams(`/${PfKapMilestoneDetailsRoot.path}/${PfKapFinalReportMilestoneInternalAssessmentEdit.path}`)(
            props,
          ),
      },
      InternalAssessmentCommentEdit: {
        ...PfKapFinalReportMilestoneInternalAssessmentCommentEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof PfKapMilestoneDetailsRoot.path,
              typeof PfKapFinalReportMilestoneInternalAssessmentCommentEdit.path
            >
          >,
        ) =>
          withParams(
            `/${PfKapMilestoneDetailsRoot.path}/${PfKapFinalReportMilestoneInternalAssessmentCommentEdit.path}`,
          )(props),
      },
      Results: {
        ...PfKapFinalReportResults,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapMilestoneDetailsRoot.path, typeof PfKapFinalReportResults.path>
          >,
        ) => withParams(`/${PfKapMilestoneDetailsRoot.path}/${PfKapFinalReportResults.path}`)(props),
      },
      QualitativeEvaluation: {
        ...PfKapFinalReportQualitativeEvaluation,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapMilestoneDetailsRoot.path, typeof PfKapFinalReportQualitativeEvaluation.path>
          >,
        ) => withParams(`/${PfKapMilestoneDetailsRoot.path}/${PfKapFinalReportQualitativeEvaluation.path}`)(props),
      },
      QualitativeEvaluationSWOTAnalysisEdit: {
        ...PfKapFinalReportQualitativeEvaluationSWOTAnalysisEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof PfKapMilestoneDetailsRoot.path,
              typeof PfKapFinalReportQualitativeEvaluationSWOTAnalysisEdit.path
            >
          >,
        ) =>
          withParams(
            `/${PfKapMilestoneDetailsRoot.path}/${PfKapFinalReportQualitativeEvaluationSWOTAnalysisEdit.path}`,
          )(props),
      },
      QualitativeEvaluationEqualOpportunityEdit: {
        ...PfKapFinalReportQualitativeEvaluationEqualOpportunityEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof PfKapMilestoneDetailsRoot.path,
              typeof PfKapFinalReportQualitativeEvaluationEqualOpportunityEdit.path
            >
          >,
        ) =>
          withParams(
            `/${PfKapMilestoneDetailsRoot.path}/${PfKapFinalReportQualitativeEvaluationEqualOpportunityEdit.path}`,
          )(props),
      },
      QualitativeEvaluationSustainabilityEdit: {
        ...PfKapFinalReportQualitativeEvaluationSustainabilityEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof PfKapMilestoneDetailsRoot.path,
              typeof PfKapFinalReportQualitativeEvaluationSustainabilityEdit.path
            >
          >,
        ) =>
          withParams(
            `/${PfKapMilestoneDetailsRoot.path}/${PfKapFinalReportQualitativeEvaluationSustainabilityEdit.path}`,
          )(props),
      },
      QualitativeEvaluationMultiplicationPotentialEdit: {
        ...PfKapFinalReportQualitativeEvaluationMultiplicationPotentialEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof PfKapMilestoneDetailsRoot.path,
              typeof PfKapFinalReportQualitativeEvaluationMultiplicationPotentialEdit.path
            >
          >,
        ) =>
          withParams(
            `/${PfKapMilestoneDetailsRoot.path}/${PfKapFinalReportQualitativeEvaluationMultiplicationPotentialEdit.path}`,
          )(props),
      },
      Summary: {
        ...PfKapMilestoneSummary,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapMilestoneDetailsRoot.path, typeof PfKapMilestoneSummary.path>
          >,
        ) => withParams(`/${PfKapMilestoneDetailsRoot.path}/${PfKapMilestoneSummary.path}`)(props),
      },
      SummaryCommentEdit: {
        ...PfKapMilestoneSummaryCommentEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapMilestoneDetailsRoot.path, typeof PfKapMilestoneSummaryCommentEdit.path>
          >,
        ) => withParams(`/${PfKapMilestoneDetailsRoot.path}/${PfKapMilestoneSummaryCommentEdit.path}`)(props),
      },
    },
  },
  PfKapAnnualReportMilestoneRoot: {
    ...PfKapMilestoneDetailsRoot,
    params: (props: ExtractRouteParams<typeof PfKapMilestoneDetailsRoot.path>) =>
      withParams(`/${PfKapMilestoneDetailsRoot.path}`)(props),
    nested: {
      Conditions: {
        ...PfKapMilestoneConditions,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapMilestoneDetailsRoot.path, typeof PfKapMilestoneConditions.path>
          >,
        ) => withParams(`/${PfKapMilestoneDetailsRoot.path}/${PfKapMilestoneConditions.path}`)(props),
      },
      ConditionsEdit: {
        ...PfKapMilestoneConditionsEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapMilestoneDetailsRoot.path, typeof PfKapMilestoneConditionsEdit.path>
          >,
        ) => withParams(`/${PfKapMilestoneDetailsRoot.path}/${PfKapMilestoneConditionsEdit.path}`)(props),
      },
      GoalsAndMeasures: {
        ...PfKapMilestoneGoalsAndMeasures,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapMilestoneDetailsRoot.path, typeof PfKapMilestoneGoalsAndMeasures.path>
          >,
        ) => withParams(`/${PfKapMilestoneDetailsRoot.path}/${PfKapMilestoneGoalsAndMeasures.path}`)(props),
      },
      MilestoneMeasureNew: {
        ...PfKapMilestoneMeasureNew,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapMilestoneDetailsRoot.path, typeof PfKapMilestoneMeasureNew.path>
          >,
        ) => withParams(`/${PfKapMilestoneDetailsRoot.path}/${PfKapMilestoneMeasureNew.path}`)(props),
      },
      MilestoneGoalEdit: {
        ...PfKapMilestoneGoalEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapMilestoneDetailsRoot.path, typeof PfKapMilestoneGoalEdit.path>
          >,
        ) => withParams(`/${PfKapMilestoneDetailsRoot.path}/${PfKapMilestoneGoalEdit.path}`)(props),
      },
      MilestoneMeasureEdit: {
        ...PfKapMilestoneMeasureEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapMilestoneDetailsRoot.path, typeof PfKapMilestoneMeasureEdit.path>
          >,
        ) => withParams(`/${PfKapMilestoneDetailsRoot.path}/${PfKapMilestoneMeasureEdit.path}`)(props),
      },
      MilestoneFeatureKpi: {
        ...PfKapMilestoneFeatureKpi,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapMilestoneDetailsRoot.path, typeof PfKapMilestoneFeatureKpi.path>
          >,
        ) => withParams(`/${PfKapMilestoneDetailsRoot.path}/${PfKapMilestoneFeatureKpi.path}`)(props),
      },
      MilestoneFeatureKpiEdit: {
        ...PfKapMilestoneFeatureKpiEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapMilestoneDetailsRoot.path, typeof PfKapMilestoneFeatureKpiEdit.path>
          >,
        ) => withParams(`/${PfKapMilestoneDetailsRoot.path}/${PfKapMilestoneFeatureKpiEdit.path}`)(props),
      },
      Summary: {
        ...PfKapMilestoneSummary,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapMilestoneDetailsRoot.path, typeof PfKapMilestoneSummary.path>
          >,
        ) => withParams(`/${PfKapMilestoneDetailsRoot.path}/${PfKapMilestoneSummary.path}`)(props),
      },
      SummaryCommentEdit: {
        ...PfKapMilestoneSummaryCommentEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof PfKapMilestoneDetailsRoot.path, typeof PfKapMilestoneSummaryCommentEdit.path>
          >,
        ) => withParams(`/${PfKapMilestoneDetailsRoot.path}/${PfKapMilestoneSummaryCommentEdit.path}`)(props),
      },
    },
  },
}
