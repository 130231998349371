import { createContext, ReactElement, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ChartContextValue } from 'src/shared/charts/ChartContextValue'
import { alpha } from '@mui/material'
import { muiTheme } from 'src/theme/theme'
import { useClient, useQuery } from 'urql'
import { useUserLocale } from 'src/user/UserContext'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ChartOptions } from 'chart.js'
import { useParams } from 'react-router'
import { PROJECT, PROJECT_TYPE } from 'src/shared/constants/constants'
import { Query_Root } from 'src/@types/graphql'
import {
  getApplicationTypeIdFromProjects,
  getCriteriaConfigForMilestoneAssessmentQuery,
} from 'src/screens/shared/assessment-criteria/assessmentQueries'
import { ASSESSMENT_TYPE, ASSESSMENT_TYPE_TYPE, CRITERIA_TYPE } from 'src/shared/constants/assessment-constants'
import { get } from 'lodash'
import { ChartUtils } from 'src/shared/charts/ChartUtils'
import {
  createGroupsAndInitialData,
  getAvgRatingFromCriteriaValues,
  CriteriaFormValues,
  CriteriaGroups,
  QUALITY_PREFIX,
  CRITERIA_RATINGS_VALUES,
  getQCriteria,
} from 'src/screens/shared/assessment-criteria/utils/AssessmentUtils'

const defaultContextValue: ChartContextValue = {
  chartData: {},
  chartOptions: {},
  chartFetching: true,
  refetchChartData: () => {},
}

const MilestoneFinalReportChartContext = createContext(defaultContextValue)

const MilestoneFinalReportChartContextProvider = ({
  children,
  process,
}: {
  children: ReactNode
  process: PROJECT_TYPE
}): ReactElement => {
  const urqlClient = useClient()
  const language = useUserLocale()
  const notificationService = useNotificationService()

  const { projectId, milestoneId } = useParams()
  const project_id = parseInt(projectId as string)
  const milestone_id = parseInt(milestoneId as string)

  const { getMessage } = useMessageSource()

  const [fetching, setFetching] = useState<boolean>(true)

  const [criteriaGroupsInt, setCriteriaGroupsInt] = useState<CriteriaGroups>()
  const [criteriaGroupsExt, setCriteriaGroupsExt] = useState<CriteriaGroups>()

  const [criteriaValuesInt, setCriteriaValuesInt] = useState<CriteriaFormValues>()
  const [criteriaValuesExt, setCriteriaValuesExt] = useState<CriteriaFormValues>()

  const isPfKap = process === PROJECT.PF_KAP
  const isPfPgv = process === PROJECT.PF_PGV

  // project application type fetch
  const [{ data: projectData, error }] = useQuery<{
    pf_kap_project: Query_Root['pf_kap_project']
    pf_pgv_project: Query_Root['pf_pgv_project']
  }>({
    query: getApplicationTypeIdFromProjects,
    variables: { isPfKap, isPfPgv, projectId },
  })
  const applicationTypeId = isPfKap
    ? projectData?.pf_kap_project?.[0]?.application_type_id
    : projectData?.pf_pgv_project?.[0]?.application_type_id

  if (error) {
    notificationService.operationFailed()
  }

  // fetch data for chart
  const fetchChartData = useCallback(async () => {
    const fetchCriteriaData = async (assessorType: ASSESSMENT_TYPE_TYPE) => {
      const { data } = await urqlClient
        .query<{
          milestone_assessment_criteria_selection: Query_Root['milestone_assessment_criteria_selection']
          criteria_group_config: Query_Root['criteria_group_config']
        }>(getCriteriaConfigForMilestoneAssessmentQuery, {
          milestoneId,
          process,
          criteriaType: CRITERIA_TYPE.QUALITY,
          assessorType,
          applicationTypeId,
        })
        .toPromise()

      const criteriaGroupedConfigs = data?.criteria_group_config
      const selectedQualities = data?.milestone_assessment_criteria_selection

      return createGroupsAndInitialData(selectedQualities, criteriaGroupedConfigs, QUALITY_PREFIX, applicationTypeId)
    }

    try {
      if (applicationTypeId) {
        const [internal, external] = await Promise.all([
          fetchCriteriaData(ASSESSMENT_TYPE.INTERNAL),
          fetchCriteriaData(ASSESSMENT_TYPE.EXTERNAL),
        ])

        setCriteriaGroupsInt(internal.criteriaGroups)
        setCriteriaValuesInt(internal.initialValues)

        setCriteriaGroupsExt(external.criteriaGroups)
        setCriteriaValuesExt(external.initialValues)
      }
    } catch (e) {
      notificationService.operationFailed()
    } finally {
      setFetching(false)
    }
  }, [milestone_id, project_id, process, applicationTypeId])

  useEffect(() => {
    fetchChartData()
  }, [fetchChartData])

  const criteriaGroups = useMemo(
    () =>
      Object.values(criteriaGroupsInt || criteriaGroupsExt || {}).sort(
        (a, b) => a?.[0].sort_number - b?.[0].sort_number,
      ),
    [criteriaGroupsInt, criteriaGroupsExt],
  )

  const labels = useMemo(
    () => criteriaGroups.map((i) => ChartUtils.breakLabel(get(i?.[0].names, language, ''))),
    [criteriaGroups],
  )

  // get chart values for external and internal datasets
  // internal dataset
  const internalChartData = useMemo(() => {
    return criteriaGroups.map((criteriaConfigs) => {
      let internalCriteriaConfigs = criteriaConfigs

      if (isPfPgv) {
        internalCriteriaConfigs = getQCriteria(criteriaConfigs)
      }

      return getAvgRatingFromCriteriaValues(internalCriteriaConfigs, criteriaValuesInt)
    })
  }, [criteriaGroups, criteriaValuesInt])

  // external dataset
  const externalChartData = useMemo(() => {
    return criteriaGroups.map((criteriaConfigs) => {
      let externalCriteriaConfigs = criteriaConfigs
      if (isPfPgv) {
        externalCriteriaConfigs = getQCriteria(criteriaConfigs)
      }

      return getAvgRatingFromCriteriaValues(externalCriteriaConfigs, criteriaValuesExt)
    })
  }, [criteriaGroups, criteriaValuesExt])

  // build chart data
  const data = useMemo(
    () => ({
      labels: labels,
      datasets: [
        {
          label: getMessage(`label.assessment.type.external_assessment`),
          data: externalChartData,
          fill: true,
          backgroundColor: alpha(muiTheme.palette.primary.main, 0.2),
          borderColor: muiTheme.palette.primary.main,
          pointBackgroundColor: muiTheme.palette.primary.main,
          pointBorderColor: muiTheme.palette.common.white,
          pointHoverBorderColor: muiTheme.palette.primary.main,
        },
        {
          label: getMessage(`label.assessment.type.internal_assessment`),
          data: internalChartData,
          fill: true,
          backgroundColor: alpha(muiTheme.palette.secondary.main, 0.2),
          borderColor: muiTheme.palette.secondary.main,
          pointBackgroundColor: muiTheme.palette.secondary.main,
          pointBorderColor: muiTheme.palette.common.white,
          pointHoverBorderColor: muiTheme.palette.secondary.main,
        },
      ],
    }),
    [getMessage, externalChartData, internalChartData, labels],
  )

  const options = useMemo(
    () =>
      ({
        scales: {
          r: {
            max: CRITERIA_RATINGS_VALUES.size,
            min: 0,
            ticks: {
              step: 1,
            },
            grid: {
              circular: true,
            },
            pointLabels: {
              padding: 0,
              font: {
                size: 14,
              },
            },
          },
        },
        aspectRatio: 1.5,
      }) as ChartOptions,
    [CRITERIA_RATINGS_VALUES],
  )

  const chartContextValue = useMemo(
    () => ({ chartData: data, chartOptions: options, chartFetching: fetching, refetchChartData: fetchChartData }),
    [data, options, fetching, fetchChartData],
  )

  return (
    <MilestoneFinalReportChartContext.Provider value={chartContextValue}>
      {children}
    </MilestoneFinalReportChartContext.Provider>
  )
}

const useMilestoneFinalReportChartContext = (): ChartContextValue => {
  return useContext(MilestoneFinalReportChartContext)
}

export {
  MilestoneFinalReportChartContext,
  MilestoneFinalReportChartContextProvider,
  useMilestoneFinalReportChartContext,
}
