import { Query_Root } from 'src/@types/graphql'
import { FactsheetAssessorManagementModal } from 'src/screens/shared/application/assessment/add-assessment/FactsheetAssessorManagementModal'

interface Props {
  onCancel: () => void
  onSuccess: () => void
  onDiscard: () => void
  assessments: Query_Root['factsheet_assessment'] | undefined
  factsheetId: number
}

export const AddFactsheetAssessmentModal = ({ factsheetId, ...restProps }: Props) => {
  return <FactsheetAssessorManagementModal {...restProps} selectedFactsheetIds={[factsheetId]} filterExistingUsers />
}
