import { Card, CardContent, Typography } from '@mui/material'
import { ReactElement, useState } from 'react'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { BaseChart } from 'src/shared/charts/BaseChart'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { AnalysisExportMenu } from 'src/shared/menu/AnalysisExportMenu'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { Section } from 'src/shared/presentation/Section'
import styled from 'styled-components/macro'
import { useSuccessFactorChartContext } from 'src/shared/charts/success-factor/SuccessFactorChartContext'

const LegendStyled = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => theme.colors.text.secondary};
`

export const AnalysisResultsPage = (): ReactElement => {
  const { getMessage } = useMessageSource()
  const { chartData, chartOptions, chartFetching } = useSuccessFactorChartContext()
  const [chartBase64Image, setChartBase64Image] = useState<string>()

  return (
    <ScreenLayout
      title={getMessage('label.success.factors.analysis.details')}
      actions={<AnalysisExportMenu chartBase64Image={chartBase64Image} />}
    >
      <PageLayout>
        <>
          {!chartFetching && (
            <>
              <Section
                id="analysis-results"
                title={getMessage('label.success.factor.analysis.results')}
                helpAndInstructions={<HelpAndInstructions labelKey="label.help.success.factor.analysis.results" />}
              >
                <Card>
                  <CardContent>
                    <BaseChart
                      type="radar"
                      data={chartData}
                      options={{
                        ...chartOptions,
                        animation: {
                          onComplete: (animationEvent) => {
                            setChartBase64Image(animationEvent.chart.toBase64Image())
                          },
                        },
                      }}
                    />
                    <LegendStyled variant="body2">
                      {getMessage('label.success.factor.analysis.results.legend')}
                    </LegendStyled>
                  </CardContent>
                </Card>
              </Section>
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
