import { gql } from 'urql'

export const queryFactsheetUsers = gql`
  query FetchFactsheetUsersQuery($id: Int!) {
    factsheetUsers: factsheet_by_pk(id: $id) {
      factsheet_users(order_by: { user: { email: asc } }) {
        user {
          first_name
          last_name
          email
          status
          id
        }
        type
      }
    }
  }
`

export const insertFactsheetUser = gql`
  mutation ($factsheetId: Int!, $email: String!, $type: UserProjectRole!) {
    addUserToFactsheet(factsheetId: $factsheetId, email: $email, type: $type) {
      status
    }
  }
`

export const deleteUserFromFactsheet = gql`
  mutation ($factsheetId: Int!, $userId: Int!) {
    delete_factsheet_user(where: { _and: { factsheet_id: { _eq: $factsheetId }, user_id: { _eq: $userId } } }) {
      affected_rows
    }
  }
`
