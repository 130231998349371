import { gql } from 'urql'

export const saveFactsheetApplicationSummaryComment = gql`
  mutation (
    $factsheetId: Int!
    $version: Int!
    $factsheetApplicationDecision: [factsheet_application_decision_insert_input!]!
  ) {
    insert_factsheet_application_decision(
      objects: $factsheetApplicationDecision
      on_conflict: {
        constraint: factsheet_application_decision_factsheet_id_uq
        update_columns: [application_comment]
        where: { _and: { factsheet: { id: { _eq: $factsheetId } }, version: { _eq: $version } } }
      }
    ) {
      returning {
        id
        version
      }
      affected_rows
    }
  }
`

export const queryFactsheetFundingRounds = gql`
  query ($process: String!) {
    funding_round(where: { process: { _eq: $process } }, order_by: { start_date: desc }) {
      id
      name
      process
      application_types
      start_date
      end_date
      decision_date
    }
  }
`
