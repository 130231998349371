import { Box, DialogActions, DialogContent, Stack } from '@mui/material'
import { ReactElement, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { Mutation_Root, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { getApplicationTypesQuery } from 'src/screens/administration/round-management/round-management-queries'
import { createFactsheetMutation } from 'src/screens/factsheets/factsheet/factsheetQueries'
import { PrimaryButton, SecondaryButton } from 'src/shared/button/Buttons'
import { ALL_MODULES, LEVEL, LEVEL_TYPE, MODULE_TYPES, PROCESS, TEXT_LENGTH } from 'src/shared/constants/constants'
import { FACTSHEET_WORKFLOW_STATUS, FACTSHEET_WORKFLOW_STATUS_TYPE } from 'src/shared/constants/factsheet-constants'
import { Option } from 'src/shared/form/control'
import { AutoCompleteField } from 'src/shared/form/control/AutoCompleteField'
import { MultiSelectField } from 'src/shared/form/control/MultiSelectField'
import { TextField } from 'src/shared/form/control/TextField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { createDecorators } from 'src/shared/form/utils/decorators'
import { composeValidators, maxChar, required } from 'src/shared/form/validation/validators'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useClient } from 'urql'

interface CreateFactsheetModalDialogProps {
  onCancel: () => void
  onSuccess: (factsheetId: number) => void
}

interface FormValues {
  title: string
  applicationTypeId: number | null
  factsheetLevel: LEVEL_TYPE | null
  modules: MODULE_TYPES[]
  version: string
}

interface AddFactsheetTypes {
  title: any
  published: boolean
  workflow_status: FACTSHEET_WORKFLOW_STATUS_TYPE
  application_type_id: number | null
  factsheetLevel: LEVEL_TYPE | null
  modules: MODULE_TYPES[]
  version: string
}

const queryDataFactory = (values: FormValues): AddFactsheetTypes => {
  return {
    title: {
      en: values.title,
      de: values.title,
      fr: values.title,
      it: values.title,
    },
    published: false,
    workflow_status: FACTSHEET_WORKFLOW_STATUS.CONCEPT,
    application_type_id: values.applicationTypeId,
    factsheetLevel: values.factsheetLevel,
    modules: Utils.sortModules(values.modules),
    version: values.version,
  }
}

export const CreateFactsheetModalDialog = ({ onCancel, onSuccess }: CreateFactsheetModalDialogProps): ReactElement => {
  const decorators = createDecorators()
  const urqlClient = useClient()
  const notificationService = useNotificationService()

  const { getMessage } = useMessageSource()

  const [initialValues, setInitialValues] = useState<FormValues>({
    title: '',
    applicationTypeId: null,
    factsheetLevel: null,
    modules: [],
    version: '',
  })
  const [applicationTypesOptions, setApplicationTypeOptions] = useState<Option[]>([])

  useEffect(() => {
    const initData = async () => {
      const { data } = await urqlClient
        .query<{ application_type: Query_Root['application_type'] }>(getApplicationTypesQuery, {
          userProcesses: [PROCESS.FACTSHEET],
        })
        .toPromise()

      const applicationTypes = data?.application_type ?? []
      const appTypesOptions = applicationTypes.map((applicationType) => ({
        label: getMessage(applicationType.key),
        value: applicationType.id,
      }))

      // select the first option
      if (appTypesOptions.length === 1) {
        setInitialValues((oldValues) => ({ ...oldValues, applicationTypeId: appTypesOptions[0].value }))
      }
      setApplicationTypeOptions(appTypesOptions)
    }

    initData()
  }, [getMessage, urqlClient])

  const autocompleteFieldOptionsFactory = (obj: any, labelBase: string) => {
    return Object.keys(obj).map((x) => {
      return {
        label: getMessage(labelBase + '.' + x),
        value: x,
      }
    })
  }
  const handleSubmitLocal = async (values: FormValues): Promise<any> => {
    const queryData = queryDataFactory(values)

    const { data } = await urqlClient
      .mutation<{ insert_factsheet_one: Mutation_Root['insert_factsheet_one'] }, AddFactsheetTypes>(
        createFactsheetMutation,
        queryData,
      )
      .toPromise()

    const factsheetId = data?.insert_factsheet_one?.id

    if (data && factsheetId) {
      setInitialValues(values)
      notificationService.changesSaved()
      onSuccess(factsheetId)
    } else {
      notificationService.operationFailed()
    }
  }

  const levelOptions = autocompleteFieldOptionsFactory(LEVEL, 'label.level')
  const moduleOptions: Option[] = ALL_MODULES.map((module) => {
    return {
      label: getMessage(`label.module.description.${module}`),
      value: module,
    }
  })

  return (
    <>
      <Box>
        {initialValues && (
          <Form<FormValues>
            initialValues={initialValues}
            onSubmit={handleSubmitLocal}
            decorators={decorators}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} noValidate>
                <DialogContent>
                  <Stack spacing={2}>
                    <AutoCompleteField
                      required
                      label={getMessage('label.type')}
                      name="applicationTypeId"
                      options={applicationTypesOptions}
                      validate={required()}
                      disabled
                    />

                    <TextField
                      required
                      label={getMessage('label.title')}
                      name="title"
                      validate={composeValidators(required(), maxChar(TEXT_LENGTH.M))}
                    />

                    <AutoCompleteField
                      required
                      label={getMessage('label.level')}
                      name="factsheetLevel"
                      options={levelOptions}
                      validate={required()}
                    />

                    <TextField
                      required
                      label={getMessage('label.factsheet.version')}
                      name="version"
                      placeholder={getMessage('label.factsheet.version.placeholder')}
                      validate={composeValidators(required(), maxChar(TEXT_LENGTH.S))}
                    />

                    <MultiSelectField
                      required
                      name="modules"
                      label={getMessage('label.modules')}
                      options={moduleOptions}
                      validate={required()}
                    />

                    <DirtyFormSpy />
                  </Stack>
                </DialogContent>
                <DialogActions>
                  <SecondaryButton messageKey="button.close" onClick={onCancel} />
                  <PrimaryButton messageKey="button.submit" type="submit" />
                </DialogActions>
              </form>
            )}
          />
        )}
      </Box>
    </>
  )
}
