import { Box } from '@mui/material'
import { useContext } from 'react'
import { Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ApplicationTypeManagementGrid } from 'src/screens/administration/application-type-management/ApplicationTypeManagementGrid'
import { getApplicationTypesOverviewQuery } from 'src/screens/administration/application-type-management/applicationTypeManagementQueries'
import { PROCESS } from 'src/shared/constants/constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { Section } from 'src/shared/presentation/Section'
import { Utils } from 'src/shared/utils/Utils'
import { UserContext } from 'src/user/UserContext'
import { useQuery } from 'urql'

export const ApplicationTypeManagementIndexPage = () => {
  const { user } = useContext(UserContext)
  const { getMessage } = useMessageSource()

  const processes = Utils.resolveProcessFromInternalGfchRole(user.roles).filter((item) => item !== PROCESS.FACTSHEET)

  const [{ data }, refetch] = useQuery<
    { application_type: Query_Root['application_type'] },
    { userProcesses: string[] }
  >({
    query: getApplicationTypesOverviewQuery,
    variables: { userProcesses: processes },
  })

  const onSuccess = () => {
    refetch()
  }

  return (
    <ScreenLayout title={getMessage('label.navigation.application-type.management')} hasSecondLevelNavigation={false}>
      <PageLayout>
        <Section
          id="application-types-overview"
          title={getMessage('label.application-type.overview')}
          helpAndInstructions={<HelpAndInstructions labelKey="label.help.application-type.overview" defaultExpansion />}
        >
          <Box>
            <ApplicationTypeManagementGrid applicationTypes={data?.application_type ?? []} onSuccess={onSuccess} />
          </Box>
        </Section>
      </PageLayout>
    </ScreenLayout>
  )
}
