import { ReactElement } from 'react'
import { useParams } from 'react-router'
import { Feature_Base_Bool_Exp, Feature_Config_Bool_Exp, Mutation_Root } from 'src/@types/graphql'
import { ROUTES } from 'src/routing/routes'
import { updateFactsheetMutation } from 'src/screens/factsheets/factsheet/factsheetQueries'
import { FeatureBaseEdit } from 'src/screens/shared/feature/edit/FeatureBaseEdit'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useClient } from 'urql'
import { usePermissionsForFactsheetDetailsFeaturesPage } from 'src/service/security/PermissionHook'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'

export const FactsheetFeaturesEditPage = (): ReactElement => {
  const { factsheetId, featureTypeId } = useParams()
  const factsheet_id = parseInt(factsheetId as string)
  const navigate = useDelayedNavigate()
  const urqlClient = useClient()
  const notificationService = useNotificationService()

  const {
    loading,
    canEdit,
    metadata: { factsheetLevel, feature_update_version: oldFeatureUpdateVersion },
  } = usePermissionsForFactsheetDetailsFeaturesPage(factsheet_id)

  const onBack = (featureGroupConfigCode: string) => {
    const route = ROUTES.FactsheetDescriptionRoot.nested.FactsheetFeatures.params({ factsheetId })
    navigate(`${route}#${featureGroupConfigCode}`)
  }

  const factsheetFeatureBaseWhereClause: Feature_Base_Bool_Exp = {
    factsheets: { id: { _eq: parseInt(factsheetId as string) } },
  }

  const factsheetFeatureConfigWhereClause: Feature_Config_Bool_Exp = {
    levels: {
      _contains: [factsheetLevel],
    },
  }

  // Updating the factsheets feature update version field and trigger the validation check function
  const factsheetUpdate = async () => {
    const { error } = await urqlClient
      .mutation<{
        update_factsheet: Mutation_Root['update_factsheet']
      }>(updateFactsheetMutation, {
        id: parseInt(factsheetId as string),
        object: {
          feature_update_version: oldFeatureUpdateVersion! + 1,
        },
      })
      .toPromise()

    if (error) {
      notificationService.operationFailed()
    }
  }

  if (!loading && !canEdit) {
    return <NotAuthorized inSecondLevelNavigationTab />
  }

  return (
    <>
      {!loading && (
        <FeatureBaseEdit
          featureBaseWhere={factsheetFeatureBaseWhereClause}
          featureConfigWhere={factsheetFeatureConfigWhereClause}
          onBack={onBack}
          featureTypeId={parseInt(featureTypeId as string)}
          requiredSelection
          onSaveCallback={factsheetUpdate}
        />
      )}
    </>
  )
}
