import { gql } from 'urql'

export const getApplicationTypesOverviewQuery = gql`
  query fetchApplicationTypesOverview($userProcesses: [String!]!) {
    application_type(where: { process: { _in: $userProcesses } }, order_by: { sort_number: asc }) {
      id
      code
      key
      process
      public
    }
  }
`

export const updateApplicationTypePublicMutation = gql`
  mutation updateApplicationTypePublic($id: Int!, $public: Boolean!) {
    update_application_type(where: { id: { _eq: $id } }, _set: { public: $public }) {
      affected_rows
    }
  }
`
