import {
  Box,
  Chip,
  IconButton,
  Paper,
  SvgIconProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { sortBy } from 'lodash'
import { ReactElement, useCallback, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Factsheet_User, Mutation_Root, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { AddNewUserFactsheetModalDialog } from 'src/screens/factsheets/factsheet/permissions/AddNewUserFactsheetDialog'
import {
  deleteUserFromFactsheet,
  queryFactsheetUsers,
} from 'src/screens/factsheets/factsheet/permissions/factsheetPermissionsQuery'
import { resolveUserPermissionMessageKey } from 'src/screens/shared/project/permissions/ProjectPermissionsUtils'
import { usePermissionsForFactsheetUsers } from 'src/service/security/PermissionHook'
import { AddButton } from 'src/shared/button/Buttons'
import { PROJECT_USER_ROLES, USER_STATUS } from 'src/shared/constants/constants'
import { DeleteIcon, PersonAddIcon, PersonIcon } from 'src/shared/icons/Icons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { FactsheetExportMenu } from 'src/shared/menu/FactsheetExportMenu'
import { ConfirmationModalDialog } from 'src/shared/modal-dialog/ConfirmationModalDialog'
import { ModalDialog } from 'src/shared/modal-dialog/ModalDialog'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { Section } from 'src/shared/presentation/Section'
import { S } from 'src/shared/styled/S'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { UserContext } from 'src/user/UserContext'
import styled from 'styled-components/macro'
import { useClient } from 'urql'

const StyledTableRow = styled(TableRow)`
  & .MuiTableCell-root {
    padding-top: ${({ theme }) => theme.spacing(1.5)};
    padding-bottom: ${({ theme }) => theme.spacing(1.5)};
  }
`

const StyledTableCell = styled(TableCell)<{ $active?: boolean }>`
  color: ${({ $active, theme }) => ($active ? theme.colors.text.primary : theme.colors.text.secondary)};
`

const StyledChip = styled(Chip)<{ $active: boolean } & SvgIconProps>`
  background-color: ${({ $active, theme }) => ($active ? theme.colors.success.light : theme.colors.grey.background)};
  color: ${({ $active, theme }) => ($active ? theme.colors.success.dark : theme.colors.text.secondary)};
`

export const FactsheetPermissionsPage = (): ReactElement => {
  const { getMessage } = useMessageSource()
  const { user } = useContext(UserContext)
  const urqlClient = useClient()
  const { factsheetId } = useParams()
  const notificationService = useNotificationService()

  const [factsheetUsers, setFactsheetUsers] = useState<Factsheet_User[]>([])
  const [openAddUserDialog, setOpenAddUserDialog] = useState(false)
  const [userDeleteConfirmationOpen, setUserDeleteConfirmationOpen] = useState(false)
  const [userIdToBeDeleted, setUserIdToBeDeleted] = useState<number | null>(null)

  const factsheetIdNumber = parseInt(factsheetId as string)

  const { loading, canView, canModifyFactsheetUsers } = usePermissionsForFactsheetUsers(factsheetIdNumber)

  const isUserActive = (user: Factsheet_User): boolean => user.user.status === USER_STATUS.ACTIVE

  const fetchUsers = useCallback(async () => {
    const { data, error } = await urqlClient
      .query<
        {
          factsheetUsers: Query_Root['factsheet_by_pk']
        },
        {
          id: number
        }
      >(queryFactsheetUsers, { id: factsheetIdNumber })
      .toPromise()

    if (error) {
      notificationService.operationFailed()
      return
    }
    const fetchedFactsheetUsers = data?.factsheetUsers?.factsheet_users ?? []
    const filteredFactsheetUsers = fetchedFactsheetUsers.filter((user) => user.type !== PROJECT_USER_ROLES.EXPERT)
    setFactsheetUsers(sortBy(filteredFactsheetUsers, [(pu: Factsheet_User) => pu.type !== PROJECT_USER_ROLES.ADMIN]))
  }, [factsheetIdNumber, urqlClient, notificationService])

  useEffect(() => {
    fetchUsers()
  }, [fetchUsers])

  const handleOpenAddNewUserDialog = () => {
    setOpenAddUserDialog(true)
  }

  const handleDeleteUser = (userId: number) => () => {
    setUserIdToBeDeleted(userId)
    setUserDeleteConfirmationOpen(true)
  }
  const handleCancelDeleteUser = () => {
    setUserDeleteConfirmationOpen(false)
  }

  const closeAddUserDialog = async () => {
    setOpenAddUserDialog(false)
    await fetchUsers()
  }

  const handleDeleteUserConfirmed = async () => {
    setUserDeleteConfirmationOpen(false)
    const { error } = await urqlClient
      .mutation<{ delete_factsheet_user: Mutation_Root['delete_factsheet_user'] }>(deleteUserFromFactsheet, {
        factsheetId: factsheetId,
        userId: userIdToBeDeleted,
      })
      .toPromise()
    if (error) {
      notificationService.operationFailed()
    } else {
      notificationService.deleteSuccessful()
      await fetchUsers()
    }
  }

  if (!loading && !canView) {
    return <NotAuthorized />
  }

  return (
    <>
      {!loading && (
        <ScreenLayout
          title={getMessage('label.navigation.factsheets.permissions')}
          hasSecondLevelNavigation={false}
          actions={<FactsheetExportMenu />}
        >
          <PageLayout>
            {!loading && (
              <Section
                title={getMessage('label.project.permissions.users')}
                actionButton={
                  canModifyFactsheetUsers && (
                    <AddButton
                      messageKey="button.user.add"
                      startIcon={<PersonAddIcon />}
                      onClick={handleOpenAddNewUserDialog}
                    />
                  )
                }
                helpAndInstructions={<HelpAndInstructions labelKey="label.help.factsheet.permissions" />}
              >
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{getMessage('label.name')}</TableCell>
                        <TableCell>{getMessage('label.permissions')}</TableCell>
                        <TableCell>{getMessage('label.status')}</TableCell>
                        {canModifyFactsheetUsers && <TableCell align="right">{getMessage('label.actions')}</TableCell>}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {factsheetUsers.map((u, i) => (
                        <StyledTableRow key={i}>
                          <StyledTableCell $active={isUserActive(u)}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <S.Avatar $randomColorKey={isUserActive(u) ? u?.user.email : ''}>
                                {isUserActive(u) ? u.user.first_name?.charAt(0) : <PersonIcon />}
                              </S.Avatar>
                              <S.DataGrid.TwoLineCell>
                                <Typography variant="subtitle2">
                                  {isUserActive(u)
                                    ? `${u.user.first_name} ${u.user.last_name}`
                                    : getMessage('label.user.unknown')}
                                </Typography>
                                <Typography variant="caption" color="textSecondary">
                                  {u.user.email}
                                </Typography>
                              </S.DataGrid.TwoLineCell>
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell $active={isUserActive(u)}>
                            {getMessage(resolveUserPermissionMessageKey(u.type))}
                          </StyledTableCell>
                          <StyledTableCell $active={isUserActive(u)}>
                            <StyledChip
                              $active={isUserActive(u)}
                              label={getMessage(`label.${u.user.status.toLowerCase()}`)}
                            />
                          </StyledTableCell>
                          {canModifyFactsheetUsers && (
                            <StyledTableCell align="right">
                              {user.email !== u.user.email && u.type !== PROJECT_USER_ROLES.ADMIN && (
                                <IconButton color="primary" onClick={handleDeleteUser(u.user.id)}>
                                  <DeleteIcon />
                                </IconButton>
                              )}
                            </StyledTableCell>
                          )}
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                {factsheetIdNumber && (
                  <>
                    <ModalDialog
                      open={openAddUserDialog}
                      title={getMessage('label.add.new.user')}
                      maxWidth="sm"
                      withCloseIcon={false}
                      onClose={closeAddUserDialog}
                    >
                      <AddNewUserFactsheetModalDialog
                        onCancel={closeAddUserDialog}
                        onSuccess={closeAddUserDialog}
                        factsheetId={factsheetIdNumber}
                      />
                    </ModalDialog>
                  </>
                )}

                <ConfirmationModalDialog
                  open={userDeleteConfirmationOpen}
                  onCancel={handleCancelDeleteUser}
                  onConfirm={handleDeleteUserConfirmed}
                >
                  {getMessage('label.delete.confirm')}
                </ConfirmationModalDialog>
              </Section>
            )}
          </PageLayout>
        </ScreenLayout>
      )}
    </>
  )
}
