import styled from 'styled-components/macro'
import { Alert } from '@mui/material'
import React, { ReactElement } from 'react'
import { InfoOutlined } from '@mui/icons-material'
import { CheckIcon, ErrorOutlineIcon, GoToPageIcon } from 'src/shared/icons/Icons'
import { LIST_HEIGHT, STYLED_FOCUS_OUTLINE } from 'src/shared/constants/styling-constants'

const AlertStyled = styled(Alert)`
  height: ${LIST_HEIGHT};
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.text.primary};

  & .MuiAlert-action {
    padding-top: 0;
  }
`

interface AlertProps {
  message: string
  onClick?: () => void
  onKeyPress?: (event: any) => void
  noItemsToValidate?: boolean
}

const AlertSuccessStyled = styled(AlertStyled)`
  background-color: ${({ theme }) => theme.colors.secondary.light};

  & .MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.secondary.main};
  }
`

const AlertInfoStyled = styled(AlertStyled)`
  background-color: ${({ theme }) => theme.colors.grey.neutral};

  & .MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.text.disabled};
  }
`

export const ListItemComplete = ({ message, noItemsToValidate, ...rest }: AlertProps): ReactElement => {
  return noItemsToValidate ? (
    <AlertInfoStyled severity="info" icon={<InfoOutlined />} {...rest}>
      {message}
    </AlertInfoStyled>
  ) : (
    <AlertSuccessStyled severity="success" icon={<CheckIcon />} {...rest}>
      {message}
    </AlertSuccessStyled>
  )
}

const AlertWarningStyled = styled(AlertStyled)`
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.primary.light};
  }

  &:hover .MuiAlert-action {
    & .MuiSvgIcon-root {
      color: ${({ theme }) => theme.colors.primary.main};
    }
  }

  &:focus-visible {
    ${STYLED_FOCUS_OUTLINE};
    outline-offset: 0;
    background-color: ${({ theme }) => theme.colors.primary.light};
  }
`

export const ListItemIncomplete = ({ message, ...rest }: AlertProps): ReactElement => {
  return (
    <AlertWarningStyled severity="warning" icon={<ErrorOutlineIcon />} action={<GoToPageIcon />} {...rest} tabIndex={0}>
      {message}
    </AlertWarningStyled>
  )
}
