import React, { ReactElement, useEffect, useState } from 'react'
import { LEVEL_TYPE } from 'src/shared/constants/constants'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { useParams } from 'react-router'
import { useUserLocale } from 'src/user/UserContext'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { Mutation_Root, Query_Root } from 'src/@types/graphql'
import {
  getCriteriaConfigByLevelQuery,
  saveFactsheetAssessmentCriteriaSelection,
} from 'src/screens/factsheets/application/assessment/factsheetAssessmentQueries'
import {
  CriteriaFormValues,
  createGroupsAndInitialData,
  CriteriaGroups,
  filterOutUnselects,
  mapSelectionsToAssessmentCriteriaSelectionsInputs,
} from 'src/screens/factsheets/application/assessment/details/common/FactsheetAssessmentUtils'
import { useClient } from 'urql'
import { ROUTES } from 'src/routing/routes'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { Section } from 'src/shared/presentation/Section'
import { Box } from '@mui/system'
import { Form } from 'react-final-form'
import { Stack } from '@mui/material'
import { FactsheetAssessmentConfigTable } from 'src/screens/factsheets/application/assessment/details/common/FactsheetAssessmentConfigTable'
import { get } from 'lodash'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { usePermissionsForFactsheetAssessmentDetails } from 'src/service/security/PermissionHook'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'

interface Props {
  level: LEVEL_TYPE
}

export const FactsheetAssessmentDetailsLevelsEditPage = ({ level }: Props): ReactElement => {
  const { getMessage } = useMessageSource()
  const { factsheetId, assessmentId } = useParams()
  const assessmentIdNumber = parseInt(assessmentId as string)

  const language = useUserLocale()
  const notificationService = useNotificationService()

  const navigate = useDelayedNavigate()
  const urqlClient = useClient()

  const { canEdit, loading } = usePermissionsForFactsheetAssessmentDetails(assessmentIdNumber)

  const [criteriaGroups, setCriteriaGroups] = useState<CriteriaGroups | undefined>()
  const [initialValues, setInitialValues] = useState<CriteriaFormValues | undefined>()

  useEffect(() => {
    const initData = async () => {
      const { data } = await urqlClient
        .query<{
          factsheet_assessment_criteria_selection: Query_Root['factsheet_assessment_criteria_selection']
          criteria_group_config: Query_Root['criteria_group_config']
        }>(getCriteriaConfigByLevelQuery, {
          level: level,
          assessmentId: assessmentId,
        })
        .toPromise()

      if (data) {
        const selectedQualities = data.factsheet_assessment_criteria_selection
        const criteriaGroupedConfigs = data.criteria_group_config

        const res = createGroupsAndInitialData(selectedQualities, criteriaGroupedConfigs)

        setCriteriaGroups(res.criteriaGroups)
        setInitialValues(res.initialValues)
      } else {
        notificationService.operationFailed()
      }
    }

    initData()
  }, [urqlClient, assessmentId, factsheetId, level])

  const onBack = () => {
    const levelRoute = ROUTES.FactsheetApplicationAssessmentDetailsRoot.nested[level]

    if (levelRoute?.params) {
      navigate(levelRoute.params({ factsheetId, assessmentId }))
    }
  }

  const handleSubmitLocal = async (formValues: CriteriaFormValues): Promise<any> => {
    const { ...values } = formValues

    const selections = Object.entries(values)
    const newCriteriaSelections = filterOutUnselects(selections)

    const factsheetAssessmentInserts = mapSelectionsToAssessmentCriteriaSelectionsInputs(
      assessmentIdNumber,
      newCriteriaSelections,
    )

    const { data, error } = await urqlClient
      .mutation<{
        delete_factsheet_assessment_criteria_selection: Mutation_Root['delete_factsheet_assessment_criteria_selection']
        insert_factsheet_assessment_criteria_selection: Mutation_Root['insert_factsheet_assessment_criteria_selection']
      }>(saveFactsheetAssessmentCriteriaSelection, {
        assessmentId: assessmentId,
        selections: factsheetAssessmentInserts,
      })
      .toPromise()

    if (error || data === undefined) {
      notificationService.operationFailed()
    } else {
      setInitialValues(formValues)
      notificationService.changesSaved()
    }
  }

  let submit: any = () => {}
  let formValid = false

  const onSave = (event: any) => {
    submit(event)
  }

  const onSaveAndBack = async (event: any) => {
    try {
      await submit(event)
      formValid && onBack()
    } catch {
      // do nothing
    }
  }

  if (!loading && !canEdit) {
    return <NotAuthorized inEditPage />
  }

  return (
    <ScreenLayout
      title={getMessage(`label.assessment.${level.toLowerCase()}.criteria.edit`)}
      onBack={onBack}
      hasSecondLevelNavigation={false}
      actions={
        <>
          <SaveAndBackButton origin="header" onClick={onSaveAndBack} />
          <SaveButton origin="header" onClick={onSave} />
        </>
      }
    >
      {!loading && (
        <PageLayout>
          <Section
            id="exclusion-criteria"
            helpAndInstructions={
              <HelpAndInstructions labelKey={`label.help.assessment.${level.toLowerCase()}.criteria`} />
            }
          >
            <Box mt={2}>
              {criteriaGroups && initialValues && (
                <Form<CriteriaFormValues>
                  initialValues={initialValues}
                  onSubmit={handleSubmitLocal}
                  render={({ handleSubmit, valid }) => {
                    submit = handleSubmit
                    formValid = valid

                    return (
                      <form onSubmit={handleSubmit} noValidate>
                        <Stack spacing={5}>
                          {Object.values(criteriaGroups)
                            .sort((a, b) => a?.[0].sort_number - b?.[0].sort_number)
                            .map((criteriaSelections) => (
                              <FactsheetAssessmentConfigTable
                                key={criteriaSelections[0].id}
                                rows={criteriaSelections}
                                groupTitle={get(criteriaSelections[0].cgNames, language, '')}
                                mode="edit"
                              />
                            ))}
                        </Stack>
                        <DirtyFormSpy />
                      </form>
                    )
                  }}
                />
              )}
            </Box>
          </Section>
        </PageLayout>
      )}
    </ScreenLayout>
  )
}
