import { get } from 'lodash'
import React, { ReactElement } from 'react'
import { useParams } from 'react-router'
import { Feature_Base_Bool_Exp, Feature_Config_Bool_Exp } from 'src/@types/graphql'
import { ROUTES } from 'src/routing/routes'
import { FeatureBase } from 'src/screens/shared/feature/FeatureBase'
import { usePermissionsForFactsheetDetailsFeaturesPage } from 'src/service/security/PermissionHook'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { FactsheetExportMenu } from 'src/shared/menu/FactsheetExportMenu'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useUserLocale } from 'src/user/UserContext'

export const FactsheetFeaturesPage = (): ReactElement => {
  const { factsheetId } = useParams()
  const factsheet_id = parseInt(factsheetId as string)
  const language = useUserLocale()
  const {
    loading,
    canEdit,
    metadata: { factsheetLevel, title },
  } = usePermissionsForFactsheetDetailsFeaturesPage(factsheet_id)
  const navigate = useDelayedNavigate()

  const handleEditFeatureTypeSection = (featureTypeId: number) => {
    navigate(ROUTES.FactsheetDescriptionRoot.nested.FactsheetFeaturesEdit.params({ factsheetId, featureTypeId }))
  }

  const factsheetBaseWhereClause: Feature_Base_Bool_Exp = {
    factsheets: {
      id: {
        _eq: parseInt(factsheetId as string),
      },
    },
  }

  const factsheetLevelWhereClause: Feature_Config_Bool_Exp = {
    levels: {
      _contains: [factsheetLevel],
    },
  }

  return (
    <>
      {!loading && (
        <ScreenLayout title={get(title, language, '')} actions={<FactsheetExportMenu />}>
          <PageLayout>
            <FeatureBase
              featureBaseWhere={factsheetBaseWhereClause}
              featureConfigWhere={factsheetLevelWhereClause}
              canEdit={canEdit}
              onEditFeature={handleEditFeatureTypeSection}
            />
          </PageLayout>
        </ScreenLayout>
      )}
    </>
  )
}
