import { Stack } from '@mui/material'
import combinedQuery, { CombinedQueryBuilder } from 'graphql-combine-query'
import { ReactElement, useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { useParams } from 'react-router'
import { valueof } from 'src/@types/global'
import { Factsheet, Mutation_Root, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { getApplicationTypesQuery } from 'src/screens/administration/round-management/round-management-queries'
import {
  fetchFactsheetBasicInfoEdit,
  updateFactsheetMutation,
  updateFactsheetProjectLeaderMutation,
  updatePreviousFactsheetLeaderRole,
} from 'src/screens/factsheets/factsheet/factsheetQueries'
import { usePermissionsForFactsheetDetailsDescriptionPage } from 'src/service/security/PermissionHook'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import {
  ALL_MODULES,
  FACTSHEET_USER_ROLE_TYPE,
  FACTSHEET_USER_ROLES,
  LEVEL,
  LEVEL_TYPE,
  MODULE_TYPES,
  PROCESS,
  PROJECT_USER_ROLES,
  TEXT_LENGTH,
} from 'src/shared/constants/constants'
import { FACTSHEET_WORKFLOW_STATUS } from 'src/shared/constants/factsheet-constants'
import { Option } from 'src/shared/form/control'
import { AutoCompleteField } from 'src/shared/form/control/AutoCompleteField'
import { HtmlEditorField } from 'src/shared/form/control/HtmlEditorField'
import { MultiSelectField } from 'src/shared/form/control/MultiSelectField'
import { TextField } from 'src/shared/form/control/TextField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { createDecorators } from 'src/shared/form/utils/decorators'
import { composeValidators, maxChar, required } from 'src/shared/form/validation/validators'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useError } from 'src/shared/utils/hooks/page-loading-error-hook'
import { useIsMounted } from 'src/shared/utils/hooks/react-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useUser } from 'src/user/UserContext'
import { useClient } from 'urql'

interface FactsheetEditForm {
  application_type_id: number
  level: LEVEL_TYPE
  modules: MODULE_TYPES[]
  published: boolean
  version: string
  organization?: string
  contact?: string
  projectLeaderId: number | null
}

const decorators = createDecorators()

export const FactsheetBasicInformationEditPage = (): ReactElement => {
  const { factsheetId } = useParams()
  const factsheetIdNumber = parseInt(factsheetId as string)
  const { user } = useUser()
  const userRoles = user.roles
  const isMounted = useIsMounted()
  const { getMessage } = useMessageSource()
  const navigate = useDelayedNavigate()
  const urqlClient = useClient()
  const notificationService = useNotificationService()

  const {
    canEdit,
    loading,
    metadata: { userFactsheetRoles },
  } = usePermissionsForFactsheetDetailsDescriptionPage(factsheetIdNumber)
  const setError = useError()

  const [initialValues, setInitialValues] = useState<FactsheetEditForm>()
  const [factsheetData, setFactsheetData] = useState<Factsheet>()
  const [applicationTypesOptions, setApplicationTypeOptions] = useState<Option[]>([])

  useEffect(() => {
    const initData = async () => {
      const { data } = await urqlClient
        .query<{
          factsheet_by_pk: Query_Root['factsheet_by_pk']
        }>(fetchFactsheetBasicInfoEdit, { id: factsheetIdNumber })
        .toPromise()

      const applicationTypes = await getApplicationTypes()
      if (data) {
        setInitialValues({
          application_type_id: data.factsheet_by_pk?.application_type_id ?? -1,
          level: data.factsheet_by_pk?.level as LEVEL_TYPE,
          modules: data.factsheet_by_pk?.modules as MODULE_TYPES[],
          published: data.factsheet_by_pk?.published ?? false,
          organization: data.factsheet_by_pk?.organization ?? '',
          contact: data.factsheet_by_pk?.contact ?? '',
          projectLeaderId: data.factsheet_by_pk?.project_leader_id ?? null,
          version: data.factsheet_by_pk?.version ?? '',
        })

        setFactsheetData(data.factsheet_by_pk as Factsheet)

        const appTypesOptions = applicationTypes.map((applicationType) => ({
          label: getMessage(applicationType.key),
          value: applicationType.id,
        }))
        setApplicationTypeOptions(appTypesOptions)
      } else {
        setError()
      }
    }

    const getApplicationTypes = async () => {
      const { data } = await urqlClient
        .query<{ application_type: Query_Root['application_type'] }>(getApplicationTypesQuery, {
          userProcesses: [PROCESS.FACTSHEET],
        })
        .toPromise()
      return data?.application_type ?? []
    }

    initData()
  }, [urqlClient, setError, factsheetIdNumber])

  const projectUsersOptions = useMemo(() => {
    const factsheetUsers = factsheetData?.factsheet_users ?? []

    return factsheetUsers
      .filter(
        (factsheet_user) =>
          factsheet_user.type === PROJECT_USER_ROLES.CONTRIBUTOR || factsheet_user.type === PROJECT_USER_ROLES.ADMIN,
      )
      .map((factsheet_user) => ({
        label: factsheet_user.user.email,
        value: factsheet_user.user_id,
      }))
      .filter(
        (project_user, index, self) =>
          self.findIndex((project_user) => project_user.label === self[index].label) === index,
      )
  }, [factsheetData, getMessage])

  let submit: any = () => {}
  let formValid = false

  const onSave = (event: any) => {
    submit(event)
  }

  const onSaveAndBack = async (event: any) => {
    try {
      await submit(event)
      formValid && onBack()
    } catch {
      // do nothing
    }
  }

  const onSubmitLocal = async (values: FactsheetEditForm) => {
    const mutationObject = {
      modules: Utils.sortModules(values.modules),
      published: values.published,
      organization: values.organization ?? '',
      contact: values.contact ?? '',
      version: values.version,
      project_leader_id: values.projectLeaderId,
    }

    const projectLeaderId = values.projectLeaderId

    let combinedMutation = combinedQuery('UpdateFactsheetDescriptionAndLeader') as unknown as CombinedQueryBuilder

    // if project leader is changed
    if (projectLeaderId !== initialValues?.projectLeaderId) {
      // project leader changed and not null, update user permission to admin
      if (projectLeaderId != null) {
        combinedMutation = combinedMutation.add<
          {
            set_current_factsheet_admin: Mutation_Root['update_factsheet_user']
          },
          {
            newProjectLeaderId: number
            projectLeaderRole: string
            newFactsheetId: number
          }
        >(updateFactsheetProjectLeaderMutation, {
          newProjectLeaderId: projectLeaderId as number,
          projectLeaderRole: PROJECT_USER_ROLES.ADMIN,
          newFactsheetId: factsheetIdNumber,
        })
      }

      // project leader changed and initial project leader is not null, update previous project leader to
      // contributor
      if (initialValues?.projectLeaderId != null) {
        combinedMutation = combinedMutation.add<
          {
            set_previous_factsheet_admin_as_contributor: Mutation_Root['update_factsheet_user']
          },
          {
            previousProjectLeaderId: number
            projectContributorRole: string
            prevFactsheetId: number
          }
        >(updatePreviousFactsheetLeaderRole, {
          previousProjectLeaderId: initialValues.projectLeaderId as number,
          projectContributorRole: PROJECT_USER_ROLES.CONTRIBUTOR,
          prevFactsheetId: factsheetIdNumber,
        })
      }
    }

    combinedMutation = combinedMutation.add<{
      update_factsheet: Mutation_Root['update_factsheet']
    }>(updateFactsheetMutation, { id: factsheetIdNumber, object: mutationObject })

    const { document, variables: vars } = combinedMutation

    const { data, error } = await urqlClient
      .mutation<{
        update_factsheet: Mutation_Root['update_factsheet']
      }>(document, vars)
      .toPromise()

    if (error || data?.update_factsheet?.affected_rows !== 1) {
      notificationService.operationFailed()
      return Promise.reject(error)
    } else if (isMounted()) {
      setInitialValues(values)
      notificationService.changesSaved()
      return Promise.resolve()
    }
  }

  const onBack = () => {
    navigate(ROUTES.FactsheetDescriptionRoot.params({ factsheetId }))
  }

  const hasCollaboratorFactsheetRole = (factsheetUserRoles: Array<valueof<FACTSHEET_USER_ROLE_TYPE>>): boolean => {
    return [FACTSHEET_USER_ROLES.ADMIN, FACTSHEET_USER_ROLES.CONTRIBUTOR, FACTSHEET_USER_ROLES.READER].some((r) =>
      factsheetUserRoles.includes(r),
    )
  }

  const showProjectLead = (factsheet: Factsheet | undefined): boolean => {
    if (factsheet) {
      if (
        factsheet.workflow_status === FACTSHEET_WORKFLOW_STATUS.CONCEPT ||
        factsheet.workflow_status === FACTSHEET_WORKFLOW_STATUS.REVISION ||
        factsheet.workflow_status === FACTSHEET_WORKFLOW_STATUS.ACCEPTED
      ) {
        if (Utils.isExternalRole(userRoles) && !hasCollaboratorFactsheetRole(userFactsheetRoles)) {
          return false
        }

        if (Utils.isCantonalRole(user.roles)) {
          return false
        }
      }

      return true
    } else {
      return false
    }
  }

  if (!loading && !canEdit) {
    return <NotAuthorized />
  }

  return (
    <>
      <ScreenLayout
        title={getMessage('label.factsheet.title.edit')}
        onBack={onBack}
        hasSecondLevelNavigation={false}
        actions={
          <>
            <SaveAndBackButton origin="header" onClick={onSaveAndBack} />
            <SaveButton origin="header" onClick={onSave} />
          </>
        }
      >
        <PageLayout>
          <>
            {!loading && initialValues && (
              <>
                <HelpAndInstructions labelKey="label.help.factsheet.basic.information" defaultExpansion />
                <Form<FactsheetEditForm>
                  initialValues={initialValues}
                  onSubmit={onSubmitLocal}
                  decorators={decorators}
                  render={({ handleSubmit, valid }) => {
                    submit = handleSubmit
                    formValid = valid
                    return (
                      <form onSubmit={handleSubmit} noValidate id="edit-factsheet-form">
                        <Stack spacing={2}>
                          <AutoCompleteField
                            name="application_type_id"
                            label={getMessage('label.factsheet.type')}
                            disabled
                            options={applicationTypesOptions}
                          />
                          <AutoCompleteField
                            name="level"
                            label={getMessage('label.level')}
                            disabled
                            options={Object.keys(LEVEL).map((level) => ({
                              label: getMessage(`label.level.${level}`),
                              value: level,
                            }))}
                          />
                          <MultiSelectField
                            name="modules"
                            label={getMessage('label.modules')}
                            options={ALL_MODULES.map((module) => ({
                              label: getMessage(`label.module.description.${module}`),
                              value: module,
                            }))}
                            unavailableOptionsMessage={getMessage('label.field.no.value')}
                            required
                            validate={required()}
                          />
                          {Utils.isFactsheetCoordinatorRole(userRoles) && (
                            <AutoCompleteField
                              name="published"
                              label={getMessage('label.factsheet.published')}
                              options={[
                                {
                                  label: getMessage('label.factsheet.published.true'),
                                  value: true,
                                },
                                {
                                  label: getMessage('label.factsheet.published.false'),
                                  value: false,
                                },
                              ]}
                              validate={required()}
                            />
                          )}
                          {Utils.isFactsheetCoordinatorRole(userRoles) && (
                            <TextField
                              name="version"
                              label={getMessage('label.factsheet.version')}
                              placeholder={getMessage('label.factsheet.version.placeholder')}
                              required
                              validate={composeValidators(required(), maxChar(TEXT_LENGTH.S))}
                            />
                          )}
                          <TextField
                            name="organization"
                            label={getMessage('label.organization')}
                            validate={maxChar(TEXT_LENGTH.M)}
                          />
                          <HtmlEditorField
                            name="contact"
                            label={getMessage('label.contact')}
                            validate={maxChar(TEXT_LENGTH.XL)}
                          />
                          {showProjectLead(factsheetData) && (
                            <AutoCompleteField
                              label={getMessage('label.project.leader')}
                              name="projectLeaderId"
                              options={projectUsersOptions}
                            />
                          )}

                          <DirtyFormSpy />
                        </Stack>
                      </form>
                    )
                  }}
                />
              </>
            )}
          </>
        </PageLayout>
      </ScreenLayout>
    </>
  )
}
