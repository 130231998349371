import React, { ReactElement, useRef } from 'react'
import { useParams } from 'react-router'
import { useUserLocale } from 'src/user/UserContext'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { usePermissionsForFactsheetCommittees } from 'src/service/security/PermissionHook'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import {
  useResolveFactsheetCommitteeData,
  useUpdateFactsheetCommitteeData,
} from 'src/screens/factsheets/application/committee/common/FactsheetCommitteeService'
import { PROCESS } from 'src/shared/constants/constants'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { FactsheetCommitteeEditForm } from 'src/screens/factsheets/application/committee/edit/FactsheetCommitteeEditForm'
import { FactsheetCommitteeValues } from 'src/screens/factsheets/application/committee/common/FactsheetApplicationCommitteeUtils'

type originType = 'SAVE' | 'SAVE_AND_BACK'

export const FactsheetApplicationCommitteeEditPage = (): ReactElement => {
  const { factsheetId } = useParams()
  const factsheet_id = parseInt(factsheetId as string)
  const { getMessage } = useMessageSource()
  const locale = useUserLocale()
  const navigate = useDelayedNavigate()
  const originRef = useRef<originType>('SAVE')

  const {
    loading,
    canEdit,
    metadata: { factsheetLevel },
  } = usePermissionsForFactsheetCommittees(factsheet_id)

  const { initialFormValues, optionValues, setInitialFormValues } = useResolveFactsheetCommitteeData(
    factsheet_id,
    PROCESS.FACTSHEET,
    factsheetLevel || undefined,
    true,
  )
  const updateCommitteeData = useUpdateFactsheetCommitteeData()

  const onUpdateHandler = async (values: FactsheetCommitteeValues) => {
    await updateCommitteeData(values, factsheet_id, setInitialFormValues, originRef, onBack)
  }

  const onSubmit = (origin: originType) => (_: any) => {
    // trigger submit event
    if (originRef) {
      originRef.current = origin
    }
    document.getElementById('committees-form')?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const onBack = () => {
    navigate(ROUTES.FactsheetApplicationCommittee.params({ factsheetId }))
  }

  if (!loading && !canEdit) {
    return <NotAuthorized inEditPage />
  }

  return (
    <ScreenLayout
      title={getMessage('label.title.edit.application.committees')}
      onBack={onBack}
      hasSecondLevelNavigation={false}
      actions={
        <>
          <SaveAndBackButton origin="header" onClick={onSubmit('SAVE_AND_BACK')} />
          <SaveButton origin="header" onClick={onSubmit('SAVE')} />
        </>
      }
    >
      <PageLayout>
        {!loading && (
          <>
            <HelpAndInstructions labelKey={`label.help.committees.factsheet`} defaultExpansion />
            <FactsheetCommitteeEditForm
              locale={locale}
              initialValues={initialFormValues}
              qualityCriteriaOptions={optionValues.qualityCriteriaOptions}
              exclusionCriteriaOptions={optionValues.exclusionCriteriaOptions}
              gfchResponsibleOptions={optionValues.gfchResponsibleOptions}
              onSave={onUpdateHandler}
            />
          </>
        )}
      </PageLayout>
    </ScreenLayout>
  )
}
