import { BaseChart } from 'src/shared/charts/BaseChart'
import React, { ReactElement } from 'react'
import { CSSProperties } from 'styled-components/macro'
import { useMilestoneFinalReportChartContext } from 'src/shared/charts/milestone-final-report/MilestoneFinalReportChartContext'

interface Props {
  setChartBase64Image: React.Dispatch<React.SetStateAction<string | undefined>>
  style?: CSSProperties
}
export const MilestoneFinalReportChart = ({ setChartBase64Image, style }: Props): ReactElement => {
  const { chartData, chartOptions, chartFetching } = useMilestoneFinalReportChartContext()

  return (
    <>
      {!chartFetching && (
        <BaseChart
          type="radar"
          style={style}
          data={chartData}
          options={{
            ...chartOptions,
            animation: {
              onComplete: (animationEvent) => {
                setChartBase64Image(animationEvent.chart.toBase64Image())
              },
            },
          }}
        />
      )}
    </>
  )
}
