import { Button, Card, Stack } from '@mui/material'
import { ReactElement, useCallback, useContext, useEffect, useState } from 'react'
import { Factsheet, FactsheetSearchInput, FilteredFactsheetResponseData, Mutation_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { fetchFactsheetSearchDataMutation } from 'src/screens/factsheets/factsheet/factsheetQueries'
import { CreateFactsheetModalDialog } from 'src/screens/factsheets/index/add-factsheet/CreateFactsheetModalDialog'
import { CopyFactsheetFormDialog } from 'src/screens/factsheets/index/copy-factsheet/CopyFactsheetFormDialog'
import { CopyFactsheetSelectionDialog } from 'src/screens/factsheets/index/copy-factsheet/CopyFactsheetSelectionDialog'
import { FactsheetsIndexGrid } from 'src/screens/factsheets/index/FactsheetsIndexGrid'
import { FactsheetsFilterCard } from 'src/screens/factsheets/index/filter-card/FactsheetsFilterCard'
import { FactsheetsReportService } from 'src/service/axios/FactsheetsReportService'
import { usePermissionsForFactsheetsIndexPage } from 'src/service/security/PermissionHook'
import { AddButton } from 'src/shared/button/Buttons'
import { ALL_MODULES, LEVEL } from 'src/shared/constants/constants'
import { AddIcon, CopyIcon } from 'src/shared/icons/Icons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { ModalDialog } from 'src/shared/modal-dialog/ModalDialog'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { Section } from 'src/shared/presentation/Section'
import { saveFileAs } from 'src/shared/utils/BlobUtils'
import { useModalCancel } from 'src/shared/utils/hooks/modal-hooks'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useError } from 'src/shared/utils/hooks/page-loading-error-hook'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import StorageUtils, { STORAGE_KEY } from 'src/shared/utils/StorageUtils'
import { UserContext, useUserLocale } from 'src/user/UserContext'
import { useClient } from 'urql'

export const FactsheetsIndexPage = (): ReactElement => {
  const delayedNavigate = useDelayedNavigate()
  const urqlClient = useClient()
  const { user } = useContext(UserContext)
  const setError = useError()
  const notificationService = useNotificationService()
  const language = useUserLocale()

  const { getMessage } = useMessageSource()
  const { canCreate, canViewAllFactsheets, canCopy } = usePermissionsForFactsheetsIndexPage()

  const [openCreateFactsheetDialog, setOpenCreateFactsheetDialog] = useState(false)

  const [openCopyFactsheetFormDialog, setOpenCopyFactsheetFormDialog] = useState<boolean>(false)
  const [openCopyFactsheetSelectionDialog, setOpenCopyFactsheetSelectionDialog] = useState<boolean>(false)
  const [selectedFactsheetForCopy, setSelectedFactsheetForCopy] = useState<Factsheet>()

  const [filteredFactsheets, setFilteredFactsheets] = useState<FilteredFactsheetResponseData[]>([])
  const [factsheetsCount, setFactsheetsCount] = useState<number>()
  const [factsheetsSearchInput, setFactsheetsSearchInput] = useState<FactsheetSearchInput>()

  const handleOpenCreateFactsheetDialog = () => setOpenCreateFactsheetDialog(true)

  const onCancelCreateFactsheet = useModalCancel(() => setOpenCreateFactsheetDialog(false))

  const onSuccessCreateFactsheet = (factsheetId: number) => {
    setOpenCreateFactsheetDialog(false)
    delayedNavigate(ROUTES.FactsheetDescriptionRoot.params({ factsheetId }))
  }

  const handleOpenCopyFactsheetSelectionDialog = () => setOpenCopyFactsheetSelectionDialog(true)

  const onCancelCopyFactsheetSelection = () => {
    setSelectedFactsheetForCopy(undefined)
    setOpenCopyFactsheetSelectionDialog(false)
  }

  const onSubmitCopyFactsheetSelection = (factsheet: Factsheet) => {
    setSelectedFactsheetForCopy(factsheet)
    setOpenCopyFactsheetSelectionDialog(false)

    setOpenCopyFactsheetFormDialog(true)
  }

  const onCancelCopyFactsheetForm = useModalCancel(() => {
    setSelectedFactsheetForCopy(undefined)
    setOpenCopyFactsheetFormDialog(false)
  })

  const onSuccessCopyFactsheetForm = (factsheetId: number) => {
    setOpenCopyFactsheetFormDialog(false)
    delayedNavigate(ROUTES.FactsheetDescriptionRoot.params({ factsheetId }))
  }

  const fetchData = useCallback(async () => {
    const search = {
      search: null,
      modules: ALL_MODULES.map((v) => v),
      levels: Object.values(LEVEL).map((l) => l),
      features: null,
      userId: canViewAllFactsheets ? null : user.id,
      language: user.language,
    }

    const { data } = await urqlClient
      .mutation<
        {
          fetchFactsheetSearchData: Mutation_Root['fetchFactsheetSearchData']
        },
        { input: FactsheetSearchInput }
      >(fetchFactsheetSearchDataMutation, {
        input: search,
      })
      .toPromise()

    if (data?.fetchFactsheetSearchData) {
      const mappedFactsheets: FilteredFactsheetResponseData[] = data.fetchFactsheetSearchData.data
      setFilteredFactsheets(mappedFactsheets)
      setFactsheetsCount(mappedFactsheets.length)
      setFactsheetsSearchInput(search)
    } else {
      setError()
    }
  }, [urqlClient, user, setError])

  useEffect(() => {
    if (!StorageUtils.get(STORAGE_KEY._FACTSHEET_FILTER_STORAGE)) {
      fetchData()
    }
  }, [fetchData])

  const onDownloadHandle = () => async () => {
    if (factsheetsSearchInput) {
      await FactsheetsReportService.generateReport(factsheetsSearchInput, language)
        .then((response) => {
          saveFileAs(response)
        })
        .catch(() => {
          notificationService.operationFailed()
        })
    }
  }

  return (
    <ScreenLayout title={getMessage('label.factsheets')} hasSecondLevelNavigation={false}>
      <PageLayout>
        <Section
          title={getMessage('label.factsheets.browse')}
          helpAndInstructions={<HelpAndInstructions labelKey="label.help.factsheets.browse" defaultExpansion />}
        >
          <FactsheetsFilterCard
            setFilteredFactsheets={setFilteredFactsheets}
            setFactsheetsCount={setFactsheetsCount}
            fetchInitialData={fetchData}
            setFactsheetsSearchInput={setFactsheetsSearchInput}
          />
        </Section>
        <ModalDialog
          open={openCreateFactsheetDialog}
          onClose={onCancelCreateFactsheet}
          title={getMessage('label.factsheet.create')}
          maxWidth="md"
        >
          <CreateFactsheetModalDialog onCancel={onCancelCreateFactsheet} onSuccess={onSuccessCreateFactsheet} />
        </ModalDialog>
        <ModalDialog
          open={openCopyFactsheetSelectionDialog}
          onClose={onCancelCopyFactsheetSelection}
          title={getMessage('label.factsheet.copy')}
          maxWidth="md"
        >
          <CopyFactsheetSelectionDialog
            onCancel={onCancelCopyFactsheetSelection}
            onSubmitCopyFactsheetSelection={onSubmitCopyFactsheetSelection}
          />
        </ModalDialog>
        {selectedFactsheetForCopy && (
          <ModalDialog
            open={openCopyFactsheetFormDialog}
            onClose={onCancelCopyFactsheetForm}
            title={getMessage('label.factsheet.copy')}
            maxWidth="md"
          >
            <CopyFactsheetFormDialog
              factsheet={selectedFactsheetForCopy}
              onCancel={onCancelCopyFactsheetForm}
              onSuccess={onSuccessCopyFactsheetForm}
            />
          </ModalDialog>
        )}

        <Section
          title={getMessage('label.reporting.search.results')}
          helpAndInstructions={<HelpAndInstructions labelKey="label.help.reporting.search.results" />}
          actionButton={
            <Stack direction="row" spacing={1}>
              {canCreate && (
                <AddButton
                  messageKey="button.add.factsheet"
                  startIcon={<AddIcon />}
                  onClick={handleOpenCreateFactsheetDialog}
                />
              )}
              {canCopy && (
                <Button onClick={handleOpenCopyFactsheetSelectionDialog} startIcon={<CopyIcon />}>
                  {getMessage('button.copy.factsheet')}
                </Button>
              )}
            </Stack>
          }
        >
          <Card>
            <FactsheetsIndexGrid
              factsheets={filteredFactsheets}
              onDownloadHandle={onDownloadHandle()}
              factsheetsCount={factsheetsCount}
            />
          </Card>
        </Section>
      </PageLayout>
    </ScreenLayout>
  )
}
