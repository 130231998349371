import { Box, Card, CardActions, CardContent, CardHeader, Typography } from '@mui/material'
import arrayMutators from 'final-form-arrays'
import { useRef } from 'react'
import { Form } from 'react-final-form'
import { useParams } from 'react-router'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { useResolveCommitteeDecisionManagementDataForFactsheet } from 'src/screens/administration/round-management/details/round-information/committee-decision-management/CommitteeDecisionManagementService'
import { FactsheetCommitteeValues } from 'src/screens/factsheets/application/committee/common/FactsheetApplicationCommitteeUtils'
import { useUpdateFactsheetCommitteeData } from 'src/screens/factsheets/application/committee/common/FactsheetCommitteeService'
import { FactsheetCommitteeDecisions } from 'src/screens/factsheets/application/committee/edit/FactsheetCommitteeDecisions'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { AutoCompleteField } from 'src/shared/form/control/AutoCompleteField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { createDecorators } from 'src/shared/form/utils/decorators'
import { ExternalIcon } from 'src/shared/icons/Icons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { S } from 'src/shared/styled/S'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useUserLocale } from 'src/user/UserContext'

type originType = 'SAVE' | 'SAVE_AND_BACK'
const decorator = createDecorators()

export const FactsheetCommitteeDecisionManagementPage = () => {
  const navigate = useDelayedNavigate()
  const { roundId } = useParams()
  const { getMessage } = useMessageSource()
  const locale = useUserLocale()
  const originRef = useRef<originType>('SAVE')

  const {
    fundingRound,
    selectedFactsheet,
    setSelectedFactsheet,
    selectedFactsheetOptions,
    initialFormValues,
    setInitialFormValues,
    optionValues,
  } = useResolveCommitteeDecisionManagementDataForFactsheet(parseInt(roundId as string))

  const backHandler = () => {
    navigate(ROUTES.RoundManagementDetailsRoot.params({ roundId }))
  }

  const onSubmit = (origin: originType) => (_: any) => {
    // trigger submit event
    if (originRef) {
      originRef.current = origin
    }
    document
      .getElementById('committee-decision-form')
      ?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const updateCommitteeData = useUpdateFactsheetCommitteeData()

  const onUpdateHandler = async (values: FactsheetCommitteeValues) => {
    await updateCommitteeData(values, selectedFactsheet?.id as number, setInitialFormValues, originRef, backHandler)
  }

  const setNewlySelectedFactsheetById = (factsheetId: number) => {
    const newlySelectedFactsheet = fundingRound?.factsheets.find((factsheet) => factsheet.id === factsheetId)
    setSelectedFactsheet(newlySelectedFactsheet)
  }

  const handleSelectFactsheetChange = (value: any, dirty: boolean) => {
    if (dirty) {
      if (window.confirm(getMessage('notification.form.unsaved.changes'))) {
        setNewlySelectedFactsheetById(parseInt(value))
        return true
      } else {
        return false
      }
    } else {
      setNewlySelectedFactsheetById(parseInt(value))
      return true
    }
  }

  return (
    <>
      {fundingRound && (
        <ScreenLayout
          title={`${fundingRound?.name} ${getMessage('label.decisions')}`}
          hasSecondLevelNavigation={false}
          onBack={backHandler}
          actions={
            selectedFactsheet &&
            initialFormValues && (
              <>
                <SaveAndBackButton origin="header" onClick={onSubmit('SAVE_AND_BACK')} />
                <SaveButton origin="header" onClick={onSubmit('SAVE')} />
              </>
            )
          }
        >
          <PageLayout>
            <HelpAndInstructions labelKey="label.help.committee.decision.management" />
            <Box>
              <Form<FactsheetCommitteeValues>
                initialValues={initialFormValues}
                onSubmit={onUpdateHandler}
                mutators={{ ...arrayMutators }}
                decorators={decorator}
                render={({ handleSubmit, values, dirty }) => {
                  return (
                    <form id="committee-decision-form" onSubmit={handleSubmit}>
                      <Card sx={{ marginBottom: 5 }}>
                        <CardHeader title={getMessage('label.select.factsheet')} />
                        <CardContent sx={{ paddingBottom: 0 }}>
                          <AutoCompleteField
                            onCustomChange={(value: any) => {
                              return handleSelectFactsheetChange(value, dirty)
                            }}
                            label={getMessage('label.factsheet')}
                            name="factsheetId"
                            options={selectedFactsheetOptions}
                            disableClearable={true}
                          />
                        </CardContent>
                        <CardActions sx={{ padding: 2 }}>
                          {selectedFactsheet && (
                            <S.Link
                              to={ROUTES.FactsheetDescriptionRoot.nested.FactsheetDescription.params({
                                factsheetId: selectedFactsheet.id,
                              })}
                              target="_blank"
                              $isLast
                            >
                              <Typography variant="h5">{getMessage('label.factsheet.link')}</Typography>
                              <ExternalIcon />
                            </S.Link>
                          )}
                        </CardActions>
                      </Card>
                      {selectedFactsheet && initialFormValues && (
                        <FactsheetCommitteeDecisions
                          locale={locale}
                          values={values}
                          qualityCriteriaOptions={optionValues.qualityCriteriaOptions}
                          exclusionCriteriaOptions={optionValues.exclusionCriteriaOptions}
                          gfchResponsibleOptions={optionValues.gfchResponsibleOptions}
                        />
                      )}
                      <DirtyFormSpy />
                    </form>
                  )
                }}
              />
            </Box>
          </PageLayout>
        </ScreenLayout>
      )}
    </>
  )
}
