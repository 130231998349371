const PROJECT = {
  PF_KAP: 'PF_KAP',
  PF_PGV: 'PF_PGV',
  KAP: 'KAP',
} as const

export type PROJECT_TYPE = keyof typeof PROJECT

const PROCESS = {
  ...PROJECT,
  FACTSHEET: 'FACTSHEET',
} as const

export type PROCESS_TYPE = keyof typeof PROCESS

const FACTSHEET_USER_ROLES = {
  ADMIN: 'EXTERNAL_ADMIN',
  CONTRIBUTOR: 'EXTERNAL_CONTRIBUTOR',
  READER: 'EXTERNAL_READER',
} as const

export type FACTSHEET_USER_ROLE_TYPE = typeof FACTSHEET_USER_ROLES

const PROJECT_USER_ROLES = {
  ADMIN: 'EXTERNAL_ADMIN',
  CONTRIBUTOR: 'EXTERNAL_CONTRIBUTOR',
  READER: 'EXTERNAL_READER',
  EXPERT: 'EXTERNAL_EXPERT',
} as const

export type PROJECT_USER_ROLE_TYPE = typeof PROJECT_USER_ROLES

const PROGRAM_USER_ROLES = {
  PD_CANTON_USER: 'PD_CANTON_USER',
  PD_GFCH_CANTON_RESPONSIBLE: 'PD_GFCH_CANTON_RESPONSIBLE',
} as const

export type PROGRAM_USER_ROLE_TYPE = typeof PROGRAM_USER_ROLES

export const GLOBAL_USER_ROLES = {
  'PD-EXT_USER': 'PD-EXT_USER',
  'PD-CANTON_KAP_CONTRIBUTOR': 'PD-CANTON_KAP_CONTRIBUTOR',
  'PD-CANTON_KAP_READER': 'PD-CANTON_KAP_READER',
  'PD-GFCH_FACTSHEET_COORDINATOR': 'PD-GFCH_FACTSHEET_COORDINATOR',
  'PD-GFCH_PF-KAP_COORDINATOR': 'PD-GFCH_PF-KAP_COORDINATOR',
  'PD-GFCH_PF-PGV_COORDINATOR': 'PD-GFCH_PF-PGV_COORDINATOR',
  'PD-GFCH_KAP_COORDINATOR': 'PD-GFCH_KAP_COORDINATOR',
  'PD-GFCH_PF-KAP_CONTRIBUTOR': 'PD-GFCH_PF-KAP_CONTRIBUTOR',
  'PD-GFCH_PF-PGV_CONTRIBUTOR': 'PD-GFCH_PF-PGV_CONTRIBUTOR',
  'PD-GFCH_KAP_CONTRIBUTOR': 'PD-GFCH_KAP_CONTRIBUTOR',
  'PD-GFCH_PF-KAP_READER': 'PD-GFCH_PF-KAP_READER',
  'PD-GFCH_PF-PGV_READER': 'PD-GFCH_PF-PGV_READER',
  'PD-GFCH_KAP_READER': 'PD-GFCH_KAP_READER',
  'PD-SUCCESS_FACTOR_CONTRIBUTOR': 'PD-SUCCESS_FACTOR_CONTRIBUTOR',
  'PD-EXT_EXPERT': 'PD-EXT_EXPERT',
  'PD-GFCH_SUCCESS_FACTOR_COORDINATOR': 'PD-GFCH_SUCCESS_FACTOR_COORDINATOR',
} as const

const USER_TYPE = {
  GFCH: 'GFCH',
  EXTERNAL_EXPERT: 'EXTERNAL_EXPERT',
  CANTON: 'CANTON',
  EXTERNAL: 'EXTERNAL',
} as const

export const GLOBAL_USER_ROLES_TRANSLATION_MAP = [
  {
    role: GLOBAL_USER_ROLES['PD-GFCH_FACTSHEET_COORDINATOR'],
    messageKey: 'label.role.coordinator.factsheet',
  },
  {
    role: GLOBAL_USER_ROLES['PD-GFCH_PF-PGV_COORDINATOR'],
    messageKey: 'label.role.coordinator.pf_pgv',
  },
  {
    role: GLOBAL_USER_ROLES['PD-GFCH_PF-KAP_COORDINATOR'],
    messageKey: 'label.role.coordinator.pf_kap',
  },
  {
    role: GLOBAL_USER_ROLES['PD-GFCH_KAP_COORDINATOR'],
    messageKey: 'label.role.coordinator.kap',
  },
  {
    role: GLOBAL_USER_ROLES['PD-GFCH_PF-PGV_CONTRIBUTOR'],
    messageKey: 'label.role.contributor.pf_pgv',
  },
  {
    role: GLOBAL_USER_ROLES['PD-GFCH_PF-KAP_CONTRIBUTOR'],
    messageKey: 'label.role.contributor.pf_kap',
  },
  {
    role: GLOBAL_USER_ROLES['PD-GFCH_KAP_CONTRIBUTOR'],
    messageKey: 'label.role.contributor.kap',
  },
  {
    role: GLOBAL_USER_ROLES['PD-GFCH_PF-PGV_READER'],
    messageKey: 'label.role.reader.pf_pgv',
  },
  {
    role: GLOBAL_USER_ROLES['PD-GFCH_PF-KAP_READER'],
    messageKey: 'label.role.reader.pf_kap',
  },
  {
    role: GLOBAL_USER_ROLES['PD-GFCH_KAP_READER'],
    messageKey: 'label.role.reader.kap',
  },
  {
    role: GLOBAL_USER_ROLES['PD-EXT_EXPERT'],
    messageKey: 'label.role.external.expert',
  },
  {
    role: GLOBAL_USER_ROLES['PD-CANTON_KAP_CONTRIBUTOR'],
    messageKey: 'label.role.canton.kap.contributor',
  },
  {
    role: GLOBAL_USER_ROLES['PD-CANTON_KAP_READER'],
    messageKey: 'label.role.canton.kap.reader',
  },
  {
    role: GLOBAL_USER_ROLES['PD-SUCCESS_FACTOR_CONTRIBUTOR'],
    messageKey: 'label.role.success.factor.contributor',
  },
  {
    role: GLOBAL_USER_ROLES['PD-GFCH_SUCCESS_FACTOR_COORDINATOR'],
    messageKey: 'label.role.coordinator.success.factor',
  },
  {
    role: GLOBAL_USER_ROLES['PD-EXT_USER'],
    messageKey: 'label.role.external.user',
  },
]

export type USER_ROLES_TYPE = keyof typeof GLOBAL_USER_ROLES

const DOSSIER_STATUS = {
  CONCEPT: 'CONCEPT',
  APPLICATION: 'APPLICATION',
  IMPLEMENTATION: 'IMPLEMENTATION',
  CANCELED: 'CANCELED',
  FINISHED: 'FINISHED',

  // Program related
  CONCEPT_REVIEW: 'CONCEPT_REVIEW',
  CONCEPT_REWORK: 'CONCEPT_REWORK',

  // Project related
  REVISION: 'REVISION',
  ACCEPTED: 'ACCEPTED',
  WITHDRAWN: 'WITHDRAWN',
  EXCLUDED: 'EXCLUDED',
  REJECTED: 'REJECTED',
} as const

export type DOSSIER_STATUS_TYPE = keyof typeof DOSSIER_STATUS

const PROJECT_STATUSES = [
  DOSSIER_STATUS.CONCEPT,
  DOSSIER_STATUS.APPLICATION,
  DOSSIER_STATUS.REVISION,
  DOSSIER_STATUS.ACCEPTED,
  DOSSIER_STATUS.IMPLEMENTATION,
  DOSSIER_STATUS.FINISHED,
  DOSSIER_STATUS.CANCELED,
  DOSSIER_STATUS.WITHDRAWN,
  DOSSIER_STATUS.EXCLUDED,
  DOSSIER_STATUS.REJECTED,
]

const PROGRAM_STATUSES = [
  DOSSIER_STATUS.CONCEPT,
  DOSSIER_STATUS.CONCEPT_REVIEW,
  DOSSIER_STATUS.CONCEPT_REWORK,
  DOSSIER_STATUS.APPLICATION,
  DOSSIER_STATUS.IMPLEMENTATION,
  DOSSIER_STATUS.FINISHED,
  DOSSIER_STATUS.CANCELED,
]

const LIFE_PHASES = {
  LIFE_PHASE_CHILDHOOD: 'LIFE_PHASE_CHILDHOOD',
  LIFE_PHASE_ADULTS: 'LIFE_PHASE_ADULTS',
  LIFE_PHASE_SENIORS: 'LIFE_PHASE_SENIORS',
}
export type LIFE_PHASE_TYPES = keyof typeof LIFE_PHASES
const KAP_LIFE_PHASES: LIFE_PHASE_TYPES[] = ['LIFE_PHASE_CHILDHOOD', 'LIFE_PHASE_ADULTS', 'LIFE_PHASE_SENIORS']
const PF_KAP_LIFE_PHASES: LIFE_PHASE_TYPES[] = ['LIFE_PHASE_CHILDHOOD', 'LIFE_PHASE_SENIORS']

export type MODULE_TYPES = 'LIFE_PHASE_CHILDHOOD' | 'LIFE_PHASE_ADULTS' | 'LIFE_PHASE_SENIORS' | 'A' | 'B' | 'C' | 'D'
const ALL_MODULES: MODULE_TYPES[] = [
  'LIFE_PHASE_CHILDHOOD',
  'LIFE_PHASE_ADULTS',
  'LIFE_PHASE_SENIORS',
  'A',
  'B',
  'C',
  'D',
]
// NOTE: KBL 2024-09-24 will be reverted to ALL_MODULES once the adults phase will be used on PF KAP and KAP
const KAP_MODULES: MODULE_TYPES[] = [
  'LIFE_PHASE_CHILDHOOD',
  'LIFE_PHASE_ADULTS',
  'LIFE_PHASE_SENIORS',
  'A',
  'B',
  'C',
  'D',
]
const PF_KAP_MODULES: MODULE_TYPES[] = ['LIFE_PHASE_CHILDHOOD', 'LIFE_PHASE_SENIORS', 'A', 'B', 'C', 'D']

export interface TextLength {
  key: 'S' | 'M' | 'L' | 'XL' | 'XXL' | 'GIRDERS_AUTH_NAME' | 'INITIAL_CANTONAL_SITUATION'
  value: number
  messageValue?: number
}

const TEXT_LENGTH = {
  S: {
    key: 'S',
    value: 50,
  },
  M: {
    key: 'M',
    value: 256,
  },
  L: {
    key: 'L',
    value: 1200,
    messageValue: 1000,
  },
  XL: {
    key: 'XL',
    value: 5500,
    messageValue: 5000,
  },
  XXL: {
    key: 'XXL',
    value: 8500,
    messageValue: 8000,
  },
  GIRDERS_AUTH_NAME: {
    key: 'GIRDERS_AUTH_NAME',
    value: 255,
  },
  INITIAL_CANTONAL_SITUATION: {
    key: 'INITIAL_CANTONAL_SITUATION',
    value: 35000,
    messageValue: 34000,
  },
} as const

const USER_TITLE = {
  MR: 'MR',
  MRS: 'MRS',
  NEUTRAL: 'NEUTRAL',
}

// https://www.postgresql.org/docs/9.1/datatype-numeric.html
const MAX_ALLOWED_INT = 2147483647

const LANGUAGES = {
  EN: 'EN',
  DE: 'DE',
  IT: 'IT',
  FR: 'FR',
}

const FILE_TYPE = {
  PROJECT_BACKGROUND_INFORMATION_STRUCTURE_PLAN: 'PROJECT_BACKGROUND_INFORMATION_STRUCTURE_PLAN',
  PROJECT_BACKGROUND_INFORMATION_OTHER: 'PROJECT_BACKGROUND_INFORMATION_OTHER',
  PROJECT_DETAILED_BUDGET: 'PROJECT_DETAILED_BUDGET',
  PROJECT_APPLICATION_PDF: 'PROJECT_APPLICATION_PDF',
  PROJECT_CANTONAL_STATEMENT: 'PROJECT_CANTONAL_STATEMENT',
  MILESTONE_DETAILS_DOCUMENT: 'MILESTONE_DETAILS_DOCUMENT',
  PROGRAM_DOCUMENT: 'PROGRAM_DOCUMENT',
  PROGRAM_STRUCTURE_PLAN: 'PROGRAM_STRUCTURE_PLAN',
  PROGRAM_APPLICATION_LETTER_OF_INTENT: 'PROGRAM_APPLICATION_LETTER_OF_INTENT',
  PROGRAM_APPLICATION_PDF: 'PROGRAM_APPLICATION_PDF',
  PROGRAM_DETAILED_BUDGET: 'PROGRAM_DETAILED_BUDGET',
  PROJECT_JOURNAL: 'PROJECT_JOURNAL',
  EVALUATION_MILESTONE_DOCUMENT: 'EVALUATION_MILESTONE_DOCUMENT',
} as const

const ENTITY_TYPE = {
  PROJECT_BASE: 'PROJECT_BASE',
  MILESTONE: 'MILESTONE',
  KAP_PROGRAM: 'KAP_PROGRAM',
  PROJECT_JOURNAL: 'PROJECT_JOURNAL',
  EVALUATION_MILESTONE: 'EVALUATION_MILESTONE',
}

const APPLICATION_TYPE = {
  MULTIPLICATION: 'MULTIPLICATION',
  INNOVATION: 'INNOVATION',
  OFFER_PROMOTION: 'OFFER_PROMOTION',
}

export type APPLICATION_TYPE_TYPE = keyof typeof APPLICATION_TYPE

const USER_STATUS = {
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
  INACTIVE: 'INACTIVE',
} as const

export type USER_STATUS_TYPE = keyof typeof USER_STATUS

const COMMITTEE_FORM_MODE = {
  PROJECT_APPLICATION_COMMITTEE: 'PROJECT_APPLICATION_COMMITTEE',
  ROUND_MANAGEMENT_COMMITTEE: 'ROUND_MANAGEMENT_COMMITTEE',
} as const

export type COMMITTEE_FORM_MODE_TYPE = keyof typeof COMMITTEE_FORM_MODE

const LEVEL = {
  INTERVENTION: 'INTERVENTION',
  POLICY: 'POLICY',
  NETWORK: 'NETWORK',
  COMMUNICATION: 'COMMUNICATION',
} as const

export type LEVEL_TYPE = keyof typeof LEVEL

const LEVELS: LEVEL_TYPE[] = [LEVEL.INTERVENTION, LEVEL.POLICY, LEVEL.NETWORK, LEVEL.COMMUNICATION]

const APPLICATION_TYPE_PF_PGV = {
  FUNDING_AREA_1: 'FUNDING_AREA_1',
  FUNDING_AREA_2: 'FUNDING_AREA_2',
  FUNDING_AREA_3: 'FUNDING_AREA_3',
  FUNDING_AREA_4: 'FUNDING_AREA_4',
  TRANSITION_FUNDING: 'TRANSITION_FUNDING',
  EXTENSION: 'EXTENSION',
  DIRECT_COLLABORATION: 'DIRECT_COLLABORATION',
}

export type APPLICATION_TYPE_PF_PGV_TYPE = keyof typeof APPLICATION_TYPE_PF_PGV

const APPLICATION_TYPE_FACTSHEET = {
  ORIENTATION_LIST: 'ORIENTATION_LIST',
}

export type APPLICATION_TYPE_FACTSHEET_TYPE = keyof typeof APPLICATION_TYPE_FACTSHEET

export const GOAL_CHAR = 'g'
export const MEASURE_CHAR = 'm'

const CANTONS = {
  AG: 'AG',
  AI: 'AI',
  AR: 'AR',
  BE: 'BE',
  BL: 'BL',
  BS: 'BS',
  FR: 'FR',
  GE: 'GE',
  GL: 'GL',
  GR: 'GR',
  JU: 'JU',
  LU: 'LU',
  NE: 'NE',
  NW: 'NW',
  OW: 'OW',
  SG: 'SG',
  SH: 'SH',
  SO: 'SO',
  SZ: 'SZ',
  TG: 'TG',
  TI: 'TI',
  UR: 'UR',
  VD: 'VD',
  VS: 'VS',
  ZG: 'ZG',
  ZH: 'ZH',
} as const

export type CANTON_TYPE = keyof typeof CANTONS

const EXPORT_FORMAT = {
  WORD: 'doc',
  PDF: 'pdf',
  EXCEL: 'xls',
}

export type EXPORT_FORMAT_TYPE = keyof typeof EXPORT_FORMAT

export interface INITIAL_NATIONAL_SITUATION {
  modules: {
    A: {
      en: string
      de: string
      fr: string
      it: string
    }
    B: {
      en: string
      de: string
      fr: string
      it: string
    }
    C: {
      en: string
      de: string
      fr: string
      it: string
    }
    D: {
      en: string
      de: string
      fr: string
      it: string
    }
    LIFE_PHASE_CHILDHOOD: {
      en: string
      de: string
      fr: string
      it: string
    }
    LIFE_PHASE_ADULTS: {
      en: string
      de: string
      fr: string
      it: string
    }
    LIFE_PHASE_SENIORS: {
      en: string
      de: string
      fr: string
      it: string
    }
  }
}

export const PROJECT_JOURNAL_STATUS = {
  OPEN: 'OPEN',
  DONE: 'DONE',
} as const

export type PROJECT_JOURNAL_STATUS_TYPE = keyof typeof PROJECT_JOURNAL_STATUS

export const KAP_ORGANIZATION_TYPE = {
  CONTACT_ORGANIZATION: 'CONTACT_ORGANIZATION',
  RESPONSIBLE_ORGANIZATION: 'RESPONSIBLE_ORGANIZATION',
  PARTNER_ORGANIZATION: 'PARTNER_ORGANIZATION',
}

export type KAP_ORGANIZATION_TYPE_TYPE = keyof typeof KAP_ORGANIZATION_TYPE

export const PF_KAP_EVALUATION_MILESTONE_TYPE = {
  EFFECTIVITY_MODEL: 'EFFECTIVITY_MODEL',
  SELF_EVALUATION: 'SELF_EVALUATION',
  THIRD_PARTY_EVALUATION: 'THIRD_PARTY_EVALUATION',
  OTHER_PROOF_OF_EVIDENCE: 'OTHER_PROOF_OF_EVIDENCE',
}

export const PF_PGV_EVALUATION_MILESTONE_TYPE = {
  EFFECTIVITY_MODEL: 'EFFECTIVITY_MODEL',
  SELF_EVALUATION: 'SELF_EVALUATION',
  THIRD_PARTY_EVALUATION: 'THIRD_PARTY_EVALUATION',
  OTHER_PROOF_OF_EVIDENCE: 'OTHER_PROOF_OF_EVIDENCE',
}

export const EVALUATION_MILESTONE_STATUS_TYPE = {
  OPEN: 'OPEN',
  DONE: 'DONE',
} as const

export type EVALUATION_MILESTONE_STATUS_TYPE_TYPE = keyof typeof EVALUATION_MILESTONE_STATUS_TYPE

const APP_TITLE = 'Promotion Digitale'

const BASE_URL = {
  HOME: '/',
  PF_KAP: '/pf-kap',
  PF_PGV: '/pf-pgv',
  KAP: '/kap',
  FACTSHEETS: '/factsheets',
  REPORTING: '/reporting',
  SUCCESS_FACTORS: '/success-factors',
  PUBLIC_LANDING: '/pub/landing',
  PUBLIC_SUCCESS_FACTOR_TOOL: '/pub/sft',
  PUBLIC_SUCCESS_FACTOR_TOOL_OVERVIEW: '/pub/sft/overview',
}

const DETAILS_URL = {
  PF_KAP: '/pf-kap/project/:projectId/*',
  PF_PGV: '/pf-pgv/project/:projectId/*',
  KAP: '/kap/program/:programId/*',
  FACTSHEETS: '/factsheets/:factsheetId/*',
  ADMINISTRATION: '/administration/*',
  REPORTING: '/reporting/type/*',
  SUCCESS_FACTOR_ANALYSIS: '/success-factors/analysis/:analysisId/*',
}

type EVALUATION_NEEDS_TYPE = {
  [key: string]: {
    [subKey: string]: string
  }
}

const EVALUATION_NEEDS: EVALUATION_NEEDS_TYPE = {
  PF_PGV: {
    EXTERNAL_EVALUATION: 'EXTERNAL_EVALUATION',
    SELF_EVALUATION: 'SELF_EVALUATION',
    OTHERS: 'OTHERS',
  },
  PF_KAP: {
    FULL_EXTERNAL_EVALUATION: 'FULL_EXTERNAL_EVALUATION',
    PARTIAL_EXTERNAL_EVALUATION: 'PARTIAL_EXTERNAL_EVALUATION',
    COACHING_EFFECTIVITY_MODEL: 'COACHING_EFFECTIVITY_MODEL',
    COACHING_SELF_EVALUATION: 'COACHING_SELF_EVALUATION',
    OTHERS: 'OTHERS',
    NO_NEED: 'NO_NEED',
  },
}

export {
  ALL_MODULES,
  APPLICATION_TYPE,
  APPLICATION_TYPE_FACTSHEET,
  APPLICATION_TYPE_PF_PGV,
  APP_TITLE,
  BASE_URL,
  CANTONS,
  COMMITTEE_FORM_MODE,
  DETAILS_URL,
  DOSSIER_STATUS,
  ENTITY_TYPE,
  EVALUATION_NEEDS,
  EXPORT_FORMAT,
  FACTSHEET_USER_ROLES,
  FILE_TYPE,
  LIFE_PHASES,
  KAP_LIFE_PHASES,
  KAP_MODULES,
  LANGUAGES,
  LEVEL,
  LEVELS,
  MAX_ALLOWED_INT,
  PF_KAP_LIFE_PHASES,
  PF_KAP_MODULES,
  PROCESS,
  PROGRAM_STATUSES,
  PROJECT,
  PROJECT_STATUSES,
  PROJECT_USER_ROLES,
  TEXT_LENGTH,
  USER_STATUS,
  USER_TITLE,
  USER_TYPE,
}
