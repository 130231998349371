import { Box } from '@mui/material'
import {
  getGridStringOperators,
  GridColDef,
  GridFilterInputValueProps,
  GridPaginationModel,
  GridRowSelectionModel,
  GridSortModel,
} from '@mui/x-data-grid-pro'
import React, { useMemo, useState } from 'react'
import { Factsheet } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ALL_MODULES, MODULE_TYPES } from 'src/shared/constants/constants'
import { Option } from 'src/shared/form/control'
import { AutoCompleteFilterInput } from 'src/shared/form/grid/filter/AutoCompleteFilterInput'
import { S } from 'src/shared/styled/S'
import { useGridTranslateHook } from 'src/shared/utils/hooks/grid-translate-hook'
import styled from 'styled-components/macro'

const DataGridProStyled = styled(S.DataGrid.ViewEditMode)`
  & .MuiDataGrid-columnHeader {
    & .MuiButtonBase-root.MuiCheckbox-root {
      display: none;
    }
  }
  & .MuiDataGrid-virtualScroller {
    overflow: hidden;
  }
`

const GridBoxStyled = styled(Box)`
  height: 668px;
`

interface Props {
  factsheets: Factsheet[]
  onSelectFactsheet: (factsheet?: Factsheet) => void
}

const PAGE_SIZE = 10

export const CopyFactsheetGrid = ({ factsheets, onSelectFactsheet }: Props) => {
  const { getMessage } = useMessageSource()
  const gridTranslations = useGridTranslateHook()
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>()
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({ page: 0, pageSize: PAGE_SIZE })
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'id',
      sort: 'desc',
    },
  ])

  const autoCompleteFilterOperator = (options: Option[], label: string, placeholder: string) =>
    getGridStringOperators()
      .filter((operator) => operator.value === 'contains')
      .map((operator) => ({
        ...operator,
        InputComponent: (gridFilterProps: GridFilterInputValueProps) => (
          <AutoCompleteFilterInput {...gridFilterProps} options={options} label={label} placeholder={placeholder} />
        ),
      }))

  const rows = useMemo(() => {
    return factsheets.map((factsheet) => {
      return {
        ...factsheet,
        modules: factsheet.modules.map((it: MODULE_TYPES) => getMessage(`label.module.${it}`)).join(', '),
      }
    })
  }, [factsheets, getMessage])

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'id',
        flex: 0.5,
        headerName: getMessage('label.id'),
        filterOperators: getGridStringOperators().filter((x) => x.value === 'equals'),
        renderCell: ({ row }) => `${getMessage('label.factsheet.id.character')}${row.id}`,
      },
      {
        field: 'title',
        flex: 3,
        headerName: getMessage('label.title'),
        filterOperators: getGridStringOperators().filter((x) => x.value === 'contains'),
      },
      {
        field: 'modules',
        flex: 2,
        headerName: getMessage('label.modules'),
        filterOperators: autoCompleteFilterOperator(
          ALL_MODULES.map((x) => ({
            label: x.startsWith('LIFE_PHASE')
              ? `${getMessage(`label.module.${x}`)}`
              : `${getMessage('label.module')} ${x}`,
            value: x,
          })),
          getMessage('label.grid.value'),
          getMessage('label.grid.filter.value'),
        ),
      },
      {
        field: 'version',
        flex: 0.5,
        headerName: getMessage('label.factsheet.version'),
      },
    ],
    [getMessage],
  )

  const rowSelectionHandler = (selection: GridRowSelectionModel) => {
    const selectionSet = new Set(selectionModel)
    const result = selection.filter((s) => !selectionSet.has(s))
    const factsheet = factsheets.find((item) => result.some((id) => id === item.id))

    if (selection.length > 1) {
      setSelectionModel(result)
    } else {
      setSelectionModel(selection)
    }

    onSelectFactsheet(factsheet)
  }

  return (
    <GridBoxStyled>
      <DataGridProStyled
        rows={rows}
        columns={columns}
        localeText={gridTranslations}
        disableRowSelectionOnClick
        disableColumnReorder
        disableColumnSelector
        disableColumnPinning
        paginationModel={paginationModel}
        onPaginationModelChange={(model) => setPaginationModel(model)}
        pageSizeOptions={[PAGE_SIZE]}
        pagination
        checkboxSelection
        rowSelectionModel={selectionModel}
        onRowSelectionModelChange={(selectionModel: GridRowSelectionModel) => rowSelectionHandler(selectionModel)}
        sortModel={sortModel}
        onSortModelChange={(model: React.SetStateAction<GridSortModel>) => setSortModel(model)}
        scrollbarSize={0}
        $viewMode={true}
      />
    </GridBoxStyled>
  )
}
