import { DialogActions, DialogContent } from '@mui/material'
import { useState } from 'react'
import { Factsheet } from 'src/@types/graphql'
import { queryAllFactsheets } from 'src/screens/factsheets/factsheet/factsheetQueries'
import { CopyFactsheetGrid } from 'src/screens/factsheets/index/copy-factsheet/CopyFactsheetGrid'
import { PrimaryButton, SecondaryButton } from 'src/shared/button/Buttons'
import { useUserLocale } from 'src/user/UserContext'
import { useQuery } from 'urql'

interface Props {
  onCancel: () => void
  onSubmitCopyFactsheetSelection: (factsheet: Factsheet) => void
}

export const CopyFactsheetSelectionDialog = ({ onSubmitCopyFactsheetSelection, onCancel }: Props) => {
  const locale = useUserLocale()

  const [selectedFactsheet, setSelectedFactsheet] = useState<Factsheet>()

  const [{ data, fetching }] = useQuery({
    query: queryAllFactsheets,
    variables: {
      locale,
    },
  })

  return (
    <>
      <DialogContent>
        {!fetching && data && (
          <CopyFactsheetGrid
            factsheets={data.factsheet}
            onSelectFactsheet={(factsheet) => setSelectedFactsheet(factsheet)}
          />
        )}
      </DialogContent>
      <DialogActions>
        <SecondaryButton messageKey={'button.cancel'} onClick={onCancel} />
        <PrimaryButton
          messageKey={'button.copy.factsheet'}
          disabled={!selectedFactsheet}
          onClick={() => {
            onSubmitCopyFactsheetSelection(selectedFactsheet!)
          }}
        />
      </DialogActions>
    </>
  )
}
