import { ROUTES } from 'src/routing/routes'
import { LEVEL_TYPE, USER_ROLES_TYPE } from 'src/shared/constants/constants'
import { MILESTONE_TYPE, MILESTONE_TYPE_TYPE } from 'src/shared/constants/milestone-constants'
import { User } from 'src/user/user'
import { LevelTabsCommentEdit, LevelTabsEdit } from 'src/routing/FactsheetRoutes'

export type ExtractRouteParams<T> = string extends T
  ? Record<string | number, string>
  : T extends `${infer _Start}:${infer Param}/${infer Rest}`
  ? { [k in Param | keyof ExtractRouteParams<Rest>]: string | number | undefined }
  : T extends `${infer _Start}:${infer Param}`
  ? { [k in Param]: string | number | undefined }
  : unknown

export type ComposePaths<P extends string, C extends string> = `/${P}/${C}`

export function withParams<T>(targetRoute: string) {
  return (params: T) =>
    Object.keys(params!).reduce(
      (accumulator, currentKey) => accumulator.replace(`:${currentKey}`, (params as any)[currentKey]),
      targetRoute,
    )
}

export const isPathAllowedForRole = (path: string, roleToCheck: User['roles']): boolean => {
  // @ts-ignore
  const routeKeyMatch = Object.keys(ROUTES).find((key) => ROUTES[key].path === path)

  if (!routeKeyMatch) {
    return false
  }

  // @ts-ignore
  const route = ROUTES[routeKeyMatch]
  if (!route.roles) {
    return true
  }

  return route.roles.some((role: string) => roleToCheck.includes(role as USER_ROLES_TYPE))
}

export const resolveDetailsRoot = (baseUrl: '/pf-kap' | '/pf-pgv') => {
  if (baseUrl === '/pf-kap') {
    return ROUTES.PfKapDetailsRoot
  } else if (baseUrl === '/pf-pgv') {
    return ROUTES.PfPgvDetailsRoot
  } else {
    throw Error(`Unknown baseUrl ${baseUrl}`)
  }
}

export const resolveApplicationDetailsRoot = (baseUrl: '/pf-kap' | '/pf-pgv') => {
  if (baseUrl === '/pf-kap') {
    return ROUTES.PfKapApplicationDetailsRoot
  } else if (baseUrl === '/pf-pgv') {
    return ROUTES.PfPgvApplicationDetailsRoot
  } else {
    throw Error(`Unknown baseUrl ${baseUrl}`)
  }
}

export const resolveApplicationAssessmentDetailsRoot = (baseUrl: '/pf-kap' | '/pf-pgv') => {
  if (baseUrl === '/pf-kap') {
    return ROUTES.PfKapApplicationAssessmentDetailsRoot
  } else if (baseUrl === '/pf-pgv') {
    return ROUTES.PfPgvApplicationAssessmentDetailsRoot
  } else {
    throw Error(`Unknown baseUrl ${baseUrl}`)
  }
}

export const resolveApplicationCommitteeRoot = (baseUrl: '/pf-kap' | '/pf-pgv') => {
  if (baseUrl === '/pf-kap') {
    return ROUTES.PfKapApplicationCommittee
  } else if (baseUrl === '/pf-pgv') {
    return ROUTES.PfPgvApplicationCommittee
  } else {
    throw Error(`Unknown baseUrl ${baseUrl}`)
  }
}

export const getFactsheetLevelCommentEditPathKey = (level: LEVEL_TYPE): LevelTabsCommentEdit => {
  const pathKey: LevelTabsCommentEdit = `${level.toString()}CommentEdit` as LevelTabsCommentEdit

  return pathKey
}

export const getFactsheetLevelEditPathKey = (level: LEVEL_TYPE): LevelTabsEdit => {
  const pathKey: LevelTabsEdit = `${level.toString()}Edit` as LevelTabsEdit

  return pathKey
}

export const resolveApplicationAssessmentRoot = (baseUrl: '/pf-kap' | '/pf-pgv') => {
  if (baseUrl === '/pf-kap') {
    return ROUTES.PfKapApplicationAssessment
  } else if (baseUrl === '/pf-pgv') {
    return ROUTES.PfPgvApplicationAssessment
  } else {
    throw Error(`Unknown baseUrl ${baseUrl}`)
  }
}

export const resolveImplementationDetailsRoot = (baseUrl: '/pf-kap' | '/pf-pgv') => {
  if (baseUrl === '/pf-kap') {
    return ROUTES.PfKapImplementationDetailsRoot
  } else if (baseUrl === '/pf-pgv') {
    return ROUTES.PfPgvImplementationDetailsRoot
  } else {
    throw Error(`Unknown baseUrl ${baseUrl}`)
  }
}

export const resolveMilestoneRoot = (baseUrl: '/pf-kap' | '/pf-pgv', milestoneType: MILESTONE_TYPE_TYPE) => {
  switch (milestoneType) {
    case MILESTONE_TYPE.CONTRACT:
      return resolveDefaultMilestoneRoot(baseUrl)
    case MILESTONE_TYPE.INTERMEDIATE_DISCUSSION:
      return resolveIntermediateMilestoneRoot(baseUrl)
    case MILESTONE_TYPE.EVALUATION_CONCEPT:
      return resolveDefaultMilestoneRoot('/pf-pgv')
    case MILESTONE_TYPE.FINANCIAL_REPORT:
      return resolveFinancialReportMilestoneRoot(baseUrl)
    case MILESTONE_TYPE.FINAL_REPORT:
      return resolveFinalReportMilestoneRoot(baseUrl)
    case MILESTONE_TYPE.ANNUAL_REPORT:
      return resolveAnnualReportMilestoneRoot(baseUrl)
    default:
      throw Error(`Unknown milestone type ${milestoneType}`)
  }
}

export const resolvePrimaryRoute = (resolvedRoute: any) => {
  const routes = resolvedRoute!.nested
  const primaryRoute = Object.keys(routes).find((key: string) => routes[key]?.primary) ?? ''
  return routes[primaryRoute]
}

export const resolveIntermediateMilestoneRoot = (baseUrl: '/pf-kap' | '/pf-pgv') => {
  if (baseUrl === '/pf-kap') {
    return ROUTES.PfKapIntermediateMilestoneRoot
  } else if (baseUrl === '/pf-pgv') {
    return ROUTES.PfPgvIntermediateMilestoneRoot
  } else {
    throw Error(`Unknown baseUrl ${baseUrl}`)
  }
}

export const resolveDefaultMilestoneRoot = (baseUrl: '/pf-kap' | '/pf-pgv') => {
  if (baseUrl === '/pf-kap') {
    return ROUTES.PfKapDefaultMilestoneRoot
  } else if (baseUrl === '/pf-pgv') {
    return ROUTES.PfPgvDefaultMilestoneRoot
  } else {
    throw Error(`Unknown baseUrl ${baseUrl}`)
  }
}

export const resolveFinancialReportMilestoneRoot = (baseUrl: '/pf-kap' | '/pf-pgv') => {
  if (baseUrl === '/pf-kap') {
    return ROUTES.PfKapFinancialReportMilestoneRoot
  } else if (baseUrl === '/pf-pgv') {
    return ROUTES.PfPgvFinancialReportMilestoneRoot
  } else {
    throw Error(`Unknown baseUrl ${baseUrl}`)
  }
}

export const resolveFinalReportMilestoneRoot = (baseUrl: '/pf-kap' | '/pf-pgv') => {
  if (baseUrl === '/pf-kap') {
    return ROUTES.PfKapFinalReportMilestoneRoot
  } else if (baseUrl === '/pf-pgv') {
    return ROUTES.PfPgvFinalReportMilestoneRoot
  } else {
    throw Error(`Unknown baseUrl ${baseUrl}`)
  }
}

export const resolveAnnualReportMilestoneRoot = (baseUrl: '/pf-kap' | '/pf-pgv') => {
  if (baseUrl === '/pf-kap') {
    return ROUTES.PfKapAnnualReportMilestoneRoot
  } else if (baseUrl === '/pf-pgv') {
    return ROUTES.PfPgvAnnualReportMilestoneRoot
  } else {
    throw Error(`Unknown baseUrl ${baseUrl}`)
  }
}

export const resolveKapMilestoneRoot = (milestoneType: MILESTONE_TYPE_TYPE) => {
  switch (milestoneType) {
    case MILESTONE_TYPE.CONTRACT:
    case MILESTONE_TYPE.INTERMEDIATE_DISCUSSION:
      return ROUTES.KapDefaultMilestoneRoot
    case MILESTONE_TYPE.FINANCIAL_REPORT:
      return ROUTES.KapFinancialReportMilestoneRoot
    case MILESTONE_TYPE.FINAL_REPORT:
      return ROUTES.KapFinalReportMilestoneRoot
    case MILESTONE_TYPE.ANNUAL_REPORT:
      return ROUTES.KapAnnualReportMilestoneRoot
    case MILESTONE_TYPE.ANNUAL_PLAN:
      return ROUTES.KapAnnualPlanningMilestoneRoot
    default:
      throw Error(`Unknown milestone type ${milestoneType}`)
  }
}
