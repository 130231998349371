import { AxiosResponse } from 'axios'
import { valueof } from 'src/@types/global'
import { GoalResponseData, Maybe, MeasureResponseData, ReportInput } from 'src/@types/graphql'
import { EnvironmentStore } from 'src/env/EnvironmentStore'
import {
  ALL_MODULES,
  GLOBAL_USER_ROLES,
  MODULE_TYPES,
  PROCESS,
  PROCESS_TYPE,
  PROJECT,
  PROJECT_TYPE,
  PROJECT_USER_ROLES,
  PROJECT_USER_ROLE_TYPE,
  USER_ROLES_TYPE,
} from 'src/shared/constants/constants'
import { MILESTONE_TYPE, MilestoneDetailsValidation } from 'src/shared/constants/milestone-constants'
import { downloadBlobFile } from 'src/shared/utils/BlobUtils'
import { DateUtils } from 'src/shared/utils/DateUtils'
import { MILESTONE_TYPE_TYPE } from './../constants/milestone-constants'

const isPublic = (): boolean => window.location.pathname.startsWith('/pub')

const resolveProcess = (baseUrl: '/pf-kap' | '/pf-pgv' | '/kap'): PROJECT_TYPE => {
  switch (baseUrl) {
    case '/pf-kap':
      return PROJECT.PF_KAP
    case '/pf-pgv':
      return PROJECT.PF_PGV
    case '/kap':
      return PROJECT.KAP
    default:
      throw new Error(`Unknown base url: ${baseUrl}`)
  }
}

const isInternalRole = (roles: Array<USER_ROLES_TYPE>): boolean => {
  // if the user has any role that starts with 'PD-GFCH-' we assume that it is an internal user
  return roles.some((x) => x.startsWith('PD-GFCH_'))
}

const isFactsheetCoordinatorRole = (roles: Array<USER_ROLES_TYPE>): boolean => {
  return roles.some((x) => x.startsWith(GLOBAL_USER_ROLES['PD-GFCH_FACTSHEET_COORDINATOR']))
}

const isGFCHCoordinatorRoleForProcess = (process: PROJECT_TYPE, globalRoles: Array<USER_ROLES_TYPE>) => {
  switch (process) {
    case 'PF_KAP': {
      return globalRoles.includes(GLOBAL_USER_ROLES['PD-GFCH_PF-KAP_COORDINATOR'])
    }
    case 'PF_PGV': {
      return globalRoles.includes(GLOBAL_USER_ROLES['PD-GFCH_PF-PGV_COORDINATOR'])
    }
    case 'KAP': {
      return globalRoles.includes(GLOBAL_USER_ROLES['PD-GFCH_KAP_COORDINATOR'])
    }
    default:
      return false
  }
}

const resolveProcessFromInternalGfchRole = (roles: Array<USER_ROLES_TYPE>): PROCESS_TYPE[] => {
  const pfKapRoles = [
    GLOBAL_USER_ROLES['PD-GFCH_PF-KAP_READER'],
    GLOBAL_USER_ROLES['PD-GFCH_PF-KAP_COORDINATOR'],
    GLOBAL_USER_ROLES['PD-GFCH_PF-KAP_CONTRIBUTOR'],
  ]
  const pfPgvRoles = [
    GLOBAL_USER_ROLES['PD-GFCH_PF-PGV_READER'],
    GLOBAL_USER_ROLES['PD-GFCH_PF-PGV_COORDINATOR'],
    GLOBAL_USER_ROLES['PD-GFCH_PF-PGV_CONTRIBUTOR'],
  ]
  const kapRoles = [
    GLOBAL_USER_ROLES['PD-GFCH_KAP_READER'],
    GLOBAL_USER_ROLES['PD-GFCH_KAP_COORDINATOR'],
    GLOBAL_USER_ROLES['PD-GFCH_KAP_CONTRIBUTOR'],
  ]

  const factsheetRoles = [GLOBAL_USER_ROLES['PD-GFCH_FACTSHEET_COORDINATOR']]

  const hasPfKap = pfKapRoles.some((r) => roles.includes(r)) ? PROCESS.PF_KAP : undefined
  const hasPfPgv = pfPgvRoles.some((r) => roles.includes(r)) ? PROCESS.PF_PGV : undefined
  const hasKap = kapRoles.some((r) => roles.includes(r)) ? PROCESS.KAP : undefined
  const hasFactsheet = factsheetRoles.some((r) => roles.includes(r)) ? PROCESS.FACTSHEET : undefined

  const processes: PROCESS_TYPE[] = [hasPfKap, hasPfPgv, hasKap, hasFactsheet].filter(
    (item) => item != null,
  ) as PROCESS_TYPE[]

  if (processes.length === 0) {
    throw new Error('Unknown role')
  }

  return processes
}

export const resolveProcessToLowerCase = (baseUrl: '/pf-kap' | '/pf-pgv' | '/kap'): string => {
  return resolveProcess(baseUrl).toString().toLowerCase()
}

export const downloadFile = async (fileUuid?: string): Promise<void> => {
  const backendUrl = EnvironmentStore.backendUrl

  if (fileUuid) {
    await downloadBlobFile(`${backendUrl}/api/file/${fileUuid}`)
  }
}

export const getDownloadFileName = (response: AxiosResponse): string => {
  const contentDisposition = response.headers['content-disposition']
  let fileName = ''
  if (contentDisposition) {
    const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
    if (fileNameMatch.length === 2) {
      fileName = fileNameMatch[1]
    }
  }
  return fileName
}

export const canExternalUserChangeProjectAdmin = (
  userGlobalRoles: Array<USER_ROLES_TYPE>,
  userProjectRoles: Array<valueof<PROJECT_USER_ROLE_TYPE>>,
): boolean => {
  if (isInternalRole(userGlobalRoles)) {
    return true
  }
  return userProjectRoles.includes(PROJECT_USER_ROLES.ADMIN)
}

const resolveMilestonePath = (milestoneType: MILESTONE_TYPE_TYPE): string => {
  if (!milestoneType) {
    return ''
  }

  return milestoneType.toString().toLowerCase().replaceAll('_', '-')
}

const resolveMilestoneType = (milestonePath: string | undefined): MILESTONE_TYPE_TYPE => {
  const resolvedType = milestonePath?.toUpperCase().replaceAll('-', '_') as MILESTONE_TYPE_TYPE
  if (MILESTONE_TYPE[resolvedType]) {
    return MILESTONE_TYPE[resolvedType]
  }

  throw Error(`Unknown milestone base: ${milestonePath}`)
}

export const resolveDefaultMilestoneValidationFields = (
  milestoneType: MILESTONE_TYPE_TYPE,
  processType: PROJECT_TYPE,
): MilestoneDetailsValidation => {
  switch (milestoneType) {
    case MILESTONE_TYPE.CONTRACT:
      return {
        requiredNotes: false,
        requiredDocuments: true,
      }
    case MILESTONE_TYPE.INTERMEDIATE_DISCUSSION:
      if (processType === PROJECT.KAP) {
        return {
          requiredNotes: false,
          requiredDocuments: true,
        }
      }
      return {
        requiredNotes: true,
        requiredDocuments: false,
      }
    case MILESTONE_TYPE.EVALUATION_CONCEPT:
      return {
        requiredNotes: false,
        requiredDocuments: true,
      }
    default:
      return {
        requiredNotes: false,
        requiredDocuments: false,
      }
  }
}

const isCantonalRole = (roles: Array<USER_ROLES_TYPE>): boolean => {
  return roles.some((r) => r.startsWith('PD-CANTON'))
}

const isExternalRole = (roles: Array<USER_ROLES_TYPE>): boolean => {
  return roles.some((r) => r === 'PD-EXT_USER')
}

const adaptYouTubeUrlForEmbedding = (url: string) => {
  const prefixRegex = /((?:https?:)?\/\/)?((?:www)\.)?(?:youtube\.com\/watch\?v=|youtu.be\/)/
  const suffixRegex = /(?=&ab_channel=).*/
  const relParameter = '?rel=0'

  const adaptedUrl = url.replace(prefixRegex, 'https://www.youtube.com/embed/').replace(suffixRegex, '').trim()

  if (adaptedUrl.includes(relParameter)) {
    return adaptedUrl
  } else return adaptedUrl.concat(relParameter)
}

export type ReportingGridResponseType = GoalResponseData | MeasureResponseData

const extendAnnualRatingReportArrayWithPrevAndNextValues = (
  data: ReportingGridResponseType[],
  reportInput: ReportInput,
): ReportingGridResponseType[] => {
  const sortFn = (a: ReportingGridResponseType, b: ReportingGridResponseType) => a.year - b.year

  if (reportInput && reportInput?.startDate && reportInput?.endDate) {
    let dataReportArray: ReportingGridResponseType[] = []
    const reportStartYear = parseInt(DateUtils.formatDate(new Date(reportInput?.startDate), DateUtils.YEAR_FORMAT))
    const reportEndYear = parseInt(DateUtils.formatDate(new Date(reportInput?.endDate), DateUtils.YEAR_FORMAT))

    const mapDefaultGoalReport = (yearsArray: number[]): ReportingGridResponseType[] =>
      yearsArray.map((year) => ({
        year: year,
        green: 0,
        orange: 0,
        red: 0,
        inactive: 0,
      }))

    const arrayRange = (start: number, stop: number) =>
      Array.from({ length: stop - start + 1 }, (_, index) => start + index)

    if (data.length === 0) {
      return mapDefaultGoalReport(arrayRange(reportStartYear, reportEndYear)).sort(sortFn)
    }

    const firstYearOfList = data?.[0].year
    const lastYearOfList = data?.[data.length - 1]?.year

    if (reportStartYear < firstYearOfList) {
      const beforeArray = mapDefaultGoalReport(arrayRange(reportStartYear, firstYearOfList - 1))
      dataReportArray = beforeArray.concat(data)
    } else {
      dataReportArray = data
    }

    if (lastYearOfList < reportEndYear) {
      const afterArray = mapDefaultGoalReport(arrayRange(lastYearOfList + 1, reportEndYear))
      dataReportArray = dataReportArray.concat(afterArray)
    }

    return dataReportArray.sort(sortFn)
  }

  return data.sort(sortFn)
}

const canExportKapMilestone = (milestoneType: MILESTONE_TYPE_TYPE) => {
  return milestoneType === MILESTONE_TYPE.ANNUAL_PLAN || milestoneType === MILESTONE_TYPE.ANNUAL_REPORT
}

const canExportMilestone = (milestoneType: MILESTONE_TYPE_TYPE) => {
  return milestoneType === MILESTONE_TYPE.ANNUAL_REPORT || milestoneType === MILESTONE_TYPE.FINANCIAL_REPORT
}

const sortModules = (modules: MODULE_TYPES[]): MODULE_TYPES[] =>
  modules.sort((a, b) => ALL_MODULES.indexOf(a) - ALL_MODULES.indexOf(b))

type TSourceKey<S> = S & { id?: Maybe<string | number> }

type SourceType<S> = {
  arr: TSourceKey<S>[]
  key: keyof TSourceKey<S>
}

const mergeBy = <T, V>(source: SourceType<T>, object: SourceType<V>): (T & V)[] => {
  // removes the id if it's not the key for merge for the second array
  if (object.key !== 'id') {
    object.arr = object.arr.map(({ id, ...payload }) => payload) as TSourceKey<V>[]
  }
  return source.arr.map((item) =>
    Object.assign({}, item, object.arr.find((i) => item?.[source.key] === i?.[object.key]) ?? []),
  ) as (T & V)[]
}

export const Utils = {
  isPublic,
  resolveProcess,
  resolveProcessToLowerCase,
  resolveProcessFromInternalGfchRole,
  downloadFile,
  getDownloadFileName,
  isInternalRole,
  isGFCHCoordinatorRoleForProcess,
  isFactsheetCoordinatorRole,
  canExternalUserChangeProjectAdmin,
  resolveMilestoneType,
  resolveMilestonePath,
  resolveDefaultMilestoneValidationFields,
  isCantonalRole,
  isExternalRole,
  adaptYouTubeUrlForEmbedding,
  extendAnnualRatingReportArrayWithPrevAndNextValues,
  canExportKapMilestone,
  canExportMilestone,
  sortModules,
  mergeBy,
}
