import { ComposePaths, ExtractRouteParams, withParams } from 'src/routing/routing-utils'
import { ApplicationTypeManagementIndexPage } from 'src/screens/administration/application-type-management/ApplicationTypeManagementIndexPage'
import { AssessorManagementPage } from 'src/screens/administration/round-management/details/assessor-management/AssessorManagementPage'
import { CommitteeDecisionManagementPage } from 'src/screens/administration/round-management/details/round-information/committee-decision-management/CommitteeDecisionManagementPage'
import { RoundInformationEditPage } from 'src/screens/administration/round-management/details/round-information/edit/RoundInformationEditPage'
import { RoundInformationPage } from 'src/screens/administration/round-management/details/round-information/RoundInformationPage'
import { RoundManagementDetailsIndexPage } from 'src/screens/administration/round-management/details/RoundManagementDetailsIndexPage'
import { RoundManagementIndexPage } from 'src/screens/administration/round-management/RoundManagementIndexPage'
import { UserAdministrationPage } from 'src/screens/administration/user-administration/UserAdministrationPage'

const UserAdministrationIndex = {
  path: 'administration/user-administration',
  Component: UserAdministrationPage,
  roles: ['PD-GFCH_PF-KAP_COORDINATOR', 'PD-GFCH_PF-PGV_COORDINATOR', 'PD-GFCH_KAP_COORDINATOR'],
} as const

const RoundManagementIndex = {
  path: 'administration/round-management',
  Component: RoundManagementIndexPage,
  roles: [
    'PD-GFCH_PF-KAP_COORDINATOR',
    'PD-GFCH_PF-PGV_COORDINATOR',
    'PD-GFCH_KAP_COORDINATOR',
    'PD-GFCH_FACTSHEET_COORDINATOR',
  ],
} as const

const ApplicationTypeManagementIndex = {
  path: 'administration/application-type-management',
  Component: ApplicationTypeManagementIndexPage,
  roles: ['PD-GFCH_PF-KAP_COORDINATOR', 'PD-GFCH_PF-PGV_COORDINATOR', 'PD-GFCH_KAP_COORDINATOR'],
} as const

const RoundManagementDetailsRoot = {
  path: 'administration/round-management/:roundId/details',
  Component: RoundManagementDetailsIndexPage,
  roles: [
    'PD-GFCH_PF-KAP_COORDINATOR',
    'PD-GFCH_PF-PGV_COORDINATOR',
    'PD-GFCH_KAP_COORDINATOR',
    'PD-GFCH_FACTSHEET_COORDINATOR',
  ],
} as const

const RoundInformation = {
  path: 'round-information',
  Component: RoundInformationPage,
  navigationKey: 'label.navigation.round.information',
  primary: true,
} as const

const RoundInformationEdit = {
  path: 'round-information/edit',
  Component: RoundInformationEditPage,
  hideNavigation: true,
} as const

const CommitteeDecisionManagement = {
  path: 'committee-decision-management',
  Component: CommitteeDecisionManagementPage,
  hideNavigation: true,
} as const

const AssessorManagement = {
  path: 'assessor-management',
  Component: AssessorManagementPage,
  navigationKey: 'label.navigation.assessor.management',
} as const

export const AdministrationRoutes = {
  RoundManagementIndex: {
    ...RoundManagementIndex,
    params: (props: ExtractRouteParams<typeof RoundManagementIndex.path>) =>
      withParams(`/${RoundManagementIndex.path}`)(props),
  },
  RoundManagementDetailsRoot: {
    ...RoundManagementDetailsRoot,
    params: (props: ExtractRouteParams<typeof RoundManagementDetailsRoot.path>) =>
      withParams(`/${RoundManagementDetailsRoot.path}`)(props),
    nested: {
      RoundInformation: {
        ...RoundInformation,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof RoundManagementDetailsRoot.path, typeof RoundInformation.path>>,
        ) => withParams(`/${RoundManagementDetailsRoot.path}/${RoundInformation.path}`)(props),
      },
      RoundInformationEdit: {
        ...RoundInformationEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof RoundManagementDetailsRoot.path, typeof RoundInformationEdit.path>
          >,
        ) => withParams(`/${RoundManagementDetailsRoot.path}/${RoundInformationEdit.path}`)(props),
      },
      CommitteeDecisionManagement: {
        ...CommitteeDecisionManagement,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof RoundManagementDetailsRoot.path, typeof CommitteeDecisionManagement.path>
          >,
        ) => withParams(`/${RoundManagementDetailsRoot.path}/${CommitteeDecisionManagement.path}`)(props),
      },
      AssessorManagement: {
        ...AssessorManagement,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof RoundManagementDetailsRoot.path, typeof AssessorManagement.path>
          >,
        ) => withParams(`/${RoundManagementDetailsRoot.path}/${AssessorManagement.path}`)(props),
      },
    },
  },
  ApplicationTypeManagementIndex: {
    ...ApplicationTypeManagementIndex,
    params: (props: ExtractRouteParams<typeof ApplicationTypeManagementIndex.path>) =>
      withParams(`/${ApplicationTypeManagementIndex.path}`)(props),
  },
  UserAdministrationIndex: {
    ...UserAdministrationIndex,
    params: (props: ExtractRouteParams<typeof UserAdministrationIndex.path>) =>
      withParams(`/${UserAdministrationIndex.path}`)(props),
  },
}
