import { Box, Chip, Tooltip } from '@mui/material'
import { DataGridPro, GridColDef, GridRowSelectionModel, GridSortModel, useGridApiRef } from '@mui/x-data-grid-pro'
import { ReactElement, useCallback, useMemo, useState } from 'react'
import { useMessageSource } from 'src/i18n/useMessageSource'
import {
  AssessmentViewModel,
  ProjectViewModel,
} from 'src/screens/administration/round-management/details/assessor-management/AssessorManagementPage'
import { PROCESS, PROCESS_TYPE } from 'src/shared/constants/constants'
import { useGridTranslateHook } from 'src/shared/utils/hooks/grid-translate-hook'

interface Props {
  projects: ProjectViewModel[]
  onRowSelection: (selection: GridRowSelectionModel) => void
}

export const AssessorManagementGrid = ({ projects, onRowSelection }: Props): ReactElement => {
  const apiRef = useGridApiRef()
  const gridTranslations = useGridTranslateHook()
  const { getMessage } = useMessageSource()

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'pid',
      sort: 'asc',
    },
  ])

  const getFieldsForProcess = (process: PROCESS_TYPE) => {
    const pfKapFields = [
      { name: 'instance_1', label: 'label.assessment.type.instance_1' },
      { name: 'instance_2', label: 'label.assessment.type.instance_2' },
    ]
    const pfPgvFields = [
      { name: 'workgroup', label: 'label.assessment.type.workgroup' },
      { name: 'expert_assessment', label: 'label.assessment.type.expert_assessment' },
    ]
    const factsheetFields = [{ name: 'factsheet_instance_1', label: 'label.assessment.type.factsheet.instance_1' }]

    switch (process) {
      case PROCESS.PF_KAP:
        return pfKapFields
      case PROCESS.PF_PGV:
        return pfPgvFields
      case PROCESS.FACTSHEET:
        return factsheetFields
      default:
        return []
    }
  }

  const getTooltipValue = (users: AssessmentViewModel[]) =>
    users?.map((value, i) => (
      <span key={i}>
        {value?.user?.firstName ? `${value?.user?.firstName} ${value?.user?.lastName}` : `${value?.user?.email}`}
        <br />
      </span>
    ))

  const assessorsChip = useCallback(
    (users: AssessmentViewModel[], value: number) => {
      return (
        <>
          <Tooltip title={users.length > 0 ? getTooltipValue(users) : ''} arrow>
            <Chip
              label={`${value} ${getMessage(`label.assessment.${value === 1 ? 'assessor' : 'assessors'}`)}`}
              variant="outlined"
            />
          </Tooltip>
        </>
      )
    },
    [getMessage],
  )

  const getGridColDefsForInstancesForProcess = useCallback(
    (process: PROCESS_TYPE): GridColDef[] => {
      const fields = getFieldsForProcess(process)
      return fields.map((field) => ({
        field: field.name,
        flex: 1,
        headerName: getMessage(field.label),
        valueGetter: (params: any) => params?.value?.length ?? 0,
        renderCell: ({ value, row }) => {
          const users = row?.[field.name] ?? []
          return assessorsChip(users, value)
        },
      }))
    },
    [assessorsChip, getMessage],
  )

  const columns = useMemo(() => {
    const process = projects?.[0]?.process
    const dynamicFields = getGridColDefsForInstancesForProcess(process)

    const getIdCharacter = (process: PROCESS_TYPE) => {
      switch (process) {
        case PROCESS.PF_KAP:
          return getMessage('label.project.id.character.pf_kap')
        case PROCESS.PF_PGV:
          return getMessage('label.project.id.character.pf_pgv')
        case PROCESS.FACTSHEET:
          return getMessage('label.factsheet.id.character')
        default:
          throw new Error('Unknown process ' + process)
      }
    }

    const fields: GridColDef[] = [
      {
        field: 'pid',
        flex: 0.2,
        headerName: getMessage('label.id'),
        renderCell: ({ row }) => `${getIdCharacter(row.process)}${row.pid}`,
      },
      {
        field: 'project',
        flex: 1,
        headerName: process === PROCESS.FACTSHEET ? getMessage('label.factsheet') : getMessage('label.project'),
      },
    ]

    fields.push(...dynamicFields)
    return fields
  }, [getGridColDefsForInstancesForProcess, projects, getMessage])

  const rows = useMemo(() => projects, [projects])

  return (
    <>
      <Box>
        <DataGridPro
          rows={rows}
          columns={columns}
          autoHeight
          apiRef={apiRef}
          localeText={gridTranslations}
          disableColumnSelector
          disableColumnReorder
          disableColumnPinning
          disableRowSelectionOnClick
          disableColumnMenu
          disableColumnFilter
          checkboxSelection
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          onRowSelectionModelChange={(selectionModel) => onRowSelection(selectionModel)}
        />
      </Box>
    </>
  )
}
