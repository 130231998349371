import { Box } from '@mui/material'
import arrayMutators from 'final-form-arrays'
import { Form } from 'react-final-form'
import { Option } from 'src/shared/form/control'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { createDecorators } from 'src/shared/form/utils/decorators'
import { FactsheetCommitteeValues } from 'src/screens/factsheets/application/committee/common/FactsheetApplicationCommitteeUtils'
import { FactsheetCommitteeDecisions } from 'src/screens/factsheets/application/committee/edit/FactsheetCommitteeDecisions'

interface Props {
  locale: string
  initialValues: FactsheetCommitteeValues | undefined
  exclusionCriteriaOptions: Option[]
  qualityCriteriaOptions: Option[]
  gfchResponsibleOptions: Option[]
  onSave: (values: FactsheetCommitteeValues) => Promise<void>
}

export const FactsheetCommitteeEditForm = ({
  locale,
  initialValues,
  qualityCriteriaOptions,
  exclusionCriteriaOptions,
  gfchResponsibleOptions,
  onSave,
}: Props) => {
  const decorators = createDecorators()
  return (
    <Box>
      <Form<FactsheetCommitteeValues>
        onSubmit={onSave}
        mutators={{ ...arrayMutators }}
        initialValues={initialValues}
        decorators={decorators}
        render={({ handleSubmit, values }) => {
          return (
            <form id="committees-form" onSubmit={handleSubmit}>
              <FactsheetCommitteeDecisions
                locale={locale}
                values={values}
                qualityCriteriaOptions={qualityCriteriaOptions}
                exclusionCriteriaOptions={exclusionCriteriaOptions}
                gfchResponsibleOptions={gfchResponsibleOptions}
              />
              <DirtyFormSpy />
            </form>
          )
        }}
      />
    </Box>
  )
}
