import { Stack } from '@mui/material'
import { ReactElement, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useParams } from 'react-router'
import { Mutation_Root, Query_Root } from 'src/@types/graphql'
import { useEnvironment } from 'src/env/EnvironmentStore'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { fetchFactsheetByIdQuery, updateFactsheetMutation } from 'src/screens/factsheets/factsheet/factsheetQueries'
import { usePermissionsForFactsheetDetailsDescriptionPage } from 'src/service/security/PermissionHook'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { TEXT_LENGTH } from 'src/shared/constants/constants'
import { ENVIRONMENT } from 'src/shared/constants/environment-constants'
import { FACTSHEET_DESCRIPTION_EDIT_OPTION } from 'src/shared/constants/factsheet-constants'
import { HtmlEditorField } from 'src/shared/form/control/HtmlEditorField'
import { TextField } from 'src/shared/form/control/TextField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { createDecorators } from 'src/shared/form/utils/decorators'
import { composeValidators, maxChar, required } from 'src/shared/form/validation/validators'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useClient } from 'urql'

interface FactsheetTitleAndDescriptionEditForm {
  title: FactsheetEditFields
  description: FactsheetEditFields
}

interface FactsheetEditFields {
  en: string
  de: string
  fr: string
  it: string
}

export const FactsheetTitleAndDescriptionEditPage = (): ReactElement => {
  const { getMessage } = useMessageSource()
  const { factsheetId } = useParams()
  const factsheetIdNumber = parseInt(factsheetId as string)
  const { decorators } = createDecorators()
  const { environment } = useEnvironment()
  const { canEdit, loading } = usePermissionsForFactsheetDetailsDescriptionPage(factsheetIdNumber)

  const navigate = useDelayedNavigate()
  const urqlClient = useClient()
  const notificationService = useNotificationService()

  const [initialValues, setInitialValues] = useState<FactsheetTitleAndDescriptionEditForm>()

  const onSave = (event: any) => {
    submit(event)
  }

  const onSaveAndBack = async (event: any) => {
    try {
      await submit(event)
      formValid && onBack()
    } catch {
      // do nothing
    }
  }

  const onBack = () => {
    const route = ROUTES.FactsheetDescriptionRoot.params({ factsheetId })
    navigate(`${route}#${FACTSHEET_DESCRIPTION_EDIT_OPTION.TITLE_AND_DESCRIPTION}`)
  }

  const onSubmitLocal = async (values: FactsheetTitleAndDescriptionEditForm) => {
    const { data } = await urqlClient
      .mutation<{
        update_factsheet: Mutation_Root['update_factsheet']
      }>(updateFactsheetMutation, { id: parseInt(factsheetId as string), object: values })
      .toPromise()

    if (data && data.update_factsheet?.affected_rows === 1) {
      setInitialValues(values)
      notificationService.changesSaved()
    } else {
      notificationService.operationFailed()
    }
  }

  useEffect(() => {
    const initData = async () => {
      const { data } = await urqlClient
        .query<{
          factsheet_by_pk: Query_Root['factsheet_by_pk']
        }>(fetchFactsheetByIdQuery, { id: parseInt(factsheetId as string) })
        .toPromise()

      const title = data?.factsheet_by_pk?.title
      const description = data?.factsheet_by_pk?.description

      setInitialValues({
        title: title,
        description: description,
      })
    }

    initData()
  }, [urqlClient, factsheetId, getMessage])

  let submit: any = () => {}
  let formValid = false

  if (!loading && !canEdit) {
    return <NotAuthorized />
  }

  return (
    <>
      <ScreenLayout
        title={getMessage('label.factsheet.edit.title_and_description')}
        onBack={onBack}
        hasSecondLevelNavigation={false}
        actions={
          <>
            <SaveAndBackButton origin="header" onClick={onSaveAndBack} />
            <SaveButton origin="header" onClick={onSave} />
          </>
        }
      >
        <PageLayout>
          {!loading && (
            <>
              <HelpAndInstructions labelKey="label.help.factsheet.title_and_description" defaultExpansion />
              <Form<FactsheetTitleAndDescriptionEditForm>
                initialValues={initialValues}
                onSubmit={onSubmitLocal}
                decorators={decorators}
                render={({ handleSubmit, valid }) => {
                  formValid = valid
                  submit = handleSubmit
                  return (
                    <form onSubmit={handleSubmit} noValidate id="edit-factsheet-form">
                      <Stack spacing={2}>
                        {environment === ENVIRONMENT.DEV && (
                          <TextField
                            name="title.en"
                            label={`${getMessage('label.title')} ${getMessage('label.language.en')}`}
                            validate={composeValidators(maxChar(TEXT_LENGTH.M), required())}
                          />
                        )}
                        <TextField
                          name="title.de"
                          label={`${getMessage('label.title')} ${getMessage('label.language.de')}`}
                          validate={composeValidators(maxChar(TEXT_LENGTH.M), required())}
                        />
                        <TextField
                          name="title.fr"
                          label={`${getMessage('label.title')} ${getMessage('label.language.fr')}`}
                          validate={composeValidators(maxChar(TEXT_LENGTH.M), required())}
                        />
                        <TextField
                          name="title.it"
                          label={`${getMessage('label.title')} ${getMessage('label.language.it')}`}
                          validate={composeValidators(maxChar(TEXT_LENGTH.M), required())}
                        />

                        {environment === ENVIRONMENT.DEV && (
                          <HtmlEditorField
                            name="description.en"
                            label={`${getMessage('label.description')} ${getMessage('label.language.en')}`}
                            validate={maxChar(TEXT_LENGTH.XL)}
                          />
                        )}
                        <HtmlEditorField
                          name="description.de"
                          label={`${getMessage('label.description')} ${getMessage('label.language.de')}`}
                          validate={maxChar(TEXT_LENGTH.XL)}
                        />
                        <HtmlEditorField
                          name="description.fr"
                          label={`${getMessage('label.description')} ${getMessage('label.language.fr')}`}
                          validate={maxChar(TEXT_LENGTH.XL)}
                        />
                        <HtmlEditorField
                          name="description.it"
                          label={`${getMessage('label.description')} ${getMessage('label.language.it')}`}
                          validate={maxChar(TEXT_LENGTH.XL)}
                        />
                        <DirtyFormSpy />
                      </Stack>
                    </form>
                  )
                }}
              />
            </>
          )}
        </PageLayout>
      </ScreenLayout>
    </>
  )
}
