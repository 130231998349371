import { Box } from '@mui/material'
import { GridColDef, GridPaginationModel, GridRowSelectionModel, GridSortModel } from '@mui/x-data-grid-pro'
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro'
import React, { MutableRefObject, useMemo, useState } from 'react'
import { FactsheetRelatedResponseData } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { HtmlRenderer } from 'src/shared/presentation/HtmlRenderer'
import { S } from 'src/shared/styled/S'
import { useGridTranslateHook } from 'src/shared/utils/hooks/grid-translate-hook'
import styled from 'styled-components/macro'

const DataGridProStyled = styled(S.DataGrid.ViewEditMode)`
  & .MuiDataGrid-columnHeader {
    & .MuiButtonBase-root.MuiCheckbox-root {
      display: none;
    }
  }
  & .MuiDataGrid-virtualScroller {
    overflow: hidden;
  }
`

const GridBoxStyled = styled(Box)`
  height: 668px;
`

interface Props {
  factsheets: FactsheetRelatedResponseData[]
  selectionModel: GridRowSelectionModel
  setSelectionModel: React.Dispatch<React.SetStateAction<GridRowSelectionModel>>
  gridRef: MutableRefObject<GridApiPro>
}

const PAGE_SIZE = 10

export const RelatedFactsheetsGrid = ({ factsheets, selectionModel, setSelectionModel, gridRef }: Props) => {
  const { getMessage } = useMessageSource()
  const gridTranslations = useGridTranslateHook()
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({ page: 0, pageSize: PAGE_SIZE })
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'id',
      sort: 'desc',
    },
  ])

  const rows = useMemo(() => factsheets, [factsheets])

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'id',
        flex: 0.5,
        headerName: getMessage('label.id'),
        renderCell: ({ row }) => `${getMessage('label.factsheet.id.character')}${row.id}`,
      },
      {
        field: 'title',
        flex: 2,
        headerName: getMessage('label.title'),
      },
      {
        field: 'description',
        flex: 2,
        headerName: getMessage('label.description'),
        renderCell: ({ row }) => {
          return <HtmlRenderer html={row.description} truncate variant="body2" />
        },
      },
      {
        field: 'topic',
        flex: 1,
        headerName: getMessage('label.project.features.topic'),
      },
      {
        field: 'modules',
        flex: 1,
        headerName: getMessage('label.modules'),
        renderCell: ({ row }) => {
          return row.modules?.map((m: string) => getMessage(`label.module.${m}`)).join(', ')
        },
      },
    ],
    [getMessage],
  )

  const rowSelectionHandler = (selection: GridRowSelectionModel) => {
    if (selection.length > 1) {
      const selectionSet = new Set(selectionModel)
      const result = selection.filter((s) => !selectionSet.has(s))

      setSelectionModel(result)
    } else {
      setSelectionModel(selection)
    }
  }

  return (
    <GridBoxStyled>
      <DataGridProStyled
        rows={rows}
        columns={columns}
        apiRef={gridRef}
        localeText={gridTranslations}
        disableRowSelectionOnClick
        disableColumnFilter
        disableColumnReorder
        disableColumnSelector
        disableColumnPinning
        paginationModel={paginationModel}
        onPaginationModelChange={(model) => setPaginationModel(model)}
        pageSizeOptions={[PAGE_SIZE]}
        pagination
        checkboxSelection
        rowSelectionModel={selectionModel}
        onRowSelectionModelChange={(selectionModel: GridRowSelectionModel) => rowSelectionHandler(selectionModel)}
        sortModel={sortModel}
        onSortModelChange={(model: React.SetStateAction<GridSortModel>) => setSortModel(model)}
        scrollbarSize={0}
        $viewMode={true}
      />
    </GridBoxStyled>
  )
}
