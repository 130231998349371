const FACTSHEET_DESCRIPTION_EDIT_OPTION = {
  BASIC_INFO: 'basic-info',
  TITLE_AND_DESCRIPTION: 'title-and-description',
  IMPORTANT_INSIGHTS: 'important-insights',
  STRENGTH: 'strength',
  FURTHER_INFORMATION: 'further-information',
  EVIDENCE_OF_EFFECTIVENESS: 'evidence-of-effectiveness',
} as const

export { FACTSHEET_DESCRIPTION_EDIT_OPTION }

export const FACTSHEET_WORKFLOW_STATUS = {
  CONCEPT: 'CONCEPT',
  APPLICATION: 'APPLICATION',
  CANCELED: 'CANCELED',
  FINISHED: 'FINISHED',
  REVISION: 'REVISION',
  ACCEPTED: 'ACCEPTED',
  WITHDRAWN: 'WITHDRAWN',
  REJECTED: 'REJECTED',
  EXCLUDED: 'EXCLUDED',
} as const

export type FACTSHEET_WORKFLOW_STATUS_TYPE = keyof typeof FACTSHEET_WORKFLOW_STATUS
