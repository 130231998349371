import { useCallback, useEffect, useMemo, useState } from 'react'
import { FactsheetRelatedResponseData, FactsheetRelatedSearchInput, Mutation_Root } from 'src/@types/graphql'
import { LEVEL_TYPE, MODULE_TYPES } from 'src/shared/constants/constants'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useUser } from 'src/user/UserContext'
import { gql, useClient } from 'urql'

export const fetchFactsheetsRelatedDataMutation = gql`
  mutation fetchFactsheetsRelated($input: FactsheetRelatedSearchInput!) {
    fetchFactsheetsRelated(search: $input) {
      data {
        id
        title
        description
        topic
        modules
        strength
      }
    }
  }
`

export const useFetchRelatedFactsheets = ({ modules, levels }: { modules: MODULE_TYPES[]; levels: LEVEL_TYPE[] }) => {
  const { user } = useUser()
  const urqlClient = useClient()
  const notificationService = useNotificationService()

  const [filteredFactsheets, setFilteredFactsheets] = useState<FactsheetRelatedResponseData[]>([])

  const fetchData = useCallback(
    async (search: FactsheetRelatedSearchInput) => {
      const { data } = await urqlClient
        .mutation<
          {
            fetchFactsheetsRelated: Mutation_Root['fetchFactsheetsRelated']
          },
          { input: FactsheetRelatedSearchInput }
        >(fetchFactsheetsRelatedDataMutation, {
          input: search,
        })
        .toPromise()

      if (data?.fetchFactsheetsRelated) {
        const mappedFactsheets: FactsheetRelatedResponseData[] = data.fetchFactsheetsRelated.data
        setFilteredFactsheets(mappedFactsheets)
        return mappedFactsheets
      } else {
        notificationService.operationFailed()
      }
    },
    [urqlClient, notificationService],
  )

  useEffect(() => {
    const search: FactsheetRelatedSearchInput = {
      search: null,
      modules: modules as unknown as string[],
      levels: levels,
      features: null,
      published: true,
      language: user.language,
    }

    fetchData(search)
  }, [urqlClient, notificationService, fetchData, user.language, modules, levels])

  const returnObject = useMemo(
    () => ({
      filteredFactsheets,
      fetchData,
    }),
    [filteredFactsheets, fetchData],
  )

  return returnObject
}
