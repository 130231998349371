import { Button, Card, CardContent, Divider, Stack } from '@mui/material'
import { FormApi } from 'final-form'
import { ReactElement, useContext, useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import {
  FactsheetSearchInput,
  FeaturesFilterInput,
  FilteredFactsheetResponseData,
  Mutation_Root,
} from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { fetchFactsheetSearchDataMutation } from 'src/screens/factsheets/factsheet/factsheetQueries'
import { FilterCardErrorText } from 'src/screens/shared/common/filter-card/FilterCardErrorText'
import { FeaturesFilter } from 'src/screens/shared/common/filter-card/filters/features/FeaturesFilter'
import { LevelFilter } from 'src/screens/shared/common/filter-card/filters/LevelFilter'
import { ModulesFilter } from 'src/screens/shared/common/filter-card/filters/ModulesFilter'
import { SearchFilter } from 'src/screens/shared/common/filter-card/filters/SearchFilter'
import { SearchButton } from 'src/shared/button/Buttons'
import { ALL_MODULES, LEVEL, LEVEL_TYPE, MODULE_TYPES } from 'src/shared/constants/constants'
import { FeaturesFilterModel } from 'src/shared/constants/filter-constants'
import { FEATURE_OPERATOR } from 'src/shared/constants/reporting-constants'
import { ResetIcon } from 'src/shared/icons/Icons'
import { S } from 'src/shared/styled/S'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import StorageUtils, { STORAGE_KEY } from 'src/shared/utils/StorageUtils'
import { UserContext } from 'src/user/UserContext'
import { useClient } from 'urql'
import { usePermissionsForFactsheetsIndexPage } from 'src/service/security/PermissionHook'

const FACTSHEET_SEARCH_FIELDS = {
  SEARCH: 'search',
  MODULES: 'modules',
  LEVEL: 'level',
  FEATURES: 'features',
  LANGUAGE: 'language',
}

interface FactsheetsFilterForm {
  search: string
  modules: MODULE_TYPES[]
  level: LEVEL_TYPE[]
  features: FeaturesFilterModel
}

interface Props {
  setFilteredFactsheets: (v: FilteredFactsheetResponseData[]) => void
  setFactsheetsCount: (v: number) => void
  fetchInitialData: () => void
  setFactsheetsSearchInput: (searchInput: FactsheetSearchInput) => void
}

export const FactsheetsFilterCard = ({
  setFilteredFactsheets,
  setFactsheetsCount,
  fetchInitialData,
  setFactsheetsSearchInput,
}: Props): ReactElement => {
  const { getMessage } = useMessageSource()
  const { user } = useContext(UserContext)
  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const { canViewAllFactsheets } = usePermissionsForFactsheetsIndexPage()

  const [beingSearched, setBeingSearched] = useState(false)
  const [featuresInput, setFeaturesInput] = useState<FeaturesFilterModel>()
  const [resetFilterState, setResetFilterState] = useState<boolean>(false)
  const [lastSearch, setLastSearch] = useState<FactsheetsFilterForm>()

  const modules = useMemo(() => ALL_MODULES.map((m) => m), [])

  const defaultFormValue: FactsheetsFilterForm = useMemo(
    () => ({
      search: '',
      modules: modules,
      level: Object.values(LEVEL).map((l) => l),
      features: {
        betweenFeatureTypesOperator: FEATURE_OPERATOR.AND,
        selectedFeatures: [],
        withinFeatureTypeOperator: FEATURE_OPERATOR.AND,
      },
    }),
    [modules],
  )

  const [initialValues, setInitialValues] = useState<FactsheetsFilterForm>()

  let formValid = false

  const featureConfigWhere = useMemo(
    () => ({
      levels: { _neq: [] },
    }),
    [],
  )

  const mapFormValuesToSearchInput = (values: FactsheetsFilterForm): FactsheetSearchInput => {
    return {
      search: values.search ?? null,
      modules: values.modules,
      levels: values.level,
      features: featuresInput as FeaturesFilterInput,
      userId: canViewAllFactsheets ? null : user.id,
      language: user.language,
    }
  }

  useEffect(() => {
    const savedSearchValue = StorageUtils.get(STORAGE_KEY._FACTSHEET_FILTER_STORAGE)
    if (savedSearchValue) {
      setFeaturesInput(savedSearchValue?.features)
      setInitialValues(savedSearchValue)
      setLastSearch(savedSearchValue)
    } else {
      setInitialValues(defaultFormValue)
    }
  }, [defaultFormValue])

  const handleSubmitSearch = async (values: FactsheetsFilterForm) => {
    setBeingSearched(true)

    setLastSearch(values)
    StorageUtils.store(STORAGE_KEY._FACTSHEET_FILTER_STORAGE, { ...values, features: featuresInput })
    const search = mapFormValuesToSearchInput(values)

    const { data } = await urqlClient
      .mutation<
        {
          fetchFactsheetSearchData: Mutation_Root['fetchFactsheetSearchData']
        },
        { input: FactsheetSearchInput }
      >(fetchFactsheetSearchDataMutation, {
        input: search,
      })
      .toPromise()

    if (data?.fetchFactsheetSearchData) {
      const mappedFactsheets: FilteredFactsheetResponseData[] = data.fetchFactsheetSearchData.data
      setFilteredFactsheets(mappedFactsheets)
      setFactsheetsCount(mappedFactsheets.length)
    } else {
      notificationService.operationFailed()
    }

    setBeingSearched(false)
    setFactsheetsSearchInput(search)
  }

  useEffect(() => {
    // Only happens when language is changed in order to only once call the Hasura action for factsheet filtering
    // (because the factsheets have multilingual fields that need to be shown in the result table).
    if (lastSearch) {
      handleSubmitSearch(lastSearch)
    }
  }, [user.language, lastSearch]) // eslint-disable-line

  const onResetFilters = async (form: FormApi<FactsheetsFilterForm>) => {
    form.restart()
    // Trigger the function that renders UI interaction states of individual filters
    setInitialValues(defaultFormValue)
    StorageUtils.remove(STORAGE_KEY._FACTSHEET_FILTER_STORAGE)
    await setResetFilterState(true)
    setResetFilterState(false)
    await fetchInitialData()
  }

  return (
    <>
      {initialValues && (
        <Card>
          <Form<FactsheetsFilterForm>
            initialValues={initialValues}
            onSubmit={handleSubmitSearch}
            render={({ form, handleSubmit, errors, valid, initialValues, values }) => {
              formValid = valid

              const searchError = !!errors?.[FACTSHEET_SEARCH_FIELDS.SEARCH]
              const modulesError = !!errors?.[FACTSHEET_SEARCH_FIELDS.MODULES]
              const levelError = !!errors?.[FACTSHEET_SEARCH_FIELDS.LEVEL]

              const fields = {
                search: {
                  name: FACTSHEET_SEARCH_FIELDS.SEARCH,
                  label: 'label.search',
                  error: searchError,
                },
                modules: {
                  name: FACTSHEET_SEARCH_FIELDS.MODULES,
                  label: 'label.modules',
                  error: modulesError,
                },
                level: {
                  name: FACTSHEET_SEARCH_FIELDS.LEVEL,
                  label: 'label.level',
                  error: levelError,
                },
              }

              return (
                <form id="factsheets-filter-form" onSubmit={handleSubmit} noValidate>
                  <CardContent>
                    <Stack spacing={1} divider={<Divider />}>
                      <SearchFilter
                        name={FACTSHEET_SEARCH_FIELDS.SEARCH}
                        error={searchError}
                        placeholder={getMessage('label.factsheets.filter.search.placeholder')}
                      />
                      <ModulesFilter
                        name={FACTSHEET_SEARCH_FIELDS.MODULES}
                        error={modulesError}
                        formValues={values.modules}
                        modules={modules}
                      />
                      <LevelFilter name={FACTSHEET_SEARCH_FIELDS.LEVEL} error={levelError} formValues={values.level} />
                      <FeaturesFilter
                        featureConfigWhere={featureConfigWhere}
                        setFeaturesInput={setFeaturesInput}
                        isReset={resetFilterState}
                        initialState={initialValues.features}
                      />
                    </Stack>
                  </CardContent>
                  <S.FilterCard.Actions>
                    <FilterCardErrorText fields={fields} formErrors={errors} />
                    <Button startIcon={<ResetIcon />} onClick={() => onResetFilters(form)} disabled={beingSearched}>
                      {getMessage('button.reporting.reset.filters')}
                    </Button>
                    <SearchButton type="submit" disabled={!formValid} loading={beingSearched} />
                  </S.FilterCard.Actions>
                </form>
              )
            }}
          />
        </Card>
      )}
    </>
  )
}
