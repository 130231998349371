import { Button, Card, CardActions, CardContent, CardHeader, Stack, Typography } from '@mui/material'
import { ReactElement, ReactNode, useState } from 'react'
import { valueof } from 'src/@types/global'
import { FactsheetValidationResponse, Feature_Type_Config } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { ListItemComplete, ListItemIncomplete } from 'src/screens/shared/application/common/SummarySectionAlert'
import { ValidationListItemProps } from 'src/screens/shared/common/SummaryValidationUtils'
import { FactsheetWorkflowService } from 'src/service/axios/FactsheetWorkflowService'
import { FACTSHEET_USER_ROLE_TYPE, USER_ROLES_TYPE } from 'src/shared/constants/constants'
import { FACTSHEET_WORKFLOW_STATUS, FACTSHEET_WORKFLOW_STATUS_TYPE } from 'src/shared/constants/factsheet-constants'
import { LIST_HEIGHT } from 'src/shared/constants/styling-constants'
import { NextStateFactsheet } from 'src/shared/constants/workflow-constants'
import { DeleteIcon } from 'src/shared/icons/Icons'
import { SecondaryConfirmationModalDialog } from 'src/shared/modal-dialog/SecondaryConfirmationModalDialog'
import { Section } from 'src/shared/presentation/Section'
import { useSidebarAPI } from 'src/shared/sidebar/SidebarAwareContext'
import { useModalCancel } from 'src/shared/utils/hooks/modal-hooks'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { NextStateFactsheetIf } from 'src/shared/workflow/NextStateFactsheetIf'
import { useUserLocale } from 'src/user/UserContext'
import styled from 'styled-components/macro'

interface FactsheetSummarySectionProps {
  title: string
  canDeleteFactsheet: boolean
  factsheetId: number
  status: FACTSHEET_WORKFLOW_STATUS_TYPE
  violations: FactsheetValidationResponse[]
  userFactsheetRoles: Array<valueof<FACTSHEET_USER_ROLE_TYPE>>
  userGlobalRoles: Array<USER_ROLES_TYPE>
  actionsCompleteMessage: string
  featureTypes: Feature_Type_Config[]
  helpAndInstructions?: ReactNode
  noItemsToValidate: boolean
  nextStates: NextStateFactsheet[]
  workflowId: string
  refetchPageData: () => void
  violationClickHandler: (validationItem: ValidationListItemProps) => void
}

const SpanStyled = styled.span<{ $isCanceled: boolean }>`
  color: ${({ $isCanceled, theme }) => ($isCanceled ? theme.colors.error.dark : 'inherit')};
  line-height: 2.25rem;
`

const ListTitle = styled(Typography)`
  height: ${LIST_HEIGHT};
  display: flex;
  align-items: center;
`

export const FactsheetSummarySection = ({
  title,
  canDeleteFactsheet,
  factsheetId,
  status,
  helpAndInstructions,
  actionsCompleteMessage,
  violations,
  featureTypes,
  nextStates,
  workflowId,
  refetchPageData,
  noItemsToValidate,
  violationClickHandler,
  userFactsheetRoles,
  userGlobalRoles,
}: FactsheetSummarySectionProps): ReactElement => {
  const { getMessage } = useMessageSource()
  const sidebarAPI = useSidebarAPI()
  const notificationService = useNotificationService()
  const language = useUserLocale()
  const navigate = useDelayedNavigate()

  const [deleteFactsheetConfirmationOpen, setDeleteFactsheetConfirmationOpen] = useState(false)
  const [withdrawnConfirmationOpen, setWithdrawnConfirmationOpen] = useState(false)
  const [applicationConfirmationOpen, setApplicationConfirmationOpen] = useState(false)
  const [transitionToStateLoading, setTransitionToStateLoading] = useState(false)

  const openDeleteFactsheetConfirmationModalDialog = () => {
    setDeleteFactsheetConfirmationOpen(true)
  }

  const cancelDeleteConfirmationModalDialog = useModalCancel(() => setDeleteFactsheetConfirmationOpen(false))

  const confirmDeleteHandler = async () => {
    try {
      setTransitionToStateLoading(true)
      await FactsheetWorkflowService.deleteFactsheet(factsheetId)
      navigate(`/${ROUTES.FactsheetsIndex.path}`)
      setDeleteFactsheetConfirmationOpen(false)
      notificationService.deleteSuccessful()
    } catch (e) {
      notificationService.operationFailed()
    } finally {
      setTransitionToStateLoading(false)
    }
  }

  const openWithdrawnConfirmationModalDialog = () => {
    setWithdrawnConfirmationOpen(true)
  }

  const cancelWithdrawnConfirmationModalDialog = useModalCancel(() => setWithdrawnConfirmationOpen(false))

  const confirmWithdrawnHandler = async () => {
    try {
      setTransitionToStateLoading(true)
      await FactsheetWorkflowService.transition(workflowId, FACTSHEET_WORKFLOW_STATUS.WITHDRAWN)
      refetchPageData()
      sidebarAPI.refetchSidebarData()
      setWithdrawnConfirmationOpen(false)
      notificationService.changesSaved()
    } catch (e) {
      notificationService.operationFailed()
    } finally {
      setTransitionToStateLoading(false)
    }
  }

  const openApplicationConfirmationModalDialog = () => {
    setApplicationConfirmationOpen(true)
  }

  const cancelApplicationConfirmationModalDialog = useModalCancel(() => setApplicationConfirmationOpen(false))

  const confirmApplicationHandler = async () => {
    try {
      setTransitionToStateLoading(true)
      await FactsheetWorkflowService.transition(workflowId, FACTSHEET_WORKFLOW_STATUS.APPLICATION)
      refetchPageData()
      sidebarAPI.refetchSidebarData()
      setApplicationConfirmationOpen(false)
      notificationService.changesSaved()
    } catch (e) {
      notificationService.operationFailed()
    } finally {
      setTransitionToStateLoading(false)
    }
  }

  return (
    <>
      <Section
        title={title}
        helpAndInstructions={helpAndInstructions}
        actionButton={
          status === FACTSHEET_WORKFLOW_STATUS.CONCEPT &&
          canDeleteFactsheet && (
            <Button
              variant="contained"
              color="error"
              onClick={openDeleteFactsheetConfirmationModalDialog}
              startIcon={<DeleteIcon />}
            >
              {getMessage('button.factsheet.delete')}
            </Button>
          )
        }
      >
        <Card>
          <CardHeader
            title={
              <>
                {getMessage('label.factsheet.status')}:{' '}
                <SpanStyled $isCanceled={status === FACTSHEET_WORKFLOW_STATUS.CANCELED}>
                  {getMessage(`label.factsheet.status.${status}`)}
                </SpanStyled>
              </>
            }
            action={
              <CardActions sx={{ padding: 0 }}>
                <NextStateFactsheetIf
                  forState={FACTSHEET_WORKFLOW_STATUS.WITHDRAWN}
                  nextStates={nextStates}
                  userGlobalRoles={userGlobalRoles}
                  userFactsheetRoles={userFactsheetRoles}
                >
                  <Button
                    variant="outlined"
                    color="secondary"
                    sx={{
                      display:
                        status == FACTSHEET_WORKFLOW_STATUS.CONCEPT || status == FACTSHEET_WORKFLOW_STATUS.REVISION
                          ? 'inline-block'
                          : 'none',
                    }}
                    onClick={openWithdrawnConfirmationModalDialog}
                  >
                    {getMessage('button.set.to.withdrawn')}
                  </Button>
                </NextStateFactsheetIf>

                <NextStateFactsheetIf
                  forState={FACTSHEET_WORKFLOW_STATUS.APPLICATION}
                  nextStates={nextStates}
                  userGlobalRoles={userGlobalRoles}
                  userFactsheetRoles={userFactsheetRoles}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={violations.length > 0}
                    onClick={openApplicationConfirmationModalDialog}
                  >
                    {getMessage('button.generate.application')}
                  </Button>
                </NextStateFactsheetIf>
              </CardActions>
            }
          />

          <CardContent>
            <Stack spacing={1}>
              <ListTitle variant="h6">
                {getMessage('label.project.summary.description.system.check.for.completion')}
              </ListTitle>

              {violations.length === 0 ? (
                <ListItemComplete message={actionsCompleteMessage} noItemsToValidate={noItemsToValidate} />
              ) : (
                violations?.map((violation) => {
                  const listItemsProps: ValidationListItemProps[] = []

                  if (violation.missingFeatureTypes.length > 0) {
                    for (const missingFeatureTypeCode of violation?.missingFeatureTypes ?? []) {
                      const featureType = featureTypes.find(
                        (featureType) => featureType.code === missingFeatureTypeCode,
                      )
                      if (featureType !== undefined) {
                        const message = getMessage(violation.validationViolationMessageKey, [
                          featureType.names[language],
                        ])
                        listItemsProps.push({
                          message: message,
                          tab: violation.factsheetDescriptionTab,
                          section: missingFeatureTypeCode,
                        })
                      }
                    }
                  } else {
                    const labelArguments = violation.labelArguments.map((labelArgument) => getMessage(labelArgument))
                    const message = getMessage(violation.validationViolationMessageKey, labelArguments)
                    listItemsProps.push({
                      message: message,
                      tab: violation.factsheetDescriptionTab,
                      section: violation.section ?? undefined,
                    })
                  }

                  return listItemsProps.map((listItemProps) => {
                    return (
                      <ListItemIncomplete
                        message={listItemProps.message}
                        key={listItemProps.message}
                        onClick={() => violationClickHandler(listItemProps)}
                        onKeyPress={(event) => {
                          if (['Enter', ' '].includes(event.key)) {
                            violationClickHandler(listItemProps)
                          }
                        }}
                      />
                    )
                  })
                })
              )}
            </Stack>
          </CardContent>
        </Card>
      </Section>

      <SecondaryConfirmationModalDialog
        open={withdrawnConfirmationOpen}
        onCancel={cancelWithdrawnConfirmationModalDialog}
        onConfirm={confirmWithdrawnHandler}
        titleKey={'label.factsheet.confirm.withdrawn.title'}
        confirmButtonKey={'button.confirm'}
        loading={transitionToStateLoading}
      >
        {getMessage('label.factsheet.confirm.withdrawn.body')}
      </SecondaryConfirmationModalDialog>

      <SecondaryConfirmationModalDialog
        open={applicationConfirmationOpen}
        onCancel={cancelApplicationConfirmationModalDialog}
        onConfirm={confirmApplicationHandler}
        titleKey={'label.factsheet.confirm.application.title'}
        confirmButtonKey={'button.confirm'}
        loading={transitionToStateLoading}
      >
        {getMessage('label.factsheet.confirm.application.body')}
      </SecondaryConfirmationModalDialog>

      <SecondaryConfirmationModalDialog
        open={deleteFactsheetConfirmationOpen}
        onCancel={cancelDeleteConfirmationModalDialog}
        onConfirm={confirmDeleteHandler}
        titleKey={'label.delete.confirm.factsheet.title'}
        confirmButtonKey={'button.factsheet.delete'}
        buttonColor={'error'}
        loading={transitionToStateLoading}
      >
        {getMessage('label.delete.confirm.factsheet')}
      </SecondaryConfirmationModalDialog>
    </>
  )
}
