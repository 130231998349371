import { Box, Stack } from '@mui/material'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Form } from 'react-final-form'
import { useParams } from 'react-router'
import { Mutation_Root, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { saveFactsheetApplicationInformation } from 'src/screens/factsheets/application/details/application-information/factsheetApplicationInformationQueries'
import {
  FactsheetApplicationInformationValues,
  useResolveFactsheetApplicationInformation,
} from 'src/screens/factsheets/application/details/application-information/FactsheetApplicationInformationService'
import { queryFactsheetFundingRounds } from 'src/screens/factsheets/application/details/summary/factsheetApplicationSummaryQueries'
import { usePermissionsForFactsheetApplication } from 'src/service/security/PermissionHook'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { RECOMMENDATION_OR_DECISION } from 'src/shared/constants/assessment-constants'
import { PROCESS } from 'src/shared/constants/constants'
import { FACTSHEET_WORKFLOW_STATUS } from 'src/shared/constants/factsheet-constants'
import { Option } from 'src/shared/form/control'
import { AutoCompleteField } from 'src/shared/form/control/AutoCompleteField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useClient } from 'urql'

type originType = 'SAVE' | 'SAVE_AND_BACK'

export const FactsheetApplicationInformationEditPage = () => {
  const urqlClient = useClient()
  const { getMessage } = useMessageSource()

  const navigate = useDelayedNavigate()
  const { factsheetId } = useParams()

  const factsheet_id = parseInt(factsheetId as string)

  const { loading, canEdit } = usePermissionsForFactsheetApplication(factsheet_id)
  const { applicationInformation, setApplicationInformation } = useResolveFactsheetApplicationInformation(factsheet_id)
  const notificationService = useNotificationService()

  const [fundingRoundOptions, setFundingRoundOptions] = useState<Option[]>([])
  const originRef = useRef<originType>('SAVE')

  const decisionOptions = useMemo(
    () => [
      { label: getMessage('label.recommendation.ACCEPT'), value: RECOMMENDATION_OR_DECISION.ACCEPT },
      { label: getMessage('label.recommendation.REJECT'), value: RECOMMENDATION_OR_DECISION.REJECT },
      { label: getMessage('label.recommendation.EXCLUSION'), value: RECOMMENDATION_OR_DECISION.EXCLUSION },
      { label: getMessage('label.field.not.available'), value: null },
    ],
    [getMessage],
  )

  useEffect(() => {
    const fetchActiveFundingRounds = async () => {
      const { data } = await urqlClient
        .query<{
          funding_round: Query_Root['funding_round']
        }>(queryFactsheetFundingRounds, {
          process: PROCESS.FACTSHEET,
        })
        .toPromise()

      const fundingRoundOptions = []

      data?.funding_round.forEach((fundingRound) => {
        fundingRoundOptions.push({
          label: `${getMessage('label.round.id.character')}${fundingRound.id} ${fundingRound.name}`,
          value: fundingRound.id,
        })
      })

      fundingRoundOptions.push({ label: getMessage('label.field.not.available'), value: null })
      setFundingRoundOptions(fundingRoundOptions)
    }

    fetchActiveFundingRounds()
  }, [factsheet_id, getMessage, urqlClient])

  const handleSubmitLocal = async (values: FactsheetApplicationInformationValues) => {
    const factsheetApplicationDecision = {
      factsheet_id: factsheet_id,
      application_decision: values.applicationDecision,
    }
    const version = applicationInformation?.version ?? 0
    const fundingRoundId = values.fundingRoundId

    const { error, data } = await urqlClient
      .mutation<{
        insert_factsheet_application_decision: Mutation_Root['insert_factsheet_application_decision']
        update_factsheet: Mutation_Root['update_factsheet']
      }>(saveFactsheetApplicationInformation, {
        factsheetId: factsheet_id,
        version,
        factsheetApplicationDecision,
        fundingRoundId,
      })
      .toPromise()

    if (
      error ||
      data?.insert_factsheet_application_decision?.affected_rows !== 1 ||
      data?.update_factsheet?.affected_rows !== 1
    ) {
      notificationService.operationFailed()
    } else {
      const newVersion = data!.insert_factsheet_application_decision?.returning[0].version
      const newApplicationInformationValues = {
        ...values,
        version: newVersion,
      } as FactsheetApplicationInformationValues
      setApplicationInformation(newApplicationInformationValues)
      notificationService.changesSaved()
      if (originRef.current === 'SAVE_AND_BACK') {
        navigate(ROUTES.FactsheetApplicationDetailsRoot.nested.Information.params({ factsheetId }))
      }
    }
  }

  const onSubmit = (origin: originType) => (_: any) => {
    // trigger submit event
    if (originRef) {
      originRef.current = origin
    }
    document.getElementById('edit-form')?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const onBack = () => {
    navigate(ROUTES.FactsheetApplicationDetailsRoot.nested.Information.params({ factsheetId }))
  }

  if (!loading && !canEdit) {
    return <NotAuthorized inEditPage />
  }

  return (
    <ScreenLayout
      title={getMessage('label.application.factsheet.info.edit')}
      onBack={onBack}
      hasSecondLevelNavigation={false}
      actions={
        <>
          <SaveAndBackButton origin="header" onClick={onSubmit('SAVE_AND_BACK')} />
          <SaveButton origin="header" onClick={onSubmit('SAVE')} />
        </>
      }
    >
      <PageLayout>
        {!loading && !!applicationInformation && (
          <>
            <HelpAndInstructions labelKey={`label.help.application.information.factsheet`} defaultExpansion />
            <Box>
              <Form<FactsheetApplicationInformationValues>
                initialValues={applicationInformation}
                onSubmit={handleSubmitLocal}
                render={({ handleSubmit }) => {
                  return (
                    <form onSubmit={handleSubmit} id="edit-form">
                      <Stack spacing={2}>
                        <AutoCompleteField
                          label={getMessage('label.funding.round')}
                          name="fundingRoundId"
                          options={fundingRoundOptions}
                          disabled={applicationInformation?.factsheetStatus === FACTSHEET_WORKFLOW_STATUS.CONCEPT}
                        />
                        <AutoCompleteField
                          label={getMessage('label.application.decision')}
                          name="applicationDecision"
                          options={decisionOptions}
                        />
                      </Stack>
                      <DirtyFormSpy />
                    </form>
                  )
                }}
              />
            </Box>
          </>
        )}
      </PageLayout>
    </ScreenLayout>
  )
}
