import { gql } from 'urql'

export const queryFactsheetApplicationInformationByFactsheetId = gql`
  query ($factsheetId: Int!) {
    factsheet_application_decision(where: { factsheet_id: { _eq: $factsheetId } }) {
      application_decision
      version
    }
    factsheet(where: { id: { _eq: $factsheetId } }) {
      workflow_status
      funding_round_id
      funding_round {
        name
        decision_date
      }
    }
  }
`

export const saveFactsheetApplicationInformation = gql`
  mutation (
    $factsheetId: Int!
    $version: Int!
    $factsheetApplicationDecision: [factsheet_application_decision_insert_input!]!
    $fundingRoundId: Int!
  ) {
    update_factsheet(where: { id: { _eq: $factsheetId } }, _set: { funding_round_id: $fundingRoundId }) {
      affected_rows
    }
    insert_factsheet_application_decision(
      objects: $factsheetApplicationDecision
      on_conflict: {
        constraint: factsheet_application_decision_factsheet_id_uq
        update_columns: [application_decision]
        where: { _and: { factsheet: { id: { _eq: $factsheetId } }, version: { _eq: $version } } }
      }
    ) {
      returning {
        id
        version
      }
      affected_rows
    }
  }
`
