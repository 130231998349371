import { gql } from 'urql'

export const queryPfKapProjectById = gql`
  query ($pf_kap_id: Int!, $locale: String!) {
    pf_kap_project_by_pk(id: $pf_kap_id) {
      id
      modules
      application_type {
        key
      }

      project_base {
        id
        dossier {
          id
          title
          short_title
          status
          description
          start_date
          end_date
        }
        user {
          first_name
          last_name
          email
          status
        }
        project_organizations(order_by: { organization: { name: asc } }) {
          organization_id
          organization {
            name
          }
        }
        organization {
          id
          name
        }
        factsheet {
          id
          title(path: $locale)
          level
          published
          application_type_id
          application_type {
            code
          }
        }
      }
    }
  }
`

export const updateProjectBaseFactsheetId = gql`
  mutation ($projectBaseId: Int!, $factsheetId: Int!) {
    update_project_base(where: { id: { _eq: $projectBaseId } }, _set: { factsheet_id: $factsheetId }) {
      affected_rows
    }
  }
`

export const unlinkFactsheetIdFromProjectBase = gql`
  mutation ($projectBaseId: Int!) {
    update_project_base(where: { id: { _eq: $projectBaseId } }, _set: { factsheet_id: null }) {
      affected_rows
    }
  }
`
