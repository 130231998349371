import { Axios } from 'src/service/axios/axios'
import { FACTSHEET_WORKFLOW_STATUS_TYPE } from 'src/shared/constants/factsheet-constants'
import { NextStateFactsheet } from 'src/shared/constants/workflow-constants'

const BASE_URL = '/api/factsheet/workflow'

const transition = async (
  workflowId: string,
  state: FACTSHEET_WORKFLOW_STATUS_TYPE,
): Promise<Array<NextStateFactsheet>> => {
  return Axios.getAxios()
    .post(`${BASE_URL}/${workflowId}/transition-to/${state}`)
    .then((response) => response.data)
}

const nextStates = async (workflowId: string): Promise<Array<NextStateFactsheet>> => {
  return Axios.getAxios()
    .get(`${BASE_URL}/${workflowId}/next-states`)
    .then((response) => response.data)
}

const deleteFactsheet = async (factsheetId: number): Promise<boolean> => {
  return Axios.getAxios()
    .delete(`${BASE_URL}/${factsheetId}`)
    .then((data) => data.data)
}

export const FactsheetWorkflowService = {
  nextStates,
  transition,
  deleteFactsheet,
}
