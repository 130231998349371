import { ListItemText } from '@mui/material'
import { useRef, useState } from 'react'
import { useParams } from 'react-router'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { EXPORT_FORMAT } from 'src/shared/constants/constants'
import { ExportMenu } from 'src/shared/menu/ExportMenu'
import { S } from 'src/shared/styled/S'
import { useUserLocale } from 'src/user/UserContext'
import { LoadingMenuItem } from 'src/shared/menu/item/LoadingMenuItem'
import { downloadFileByUrl } from 'src/shared/utils/BlobUtils'
import { usePermissionsForFactsheetExportMenu } from 'src/service/security/PermissionHook'
import { FACTSHEET_WORKFLOW_STATUS } from 'src/shared/constants/factsheet-constants'

export const FactsheetExportMenu = () => {
  const { getMessage } = useMessageSource()
  const { factsheetId } = useParams()
  const factsheet_id = parseInt(factsheetId as string)
  const language = useUserLocale()

  const exportMenuRef = useRef<{ closeMenu: () => void }>({ closeMenu: () => {} })
  const {
    metadata: { workflowStatus },
  } = usePermissionsForFactsheetExportMenu(factsheet_id)

  const [loadingExport, setLoadingExport] = useState(false)
  const [loadingExportPdf, setLoadingExportPdf] = useState(false)

  const handleFactsheetExport = () => {
    setLoadingExport(true)
    downloadFileByUrl(`api/factsheet/${factsheetId}/export/word?lang=${language}`)
      .then(() => exportMenuRef.current.closeMenu())
      .finally(() => setLoadingExport(false))
  }

  const handleFactsheetExportPDF = () => {
    setLoadingExportPdf(true)
    downloadFileByUrl(`api/factsheet/${factsheetId}/export/pdf?lang=${language}`)
      .then(() => exportMenuRef.current.closeMenu())
      .finally(() => setLoadingExportPdf(false))
  }

  return (
    <ExportMenu ref={exportMenuRef}>
      <LoadingMenuItem loading={loadingExport} disabled={loadingExport} onClick={handleFactsheetExport}>
        <ListItemText>{getMessage('label.export.factsheet')}</ListItemText>
        <S.Typography.ExportFormat>({EXPORT_FORMAT.WORD})</S.Typography.ExportFormat>
      </LoadingMenuItem>

      <LoadingMenuItem
        loading={loadingExportPdf}
        disabled={loadingExportPdf || workflowStatus === FACTSHEET_WORKFLOW_STATUS.CONCEPT}
        onClick={handleFactsheetExportPDF}
      >
        <ListItemText>{getMessage('button.download.application')}</ListItemText>
        <S.Typography.ExportFormat>({EXPORT_FORMAT.PDF})</S.Typography.ExportFormat>
      </LoadingMenuItem>
    </ExportMenu>
  )
}
