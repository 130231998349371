import { useContext, useMemo, useState } from 'react'
import { valueof } from 'src/@types/global'
import {
  Factsheet_Application_Decision,
  Funding_Round,
  Maybe,
  Project_Base_Bool_Exp,
  Query_Root,
} from 'src/@types/graphql'
import { PermissionService } from 'src/service/security/PermissionService'
import {
  fetchAssessorId,
  fetchDossierMetadataAndProjectUserRoles,
  fetchFactsheetAssessorId,
  fetchFactsheetMetadata,
  fetchProgramCantonAndStatus,
} from 'src/service/security/PermissionServiceQueries'
import {
  CANTON_TYPE,
  DOSSIER_STATUS_TYPE,
  FACTSHEET_USER_ROLE_TYPE,
  LEVEL_TYPE,
  PROJECT_TYPE,
  PROJECT_USER_ROLE_TYPE,
  USER_ROLES_TYPE,
} from 'src/shared/constants/constants'
import { FACTSHEET_WORKFLOW_STATUS_TYPE } from 'src/shared/constants/factsheet-constants'
import { MILESTONE_RESPONSIBLE_TYPE_TYPE, MILESTONE_STATUS_TYPE } from 'src/shared/constants/milestone-constants'
import { UserContext, useUser } from 'src/user/UserContext'
import { useClient, useQuery } from 'urql'

const resolveProjectBaseQuery = (projectType: PROJECT_TYPE, entityId: number): Project_Base_Bool_Exp => {
  const projectPfKapBaseWhereClause: Project_Base_Bool_Exp = {
    pf_kap_projects: {
      id: {
        _eq: entityId,
      },
    },
  }

  const projectPfPgvBaseWhereClause: Project_Base_Bool_Exp = {
    pf_pgv_projects: {
      id: {
        _eq: entityId,
      },
    },
  }

  switch (projectType) {
    case 'PF_KAP':
      return projectPfKapBaseWhereClause
    case 'PF_PGV':
      return projectPfPgvBaseWhereClause
    default:
      throw Error(`Unknown project type ${projectType}`)
  }
}

interface PermissionResponse {
  loading: boolean
  canEdit: boolean
  canView: boolean
  refetch: () => unknown
}

interface FactsheetDetailsPermissionResponse extends PermissionResponse {
  canDelete: boolean
  metadata: FactsheetBaseMetadata
}

interface FactsheetApplicationInformationPermissionResponse extends PermissionResponse {
  metadata: FactsheetBaseMetadata
}

interface FactsheetCommitteePermissionResponse extends PermissionResponse {
  metadata: FactsheetBaseMetadata
}

interface FactsheetBaseMetadata {
  factsheetId: Maybe<number>
  factsheetLevel: Maybe<LEVEL_TYPE>
  title: Maybe<string>
  workflowStatus: Maybe<FACTSHEET_WORKFLOW_STATUS_TYPE>
  factsheet_workflow_id: Maybe<string>
  userGlobalRoles: Array<USER_ROLES_TYPE>
  userFactsheetRoles: Array<valueof<FACTSHEET_USER_ROLE_TYPE>>
  feature_update_version: Maybe<number>
  factsheetApplicationDecision?: Maybe<Factsheet_Application_Decision> | undefined
  fundingRound?: Maybe<Funding_Round> | undefined
}

interface ProjectBaseMetadata {
  projectBaseId: Maybe<number>
  dossierId?: Maybe<number>
  userProjectRoles: Array<valueof<PROJECT_USER_ROLE_TYPE>>
  userGlobalRoles: Array<USER_ROLES_TYPE>
}

interface ProjectDetailsPermissionResponse extends PermissionResponse {
  canEditProjectBudgetApproved: boolean
  canEditCommentInSummary: boolean
  canModifyProjectUsers: boolean
  canTransitToApplication: boolean
  canDeleteProject: boolean
  metadata: ProjectBaseMetadata
}

interface AdministrationPermissionResponse {
  canEdit: boolean
  canView: boolean
}

interface MilestonePermissionResponse {
  loading: boolean
  canView: boolean
  canEdit: boolean
  metadata: ProjectBaseMetadata
}

interface ApplicationDetailsPermissionResponse extends PermissionResponse {
  canViewSummaryTab: boolean
  metadata: ProjectBaseMetadata
}

interface AssessmentPermissionResponse extends PermissionResponse {
  canAdd: boolean
  canDelete: boolean
  metadata: ProjectBaseMetadata
}

interface FactsheetAssessmentPermissionResponse extends PermissionResponse {
  canAdd: boolean
  canDelete: boolean
}

interface CommitteePermissionResponse extends PermissionResponse {
  metadata: ProjectBaseMetadata
}

interface ProjectImplementationResponse extends PermissionResponse {
  metadata: ProjectBaseMetadata
}

interface ProgramDetailsPermissionResponse {
  loading: boolean
  canEdit: boolean
  canEditMeasures: boolean
  canEditApplication: boolean
  canCopyMeasures: boolean
  canViewCantonRestricted: boolean
  canView: boolean
  canManageMilestones: boolean
  canDeleteProgram: boolean
  isOwnCanton: boolean
  userGlobalRoles: Array<USER_ROLES_TYPE>
  refetch: () => unknown
}

interface ProgramMilestonesPermissionResponse {
  loading: boolean
  canEdit: boolean
  canView: boolean
}

interface FinalMilestonePermissionResponse extends MilestonePermissionResponse {
  canEditInternalAssessment: boolean
}

interface MilestoneConditionsPermissionResponse extends MilestonePermissionResponse {
  canUploadFiles: boolean
}

/**
 * Data fetch for permissions hooks.
 */

const useResolveProcessData = (projectType: PROJECT_TYPE, entityId: number) => {
  const { user } = useContext(UserContext)
  const email = user.email
  const projectBaseWhere = resolveProjectBaseQuery(projectType, entityId)
  const [{ data, fetching }, refetch] = useQuery<
    { project_base: Query_Root['project_base'] },
    { email: string; projectBaseWhere: Project_Base_Bool_Exp }
  >({
    query: fetchDossierMetadataAndProjectUserRoles,
    variables: {
      email,
      projectBaseWhere,
    },
  })

  const userGlobalRoles = user.roles

  const userId = user.id

  const userProjectRoles = useMemo(
    () => (data?.project_base[0]?.project_users?.map((u) => u.type) ?? []) as Array<valueof<PROJECT_USER_ROLE_TYPE>>,
    [data?.project_base],
  )

  return {
    data,
    userGlobalRoles,
    userProjectRoles,
    userId,
    fetching,
    refetch,
  }
}

const useResolveFactsheetAssessorId = (assessmentId: number) => {
  const [{ data, fetching }, refetch] = useQuery<{
    factsheet_assessment: Query_Root['factsheet_assessment']
  }>({
    query: fetchFactsheetAssessorId,
    variables: {
      assessmentId: assessmentId,
    },
  })

  return {
    data,
    fetching,
    refetch,
  }
}

const useFactsheetData = (factsheetId: number) => {
  const { user } = useContext(UserContext)
  const email = user.email

  const [{ data, fetching }, refetch] = useQuery<{
    factsheet: Query_Root['factsheet']
  }>({
    query: fetchFactsheetMetadata,
    variables: {
      factsheetId: factsheetId,
      email: email,
    },
  })

  const userGlobalRoles = user.roles
  const userId = user.id
  const factsheetWorkflowStatus = data?.factsheet[0]?.workflow_status as FACTSHEET_WORKFLOW_STATUS_TYPE

  const userFactsheetRoles = useMemo(
    () => (data?.factsheet[0]?.factsheet_users?.map((u) => u.type) ?? []) as Array<valueof<FACTSHEET_USER_ROLE_TYPE>>,
    [data?.factsheet],
  )

  return {
    data,
    userGlobalRoles,
    userFactsheetRoles,
    userId,
    fetching,
    factsheetWorkflowStatus,
    refetch,
  }
}

const useProgramData = (programId: number) => {
  const { user } = useContext(UserContext)

  const [{ data, fetching }, refetch] = useQuery<{
    kap_program: Query_Root['kap_program']
  }>({
    query: fetchProgramCantonAndStatus,
    variables: {
      programId,
    },
  })

  const userGlobalRoles = user.roles
  const userCanton = user.cantonalUserCanton as CANTON_TYPE | null

  return {
    data,
    userGlobalRoles,
    userCanton,
    fetching,
    refetch,
  }
}

const useResolveAssessorId = async (assessmentId?: number) => {
  const urqlClient = useClient()

  const { data } = await urqlClient
    .query<
      {
        project_assessment: Query_Root['project_assessment']
      },
      { assessmentId?: number }
    >(fetchAssessorId, { assessmentId: assessmentId })
    .toPromise()

  return data?.project_assessment[0]?.assessor_id
}

/**
 * Project & Program permissions.
 */

export const usePermissionsForProjectCommittees = (
  projectType: PROJECT_TYPE,
  entityId: number,
): CommitteePermissionResponse => {
  const { data, userGlobalRoles, userProjectRoles, fetching, refetch } = useResolveProcessData(projectType, entityId)

  if (fetching) {
    return {
      loading: true,
      canView: false,
      canEdit: false,
      metadata: { projectBaseId: null, userProjectRoles: [], userGlobalRoles: [] },
      refetch: () => {},
    }
  }

  const dossierStatus = data?.project_base[0]?.dossier.status as DOSSIER_STATUS_TYPE

  const projectBaseId = data?.project_base[0]?.id as number

  const { canEdit, canView } = PermissionService.permissionsForProjectCommittees(
    userGlobalRoles,
    dossierStatus,
    projectType,
  )

  return {
    loading: false,
    canView,
    canEdit,
    metadata: {
      projectBaseId,
      userProjectRoles,
      userGlobalRoles,
    },
    refetch,
  }
}

export const usePermissionsForProjectAssessment = (
  projectType: PROJECT_TYPE,
  entityId: number,
  assessmentId?: number,
): AssessmentPermissionResponse => {
  const [assessorId, setAssessorId] = useState<number | undefined>()
  const [loading, setLoading] = useState<boolean>(true)

  const { data, userGlobalRoles, userProjectRoles, userId, fetching, refetch } = useResolveProcessData(
    projectType,
    entityId,
  )

  useResolveAssessorId(assessmentId)
    .then((value) => {
      setAssessorId(value)
    })
    .finally(() => {
      setLoading(false)
    })

  if (fetching || loading) {
    return {
      loading: true,
      canView: false,
      canEdit: false,
      canAdd: false,
      canDelete: false,
      metadata: { projectBaseId: null, userProjectRoles: [], userGlobalRoles: [] },
      refetch: () => {},
    }
  }

  const dossierStatus = data?.project_base[0]?.dossier.status as DOSSIER_STATUS_TYPE

  const projectBaseId = data?.project_base[0]?.id as number

  const userIsAssessor = assessorId === userId

  const { canEdit, canView, canAdd, canDelete } = PermissionService.permissionsForProjectAssessment(
    userGlobalRoles,
    userProjectRoles,
    dossierStatus,
    projectType,
    userIsAssessor,
  )

  return {
    loading: false,
    canView,
    canEdit,
    canAdd,
    canDelete,
    metadata: {
      projectBaseId,
      userProjectRoles,
      userGlobalRoles,
    },
    refetch,
  }
}

export const usePermissionsForProjectApplication = (
  projectType: PROJECT_TYPE,
  entityId: number,
): ApplicationDetailsPermissionResponse => {
  const { data, userGlobalRoles, userProjectRoles, fetching, refetch } = useResolveProcessData(projectType, entityId)

  if (fetching) {
    return {
      loading: true,
      canEdit: false,
      canView: false,
      canViewSummaryTab: false,
      metadata: { projectBaseId: null, userProjectRoles: [], userGlobalRoles: [] },
      refetch: () => {},
    }
  }

  const dossierStatus = data?.project_base[0]?.dossier.status as DOSSIER_STATUS_TYPE

  const projectBaseId = data?.project_base[0]?.id as number

  const { canEdit, canView, canViewSummaryTab } = PermissionService.permissionsForProjectApplication(
    userGlobalRoles,
    userProjectRoles,
    dossierStatus,
    projectType,
  )

  return {
    loading: false,
    canView,
    canEdit,
    canViewSummaryTab,
    metadata: {
      projectBaseId,
      userProjectRoles,
      userGlobalRoles,
    },
    refetch,
  }
}

export const usePermissionsForProject = (
  projectType: PROJECT_TYPE,
  entityId: number,
  existsActiveFundingRound = false,
): ProjectDetailsPermissionResponse => {
  const { data, userGlobalRoles, userProjectRoles, fetching, refetch } = useResolveProcessData(projectType, entityId)

  if (fetching) {
    return {
      loading: true,
      canEdit: false,
      canView: false,
      canEditProjectBudgetApproved: false,
      canEditCommentInSummary: false,
      canModifyProjectUsers: false,
      canTransitToApplication: false,
      canDeleteProject: false,
      metadata: { projectBaseId: null, userProjectRoles: [], userGlobalRoles: [] },
      refetch: () => {},
    }
  }

  const dossierStatus = data?.project_base[0]?.dossier?.status as DOSSIER_STATUS_TYPE

  const projectBaseId = data?.project_base[0]?.id as number
  const dossierId = data?.project_base[0]?.dossier.id as number

  const {
    canEdit,
    canView,
    canEditProjectBudgetApproved,
    canEditCommentInSummary,
    canModifyProjectUsers,
    canTransitToApplication,
    canDeleteProject,
  } = PermissionService.permissionsForProjectDescription(
    userGlobalRoles,
    userProjectRoles,
    dossierStatus,
    projectType,
    existsActiveFundingRound,
  )

  return {
    loading: false,
    canView,
    canEdit,
    canEditProjectBudgetApproved,
    canEditCommentInSummary,
    canModifyProjectUsers,
    canTransitToApplication,
    canDeleteProject,
    metadata: {
      projectBaseId,
      dossierId,
      userProjectRoles,
      userGlobalRoles,
    },
    refetch,
  }
}

export const usePermissionsForProjectImplementation = (
  projectType: PROJECT_TYPE,
  entityId: number,
): ProjectImplementationResponse & { canManageMilestones: boolean } => {
  const { data, userGlobalRoles, userProjectRoles, fetching, refetch } = useResolveProcessData(projectType, entityId)

  if (fetching) {
    return {
      loading: true,
      canEdit: false,
      canView: false,
      canManageMilestones: false,
      metadata: { projectBaseId: null, userProjectRoles: [], userGlobalRoles: [] },
      refetch: () => {},
    }
  }

  const dossierStatus = data?.project_base[0]?.dossier.status as DOSSIER_STATUS_TYPE

  const projectBaseId = data?.project_base[0]?.id as number

  const { canEdit, canView, canManageMilestones } = PermissionService.permissionsForProjectImplementation(
    userGlobalRoles,
    userProjectRoles,
    dossierStatus,
    projectType,
  )

  return {
    loading: false,
    canView,
    canEdit,
    canManageMilestones,
    metadata: { projectBaseId, userProjectRoles, userGlobalRoles },
    refetch,
  }
}

export const usePermissionsForKapProgram = (programId: number): ProgramDetailsPermissionResponse => {
  const { userGlobalRoles, userCanton, data, fetching, refetch } = useProgramData(programId)

  if (fetching) {
    return {
      loading: true,
      canEdit: false,
      canEditMeasures: false,
      canEditApplication: false,
      canCopyMeasures: false,
      canViewCantonRestricted: false,
      canView: false,
      canManageMilestones: false,
      canDeleteProgram: false,
      isOwnCanton: false,
      userGlobalRoles: [],
      refetch: () => {},
    }
  }

  const dossierStatus = data?.kap_program?.[0]?.dossier?.status as DOSSIER_STATUS_TYPE
  const canton = data?.kap_program?.[0]?.canton_code as CANTON_TYPE
  const isOwnCanton = userCanton === canton

  const {
    canEdit,
    canEditMeasures,
    canEditApplication,
    canCopyMeasures,
    canViewCantonRestricted,
    canView,
    canManageMilestones,
    canDeleteProgram,
  } = PermissionService.permissionsForKapProgramDescription(userGlobalRoles, dossierStatus, isOwnCanton)

  return {
    loading: false,
    canView,
    canEdit,
    canEditMeasures,
    canEditApplication,
    canCopyMeasures,
    canViewCantonRestricted,
    canManageMilestones,
    canDeleteProgram,
    userGlobalRoles,
    isOwnCanton,
    refetch,
  }
}

/**
 * Factsheet permissions.
 */
export const usePermissionsForFactsheetsIndexPage = (): {
  canCreate: boolean
  canViewAllFactsheets: boolean
  canDownloadReport: boolean
  canCopy: boolean
} => {
  const { user } = useContext(UserContext)
  const userGlobalRoles = user.roles

  const { canCreate, canViewAllFactsheets, canDownloadReport, canCopy } =
    PermissionService.permissionToFactsheets(userGlobalRoles)

  return {
    canCreate,
    canViewAllFactsheets,
    canDownloadReport,
    canCopy,
  }
}

export const usePermissionsForFactsheetDetailsSidebar = (factsheetId: number): FactsheetDetailsPermissionResponse => {
  return usePermissionsForFactsheetDetailsInternal(factsheetId)
}

export const usePermissionsForFactsheetExportMenu = (
  factsheetId: number,
): Pick<FactsheetDetailsPermissionResponse, 'metadata'> => {
  return usePermissionsForFactsheetDetailsInternal(factsheetId)
}

export const usePermissionsForFactsheetDetails = (factsheetId: number): FactsheetDetailsPermissionResponse => {
  return usePermissionsForFactsheetDetailsInternal(factsheetId)
}

export const usePermissionsForFactsheetDetailsDescriptionPage = (
  factsheetId: number,
): Pick<FactsheetDetailsPermissionResponse, 'canEdit' | 'loading' | 'metadata'> => {
  const { canEdit, loading, metadata } = usePermissionsForFactsheetDetailsInternal(factsheetId)

  return {
    canEdit,
    loading,
    metadata,
  }
}

export const usePermissionsForFactsheetDetailsFeaturesPage = (
  factsheetId: number,
): Pick<FactsheetDetailsPermissionResponse, 'canEdit' | 'loading' | 'metadata'> => {
  const { canEdit, loading, metadata } = usePermissionsForFactsheetDetailsInternal(factsheetId)

  return {
    canEdit,
    loading,
    metadata,
  }
}

export const usePermissionsForFactsheetDetailsSummaryPage = (
  factsheetId: number,
): Pick<
  FactsheetDetailsPermissionResponse,
  'canEdit' | 'canDelete' | 'canView' | 'loading' | 'metadata' | 'refetch'
> => {
  const { user } = useUser()
  const { data, userGlobalRoles, userFactsheetRoles, fetching, refetch } = useFactsheetData(factsheetId)

  if (fetching || data === undefined || data.factsheet[0] === undefined) {
    return {
      loading: fetching,
      metadata: {
        factsheetLevel: null,
        factsheetId: null,
        factsheet_workflow_id: null,
        title: null,
        workflowStatus: null,
        userGlobalRoles: [],
        userFactsheetRoles: [],
        feature_update_version: null,
      },
      canEdit: false,
      canView: false,
      canDelete: false,
      refetch: () => {},
    }
  }

  const workflowStatus = data.factsheet[0].workflow_status as FACTSHEET_WORKFLOW_STATUS_TYPE
  const published = data.factsheet[0].published
  const level = data.factsheet[0].level as LEVEL_TYPE
  const workflowId = data.factsheet[0].factsheet_workflow_id as string
  const title = data.factsheet[0].title as string
  const feature_update_version = data.factsheet[0].feature_update_version as number
  const isProjectLead = data.factsheet[0].project_leader_id === user.id

  const { canView, canDelete, canEdit } = PermissionService.permissionToFactsheetDetailsSummary(
    userGlobalRoles,
    userFactsheetRoles,
    workflowStatus,
    published,
    isProjectLead,
  )

  return {
    loading: false,
    metadata: {
      factsheetId: factsheetId,
      factsheetLevel: level,
      factsheet_workflow_id: workflowId,
      title: title,
      workflowStatus: workflowStatus,
      userFactsheetRoles: userFactsheetRoles,
      userGlobalRoles: userGlobalRoles,
      feature_update_version,
    },
    canEdit: canEdit,
    canView: canView,
    canDelete: canDelete,
    refetch,
  }
}

export const usePermissionsForFactsheetUsers = (
  factsheetId: number,
): { loading: boolean; canModifyFactsheetUsers: boolean; canView: boolean } => {
  const { data, userGlobalRoles, userFactsheetRoles, factsheetWorkflowStatus, fetching } = useFactsheetData(factsheetId)

  if (fetching || data === undefined || data.factsheet[0] === undefined) {
    return {
      loading: fetching,
      canView: false,
      canModifyFactsheetUsers: false,
    }
  }

  const published = data.factsheet[0].published

  const permissions = PermissionService.permissionsForFactsheetUsers(
    userGlobalRoles,
    published,
    userFactsheetRoles,
    factsheetWorkflowStatus,
  )

  return {
    loading: false,
    canModifyFactsheetUsers: permissions.canModifyFactsheetUsers,
    canView: permissions.canView,
  }
}

export const usePermissionsForFactsheetDetailsRelatedProjects = (
  factsheetId: number,
): Pick<FactsheetDetailsPermissionResponse, 'loading' | 'metadata'> & {
  canViewLinkedProjects: boolean
  canClickOnLinkedProjects: boolean
  canViewLinkedMeasures: boolean
  canClickOnLinkedMeasures: boolean
} => {
  const { data, userGlobalRoles, userFactsheetRoles, fetching } = useFactsheetData(factsheetId)

  if (fetching || data === undefined || data.factsheet[0] === undefined) {
    return {
      loading: fetching,
      metadata: {
        factsheetLevel: null,
        factsheetId: null,
        factsheet_workflow_id: null,
        title: null,
        workflowStatus: null,
        userGlobalRoles: [],
        userFactsheetRoles: [],
        feature_update_version: null,
      },
      canViewLinkedProjects: false,
      canViewLinkedMeasures: false,
      canClickOnLinkedProjects: false,
      canClickOnLinkedMeasures: false,
    }
  }

  const workflowStatus = data.factsheet[0].workflow_status as FACTSHEET_WORKFLOW_STATUS_TYPE
  const published = data.factsheet[0].published
  const level = data.factsheet[0].level as LEVEL_TYPE
  const workflowId = data.factsheet[0].factsheet_workflow_id as string
  const title = data.factsheet[0].title as string
  const feature_update_version = data.factsheet[0].feature_update_version as number

  const { canViewLinkedProjects, canViewLinkedMeasures, canClickOnLinkedMeasures, canClickOnLinkedProjects } =
    PermissionService.permissionToFactsheetRelatedProjects(
      userGlobalRoles,
      userFactsheetRoles,
      workflowStatus,
      published,
    )

  return {
    metadata: {
      factsheetId: factsheetId,
      factsheetLevel: level,
      factsheet_workflow_id: workflowId,
      title: title,
      workflowStatus: workflowStatus,
      userFactsheetRoles: userFactsheetRoles,
      userGlobalRoles: userGlobalRoles,
      feature_update_version,
    },
    loading: false,
    canViewLinkedMeasures,
    canViewLinkedProjects,
    canClickOnLinkedMeasures,
    canClickOnLinkedProjects,
  }
}

export const usePermissionsForFactsheetApplication = (
  factsheetId: number,
): FactsheetApplicationInformationPermissionResponse => {
  const { data, userGlobalRoles, userFactsheetRoles, fetching, refetch } = useFactsheetData(factsheetId)

  if (fetching || data === undefined || data.factsheet[0] === undefined) {
    return {
      loading: fetching,
      canView: false,
      canEdit: false,
      metadata: {
        factsheetId: null,
        factsheetLevel: null,
        title: null,
        workflowStatus: null,
        factsheet_workflow_id: null,
        userGlobalRoles: [],
        userFactsheetRoles: [],
        feature_update_version: null,
      },
      refetch: () => {},
    }
  }

  const workflowStatus = data.factsheet[0].workflow_status as FACTSHEET_WORKFLOW_STATUS_TYPE
  const published = data.factsheet[0].published
  const level = data.factsheet[0].level as LEVEL_TYPE
  const workflowId = data.factsheet[0].factsheet_workflow_id as string
  const title = data.factsheet[0].title as string
  const factsheetApplicationDecision = data.factsheet[0].factsheet_application_decision
  const feature_update_version = data.factsheet[0].feature_update_version as number

  const permissions = PermissionService.permissionsForFactsheetApplicationDetails(
    userGlobalRoles,
    workflowStatus,
    published,
  )

  return {
    loading: false,
    canEdit: permissions.canEdit,
    canView: permissions.canView,
    metadata: {
      factsheetId: factsheetId,
      factsheetLevel: level,
      factsheet_workflow_id: workflowId,
      title: title,
      workflowStatus: workflowStatus,
      userFactsheetRoles: userFactsheetRoles,
      userGlobalRoles: userGlobalRoles,
      factsheetApplicationDecision: factsheetApplicationDecision,
      feature_update_version,
    },
    refetch,
  }
}

const usePermissionsForFactsheetDetailsInternal = (factsheetId: number): FactsheetDetailsPermissionResponse => {
  const { data, userGlobalRoles, userFactsheetRoles, fetching, refetch } = useFactsheetData(factsheetId)

  if (fetching || data === undefined || data.factsheet[0] === undefined) {
    return {
      loading: fetching,
      metadata: {
        factsheetLevel: null,
        factsheetId: null,
        factsheet_workflow_id: null,
        title: null,
        workflowStatus: null,
        userGlobalRoles: [],
        userFactsheetRoles: [],
        feature_update_version: null,
      },
      canEdit: false,
      canView: false,
      canDelete: false,
      refetch: () => {},
    }
  }

  const workflowStatus = data.factsheet[0].workflow_status as FACTSHEET_WORKFLOW_STATUS_TYPE
  const published = data.factsheet[0].published
  const level = data.factsheet[0].level as LEVEL_TYPE
  const workflowId = data.factsheet[0].factsheet_workflow_id as string
  const title = data.factsheet[0].title as string
  const feature_update_version = data.factsheet[0].feature_update_version as number
  const fundingRound = data.factsheet[0].funding_round

  const { canEdit, canView, canDelete } = PermissionService.permissionToFactsheetDetails(
    userGlobalRoles,
    userFactsheetRoles,
    workflowStatus,
    published,
  )

  return {
    metadata: {
      factsheetId: factsheetId,
      factsheetLevel: level,
      factsheet_workflow_id: workflowId,
      title: title,
      workflowStatus: workflowStatus,
      userFactsheetRoles: userFactsheetRoles,
      userGlobalRoles: userGlobalRoles,
      feature_update_version,
      fundingRound,
    },
    loading: false,
    canView: canView,
    canEdit: canEdit,
    canDelete: canDelete,
    refetch,
  }
}

export const usePermissionsForFactsheetCommittees = (factsheetId: number): FactsheetCommitteePermissionResponse => {
  const { data, userGlobalRoles, userFactsheetRoles, fetching, refetch } = useFactsheetData(factsheetId)

  if (fetching || data === undefined || data.factsheet[0] === undefined) {
    return {
      loading: fetching,
      canView: false,
      canEdit: false,
      metadata: {
        factsheetLevel: null,
        factsheetId: null,
        factsheet_workflow_id: null,
        title: null,
        workflowStatus: null,
        userGlobalRoles: [],
        userFactsheetRoles: [],
        feature_update_version: null,
      },
      refetch: () => {},
    }
  }

  const workflowStatus = data.factsheet[0].workflow_status as FACTSHEET_WORKFLOW_STATUS_TYPE
  const workflowId = data.factsheet[0].factsheet_workflow_id as string
  const published = data.factsheet[0].published as boolean
  const level = data.factsheet[0].level as LEVEL_TYPE
  const title = data.factsheet[0].title as string
  const feature_update_version = data.factsheet[0].feature_update_version as number

  const { canEdit, canView } = PermissionService.permissionsForFactsheetCommittees(
    userGlobalRoles,
    workflowStatus,
    published,
  )

  return {
    loading: false,
    canView,
    canEdit,
    metadata: {
      factsheetId: factsheetId,
      factsheetLevel: level,
      factsheet_workflow_id: workflowId,
      title: title,
      workflowStatus: workflowStatus,
      userFactsheetRoles: userFactsheetRoles,
      userGlobalRoles: userGlobalRoles,
      feature_update_version,
    },
    refetch,
  }
}

export const usePermissionsForFactsheetAssessmentIndexPage = (
  factsheetId: number,
): {
  canAdd: boolean
  canView: boolean
  canDelete: boolean
  loading: boolean
} => {
  const { data, userGlobalRoles, fetching } = useFactsheetData(factsheetId)

  if (fetching || data === undefined || data.factsheet[0] === undefined) {
    return {
      loading: fetching,
      canView: false,
      canAdd: false,
      canDelete: false,
    }
  }

  const workflowStatus = data.factsheet[0].workflow_status as FACTSHEET_WORKFLOW_STATUS_TYPE
  const published = data.factsheet[0].published as boolean

  const { canAdd, canDelete, canView } = PermissionService.permissionsForFactsheetAssessment(
    workflowStatus,
    false,
    published,
    userGlobalRoles,
  )

  return {
    loading: false,
    canView,
    canAdd,
    canDelete,
  }
}

export const usePermissionsForFactsheetAssessmentDetails = (
  assessmentId: number,
): Pick<FactsheetDetailsPermissionResponse, 'canEdit' | 'loading'> => {
  const { user } = useUser()

  const { data, fetching } = useResolveFactsheetAssessorId(assessmentId)

  if (fetching || data === undefined || data.factsheet_assessment[0] === undefined) {
    return {
      loading: fetching,
      canEdit: false,
    }
  }

  const workflowStatus = data.factsheet_assessment[0].factsheet.workflow_status as FACTSHEET_WORKFLOW_STATUS_TYPE
  const published = data.factsheet_assessment[0].factsheet.published
  const userIsAssessor = data.factsheet_assessment[0].assessor_id === user.id

  const { canEdit } = PermissionService.permissionsForFactsheetAssessment(
    workflowStatus,
    userIsAssessor,
    published,
    user.roles,
  )

  return {
    loading: false,
    canEdit,
  }
}

/**
 * Administrative permissions.
 */
export const usePermissionsForUserAdministration = (): AdministrationPermissionResponse => {
  const { user } = useContext(UserContext)
  const userGlobalRoles = user.roles

  const { canView, canEdit } = PermissionService.permissionToAdministrateUsers(userGlobalRoles)

  return {
    canView: canView,
    canEdit: canEdit,
  }
}

/**
 * Route management permissions.
 */

export const usePermissionsForRoundManagementAdministration = (): AdministrationPermissionResponse => {
  const { user } = useContext(UserContext)
  const userGlobalRoles = user.roles

  const { canView, canEdit } = PermissionService.permissionToAdministrateFundingRounds(userGlobalRoles)

  return {
    canView: canView,
    canEdit: canEdit,
  }
}

/**
 * Milestone permissions.
 */

export const usePermissionsForMilestones = (
  projectType: PROJECT_TYPE,
  entityId: number,
  milestoneStatus: MILESTONE_STATUS_TYPE,
  milestoneType: MILESTONE_RESPONSIBLE_TYPE_TYPE,
): MilestonePermissionResponse => {
  const { data, userGlobalRoles, userProjectRoles, fetching } = useResolveProcessData(projectType, entityId)

  if (fetching) {
    return {
      loading: true,
      canView: false,
      canEdit: false,
      metadata: { projectBaseId: null, userProjectRoles: [], userGlobalRoles: [] },
    }
  }

  const dossierStatus = data?.project_base[0]?.dossier.status as DOSSIER_STATUS_TYPE

  const projectBaseId = data?.project_base[0]?.id as number

  const { canView, canEdit } = PermissionService.permissionsForMilestones(
    userGlobalRoles,
    userProjectRoles,
    dossierStatus,
    projectType,
    milestoneStatus,
    milestoneType,
  )

  return {
    loading: false,
    canView: canView,
    canEdit: canEdit,
    metadata: { projectBaseId, userProjectRoles, userGlobalRoles },
  }
}

export const usePermissionsForKapMilestones = (
  programId: number,
  milestoneStatus: MILESTONE_STATUS_TYPE,
  milestoneType: MILESTONE_RESPONSIBLE_TYPE_TYPE,
): ProgramMilestonesPermissionResponse => {
  const { data, userGlobalRoles, userCanton, fetching } = useProgramData(programId)

  if (fetching) {
    return {
      loading: true,
      canView: false,
      canEdit: false,
    }
  }

  const dossierStatus = data?.kap_program?.[0]?.dossier?.status as DOSSIER_STATUS_TYPE
  const canton = data?.kap_program?.[0]?.canton_code as CANTON_TYPE
  const isOwnCanton = userCanton === canton

  const { canView, canEdit } = PermissionService.permissionsForKapMilestones(
    userGlobalRoles,
    dossierStatus,
    milestoneStatus,
    milestoneType,
    isOwnCanton,
  )

  return {
    loading: false,
    canView: canView,
    canEdit: canEdit,
  }
}

export const usePermissionsForFinalMilestone = (
  projectType: PROJECT_TYPE,
  entityId: number,
  milestoneStatus: MILESTONE_STATUS_TYPE,
  milestoneType: MILESTONE_RESPONSIBLE_TYPE_TYPE,
): FinalMilestonePermissionResponse => {
  const { userGlobalRoles, fetching } = useResolveProcessData(projectType, entityId)

  const { canView, canEdit, metadata } = usePermissionsForMilestones(
    projectType,
    entityId,
    milestoneStatus,
    milestoneType,
  )

  if (fetching) {
    return {
      loading: true,
      canView: false,
      canEdit: false,
      canEditInternalAssessment: false,
      metadata: { projectBaseId: null, userProjectRoles: [], userGlobalRoles: [] },
    }
  }

  const { canEditInternalAssessment } = PermissionService.permissionsForFinalMilestoneInternalAssessment(
    userGlobalRoles,
    projectType,
  )

  return {
    loading: false,
    canView,
    canEdit,
    canEditInternalAssessment: canEdit && canEditInternalAssessment,
    metadata,
  }
}

export const usePermissionsForMilestoneConditions = (
  projectType: PROJECT_TYPE,
  entityId: number,
  milestoneStatus: MILESTONE_STATUS_TYPE,
  milestoneType: MILESTONE_RESPONSIBLE_TYPE_TYPE,
): MilestoneConditionsPermissionResponse => {
  const { data, userGlobalRoles, fetching } = useResolveProcessData(projectType, entityId)

  const dossierStatus = data?.project_base[0]?.dossier.status as DOSSIER_STATUS_TYPE

  const {
    canView,
    metadata,
    canEdit: canEditMilestones,
  } = usePermissionsForMilestones(projectType, entityId, milestoneStatus, milestoneType)

  if (fetching) {
    return {
      loading: true,
      canView: false,
      canEdit: false,
      canUploadFiles: false,
      metadata: { projectBaseId: null, userProjectRoles: [], userGlobalRoles: [] },
    }
  }

  const canEdit = PermissionService.permissionToEditProjectMilestoneConditions(
    projectType,
    userGlobalRoles,
    dossierStatus,
    milestoneStatus,
  )

  return {
    loading: false,
    canView: canView,
    canEdit: canEdit,
    canUploadFiles: canEdit || canEditMilestones,
    metadata,
  }
}

/**
 * Success factors permissions.
 */

export const usePermissionsForSuccessFactors = (): {
  canView: boolean
  canEdit: boolean
} => {
  const { user } = useContext(UserContext)
  const userGlobalRoles = user.roles
  const { canView, canEdit } = PermissionService.permissionsForSuccessFactors(userGlobalRoles)

  return {
    canView,
    canEdit,
  }
}
