import { Box, IconButton } from '@mui/material'
import { DataGridPro, GridColDef, GridSortModel } from '@mui/x-data-grid-pro'
import { ReactElement, useCallback, useMemo, useState } from 'react'
import { Application_Type } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { EditApplicationTypeModal } from 'src/screens/administration/application-type-management/EditApplicationTypeModal'
import { EditIcon } from 'src/shared/icons/Icons'
import { ModalDialog } from 'src/shared/modal-dialog/ModalDialog'
import { useGridTranslateHook } from 'src/shared/utils/hooks/grid-translate-hook'
import styled from 'styled-components/macro'

interface Props {
  applicationTypes: Application_Type[]
  onSuccess: () => void
}

const DataGridProStyled = styled(DataGridPro)`
  & .MuiDataGrid-row {
    &:hover {
      cursor: pointer;
    }
  }
`

export const ApplicationTypeManagementGrid = ({ applicationTypes, onSuccess }: Props): ReactElement => {
  const { getMessage } = useMessageSource()
  const gridTranslations = useGridTranslateHook()
  const [openEditModal, setOpenEditModal] = useState(false)
  const [selectedApplicationType, setSelectedApplicationType] = useState<Application_Type | null>(null)
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'sort_number',
      sort: 'asc',
    },
  ])

  const handleSelectApplicationTypeForEdit = useCallback((event: any, applicationType: Application_Type) => {
    event.stopPropagation()

    setOpenEditModal(true)
    setSelectedApplicationType(applicationType)
  }, [])

  const columns = useMemo(() => {
    return [
      {
        field: 'key',
        headerName: getMessage('label.name'),
        sortable: true,
        flex: 1.5,
        renderCell: ({ value }) => getMessage(value),
      },
      {
        field: 'process',
        headerName: getMessage('label.process'),
        sortable: true,
        flex: 1.5,
        renderCell: ({ value }) => getMessage(`label.process.${value}`),
      },
      {
        field: 'public',
        headerName: getMessage('label.application-type.public'),
        sortable: true,
        flex: 1.5,
        renderCell: ({ value }) => getMessage(`label.application-type.public.${value}`),
      },
      {
        field: 'action',
        headerName: getMessage('label.actions'),
        sortable: false,
        flex: 0.5,
        headerAlign: 'right',
        align: 'right',
        renderCell: ({ row }) => {
          return (
            <IconButton color="primary" onClick={(event) => handleSelectApplicationTypeForEdit(event, row)}>
              <EditIcon />
            </IconButton>
          )
        },
      },
    ] as GridColDef[]
  }, [getMessage, handleSelectApplicationTypeForEdit])

  const handleCancelEditApplicationType = () => {
    setOpenEditModal(false)
  }

  const handleOnSuccessEdit = () => {
    setOpenEditModal(false)
    onSuccess()
  }

  const rows = useMemo(
    () =>
      applicationTypes.map((applicationType) => ({
        ...applicationType,
        id: applicationType.id,
        code: applicationType.code,
        key: applicationType.key,
        process: applicationType.process,
        public: applicationType.public,
        action: {},
      })),

    [applicationTypes],
  )

  return (
    <Box>
      <DataGridProStyled
        rows={rows}
        columns={columns}
        autoHeight
        localeText={gridTranslations}
        disableColumnSelector
        disableColumnReorder
        disableColumnPinning
        disableRowSelectionOnClick
        disableColumnMenu
        disableColumnFilter
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
      />
      <ModalDialog
        maxWidth="sm"
        open={openEditModal}
        onClose={() => setOpenEditModal(false)}
        title={getMessage('label.application-type.edit')}
      >
        {selectedApplicationType && (
          <EditApplicationTypeModal
            applicationType={selectedApplicationType}
            onSuccess={handleOnSuccessEdit}
            onCancel={handleCancelEditApplicationType}
          />
        )}
      </ModalDialog>
    </Box>
  )
}
