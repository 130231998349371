import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import {
  DataGridPro,
  getGridDateOperators,
  getGridStringOperators,
  GridColDef,
  GridFilterInputValueProps,
  GridFilterModel,
  GridPaginationModel,
  GridRowModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro'
import { ReactElement, useMemo, useState } from 'react'
import { Task } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { resolveKapMilestoneRoot, resolveMilestoneRoot } from 'src/routing/routing-utils'
import { PROCESS, PROCESS_TYPE, PROJECT, PROJECT_TYPE } from 'src/shared/constants/constants'
import { MILESTONE_TYPE_TYPE } from 'src/shared/constants/milestone-constants'
import { TEXT_OVERFLOW_MIXIN } from 'src/shared/constants/styling-constants'
import {
  ALL_TASK_TYPES,
  FACTSHEET_TASKS,
  MILESTONE_TASKS,
  PROGRAM_DESCRIPTION_TASKS,
  PROJECT_DESCRIPTION_TASKS,
  TASK,
} from 'src/shared/constants/task-constants'
import { Option } from 'src/shared/form/control'
import { AutoCompleteFilterInput } from 'src/shared/form/grid/filter/AutoCompleteFilterInput'
import { GridToolbar } from 'src/shared/grid/GridToolbar'
import { S } from 'src/shared/styled/S'
import { DateUtils } from 'src/shared/utils/DateUtils'
import { useGridTranslateHook } from 'src/shared/utils/hooks/grid-translate-hook'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { Utils } from 'src/shared/utils/Utils'
import styled from 'styled-components/macro'

interface Props {
  tasks: Task[]
}

const DataGridProStyled = styled(DataGridPro)`
  & .MuiDataGrid-row {
    &:hover {
      cursor: pointer;
    }
  }
`

const TypographyStyled = styled(Typography)`
  ${TEXT_OVERFLOW_MIXIN};
`

const getTypeFilterOperators = (options: Option[], label: string, placeholder: string) =>
  getGridStringOperators()
    .filter((operator) => operator.value === 'equals')
    .map((operator) => ({
      ...operator,
      InputComponent: (gridFilterProps: GridFilterInputValueProps) => (
        <AutoCompleteFilterInput {...gridFilterProps} options={options} label={label} placeholder={placeholder} />
      ),
    }))

const getResponsibleFilterOperators = (options: Option[], label: string, placeholder: string) =>
  getGridStringOperators()
    .filter((operator) => operator.value === 'equals')
    .map((operator) => ({
      ...operator,
      InputComponent: (gridFilterProps: GridFilterInputValueProps) => (
        <AutoCompleteFilterInput {...gridFilterProps} options={options} label={label} placeholder={placeholder} />
      ),
    }))

const getDueDateFilterOperators = (getMessage: (key: string) => string) =>
  getGridDateOperators()
    .filter((operator) => ['is', 'onOrAfter', 'onOrBefore'].some((item) => item.includes(operator.value)))
    .map((operator) => ({
      ...operator,
      label:
        operator.value === 'onOrAfter'
          ? getMessage('label.grid.from')
          : operator.value === 'onOrBefore'
          ? getMessage('label.grid.to')
          : operator.value,
    }))

export const TaskGrid = ({ tasks }: Props): ReactElement => {
  const { getMessage } = useMessageSource()
  const apiRef = useGridApiRef()
  const gridTranslations = useGridTranslateHook()
  const navigate = useDelayedNavigate()
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  })
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({ page: 0, pageSize: 10 })

  const rows = useMemo(() => {
    return tasks.map((item) => {
      return {
        ...item,
        responsible:
          item.responsiblePerson ?? getMessage(`label.task.responsible.group.${item.responsibleGroupMessageKey}`),
        dueDate: item.dueDate ? DateUtils.parseDate(item.dueDate) : null,
      }
    })
  }, [tasks, getMessage])

  const typeOptions = useMemo(
    () =>
      ALL_TASK_TYPES.map((task) => {
        return { label: getMessage(`label.task.${task}`), value: task }
      }),
    [getMessage],
  )

  const responsibleOptions = useMemo(
    () =>
      tasks
        .map((task) => {
          return {
            responsible:
              task.responsiblePerson || getMessage(`label.task.responsible.group.${task.responsibleGroupMessageKey}`),
          }
        })
        .filter((task, index, self) => self.findIndex((o: any) => o.responsible === self[index].responsible) === index)
        .map((task) => {
          return { label: task.responsible, value: task.responsible }
        }) as Option[],
    [tasks, getMessage],
  )

  const columns = useMemo(() => {
    return [
      {
        field: 'taskType',
        headerName: getMessage('label.project.type'),
        flex: 1.5,
        filterOperators: getTypeFilterOperators(
          typeOptions,
          getMessage('label.grid.value'),
          getMessage('label.grid.filter.value'),
        ),
        renderCell: (props) => {
          return (
            <S.DataGrid.TwoLineCell>
              <TypographyStyled variant="subtitle2">{getMessage(`label.task.${props.value}`)}</TypographyStyled>
              {props.row?.milestoneType && (
                <Typography variant="caption" color="textSecondary">
                  {getMessage(`label.milestone.type.${props.row.milestoneType}`)}
                </Typography>
              )}
            </S.DataGrid.TwoLineCell>
          )
        },
      },
      {
        field: 'entityId',
        headerName: getMessage('label.task.grid.id'),
        renderCell: ({ row }) => {
          if (row.process === PROJECT.KAP) {
            return `${getMessage('label.program.id.character')}${row.entityId}`
          } else if (row.process === PROJECT.PF_PGV || row.process === PROJECT.PF_KAP) {
            const process = row.process.toLowerCase()
            return `${getMessage(`label.project.id.character.${process}`)}${row.entityId}`
          } else if (row.process === PROCESS.FACTSHEET) {
            return `${getMessage(`label.factsheet.id.character`)}${row.entityId}`
          }
        },
        flex: 0.5,
      },
      { field: 'entityShortTitle', headerName: getMessage('label.project.program.factsheet'), flex: 1.5 },
      {
        field: 'dueDate',
        headerName: getMessage('label.due.date'),
        flex: 0.6,
        type: 'date',
        filterOperators: getDueDateFilterOperators(getMessage),
        valueFormatter: (props): string => {
          if (props.value) {
            return DateUtils.formatDate(props.value as Date)
          }
          return ''
        },
      },
      {
        field: 'responsible',
        headerName: getMessage('label.responsible'),
        flex: 1.2,
        filterOperators: getResponsibleFilterOperators(
          responsibleOptions,
          getMessage('label.grid.value'),
          getMessage('label.grid.filter.value'),
        ),
        renderCell: (props) => {
          return (
            <>
              <S.Avatar $randomColorKey={props.value!.toString()}>{props.value?.toString()?.charAt(0)}</S.Avatar>{' '}
              <S.Span.TextOverflow>{props.value}</S.Span.TextOverflow>
            </>
          )
        },
      },
    ] as GridColDef[]
  }, [getMessage, typeOptions, responsibleOptions])

  const handleRowClick = (item: GridRowModel) => {
    const entityId = item?.row?.entityId
    const process = item?.row?.process
    const relatedEntityId = item?.row?.relatedEntityId
    const taskType = item?.row?.taskType
    if (taskType === TASK.FILL_OUT_ASSESSMENT) {
      navigateToAssessmentFromProcess(process, entityId, relatedEntityId)
    } else if (taskType === TASK.REWORK_PROJECT_DESCRIPTION || PROGRAM_DESCRIPTION_TASKS.includes(taskType)) {
      navigateToConceptSummaryFromProcess(process, entityId)
    } else if (PROJECT_DESCRIPTION_TASKS.includes(taskType)) {
      navigateToProjectDescriptionFromProcess(process, entityId)
    } else if (MILESTONE_TASKS.includes(taskType)) {
      const milestoneType = item?.row?.milestoneType
      navigateToMilestoneFromProcess(process, milestoneType, entityId, relatedEntityId)
    } else if (taskType === TASK.FINALIZE_PROGRAM_APPLICATION) {
      navigate(ROUTES.KapApplicationDetailsRoot.nested.Summary.params({ programId: entityId }))
    } else if (FACTSHEET_TASKS.includes(taskType)) {
      navigateToFactsheetDescription(entityId)
    }
  }

  const navigateToConceptSummaryFromProcess = (process: PROJECT_TYPE, entityId: string) => {
    switch (process) {
      case PROJECT.PF_KAP:
        navigate(ROUTES.PfKapDetailsRoot.nested.ProjectSummary.params({ projectId: entityId }))
        break
      case PROJECT.PF_PGV:
        navigate(ROUTES.PfPgvDetailsRoot.nested.ProjectSummary.params({ projectId: entityId }))
        break
      case PROJECT.KAP:
        navigate(ROUTES.KapDetailsRoot.nested.Summary.params({ programId: entityId }))
        break
      default:
        navigate('/')
    }
  }

  const navigateToProjectDescriptionFromProcess = (process: PROJECT_TYPE, entityId: string) => {
    switch (process) {
      case PROJECT.PF_KAP:
        navigate(ROUTES.PfKapDetailsRoot.nested.BasicInformation.params({ projectId: entityId }))
        break
      case PROJECT.PF_PGV:
        navigate(ROUTES.PfPgvDetailsRoot.nested.BasicInformation.params({ projectId: entityId }))
        break
      default:
        navigate('/')
    }
  }

  const navigateToFactsheetDescription = (entityId: string) => {
    navigate(ROUTES.FactsheetDescriptionRoot.nested.FactsheetDescription.params({ factsheetId: entityId }))
  }

  const navigateToAssessmentFromProcess = (process: PROCESS_TYPE, projectId: number, assessmentId: number) => {
    switch (process) {
      case PROCESS.PF_KAP:
        navigate(ROUTES.PfKapApplicationAssessmentDetailsRoot.params({ projectId, assessmentId }))
        break
      case PROCESS.PF_PGV:
        navigate(ROUTES.PfPgvApplicationAssessmentDetailsRoot.params({ projectId, assessmentId }))
        break
      case PROCESS.FACTSHEET:
        navigate(ROUTES.FactsheetApplicationAssessmentDetailsRoot.params({ factsheetId: projectId, assessmentId }))
        break
      default:
        navigate('/')
    }
  }

  const navigateToMilestoneFromProcess = (
    process: PROJECT_TYPE,
    milestoneType: MILESTONE_TYPE_TYPE,
    entityId: number,
    milestoneId: number,
  ) => {
    const milestonePath = Utils.resolveMilestonePath(milestoneType)
    switch (process) {
      case PROJECT.PF_KAP:
        navigate(
          resolveMilestoneRoot('/pf-kap', milestoneType).nested.Summary.params({
            projectId: entityId,
            milestonePath,
            milestoneId,
          }),
        )
        break
      case PROJECT.PF_PGV:
        navigate(
          resolveMilestoneRoot('/pf-pgv', milestoneType).nested.Summary.params({
            projectId: entityId,
            milestonePath,
            milestoneId,
          }),
        )
        break
      case PROJECT.KAP:
        navigate(
          resolveKapMilestoneRoot(milestoneType).nested.Summary.params({
            programId: entityId,
            milestoneId,
            milestonePath,
          }),
        )
        break
      default:
        navigate('/')
    }
  }

  return (
    <Box>
      <DataGridProStyled
        rows={rows}
        columns={columns}
        autoHeight
        apiRef={apiRef}
        localeText={gridTranslations}
        onRowClick={(props) => handleRowClick(props)}
        disableRowSelectionOnClick
        disableColumnReorder
        disableColumnSelector
        disableColumnPinning
        paginationModel={paginationModel}
        onPaginationModelChange={(model) => setPaginationModel(model)}
        pageSizeOptions={[10, 25, 50]}
        pagination
        filterModel={filterModel}
        onFilterModelChange={setFilterModel}
        components={{
          Toolbar: () => <GridToolbar title={getMessage('label.tasks.title')} />,
        }}
      />
    </Box>
  )
}
