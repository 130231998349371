import { ListItemText } from '@mui/material'
import { useRef, useState } from 'react'
import { useParams } from 'react-router'
import { useEnvironment } from 'src/env/EnvironmentStore'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { Axios } from 'src/service/axios/axios'
import { EXPORT_FORMAT, PROJECT_TYPE } from 'src/shared/constants/constants'
import { ExportMenu } from 'src/shared/menu/ExportMenu'
import { LoadingMenuItem } from 'src/shared/menu/item/LoadingMenuItem'
import { S } from 'src/shared/styled/S'
import { saveFileAs } from 'src/shared/utils/BlobUtils'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useUserLocale } from 'src/user/UserContext'

interface Props {
  process: PROJECT_TYPE
  chartBase64Image?: string
}

export const FinalProjectReportExportMenu = ({ process, chartBase64Image }: Props) => {
  const { getMessage } = useMessageSource()
  const { backendUrl } = useEnvironment()
  const { projectId } = useParams()
  const language = useUserLocale()
  const notificationService = useNotificationService()

  const exportMenuRef = useRef<{ closeMenu: () => void }>({
    closeMenu: () => {},
  })

  const [loadingExportFinalProjectReport, setLoadingExportFinalProjectReport] = useState(false)

  const handleAnalysisExport = () => {
    setLoadingExportFinalProjectReport(true)

    if (projectId !== undefined && chartBase64Image !== undefined && chartBase64Image.trim() !== '') {
      Axios.getAxios()
        .post(`${backendUrl}/api/${process}/project/${projectId}/milestone/final-report/export`, chartBase64Image, {
          headers: {
            'content-type': 'text/plain',
          },
          params: {
            lang: language,
          },
          responseType: 'blob',
        })
        .then((response) => {
          saveFileAs(response)
        })
        .catch((e) => {
          notificationService.operationFailed()
          console.error(e)
        })
        .finally(() => {
          setLoadingExportFinalProjectReport(false)
          exportMenuRef.current.closeMenu()
        })
    } else {
      console.error('Invalid chart data image passed for exporting final report')
    }
  }

  return (
    <ExportMenu ref={exportMenuRef}>
      <LoadingMenuItem
        loading={loadingExportFinalProjectReport}
        disabled={loadingExportFinalProjectReport || chartBase64Image === undefined}
        onClick={handleAnalysisExport}
      >
        <ListItemText>{getMessage('label.export.final.report')}</ListItemText>
        <S.Typography.ExportFormat>({EXPORT_FORMAT.WORD})</S.Typography.ExportFormat>
      </LoadingMenuItem>
    </ExportMenu>
  )
}
