import { gql } from 'urql'

/**
 * Project committee Queries.
 */
export const fetchCommitteeDataQuery = gql`
  query fetchCommitteeData(
    $process: jsonb!
    $projectBaseWhere: project_base_bool_exp!
    $role: String
    $isEdit: Boolean! = false
    $userStatus: String!
  ) {
    committee_config(
      where: { processes: { _contains: $process }, active: { _eq: true } }
      order_by: { sort_number: asc }
    ) {
      active
      code
      descriptions
      id
      sort_number
    }

    project_committee_recommendation(where: { project_base: $projectBaseWhere }) {
      id
      committee_config_id
      recommendation
      description
      committee_config {
        active
        code
        descriptions
        id
        sort_number
      }
      project_base {
        id
      }
    }

    project_application_decision(where: { project_base: $projectBaseWhere }) {
      id
      version
      pros
      cons
      final_decision
      quality_criteria
      exclusion_criteria
      decision_letter_text
      version
      project_base {
        gfch_responsible {
          id
          email
          first_name
          last_name
        }
      }
    }

    criteria_config(where: { processes: { _contains: $process } }, order_by: { sort_number: asc }) {
      criteria_type
      criteria_id
      code
      active
      description
      sort_number
    }

    user(
      where: { user_roles: { _and: { role: { _eq: $role }, user: { status: { _eq: $userStatus } } } } }
      order_by: { first_name: asc, last_name: asc, email: asc }
    ) @include(if: $isEdit) {
      id
      first_name
      last_name
      email
    }
  }
`

export const saveCommitteeDataMutationQuery = gql`
  mutation upsert_committee_recommendation(
    $projectBaseId: Int!
    $projectCommitteeRecommendations: [project_committee_recommendation_insert_input!]!
    $projectApplicationDecision: [project_application_decision_insert_input!]!
    $gfchResponsibleId: Int
  ) {
    insert_project_committee_recommendation(
      objects: $projectCommitteeRecommendations
      on_conflict: {
        constraint: committee_config_id_project_base_id_uq
        update_columns: [description, recommendation]
        where: { project_base: { id: { _eq: $projectBaseId } } }
      }
    ) {
      returning {
        id
      }
      affected_rows
    }

    update_project_base(where: { id: { _eq: $projectBaseId } }, _set: { gfch_responsible_id: $gfchResponsibleId }) {
      affected_rows
    }

    insert_project_application_decision(
      objects: $projectApplicationDecision
      on_conflict: {
        constraint: project_application_decision_project_base_id_uq
        update_columns: [
          pros
          cons
          final_decision
          quality_criteria
          exclusion_criteria
          decision_letter_text
          version
        ]
        where: { project_base: { id: { _eq: $projectBaseId } } }
      }
    ) {
      returning {
        id
        version
      }
      affected_rows
    }
  }
`
