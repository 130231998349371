import { ReactElement, ReactNode } from 'react'
import { NextStateFactsheet } from 'src/shared/constants/workflow-constants'
import { FACTSHEET_WORKFLOW_STATUS_TYPE } from 'src/shared/constants/factsheet-constants'
import { valueof } from 'src/@types/global'
import { FACTSHEET_USER_ROLE_TYPE, USER_ROLES_TYPE } from 'src/shared/constants/constants'

interface Props {
  forState: FACTSHEET_WORKFLOW_STATUS_TYPE | FACTSHEET_WORKFLOW_STATUS_TYPE[]
  nextStates: NextStateFactsheet[]
  children: ReactNode
  userFactsheetRoles: Array<valueof<FACTSHEET_USER_ROLE_TYPE>>
  userGlobalRoles: Array<USER_ROLES_TYPE>
}

/**
 * Component that conditionally renders children based on `forState`.
 */
export const NextStateFactsheetIf = ({
  children,
  nextStates,
  forState,
  userGlobalRoles,
  userFactsheetRoles,
}: Props): ReactElement | null => {
  const nextStateFactsheet = nextStates.find(({ state }) => forState.includes(state))

  if (nextStateFactsheet) {
    const hasGlobalRole = nextStateFactsheet.userRoles.some((r) => userGlobalRoles.includes(r))
    const hasFactsheetRole = nextStateFactsheet.factsheetRoles.some((r) => userFactsheetRoles.includes(r))

    if (hasGlobalRole || hasFactsheetRole) {
      return <>{children}</>
    }
  }

  return null
}
