import React, { ReactElement, useEffect, useRef, useState } from 'react'
import {
  ApplicationAssessmentRecommendationEditForm,
  AssessmentRecommendationFormValues,
} from 'src/screens/shared/application/assessment/details/recommendation/edit/ApplicationAssessmentRecommendationEditForm'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { useParams } from 'react-router'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useClient } from 'urql'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Mutation_Root, Query_Root } from 'src/@types/graphql'
import { originType } from 'src/screens/shared/implementation/details/milestone/details/default-milestone/edit/MilestoneDetailsEditForm'
import {
  getFactsheetRecommendationById,
  updateFactsheetAssessmentRecommendationById,
} from 'src/screens/factsheets/application/assessment/factsheetAssessmentQueries'
import { ROUTES } from 'src/routing/routes'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { usePermissionsForFactsheetAssessmentDetails } from 'src/service/security/PermissionHook'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'

export const FactsheetAssessmentDetailsRecommendationEditPage = (): ReactElement => {
  const { factsheetId, assessmentId } = useParams()
  const assessmentIdNumber = parseInt(assessmentId as string)

  const { getMessage } = useMessageSource()
  const navigate = useDelayedNavigate()
  const urqlClient = useClient()
  const originRef = useRef<originType>('SAVE')
  const notificationService = useNotificationService()

  const { canEdit, loading } = usePermissionsForFactsheetAssessmentDetails(assessmentIdNumber)

  const [initialValues, setInitialValues] = useState<AssessmentRecommendationFormValues>()

  useEffect(() => {
    const initFormValues = async () => {
      const { data } = await urqlClient
        .query<{ factsheet_assessment: Query_Root['factsheet_assessment'] }>(getFactsheetRecommendationById, {
          assessmentId,
        })
        .toPromise()

      if (data && data.factsheet_assessment[0]) {
        const assessment = data.factsheet_assessment[0]
        setInitialValues({
          recommendation: assessment?.recommendation || null,
          pros: assessment?.pros || null,
          cons: assessment?.cons || null,
          comment: assessment?.recommendation_comment || null,
          version: assessment?.version || 0,
        })
      }
    }

    initFormValues()
  }, [assessmentId, urqlClient])

  const onSubmit = (origin: originType) => (_: any) => {
    // trigger submit event
    if (originRef) {
      originRef.current = origin
    }
    document.getElementById('edit-form')?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const onBack = () => {
    const recommendationEdit = ROUTES.FactsheetApplicationAssessmentDetailsRoot.nested.Recommendation

    if (recommendationEdit?.params) {
      navigate(recommendationEdit.params({ factsheetId, assessmentId }))
    }
  }

  const updateHandler = async (values: AssessmentRecommendationFormValues) => {
    const updateObject = {
      assessmentId: assessmentId,
      recommendation: values.recommendation,
      pros: values.pros,
      cons: values.cons,
      comment: values.comment,
    }

    const { error, data } = await urqlClient
      .mutation<{ update_factsheet_assessment: Mutation_Root['update_factsheet_assessment'] }>(
        updateFactsheetAssessmentRecommendationById,
        updateObject,
      )
      .toPromise()

    if (error || data?.update_factsheet_assessment?.affected_rows !== 1) {
      notificationService.operationFailed()
    } else {
      const newInitialValues = { ...values }
      setInitialValues(newInitialValues)
      notificationService.changesSaved()
      if (originRef.current === 'SAVE_AND_BACK') {
        onBack()
      }
    }
  }

  if (!loading && !canEdit) {
    return <NotAuthorized inEditPage />
  }

  return (
    <ScreenLayout
      title={getMessage('label.assessment.recommendation.edit')}
      hasSecondLevelNavigation={false}
      onBack={onBack}
      actions={
        <>
          <SaveAndBackButton origin="header" onClick={onSubmit('SAVE_AND_BACK')} />
          <SaveButton origin="header" onClick={onSubmit('SAVE')} />
        </>
      }
    >
      <PageLayout>
        <>
          {!loading && initialValues && (
            <>
              <HelpAndInstructions labelKey={`label.help.assessment.recommendation.factsheet`} defaultExpansion />
              <ApplicationAssessmentRecommendationEditForm initialValues={initialValues} onSave={updateHandler} />
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
