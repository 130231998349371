import { gql } from '@urql/core'

export const validateFactsheet = gql`
  query ($factsheetId: Int!, $factsheetLevel: String!) {
    validateFactsheetDescription(factsheetId: $factsheetId, factsheetLevel: $factsheetLevel) {
      validationViolationMessageKey
      factsheetDescriptionTab
      section
      missingFeatureTypes
      labelArguments
    }
  }
`

export const queryFactsheetComment = gql`
  query ($id: Int!) {
    factsheet(where: { id: { _eq: $id } }) {
      id
      comment
    }
  }
`

export const updateFactsheetCommentById = gql`
  mutation ($factsheetId: Int!, $comment: String!) {
    update_factsheet(where: { _and: { id: { _eq: $factsheetId } } }, _set: { comment: $comment }) {
      affected_rows
      returning {
        comment
      }
    }
  }
`
