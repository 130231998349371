import { Card, CardContent } from '@mui/material'
import { ReactElement, useState } from 'react'
import { useParams } from 'react-router'
import { Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { fetchSuccessFactorAnalysisById } from 'src/screens/success-factor-tool/analysis/details/successFactorAnalysisQueries'
import { usePermissionsForSuccessFactors } from 'src/service/security/PermissionHook'
import { EditButton } from 'src/shared/button/Buttons'
import { BaseChart } from 'src/shared/charts/BaseChart'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { AnalysisExportMenu } from 'src/shared/menu/AnalysisExportMenu'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { HtmlRenderer } from 'src/shared/presentation/HtmlRenderer'
import { ReadOnlyTextField } from 'src/shared/presentation/ReadOnly'
import { Section } from 'src/shared/presentation/Section'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useQuery } from 'urql'
import { useSuccessFactorChartContext } from 'src/shared/charts/success-factor/SuccessFactorChartContext'

export const AnalysisCoverPage = (): ReactElement => {
  const { getMessage } = useMessageSource()
  const { analysisId } = useParams()
  const notificationService = useNotificationService()
  const navigate = useDelayedNavigate()
  const id = parseInt(analysisId as string)
  const { chartData, chartOptions, chartFetching } = useSuccessFactorChartContext()
  const [chartBase64Image, setChartBase64Image] = useState<string>()
  const { canEdit } = usePermissionsForSuccessFactors()

  const [{ data, error, fetching }] = useQuery<
    { success_factor_analysis_by_pk: Query_Root['success_factor_analysis_by_pk'] },
    { id: number }
  >({ query: fetchSuccessFactorAnalysisById, variables: { id } })

  if (error) {
    notificationService.operationFailed()
  }

  const title = data?.success_factor_analysis_by_pk?.title
  const responsibility = data?.success_factor_analysis_by_pk?.responsibility
  const furtherInformation = data?.success_factor_analysis_by_pk?.further_information

  const onEdit = () => {
    navigate(ROUTES.SuccessFactorAnalysisRoot.nested.AnalysisCoverEdit.params({ analysisId }))
  }

  return (
    <ScreenLayout
      title={getMessage('label.success.factors.analysis.details')}
      actions={<AnalysisExportMenu chartBase64Image={chartBase64Image} />}
    >
      <PageLayout>
        <>
          {!fetching && (
            <>
              <Section
                id="analysis-information"
                title={getMessage('label.success.factor.analysis.information')}
                actionButton={canEdit && <EditButton onClick={onEdit} />}
                helpAndInstructions={
                  <HelpAndInstructions labelKey="label.help.success.factor.cover.analysis.information" />
                }
              >
                <Card>
                  <CardContent>
                    <ReadOnlyTextField text={getMessage('label.title')}>{title}</ReadOnlyTextField>
                    <ReadOnlyTextField text={getMessage('label.success.factor.responsibility')}>
                      {responsibility && <HtmlRenderer html={responsibility} />}
                    </ReadOnlyTextField>
                    <ReadOnlyTextField text={getMessage('label.success.factor.further.information')} isLast>
                      {furtherInformation && <HtmlRenderer html={furtherInformation} />}
                    </ReadOnlyTextField>
                  </CardContent>
                </Card>
              </Section>
            </>
          )}
          {!chartFetching && (
            <BaseChart
              style={{
                visibility: 'hidden',
                display: chartBase64Image ? 'none' : 'block',
              }}
              type="radar"
              data={chartData}
              options={{
                ...chartOptions,
                animation: {
                  duration: 0,
                  onComplete: (animationEvent) => {
                    if (!chartBase64Image) {
                      setChartBase64Image(animationEvent.chart.toBase64Image())
                    }
                  },
                },
              }}
            />
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
