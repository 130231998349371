import { CardActions, CardHeader, Typography } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Stack from '@mui/material/Stack'
import { ReactNode } from 'react'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ValidationListItemProps } from 'src/screens/shared/common/SummaryValidationUtils'
import { LIST_HEIGHT } from 'src/shared/constants/styling-constants'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { HtmlRenderer } from 'src/shared/presentation/HtmlRenderer'
import { Section } from 'src/shared/presentation/Section'
import styled from 'styled-components/macro'
import { ListItemComplete, ListItemIncomplete } from 'src/screens/shared/application/common/SummarySectionAlert'

interface Props {
  title: string
  actionsHelpLabel: string
  status: ReactNode
  violations: ValidationListItemProps[]
  violationClickHandler: (validationItem: ValidationListItemProps) => void
  actionsCompleteMessage: string
  statusActions: ReactNode
  commentsHelpLabel: string
  commentActions: ReactNode
  comment: string | null | undefined
  noItemsToValidate?: boolean
  sectionActionButton?: ReactNode
}

const ListTitle = styled(Typography)`
  height: ${LIST_HEIGHT};
  display: flex;
  align-items: center;
`

const CardHeaderStyled = styled(CardHeader)`
  span {
    line-height: 2.25rem;
  }
`

export const SummarySection = ({
  title,
  actionsHelpLabel,
  status,
  violations,
  violationClickHandler,
  actionsCompleteMessage,
  statusActions,
  commentsHelpLabel,
  commentActions,
  comment,
  noItemsToValidate,
  sectionActionButton,
}: Props) => {
  const { getMessage } = useMessageSource()

  return (
    <>
      <Section
        title={title}
        helpAndInstructions={<HelpAndInstructions labelKey={actionsHelpLabel} />}
        actionButton={sectionActionButton}
      >
        {violations && (
          <Card>
            <CardHeaderStyled
              title={<>{status}</>}
              action={<CardActions sx={{ padding: 0 }}>{statusActions}</CardActions>}
            />
            <CardContent>
              <Stack spacing={1}>
                <ListTitle variant="h6">{getMessage('label.validation')}</ListTitle>

                {violations.length === 0 ? (
                  <ListItemComplete message={actionsCompleteMessage} noItemsToValidate={noItemsToValidate} />
                ) : (
                  violations.map((violation) => {
                    return (
                      <ListItemIncomplete
                        message={violation.message}
                        key={violation.message}
                        onClick={() => violationClickHandler(violation)}
                        onKeyPress={(event) => {
                          if (['Enter', ' '].includes(event.key)) {
                            violationClickHandler(violation)
                          }
                        }}
                      />
                    )
                  })
                )}
              </Stack>
            </CardContent>
          </Card>
        )}
      </Section>
      <Section
        id="comments"
        title={getMessage('label.summary.comments')}
        helpAndInstructions={<HelpAndInstructions labelKey={commentsHelpLabel} />}
        actionButton={commentActions}
      >
        {comment && (
          <Card>
            <CardContent>
              <HtmlRenderer html={comment} />
            </CardContent>
          </Card>
        )}
      </Section>
    </>
  )
}
