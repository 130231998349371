import { PROJECT_USER_ROLES } from 'src/shared/constants/constants'

export const resolveUserPermissionMessageKey = (type: string): string => {
  if (type === PROJECT_USER_ROLES.ADMIN) {
    return 'label.admin'
  } else if (type === PROJECT_USER_ROLES.CONTRIBUTOR) {
    return 'label.can.edit'
  } else if (type === PROJECT_USER_ROLES.READER) {
    return 'label.can.view'
  }
  throw Error(`Unknown user role ${type}`)
}
