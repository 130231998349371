import { ReactElement, useContext, useEffect, useState } from 'react'
import { Application_Type, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import {
  getApplicationTypesQuery,
  queryActiveFundingRoundsByProcess,
} from 'src/screens/administration/round-management/round-management-queries'
import { PfPgvCreateProjectModalDialog } from 'src/screens/pf-pgv/index/add-project/PfPgvCreateProjectModalDialog'
import { AddProjectToolbar } from 'src/screens/shared/project/index/AddProjectToolbar'
import { ProjectCockpitGrid } from 'src/screens/shared/project/index/ProjectCockpitGrid'
import { PermissionService } from 'src/service/security/PermissionService'
import { APPLICATION_TYPE_TYPE, PROJECT } from 'src/shared/constants/constants'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { ModalDialog } from 'src/shared/modal-dialog/ModalDialog'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { useModalCancel } from 'src/shared/utils/hooks/modal-hooks'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { Utils } from 'src/shared/utils/Utils'
import { UserContext } from 'src/user/UserContext'
import { useClient } from 'urql'

export const PfPgvIndexPage = (): ReactElement => {
  const { getMessage } = useMessageSource()
  const { user } = useContext(UserContext)

  const urqlClient = useClient()
  const navigate = useDelayedNavigate()

  const [canCreateProject, setCanCreateProject] = useState(false)
  const [open, setOpen] = useState(false)
  const [activeApplicationTypes, setActiveApplicationTypes] = useState<Application_Type[]>([])

  useEffect(() => {
    const initData = async () => {
      const allApplicationTypes = await getActiveApplicationTypes()

      const isGfch = Utils.isInternalRole(user.roles)
      let roundTypes: APPLICATION_TYPE_TYPE[]

      if (!isGfch) {
        // External active application types (for external users)
        roundTypes = await getExternalActiveApplicationTypes(allApplicationTypes)
      } else {
        // All existing application types
        roundTypes = allApplicationTypes.map((it) => it.code as APPLICATION_TYPE_TYPE)
        setActiveApplicationTypes(allApplicationTypes)
      }

      setCanCreateProject(
        PermissionService.permissionToCreateProject(PROJECT.PF_PGV, user.roles, roundTypes.length > 0),
      )
    }

    const getExternalActiveApplicationTypes = async (allApplicationTypes: Application_Type[]) => {
      const activeRoundTypes: APPLICATION_TYPE_TYPE[] = []
      const externalApplicationTypes: Application_Type[] = allApplicationTypes.filter((it) => it.public)

      const { data } = await urqlClient
        .query<{ funding_round: Query_Root['funding_round'] }>(queryActiveFundingRoundsByProcess, {
          process: PROJECT.PF_PGV,
        })
        .toPromise()

      if (data?.funding_round) {
        data.funding_round.forEach((round) => {
          round.application_types?.map((type: APPLICATION_TYPE_TYPE) => {
            activeRoundTypes.push(type)
          })
        })
      }

      setActiveApplicationTypes(
        externalApplicationTypes.filter((applicationType) =>
          activeRoundTypes.includes(applicationType.code as APPLICATION_TYPE_TYPE),
        ),
      )

      return activeRoundTypes.filter((it) => externalApplicationTypes.map((it) => it.code).includes(it))
    }

    const getActiveApplicationTypes = async () => {
      const { data } = await urqlClient
        .query<{ application_type: Query_Root['application_type'] }>(getApplicationTypesQuery, {
          userProcesses: [PROJECT.PF_PGV],
        })
        .toPromise()

      return data?.application_type ?? []
    }

    initData()
  }, [urqlClient, user])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const onSuccess = (projectId: number) => {
    setOpen(false)
    navigate(ROUTES.PfPgvDetailsRoot.nested.BasicInformation.params({ projectId }))
  }

  const onCancel = useModalCancel(() => setOpen(false))

  return (
    <ScreenLayout title={getMessage('label.project.pfpgv.projects.cockpit')} hasSecondLevelNavigation={false}>
      <PageLayout>
        <HelpAndInstructions labelKey="label.help.pf_pgv.cockpit" defaultExpansion />
        <ModalDialog open={open} onClose={onCancel} title={getMessage('label.project.pfpgv.create')} maxWidth="md">
          <PfPgvCreateProjectModalDialog
            onCancel={onCancel}
            onSuccess={onSuccess}
            activeApplicationTypes={activeApplicationTypes}
          />
        </ModalDialog>
        <ProjectCockpitGrid
          process={PROJECT.PF_PGV}
          ProjectToolbar={
            <AddProjectToolbar
              addProject={handleClickOpen}
              canCreateProject={canCreateProject}
              title={getMessage('label.project.pfpgv.list')}
              buttonKey="button.project.pfpgv.add"
            />
          }
        />
      </PageLayout>
    </ScreenLayout>
  )
}
