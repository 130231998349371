import { ReactElement } from 'react'
import { Location, Outlet, useLocation, useParams } from 'react-router'
import { ROUTES } from 'src/routing/routes'
import { NestedRoute, Route, SecondLevelRoute } from 'src/routing/routing'
import { SecondLevelNavigation } from 'src/routing/SecondLevelNavigation'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { SecondLevelNavigationContainer } from 'src/shared/layout/SecondLevelNavigationContainer'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { usePermissionsForFactsheetApplication } from 'src/service/security/PermissionHook'

const resolveActiveRoute = (location: Location, factsheetId: string): SecondLevelRoute => {
  const nested = ROUTES.FactsheetApplicationDetailsRoot.nested as unknown as NestedRoute
  const activeRoute = Object.keys(nested).find(
    (key) =>
      location.pathname.endsWith(nested[key].path) ||
      (ROUTES.FactsheetApplicationDetailsRoot.params({ factsheetId }) === location.pathname && nested[key].primary),
  ) as string
  return nested[activeRoute] as SecondLevelRoute
}

export const FactsheetApplicationDetailsIndexPage = (): ReactElement => {
  const { factsheetId } = useParams()
  const factsheet_id = parseInt(factsheetId as string)

  const location = useLocation()

  const activeRoute = resolveActiveRoute(location, factsheetId as string)
  const showNavigation = activeRoute && !activeRoute.hideNavigation

  const { canView, loading } = usePermissionsForFactsheetApplication(factsheet_id)

  if (!loading && !canView) {
    return <NotAuthorized />
  }

  return (
    <>
      {!loading && (
        <SecondLevelNavigationContainer $showNavigation={showNavigation}>
          {showNavigation && (
            <PageLayout>
              <SecondLevelNavigation
                activeRoute={activeRoute}
                route={ROUTES.FactsheetApplicationDetailsRoot as unknown as Route}
                baseUrl={ROUTES.FactsheetApplicationDetailsRoot.params({ factsheetId })}
              />
            </PageLayout>
          )}
          <Outlet />
        </SecondLevelNavigationContainer>
      )}
    </>
  )
}
