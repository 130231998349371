import { IconButton } from '@mui/material'
import combinedQuery from 'graphql-combine-query'
import { ReactElement, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { SupportedLocale } from 'src/@types'
import { Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import {
  getAnalysisAreaOfActivityData,
  getAnalysisFocusTopicData,
  getAnalysisSuccessFactorData,
} from 'src/screens/success-factor-tool/analysis/details/analysis/analysisQueries'
import { AreaOfActivitySection } from 'src/screens/success-factor-tool/analysis/details/analysis/AreaOfActivitySection'
import { FocusTopicSection } from 'src/screens/success-factor-tool/analysis/details/analysis/FocusTopicSection'
import { SuccessFactorSection } from 'src/screens/success-factor-tool/analysis/details/analysis/SuccessFactorSection'
import { AnalysisUtils } from 'src/screens/success-factor-tool/analysis/details/analysis/utils/AnalysisUtils'
import { usePermissionsForSuccessFactors } from 'src/service/security/PermissionHook'
import { EditButton } from 'src/shared/button/Buttons'
import { BaseChart } from 'src/shared/charts/BaseChart'
import { EditIcon } from 'src/shared/icons/Icons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { AnalysisExportMenu } from 'src/shared/menu/AnalysisExportMenu'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useUserLocale } from 'src/user/UserContext'
import { useClient } from 'urql'
import { useSuccessFactorChartContext } from 'src/shared/charts/success-factor/SuccessFactorChartContext'

export interface AnalysisAreaOfActivity {
  id: number
  code: string
  sort_number: number
  name: string
  comment: string
}

export interface AnalysisFocusTopic {
  id: number
  code: string
  areaOfActivityId: number
  sortNumber: number
  name: string
  comment: string
}

export interface AnalysisSuccessFactor {
  id: number
  code: string
  focusTopicId: number
  sortNumber: number
  name: string
  comment: string
  status?: string
  ratingStatus?: number
  ratingPriority?: number
}

interface SuccessFactorAnalysis {
  areasOfActivities: AnalysisAreaOfActivity[]
  focusTopics: AnalysisFocusTopic[]
  successFactors: AnalysisSuccessFactor[]
}

export const AnalysisDetailsPage = (): ReactElement => {
  const { analysisId } = useParams()
  const { getMessage } = useMessageSource()
  const notificationService = useNotificationService()
  const urqlClient = useClient()
  const locale = useUserLocale()
  const navigate = useDelayedNavigate()
  const [analysisData, setAnalysisData] = useState<SuccessFactorAnalysis | null>(null)
  const { chartData, chartOptions, chartFetching } = useSuccessFactorChartContext()
  const [chartBase64Image, setChartBase64Image] = useState<string>()
  const { canEdit } = usePermissionsForSuccessFactors()

  useEffect(() => {
    const initData = async () => {
      const { document, variables } = combinedQuery('fetchAreaOfActivityAndFocusTopicData')
        .add(getAnalysisAreaOfActivityData, { analysisId: parseInt(analysisId as string), language: locale })
        .add(getAnalysisFocusTopicData, {
          focusTopicAnalysisId: parseInt(analysisId as string),
          focusTopicLanguage: locale,
        })
        .add(getAnalysisSuccessFactorData, {
          successFactorAnalysisId: parseInt(analysisId as string),
          successFactorLanguage: locale,
        })

      const { data, error } = await urqlClient
        .query<
          {
            area_of_activity: Query_Root['area_of_activity']
            analysis_area_of_activity: Query_Root['analysis_area_of_activity']
            focus_topic: Query_Root['focus_topic']
            analysis_focus_topic: Query_Root['analysis_focus_topic']
            success_factor: Query_Root['success_factor']
            analysis_success_factor_rating: Query_Root['analysis_success_factor_rating']
          },
          {
            analysisId: number
            language: SupportedLocale
            focusTopicAnalysisId: number
            focusTopicLanguage: SupportedLocale
            successFactorAnalysisId: number
            successFactorLanguage: SupportedLocale
          }
        >(document, variables!)
        .toPromise()

      if (error) {
        notificationService.operationFailed()
      } else if (data) {
        const adaptedAnalysisAreaOfActivityData = AnalysisUtils.adaptDataForRendering(
          data.area_of_activity,
          data.analysis_area_of_activity,
        )
        const focusTopicData = AnalysisUtils.mapFocusTopicToAnalysisFocusTopicModel(
          data.focus_topic,
          data.analysis_focus_topic,
        )
        const successFactorData = AnalysisUtils.mapSuccessFactorToAnalysisSuccessFactorModel(
          data?.success_factor,
          data?.analysis_success_factor_rating,
        )
        setAnalysisData({
          areasOfActivities: adaptedAnalysisAreaOfActivityData,
          focusTopics: focusTopicData,
          successFactors: successFactorData,
        } as SuccessFactorAnalysis)
      }
    }
    initData()
  }, [analysisId, locale, notificationService, urqlClient])

  const handleEditAreaOfActivity = (areaOfActivityId: number) => () => {
    navigate(
      ROUTES.SuccessFactorAnalysisRoot.nested.AnalysisAreaOfActivityEdit.params({ analysisId, areaOfActivityId }),
    )
  }

  const handleEditFocusTopic = (focusTopicId: number) => () => {
    navigate(ROUTES.SuccessFactorAnalysisRoot.nested.AnalysisFocusTopicEdit.params({ analysisId, focusTopicId }))
  }

  const handleEditSuccessFactor = (successFactorId: number) => () => {
    navigate(ROUTES.SuccessFactorAnalysisRoot.nested.AnalysisSuccessFactorEdit.params({ analysisId, successFactorId }))
  }

  return (
    <ScreenLayout
      title={getMessage('label.success.factors.analysis.details')}
      actions={<AnalysisExportMenu chartBase64Image={chartBase64Image} />}
    >
      <PageLayout>
        <>
          {analysisData &&
            analysisData.areasOfActivities.map((areaOfActivity) => {
              return (
                <AreaOfActivitySection
                  id={areaOfActivity.code.replaceAll('_', '-')}
                  key={areaOfActivity.id}
                  title={areaOfActivity.name}
                  code={areaOfActivity.code}
                  actionButton={canEdit && <EditButton onClick={handleEditAreaOfActivity(areaOfActivity.id)} />}
                  comment={areaOfActivity.comment}
                >
                  {analysisData?.focusTopics
                    .filter((focusTopic) => focusTopic.areaOfActivityId === areaOfActivity.id)
                    .map((focusTopic) => (
                      <FocusTopicSection
                        id={focusTopic.code.replaceAll('_', '-')}
                        key={focusTopic.id}
                        title={focusTopic.name}
                        code={focusTopic.code}
                        comment={focusTopic.comment}
                        ratingStatus={AnalysisUtils.resolveAverageRating(
                          analysisData?.successFactors.filter(
                            (successFactor) => successFactor.focusTopicId === focusTopic.id,
                          ),
                          'ratingStatus',
                        )}
                        ratingPriority={AnalysisUtils.resolveAverageRating(
                          analysisData?.successFactors.filter(
                            (successFactor) => successFactor.focusTopicId === focusTopic.id,
                          ),
                          'ratingPriority',
                        )}
                        actionButton={
                          canEdit && (
                            <IconButton onClick={handleEditFocusTopic(focusTopic.id)} color="primary">
                              <EditIcon />
                            </IconButton>
                          )
                        }
                      >
                        {analysisData?.successFactors
                          .filter((successFactor) => successFactor.focusTopicId === focusTopic.id)
                          .map((successFactor) => (
                            <SuccessFactorSection
                              id={successFactor.code.replaceAll('_', '-')}
                              key={successFactor.id}
                              title={successFactor.name}
                              sortNumber={successFactor.sortNumber}
                              code={successFactor.code}
                              status={successFactor.status}
                              ratingStatus={successFactor.ratingStatus}
                              ratingPriority={successFactor.ratingPriority}
                              comment={successFactor.comment}
                              actionButton={
                                <IconButton onClick={handleEditSuccessFactor(successFactor.id)} color="primary">
                                  <EditIcon />
                                </IconButton>
                              }
                            />
                          ))}
                      </FocusTopicSection>
                    ))}
                </AreaOfActivitySection>
              )
            })}
          {!chartFetching && (
            <BaseChart
              style={{ visibility: 'hidden', display: chartBase64Image ? 'none' : 'block' }}
              type="radar"
              data={chartData}
              options={{
                ...chartOptions,
                animation: {
                  duration: 0,
                  onComplete: (animationEvent) => {
                    if (!chartBase64Image) {
                      setChartBase64Image(animationEvent.chart.toBase64Image())
                    }
                  },
                },
              }}
            />
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
