import { DialogActions, DialogContent, Stack } from '@mui/material'
import { Box } from '@mui/system'
import { FormApi } from 'final-form'
import combinedQuery from 'graphql-combine-query'
import { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import { AssessorInput, Factsheet_Assessment, Mutation_Root, Query_Root, SupportedLocale } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import {
  getFactsheetAssessmentTypesQuery,
  getUsersForFactsheetAssessment,
  insertMultipleFactsheetAssessmentsMutation,
} from 'src/screens/factsheets/application/assessment/factsheetAssessmentQueries'
import { notifyAssessorsQuery } from 'src/screens/shared/assessment-criteria/assessmentQueries'
import { PrimaryButton, SecondaryButton } from 'src/shared/button/Buttons'
import {
  ASSESSMENT_STATUS,
  ASSESSMENT_STATUS_TYPE,
  ASSESSMENT_TYPE,
  ASSESSMENT_TYPE_TYPE,
} from 'src/shared/constants/assessment-constants'
import { GLOBAL_USER_ROLES, USER_STATUS } from 'src/shared/constants/constants'
import { Option } from 'src/shared/form/control'
import { AutoCompleteField } from 'src/shared/form/control/AutoCompleteField'
import { MultiSelectField } from 'src/shared/form/control/MultiSelectField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { createDecorators } from 'src/shared/form/utils/decorators'
import { required } from 'src/shared/form/validation/validators'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useUser } from 'src/user/UserContext'
import { useClient } from 'urql'

export interface AddFactsheetAssessmentType {
  factsheet_id: number
  assessor_type: ASSESSMENT_TYPE_TYPE
  assessor_id: number
  instance_id: number
  status: ASSESSMENT_STATUS_TYPE
}

interface Props {
  onCancel: () => void
  onSuccess: () => void
  onDiscard: () => void
  assessments?: Query_Root['factsheet_assessment'] | undefined
  selectedFactsheetIds: number[]
  filterExistingUsers?: boolean
}

export interface AssessmentFormValues {
  instanceId: number | undefined
  internalAssessmentsIds: number[]
}

const decorators = createDecorators()

export const FactsheetAssessorManagementModal = ({
  onCancel,
  onSuccess,
  onDiscard,
  selectedFactsheetIds,
  assessments,
  filterExistingUsers = false,
}: Props) => {
  const { getMessage } = useMessageSource()
  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const { user } = useUser()

  const [initialValues] = useState<AssessmentFormValues>({
    instanceId: undefined,
    internalAssessmentsIds: [],
  })
  const [assessmentInstances, setAssessmentInstances] = useState<Option[] | undefined>()
  const [gfchUsers, setGfchUsers] = useState<Option[] | undefined>()
  const [filteredGfchUsers, setFilteredGfchUsers] = useState<Option[] | undefined>()

  const mapValuesToFactsheetAssessmentType = (
    userIds: number[],
    assessorType: string,
    instanceId: number,
    factsheetId: number,
  ): AddFactsheetAssessmentType[] =>
    userIds.map((userId) => ({
      assessor_type: assessorType,
      assessor_id: userId,
      instance_id: instanceId,
      status: ASSESSMENT_STATUS.OPEN,
      factsheet_id: factsheetId,
    })) as AddFactsheetAssessmentType[]

  useEffect(() => {
    const initializeData = async () => {
      const { document, variables } = combinedQuery('AssessmentInstancesAndUsers')
        .add(getFactsheetAssessmentTypesQuery, { process: 'FACTSHEET' })
        .add(getUsersForFactsheetAssessment, {
          role: GLOBAL_USER_ROLES['PD-GFCH_PF-KAP_CONTRIBUTOR'],
          status: USER_STATUS.ACTIVE,
        })

      const { data } = await urqlClient
        .query<{
          assessment_instance_type: Query_Root['assessment_instance_type']
          internal_users: Query_Root['user_roles']
        }>(document, variables)
        .toPromise()

      if (data?.assessment_instance_type && data?.internal_users) {
        const assessmentInstances = data?.assessment_instance_type
        const gfchUsers = data?.internal_users

        const assessmentInstancesOptions = assessmentInstances
          ?.sort((a, b) => a.sort_number - b.sort_number)
          .map((item) => ({ label: getMessage(item.key), value: item.id })) as Option[]

        const gfchUsersOptions: Option[] = []

        if (Utils.isFactsheetCoordinatorRole(user.roles)) {
          const userOption: Option = {
            label: user.firstName ? `${user.firstName} ${user.lastName} (${user.email})` : user.email,
            value: user.id,
            sortNumber: 1,
          }

          gfchUsersOptions.push(userOption)
        }

        gfchUsers?.forEach((item, index) => {
          const gfchUser: Option = {
            label: item.user.first_name
              ? `${item.user.first_name} ${item.user.last_name} (${item.user.email})`
              : item.user.email,
            value: item.user.id,
            sortNumber: index + 2,
          }
          gfchUsersOptions.push(gfchUser)
        })

        setAssessmentInstances(assessmentInstancesOptions)
        setGfchUsers(gfchUsersOptions)
        setFilteredGfchUsers(gfchUsersOptions)
      }
    }

    initializeData()
  }, [urqlClient, getMessage, user])

  const handleSubmitLocal = async (values: AssessmentFormValues, _: FormApi<AssessmentFormValues>): Promise<any> => {
    const { internalAssessmentsIds, instanceId } = values

    const internalAssessments = selectedFactsheetIds.flatMap((factsheetId) =>
      mapValuesToFactsheetAssessmentType(
        internalAssessmentsIds,
        ASSESSMENT_TYPE.INTERNAL,
        instanceId as number,
        factsheetId,
      ),
    )

    const queryVariables = {
      objects: internalAssessments,
    }

    if (queryVariables.objects.length === 0) {
      onDiscard()
      return
    }

    const { data } = await urqlClient
      .mutation<{
        insert_factsheet_assessment: Mutation_Root['insert_factsheet_assessment']
      }>(insertMultipleFactsheetAssessmentsMutation, queryVariables)
      .toPromise()

    if (data) {
      const returningList = data?.insert_factsheet_assessment?.returning as Factsheet_Assessment[]
      sendNotificationToAssessors(returningList)
      notificationService.changesSaved()
      onSuccess()
    } else {
      notificationService.operationFailed()
    }
  }

  const sendNotificationToAssessors = async (returningFactsheetAssessment: Factsheet_Assessment[]) => {
    const assessors: AssessorInput[] = returningFactsheetAssessment
      .map((factsheetAssessment) => ({
        firstName: factsheetAssessment.user.first_name ?? '',
        lastName: factsheetAssessment.user.last_name ?? '',
        email: factsheetAssessment.user.email ?? '',
        language: factsheetAssessment.user.language as SupportedLocale,
      }))
      .filter((_, index, self) => self.findIndex((o: any) => o.email === self[index].email) === index)

    const { data, error } = await urqlClient
      .mutation<{
        notifyAssessors: Mutation_Root['notifyAssessors']
      }>(notifyAssessorsQuery, { assessors })
      .toPromise()

    if (error || (data && data.notifyAssessors.status === 'FAILURE')) {
      notificationService.operationFailed()
    }
  }

  const filterExistingGfchUsers = (instanceId: number) => {
    const currentAssessorsInInstance = assessments?.filter((assessment) => assessment.instance_id === instanceId) || []
    const filteredInternalUsers = gfchUsers?.filter(
      (user) => !currentAssessorsInInstance.some((assessment) => assessment.assessor_id === user.value),
    )
    setFilteredGfchUsers(filteredInternalUsers)
  }

  return (
    <Box>
      {assessmentInstances && filteredGfchUsers && (
        <Form<AssessmentFormValues>
          initialValues={initialValues}
          onSubmit={handleSubmitLocal}
          decorators={decorators}
          mutators={{
            clearInternalUsers: (_, state, utils) => {
              utils.changeValue(state, 'internalAssessmentsIds', () => [])
            },
          }}
          render={({ form, handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit} noValidate>
                <DialogContent>
                  <Stack spacing={2}>
                    <AutoCompleteField
                      required
                      label={getMessage('label.instance')}
                      name="instanceId"
                      options={assessmentInstances}
                      validate={required()}
                    />
                    <OnChange name="instanceId">
                      {(value) => {
                        if (filterExistingUsers) {
                          filterExistingGfchUsers(value)
                        }
                        form.mutators.clearInternalUsers()
                      }}
                    </OnChange>
                    <MultiSelectField
                      options={filteredGfchUsers}
                      name="internalAssessmentsIds"
                      label={getMessage('label.users.internal')}
                      unavailableOptionsMessage={getMessage('label.assessment.no.available.users')}
                    />
                    <DirtyFormSpy />
                  </Stack>
                </DialogContent>
                <DialogActions>
                  <SecondaryButton messageKey="button.close" onClick={onCancel} />
                  <PrimaryButton messageKey="button.submit" type="submit" />
                </DialogActions>
              </form>
            )
          }}
        />
      )}
    </Box>
  )
}
