import {
  AutocompleteProps as MuiAutoCompleteProps,
  AutocompleteRenderInputParams as MuiAutoCompleteRenderInputParams,
  default as MuiAutoComplete,
} from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { ReactElement, ReactNode } from 'react'
import { Field } from 'react-final-form'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { FinalFormInput, Option } from 'src/shared/form/control/index'

interface AutocompleteFieldOption extends Omit<Option, 'value'> {
  value: number | string | boolean | null
}

type MuiAutoCompletePropsToBeExcluded = FinalFormInput | 'renderInput' | 'getOptionValue'

type AutoCompleteFieldProps = Omit<
  MuiAutoCompleteProps<AutocompleteFieldOption, false, boolean, false>,
  MuiAutoCompletePropsToBeExcluded
> & {
  name: string
  label: string
  options: AutocompleteFieldOption[]
  validate?: any
  required?: boolean
  onCustomChange?: (value: any) => boolean
  variant?: 'standard' | 'filled'
}

export const AutoCompleteField = (props: AutoCompleteFieldProps): ReactElement => {
  const { name, validate, label, options, required, onCustomChange, variant = 'filled', ...rest } = props
  const { getMessage } = useMessageSource()

  return (
    <Field
      name={name}
      allowNull
      validate={validate}
      render={({ input: { onFocus, onBlur, onChange, value }, meta: { touched, error: errorObject } }) => {
        const onChangeLocal = (_: any, optionValue: any): void => {
          if (onCustomChange) {
            const confirmation = onCustomChange(optionValue?.value ?? null)
            if (confirmation) {
              onChange(optionValue?.value ?? null)
            }
          } else {
            onChange(optionValue?.value ?? null)
          }
        }
        // react-final-form for a Field that doesn't have an initialValue will always initialize it as an empty string
        // treat the empty string as the nullable value from the options array
        const resolvedOptionValue = options.find((x) => x.value === value || (value === '' && x.value === null)) || null
        const error = errorObject && getMessage(errorObject.errorKey, errorObject.params)
        const invalid = Boolean(touched && error)
        return (
          <MuiAutoComplete
            {...rest}
            onBlur={onBlur}
            options={options}
            onFocus={onFocus}
            getOptionLabel={(option: any): string => option?.label ?? ''}
            value={resolvedOptionValue}
            onChange={onChangeLocal}
            multiple={false}
            renderInput={(params: MuiAutoCompleteRenderInputParams): ReactNode => (
              <TextField
                {...params}
                name={name}
                required={required}
                fullWidth
                label={label}
                onBlur={onBlur}
                error={invalid}
                variant={variant}
                helperText={(invalid && error) || ' '}
              />
            )}
          />
        )
      }}
    />
  )
}
