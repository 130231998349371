import {
  GLOBAL_USER_ROLES,
  LEVEL_TYPE,
  PROCESS_TYPE,
  USER_ROLES_TYPE,
  USER_STATUS,
} from 'src/shared/constants/constants'
import { useCallback, useEffect, useState } from 'react'
import { Option } from 'src/shared/form/control'
import { useClient } from 'urql'
import { Factsheet_Bool_Exp, Mutation_Root, Query_Root } from 'src/@types/graphql'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import {
  fetchCommitteeFactsheetDataQuery,
  saveFactsheetCommitteeDataMutationQuery,
} from 'src/screens/factsheets/application/committee/factsheetCommitteeQueries'
import {
  FactsheetApplicationCommitteeUtils,
  FactsheetCommitteeValues,
} from 'src/screens/factsheets/application/committee/common/FactsheetApplicationCommitteeUtils'

export const useResolveFactsheetCommitteeData = (
  factsheetId: number | undefined,
  process: PROCESS_TYPE | undefined,
  level: LEVEL_TYPE | undefined,
  isEdit: boolean,
) => {
  const [initialFormValues, setInitialFormValues] = useState<FactsheetCommitteeValues>()
  const [optionValues, setOptionValues] = useState<{
    exclusionCriteriaOptions: Option[]
    qualityCriteriaOptions: Option[]
    gfchResponsibleOptions: Option[]
  }>({
    exclusionCriteriaOptions: [],
    qualityCriteriaOptions: [],
    gfchResponsibleOptions: [],
  })

  const urqlClient = useClient()

  useEffect(() => {
    const initializeData = async () => {
      if (factsheetId && process && level) {
        const { data } = await urqlClient
          .query<
            {
              committee_config: Query_Root['committee_config']
              factsheet_committee_recommendation: Query_Root['factsheet_committee_recommendation']
              factsheet_application_decision: Query_Root['factsheet_application_decision']
              criteria_config: Query_Root['criteria_config']
              user: Query_Root['user']
            },
            {
              process: PROCESS_TYPE
              factsheetLevel: LEVEL_TYPE
              factsheetWhere: Factsheet_Bool_Exp
              role: USER_ROLES_TYPE
              isEdit: boolean
              userStatus: typeof USER_STATUS.ACTIVE
            }
          >(fetchCommitteeFactsheetDataQuery, {
            process: process,
            factsheetLevel: level,
            factsheetWhere: {
              id: {
                _eq: factsheetId,
              },
            },
            role: GLOBAL_USER_ROLES['PD-GFCH_PF-KAP_CONTRIBUTOR'],
            isEdit: isEdit,
            userStatus: USER_STATUS.ACTIVE,
          })
          .toPromise()

        if (data) {
          const criteriaOptions = FactsheetApplicationCommitteeUtils.getCriteriaOptions(data.criteria_config)

          let gfchResponsibleOptions: { label: string; value: number }[] = []
          if (isEdit) {
            gfchResponsibleOptions = FactsheetApplicationCommitteeUtils.getGFCHResponsibleOptions(data.user)
          }

          setOptionValues({
            exclusionCriteriaOptions: criteriaOptions.exclusionCriteriaOptions,
            qualityCriteriaOptions: criteriaOptions.qualityCriteriaOptions,
            gfchResponsibleOptions: gfchResponsibleOptions,
          })

          setInitialFormValues(FactsheetApplicationCommitteeUtils.adaptAsFormValues(data, factsheetId))
        }
      }
    }

    initializeData()
  }, [factsheetId, process, urqlClient, isEdit, level])

  return {
    initialFormValues,
    optionValues,
    setInitialFormValues,
  }
}

type originType = 'SAVE' | 'SAVE_AND_BACK'

export const useUpdateFactsheetCommitteeData = () => {
  const urqlClient = useClient()
  const notificationService = useNotificationService()

  return useCallback(
    async (
      values: FactsheetCommitteeValues,
      factsheetId: number | null,
      setInitialFormValues: React.Dispatch<React.SetStateAction<FactsheetCommitteeValues | undefined>>,
      originRef: React.MutableRefObject<originType>,
      onBack: any,
    ) => {
      const updateValues = FactsheetApplicationCommitteeUtils.readFormValues(values)

      const { error, data } = await urqlClient
        .mutation<{
          insert_factsheet_committee_recommendation: Mutation_Root['insert_factsheet_committee_recommendation']
          insert_factsheet_application_decision: Mutation_Root['insert_factsheet_application_decision']
          update_factsheet: Mutation_Root['update_factsheet']
        }>(saveFactsheetCommitteeDataMutationQuery, {
          factsheetId: factsheetId,
          factsheetCommitteeRecommendations: updateValues.factsheetCommitteeRecommendations,
          factsheetApplicationDecision: updateValues.applicationDecision,
          gfchResponsibleId: updateValues.gfchResponsibleId,
        })
        .toPromise()

      if (error || data?.insert_factsheet_application_decision?.affected_rows !== 1) {
        notificationService.operationFailed()
        return Promise.reject(error)
      } else {
        const newVersion = data?.insert_factsheet_application_decision?.returning[0]?.version
        setInitialFormValues({ ...values, version: newVersion })
        notificationService.changesSaved()
        if (originRef.current === 'SAVE_AND_BACK') {
          onBack()
        }
      }
    },
    [notificationService, urqlClient],
  )
}
