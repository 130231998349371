import React, { ReactElement, useState } from 'react'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { useParams } from 'react-router'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { MILESTONE_TYPE } from 'src/shared/constants/milestone-constants'
import { resolveImplementationDetailsRoot } from 'src/routing/routing-utils'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { Section } from 'src/shared/presentation/Section'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { Card, CardContent, Typography } from '@mui/material'
import styled from 'styled-components/macro'
import { Utils } from 'src/shared/utils/Utils'
import { FinalProjectReportExportMenu } from 'src/shared/menu/FinalProjectReportExportMenu'
import { MilestoneFinalReportChart } from 'src/shared/charts/milestone-final-report/MilestoneFinalReportChart'

const LegendStyled = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => theme.colors.text.secondary};
`

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
}

export const FinalReportResultsPage = ({ baseUrl }: Props): ReactElement => {
  const { projectId } = useParams()

  const navigate = useDelayedNavigate()
  const { getMessage } = useMessageSource()

  const [chartBase64Image, setChartBase64Image] = useState<string>()

  const projectType = Utils.resolveProcess(baseUrl)

  const onBackHandler = () => {
    navigate(resolveImplementationDetailsRoot(baseUrl).params({ projectId }))
  }

  return (
    <ScreenLayout
      title={getMessage(`label.milestone.type.${MILESTONE_TYPE.FINAL_REPORT}`)}
      actions={<FinalProjectReportExportMenu process={projectType} chartBase64Image={chartBase64Image} />}
      onBack={onBackHandler}
    >
      <PageLayout>
        <>
          <Section
            id="analysis-results"
            title={getMessage('label.milestone.final.report.results')}
            helpAndInstructions={<HelpAndInstructions labelKey="label.help.milestone.final.report.results" />}
          >
            <Card>
              <CardContent>
                <MilestoneFinalReportChart setChartBase64Image={setChartBase64Image} />

                <LegendStyled variant="body2">
                  {getMessage(`label.${projectType}.milestone.final.report.results.legend`)}
                </LegendStyled>
              </CardContent>
            </Card>
          </Section>
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
