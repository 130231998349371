import { gql } from '@urql/core'

export const fetchDossierMetadataAndProjectUserRoles = gql`
  query FetchDossierMetadataAndProjectUserRoles($projectBaseWhere: project_base_bool_exp!, $email: String!) {
    project_base(where: $projectBaseWhere) {
      id
      dossier {
        id
        status
      }
      project_users(where: { user: { email: { _eq: $email } } }) {
        type
      }
    }
  }
`

export const fetchFactsheetMetadata = gql`
  query FetchFactsheetMetadata($factsheetId: Int!, $email: String!) {
    factsheet(where: { id: { _eq: $factsheetId } }) {
      id
      published
      level
      project_leader_id
      workflow_status
      factsheet_workflow_id
      feature_update_version
      title
      factsheet_users(where: { user: { email: { _eq: $email } } }) {
        type
      }
      factsheet_application_decision {
        application_decision
        version
        application_comment
      }
      funding_round {
        id
        name
      }
    }
  }
`

export const fetchFactsheetAssessorId = gql`
  query FetchFactsheetAssessorId($assessmentId: Int!) {
    factsheet_assessment(where: { id: { _eq: $assessmentId } }) {
      assessor_id
      factsheet {
        workflow_status
        published
      }
    }
  }
`

export const fetchProgramCantonAndStatus = gql`
  query fetchProgramCantonAndStatus($programId: Int!) {
    kap_program(where: { id: { _eq: $programId } }) {
      canton_code
      dossier {
        status
      }
    }
  }
`

export const fetchAssessorId = gql`
  query FetchAssessorId($assessmentId: Int!) {
    project_assessment(where: { id: { _eq: $assessmentId } }) {
      assessor_id
    }
  }
`
