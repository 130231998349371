import { IconButton } from '@mui/material'
import { DataGridPro, GridColDef, GridRowModel, GridRowParams, useGridApiRef } from '@mui/x-data-grid-pro'
import { useMemo, useState } from 'react'
import { Mutation_Root, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { deleteFactsheetAssessmentMutationQuery } from 'src/screens/factsheets/application/assessment/factsheetAssessmentQueries'
import { DeleteIcon } from 'src/shared/icons/Icons'
import { ConfirmationModalDialog } from 'src/shared/modal-dialog/ConfirmationModalDialog'
import { useGridTranslateHook } from 'src/shared/utils/hooks/grid-translate-hook'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import styled from 'styled-components/macro'
import { useClient } from 'urql'
import { ROUTES } from 'src/routing/routes'

const DataGridProStyled = styled(DataGridPro)`
  & .MuiDataGrid-row {
    &:hover {
      cursor: pointer;
    }
  }
`

interface Props {
  factsheetId: number
  assessments: Query_Root['factsheet_assessment'] | undefined
  refetch: () => void
  canDeleteAssessment: boolean
}

export const FactsheetAssessmentGrid = ({ factsheetId, assessments, refetch, canDeleteAssessment }: Props) => {
  const { getMessage } = useMessageSource()
  const apiRef = useGridApiRef()
  const gridTranslations = useGridTranslateHook()
  const navigate = useDelayedNavigate()
  const [assessmentDeleteConfirmationOpen, setAssessmentDeleteConfirmationOpen] = useState(false)
  const [assessmentIdToBeDeleted, setAssessmentIdToBeDeleted] = useState<number | null>(null)
  const urqlClient = useClient()
  const notificationService = useNotificationService()

  const assessmentRows = useMemo(() => {
    return assessments?.map((assessment) => {
      return {
        ...assessment,
        assessor: assessment.user.first_name
          ? `${assessment?.user?.first_name} ${assessment?.user?.last_name}`
          : assessment?.user?.email,
        instance: assessment?.assessment_instance_type?.key,
        action: assessment.id,
      }
    }) as GridRowModel[]
  }, [assessments])

  const assessmentColumns = useMemo(() => {
    const getRecommendationValue = (recommendation: any) => {
      if (!recommendation) {
        return getMessage('label.field.not.available')
      }
      return getMessage(`label.recommendation.${recommendation}`)
    }

    const columns: GridColDef[] = [
      {
        field: 'assessor',
        headerName: getMessage('label.assessment.assessor'),
        flex: 1,
      },
      {
        field: 'instance',
        headerName: getMessage('label.instance'),
        flex: 1,
        renderCell: ({ value }) => getMessage(`${value}`),
      },
      {
        field: 'recommendation',
        headerName: getMessage('label.recommendation'),
        flex: 1,
        renderCell: ({ value }) => getRecommendationValue(value),
      },
      {
        field: 'action',
        headerName: getMessage('label.actions'),
        headerAlign: 'right',
        flex: 0.3,
        align: 'right',
        sortable: false,
        renderCell: ({ value }) => {
          return (
            <IconButton color="primary" onClick={(event) => handleDeleteAssessment(event, parseInt(value as string))}>
              <DeleteIcon />
            </IconButton>
          )
        },
      },
    ]

    return columns
  }, [getMessage])

  const handleDeleteAssessment = (event: any, assessmentId: number) => {
    setAssessmentDeleteConfirmationOpen(true)
    setAssessmentIdToBeDeleted(assessmentId)
    event.stopPropagation()
  }

  const handleCancelDeleteAssessment = () => {
    setAssessmentDeleteConfirmationOpen(false)
    setAssessmentIdToBeDeleted(null)
  }

  const handleDeleteAssessmentConfirmed = async () => {
    setAssessmentDeleteConfirmationOpen(false)
    const { error } = await urqlClient
      .mutation<{ delete_factsheet_assessment: Mutation_Root['delete_factsheet_assessment'] }>(
        deleteFactsheetAssessmentMutationQuery,
        { assessment_id: assessmentIdToBeDeleted },
      )
      .toPromise()
    if (error) {
      notificationService.operationFailed()
    } else {
      notificationService.deleteSuccessful()
      refetch()
    }
  }

  const handleRowClick = (item: GridRowParams) => {
    const assessmentId = item?.row?.id
    navigate(ROUTES.FactsheetApplicationAssessmentDetailsRoot.params({ factsheetId, assessmentId }))
  }

  return (
    <>
      <DataGridProStyled
        initialState={{ columns: { columnVisibilityModel: { action: canDeleteAssessment } } }}
        apiRef={apiRef}
        rows={assessmentRows}
        columns={assessmentColumns}
        localeText={gridTranslations}
        onRowClick={(props) => handleRowClick(props)}
        autoHeight
        disableRowSelectionOnClick
        disableColumnReorder
        disableColumnFilter
        disableColumnPinning
        hideFooter
        disableColumnSelector
      />

      <ConfirmationModalDialog
        open={assessmentDeleteConfirmationOpen}
        onCancel={handleCancelDeleteAssessment}
        onConfirm={handleDeleteAssessmentConfirmed}
      >
        {getMessage('label.delete.confirm')}
      </ConfirmationModalDialog>
    </>
  )
}
