import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { Outlet, useLocation, useParams } from 'react-router'
import { Location } from 'history'
import { NestedRoute, Route, SecondLevelRoute } from 'src/routing/routing'
import { ROUTES } from 'src/routing/routes'
import { SecondLevelNavigationContainer } from 'src/shared/layout/SecondLevelNavigationContainer'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { SecondLevelNavigation } from 'src/routing/SecondLevelNavigation'
import { usePermissionsForFactsheetAssessmentIndexPage } from 'src/service/security/PermissionHook'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'

const resolveActiveRoute = (location: Location, factsheetId: string, assessmentId: string): SecondLevelRoute => {
  const nested = ROUTES.FactsheetApplicationAssessmentDetailsRoot.nested as NestedRoute

  const activeRoute = Object.keys(nested).find(
    (key) =>
      location.pathname.endsWith(nested[key].path) ||
      (ROUTES.FactsheetApplicationAssessmentDetailsRoot.params({ factsheetId, assessmentId }) === location.pathname &&
        nested[key].primary),
  ) as string
  return nested[activeRoute] as SecondLevelRoute
}

export const FactsheetAssessmentDetailsIndexPage = (): ReactElement => {
  const { factsheetId, assessmentId } = useParams()
  const factsheetIdNumber = parseInt(factsheetId as string)
  const location = useLocation()

  const { loading, canView } = usePermissionsForFactsheetAssessmentIndexPage(factsheetIdNumber)

  const routeParams = useMemo(
    () => ({
      factsheetId: factsheetId as string,
      assessmentId: assessmentId as string,
    }),
    [factsheetId, assessmentId],
  )

  const [activeRoute, setActiveRoute] = useState<SecondLevelRoute>(() => {
    return resolveActiveRoute(location, routeParams.factsheetId, routeParams.assessmentId)
  })

  useEffect(() => {
    const activeRoute = resolveActiveRoute(location, routeParams.factsheetId, routeParams.assessmentId)
    setActiveRoute(activeRoute)
  }, [location, routeParams])

  const showNavigation = activeRoute && !activeRoute.hideNavigation

  if (!loading && !canView) {
    return <NotAuthorized />
  }

  return (
    <SecondLevelNavigationContainer $showNavigation={showNavigation}>
      {!loading && showNavigation && (
        <PageLayout>
          <SecondLevelNavigation
            activeRoute={activeRoute}
            route={ROUTES.FactsheetApplicationAssessmentDetailsRoot as unknown as Route}
            baseUrl={ROUTES.FactsheetApplicationAssessmentDetailsRoot.params(routeParams)}
          />
        </PageLayout>
      )}

      <Outlet />
    </SecondLevelNavigationContainer>
  )
}
