import { Box, DialogActions, DialogContent, Stack } from '@mui/material'
import { ReactElement, useState } from 'react'
import { Form } from 'react-final-form'
import { Factsheet, Mutation_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { copyFactsheet } from 'src/screens/factsheets/factsheet/factsheetQueries'
import { PrimaryButton, SecondaryButton } from 'src/shared/button/Buttons'
import { ALL_MODULES, LEVEL, LEVEL_TYPE, MODULE_TYPES, TEXT_LENGTH } from 'src/shared/constants/constants'
import { Option } from 'src/shared/form/control'
import { AutoCompleteField } from 'src/shared/form/control/AutoCompleteField'
import { MultiSelectField } from 'src/shared/form/control/MultiSelectField'
import { TextField } from 'src/shared/form/control/TextField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { createDecorators } from 'src/shared/form/utils/decorators'
import { composeValidators, maxChar, required } from 'src/shared/form/validation/validators'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useUserLocale } from 'src/user/UserContext'
import { useClient } from 'urql'

interface CopyFactsheetValues {
  factsheetId: number
  title: string
  level: LEVEL_TYPE
  modules: MODULE_TYPES[]
  version: string
}

const createValuesFromFactsheet = (factsheet: Factsheet): CopyFactsheetValues => {
  const formValues: CopyFactsheetValues = {
    factsheetId: factsheet.id,
    title: factsheet.title,
    modules: factsheet.modules ?? [],
    level: factsheet.level as LEVEL_TYPE,
    version: factsheet.version,
  }

  return formValues
}

interface CopyFactsheetFormDialogProps {
  factsheet: Factsheet
  onCancel: () => void
  onSuccess: (factsheetId: number) => void
}

export const CopyFactsheetFormDialog = ({
  factsheet,
  onCancel,
  onSuccess,
}: CopyFactsheetFormDialogProps): ReactElement => {
  const decorators = createDecorators()
  const urqlClient = useClient()
  const notificationService = useNotificationService()

  const { getMessage } = useMessageSource()
  const locale = useUserLocale()

  const [initialValues, setInitialValues] = useState<CopyFactsheetValues>(() => createValuesFromFactsheet(factsheet))

  const autocompleteFieldOptionsFactory = (obj: any, labelBase: string) => {
    return Object.keys(obj).map((x) => {
      return {
        label: getMessage(labelBase + '.' + x),
        value: x,
      }
    })
  }
  const handleSubmitLocal = async (values: CopyFactsheetValues): Promise<any> => {
    const { data, error } = await urqlClient
      .mutation<{ copyFactsheet: Mutation_Root['copyFactsheet'] }, CopyFactsheetValues & { language: string }>(
        copyFactsheet,
        {
          factsheetId: values.factsheetId,
          level: values.level,
          modules: Utils.sortModules(values.modules).map((item) => item),
          title: values.title,
          language: locale,
          version: values.version,
        },
      )
      .toPromise()

    if (error || data?.copyFactsheet?.status === 'FAILURE') {
      notificationService.operationFailed()
    } else if (data?.copyFactsheet?.factsheetId) {
      setInitialValues(values)
      notificationService.changesSaved()
      onSuccess(data.copyFactsheet.factsheetId)
    }
  }

  const levelOptions = autocompleteFieldOptionsFactory(LEVEL, 'label.level')
  const moduleOptions: Option[] = ALL_MODULES.map((module) => {
    return {
      label: getMessage(`label.module.description.${module}`),
      value: module,
    }
  })

  return (
    <>
      <Box>
        {initialValues && (
          <Form<CopyFactsheetValues>
            initialValues={initialValues}
            onSubmit={handleSubmitLocal}
            decorators={decorators}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} noValidate>
                <DialogContent>
                  <Stack spacing={2}>
                    <TextField
                      required
                      label={getMessage('label.title')}
                      name="title"
                      validate={composeValidators(required(), maxChar(TEXT_LENGTH.M))}
                    />

                    <AutoCompleteField
                      required
                      label={getMessage('label.level')}
                      name="level"
                      options={levelOptions}
                      disabled
                      validate={required()}
                    />

                    <TextField
                      required
                      label={getMessage('label.factsheet.version')}
                      name="version"
                      placeholder={getMessage('label.factsheet.version.placeholder')}
                      validate={composeValidators(required(), maxChar(TEXT_LENGTH.S))}
                    />

                    <MultiSelectField
                      required
                      name="modules"
                      label={getMessage('label.modules')}
                      options={moduleOptions}
                      validate={required()}
                    />

                    <DirtyFormSpy />
                  </Stack>
                </DialogContent>
                <DialogActions>
                  <SecondaryButton messageKey="button.close" onClick={onCancel} />
                  <PrimaryButton messageKey="button.submit" type="submit" />
                </DialogActions>
              </form>
            )}
          />
        )}
      </Box>
    </>
  )
}
