import React, { ReactElement, useEffect, useState } from 'react'
import { LEVEL, LEVEL_TYPE } from 'src/shared/constants/constants'
import { Query_Root } from 'src/@types/graphql'
import { useParams } from 'react-router'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useClient, useQuery } from 'urql'
import {
  getCriteriaConfigByLevelQuery,
  getFactsheetAssessmentLevelCommentsQuery,
  getFactsheetAssessorForAssessment,
} from 'src/screens/factsheets/application/assessment/factsheetAssessmentQueries'
import {
  createGroupsAndInitialData,
  CriteriaFormValues,
  CriteriaGroups,
} from 'src/screens/factsheets/application/assessment/details/common/FactsheetAssessmentUtils'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { EditButton } from 'src/shared/button/Buttons'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { Section } from 'src/shared/presentation/Section'
import { Box, Card, CardContent, Stack } from '@mui/material'
import { Form } from 'react-final-form'
import { get } from 'lodash'
import { useUserLocale } from 'src/user/UserContext'
import { FactsheetAssessmentConfigTable } from 'src/screens/factsheets/application/assessment/details/common/FactsheetAssessmentConfigTable'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { usePermissionsForFactsheetAssessmentDetails } from 'src/service/security/PermissionHook'
import { ROUTES } from 'src/routing/routes'
import { HtmlRenderer } from 'src/shared/presentation/HtmlRenderer'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { FactsheetExportMenu } from 'src/shared/menu/FactsheetExportMenu'
import { getFactsheetLevelCommentEditPathKey, getFactsheetLevelEditPathKey } from 'src/routing/routing-utils'

interface Props {
  level: LEVEL_TYPE
}

export const FactsheetAssessmentDetailsLevelsPage = ({ level }: Props): ReactElement => {
  const { getMessage } = useMessageSource()
  const language = useUserLocale()

  const { factsheetId, assessmentId } = useParams()
  const assessmentIdNumber = parseInt(assessmentId as string)

  const navigate = useDelayedNavigate()
  const urqlClient = useClient()
  const notificationService = useNotificationService()

  const { canEdit, loading } = usePermissionsForFactsheetAssessmentDetails(assessmentIdNumber)

  const [criteriaGroups, setCriteriaGroups] = useState<CriteriaGroups | undefined>()
  const [initialValues, setInitialValues] = useState<CriteriaFormValues | undefined>()
  const [comment, setComment] = useState<string | null>(null)

  const [{ data }] = useQuery<{
    factsheet_assessment: Query_Root['factsheet_assessment']
  }>({
    query: getFactsheetAssessorForAssessment,
    variables: { assessmentId: assessmentIdNumber },
  })

  const factsheetAssessment = data?.factsheet_assessment?.[0]
  const firstName = factsheetAssessment?.user?.first_name
  const lastName = factsheetAssessment?.user?.last_name
  const email = factsheetAssessment?.user?.email
  const assessmentUserFullName = !firstName ? email : `${firstName} ${lastName}`

  useEffect(() => {
    const initData = async () => {
      const { data } = await urqlClient
        .query<{
          factsheet_assessment_criteria_selection: Query_Root['factsheet_assessment_criteria_selection']
          criteria_group_config: Query_Root['criteria_group_config']
        }>(getCriteriaConfigByLevelQuery, {
          level: level,
          assessmentId: assessmentId,
        })
        .toPromise()

      const { data: factsheetLevelCommentData } = await urqlClient
        .query<
          { factsheet_assessment_by_pk: Query_Root['factsheet_assessment_by_pk'] },
          { id: number; isIntervention: boolean; isCommunication: boolean; isNetwork: boolean; isPolicy: boolean }
        >(getFactsheetAssessmentLevelCommentsQuery, {
          id: parseInt(assessmentId as string),
          isIntervention: level === LEVEL.INTERVENTION,
          isCommunication: level === LEVEL.COMMUNICATION,
          isNetwork: level === LEVEL.NETWORK,
          isPolicy: level === LEVEL.POLICY,
        })
        .toPromise()

      // process groups and criteria selections
      if (data) {
        const selectedQualities = data.factsheet_assessment_criteria_selection
        const criteriaGroupedConfigs = data.criteria_group_config

        const res = createGroupsAndInitialData(selectedQualities, criteriaGroupedConfigs)
        if (res != null) {
          setCriteriaGroups(res.criteriaGroups)
          setInitialValues(res.initialValues)
        }
      }

      // process comments
      if (factsheetLevelCommentData?.factsheet_assessment_by_pk) {
        let comment: string | null | undefined
        switch (level) {
          case LEVEL.INTERVENTION:
            comment = factsheetLevelCommentData.factsheet_assessment_by_pk.intervention_comment
            break
          case LEVEL.COMMUNICATION:
            comment = factsheetLevelCommentData.factsheet_assessment_by_pk.communication_comment
            break
          case LEVEL.NETWORK:
            comment = factsheetLevelCommentData.factsheet_assessment_by_pk.network_comment
            break
          case LEVEL.POLICY:
            comment = factsheetLevelCommentData.factsheet_assessment_by_pk.policy_comment
            break
          default:
            comment = null
        }
        setComment(comment || null)
      }

      if (data === undefined || factsheetLevelCommentData?.factsheet_assessment_by_pk === undefined) {
        notificationService.operationFailed()
      }
    }

    initData()
  }, [urqlClient, assessmentId, factsheetId, level])

  const onBackHandler = () => {
    navigate(ROUTES.FactsheetApplicationAssessment.params({ factsheetId }))
  }

  const onEditHandler = () => {
    const levelRoute = ROUTES.FactsheetApplicationAssessmentDetailsRoot.nested[getFactsheetLevelEditPathKey(level)]

    if (levelRoute?.params) {
      navigate(levelRoute.params({ factsheetId, assessmentId }))
    }
  }

  const onEditCommentHandler = () => {
    const levelRoute =
      ROUTES.FactsheetApplicationAssessmentDetailsRoot.nested[getFactsheetLevelCommentEditPathKey(level)]

    if (levelRoute?.params) {
      navigate(levelRoute.params({ factsheetId, assessmentId }))
    }
  }

  return (
    <ScreenLayout
      title={`${getMessage('label.navigation.application.assessment')}: ${assessmentUserFullName || ''}`}
      onBack={onBackHandler}
      actions={<FactsheetExportMenu />}
    >
      {!loading && (
        <PageLayout>
          <>
            <Section
              id="assessment-criteria"
              title={getMessage(`label.${level.toLowerCase()}.criteria`)}
              actionButton={<EditButton origin="content" color="primary" hidden={!canEdit} onClick={onEditHandler} />}
              helpAndInstructions={
                <HelpAndInstructions labelKey={`label.help.assessment.${level.toLowerCase()}.criteria`} />
              }
              optional={undefined}
            >
              <Box mt={2}>
                {criteriaGroups && initialValues && (
                  <Form<CriteriaFormValues>
                    initialValues={initialValues}
                    onSubmit={() => {}}
                    render={({ handleSubmit, values }) => {
                      return (
                        <form onSubmit={handleSubmit} noValidate>
                          <Stack spacing={5}>
                            {Object.values(criteriaGroups)
                              .sort((a, b) => a?.[0].sort_number - b?.[0].sort_number)
                              .map((criteriaSelections) => (
                                <FactsheetAssessmentConfigTable
                                  key={criteriaSelections[0].id}
                                  values={values}
                                  rows={criteriaSelections}
                                  groupTitle={get(criteriaSelections[0].cgNames, language, '')}
                                  mode="view"
                                />
                              ))}
                          </Stack>
                          <DirtyFormSpy />
                        </form>
                      )
                    }}
                  />
                )}
              </Box>
            </Section>

            {/* Comment section */}
            <Section
              id="comment"
              title={getMessage('label.summary.comments')}
              helpAndInstructions={
                <HelpAndInstructions labelKey={`label.help.assessment.${level.toLowerCase()}.comment`} />
              }
              actionButton={
                <EditButton origin="content" color="primary" hidden={!canEdit} onClick={onEditCommentHandler} />
              }
              optional
            >
              {comment && (
                <Card>
                  <CardContent>
                    <HtmlRenderer html={comment} />
                  </CardContent>
                </Card>
              )}
            </Section>
          </>
        </PageLayout>
      )}
    </ScreenLayout>
  )
}
