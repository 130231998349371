import { ReactElement, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Maybe, Mutation_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { saveFactsheetApplicationSummaryComment } from 'src/screens/factsheets/application/details/summary/factsheetApplicationSummaryQueries'
import { SummaryCommentEditForm, SummaryCommentFormValues } from 'src/screens/shared/common/SummaryCommentEditForm'
import { usePermissionsForFactsheetApplication } from 'src/service/security/PermissionHook'
import { SaveAndBackButton, SaveButton } from 'src/shared/button/Buttons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useClient } from 'urql'

type originType = 'SAVE' | 'SAVE_AND_BACK'

type SummaryComment = {
  comment: Maybe<string>
  version: Maybe<number>
}

export const FactsheetApplicationSummaryCommentEditPage = (): ReactElement => {
  const { factsheetId } = useParams()
  const factsheet_id = parseInt(factsheetId as string)

  const { getMessage } = useMessageSource()
  const notificationService = useNotificationService()
  const navigate = useDelayedNavigate()
  const urqlClient = useClient()
  const originRef = useRef<originType>('SAVE')

  const [applicationSummaryComment, setApplicationSummaryComment] = useState<SummaryComment>()

  const {
    loading,
    canEdit,
    metadata: { factsheetApplicationDecision },
  } = usePermissionsForFactsheetApplication(factsheet_id)

  useEffect(() => {
    const applicationSummaryComment = {
      comment: factsheetApplicationDecision?.application_comment ?? null,
      version: factsheetApplicationDecision?.version ?? null,
    } as SummaryComment
    setApplicationSummaryComment(applicationSummaryComment)
  }, [factsheetApplicationDecision])

  const onSubmit = (origin: originType) => (_: any) => {
    // trigger submit event
    if (originRef) {
      originRef.current = origin
    }
    document.getElementById('edit-form')?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const updateHandler = async (values: SummaryCommentFormValues) => {
    const factsheetApplicationDecision = {
      factsheet_id: factsheet_id,
      application_comment: values.comment,
    }
    const version = applicationSummaryComment?.version ?? 0

    const { error, data } = await urqlClient
      .mutation<{ insert_factsheet_application_decision: Mutation_Root['insert_factsheet_application_decision'] }>(
        saveFactsheetApplicationSummaryComment,
        { factsheetId: factsheet_id, version, factsheetApplicationDecision },
      )
      .toPromise()

    if (error || data?.insert_factsheet_application_decision?.affected_rows !== 1) {
      notificationService.operationFailed()
    } else {
      const newVersion = data!.insert_factsheet_application_decision?.returning[0].version
      const newApplicationSummaryCommentValues = { ...values, version: newVersion } as SummaryComment
      setApplicationSummaryComment(newApplicationSummaryCommentValues)
      notificationService.changesSaved()
      if (originRef.current === 'SAVE_AND_BACK') {
        navigate(ROUTES.FactsheetApplicationDetailsRoot.nested.Summary.params({ factsheetId }))
      }
    }
  }

  if (!loading && !canEdit) {
    return <NotAuthorized />
  }

  return (
    <ScreenLayout
      title={getMessage('label.title.edit.comments')}
      onBack={() => {
        navigate(ROUTES.FactsheetApplicationDetailsRoot.nested.Summary.params({ factsheetId }))
      }}
      hasSecondLevelNavigation={false}
      actions={
        <>
          <SaveAndBackButton origin="header" onClick={onSubmit('SAVE_AND_BACK')} />
          <SaveButton origin="header" onClick={onSubmit('SAVE')} />
        </>
      }
    >
      <PageLayout>
        <>
          {!loading && applicationSummaryComment && (
            <>
              <HelpAndInstructions labelKey={`label.help.application.summary.comments.factsheet`} defaultExpansion />
              <SummaryCommentEditForm comment={applicationSummaryComment.comment} onSave={updateHandler} />
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
