import { Box, Chip, Divider, List, ListSubheader, Stack, Typography } from '@mui/material'
import { ReactElement } from 'react'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ListItemLink } from 'src/routing/ListItemLink'
import { isPathAllowedForRole } from 'src/routing/routing-utils'
import { BASE_URL, PROCESS, PROCESS_TYPE } from 'src/shared/constants/constants'
import { DashboardIcon } from 'src/shared/icons/Icons'
import { S } from 'src/shared/styled/S'
import { muiTheme } from 'src/theme/theme'
import { useUser } from 'src/user/UserContext'
import styled from 'styled-components/macro'

export interface SidebarGroupObject {
  groupMessageKey: string
  list: SidebarGroupListObject[]
}

export interface SidebarGroupListObject {
  listMessageKey: string
  href: string
  routePath: string
}

interface Props {
  title?: string
  entityId?: string
  fundingRoundName?: string
  status?: string
  routes: SidebarGroupObject[]
  hideDetailedInformation?: boolean
  process?: PROCESS_TYPE | 'SUCCESS_FACTORS'
  home?: {
    url: string
    label: string
  }
  loading?: boolean
  canton?: string
}

const SideBarList = styled(List)`
  width: 100%;
  max-width: 360px;
  background-color: ${({ theme }) => theme.colors.common.white};
  margin-top: ${({ theme }) => theme.spacing(2)};
`

const ListSubheaderStyled = styled(ListSubheader)`
  color: ${({ theme }) => theme.colors.text.disabled};
  height: 24px;
  display: flex;
  align-items: center;
  padding-left: ${({ theme }) => theme.spacing(3)};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`

const DetailedInfoWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: ${({ theme }) => theme.spacing(2)};
`

const BoxStyled = styled(Box)<{ $showMargin?: boolean }>`
  // Vertical scroll of the sidebar for screens smaller in the y-axis
  overflow-y: auto;
  margin-bottom: ${({ $showMargin }) => ($showMargin ? 'calc(3rem + 1px)' : 'initial')};
`

export const Sidebar = ({
  title,
  entityId,
  fundingRoundName,
  status,
  routes,
  hideDetailedInformation,
  process,
  home,
  loading,
  canton,
}: Props): ReactElement => {
  const { getMessage } = useMessageSource()
  const { user } = useUser()
  const userRoles = user.roles

  const resolveEntityLabel = (process: PROCESS_TYPE | 'SUCCESS_FACTORS' | undefined) => {
    switch (process) {
      case PROCESS.PF_KAP:
      case PROCESS.PF_PGV:
        return `${getMessage('label.project.id')}: ${entityId}`
      case PROCESS.KAP:
        return `${getMessage('label.program.id')}: ${entityId}`
      case PROCESS.FACTSHEET:
        return `${getMessage('label.factsheet.id')}: ${entityId}`
      case 'SUCCESS_FACTORS':
        return `${getMessage('label.canton')}: ${canton}`
      default:
        return ''
    }
  }

  return (
    <BoxStyled $showMargin={home?.url !== BASE_URL.REPORTING}>
      {home && (
        <>
          <S.ListItem.SidebarHome to={home.url} icon={<DashboardIcon />} end primary={home.label} />
          <Divider />
        </>
      )}

      {!hideDetailedInformation && (
        <>
          <DetailedInfoWrapper py={2} pl={3} pr={2} sx={{ visibility: loading ? 'hidden' : 'visible' }}>
            <Typography
              variant="subtitle2"
              color="primary"
              // Fixed height is set so there won't be content jumping after project data is loaded
              sx={{ minHeight: `calc(${muiTheme.typography.subtitle2.lineHeight} * 2)` }}
            >
              {title !== undefined ? title.toUpperCase() : `${getMessage('label.not.available')}`.toUpperCase()}
            </Typography>

            <Box>
              <Typography variant="subtitle2" color="textSecondary">
                {resolveEntityLabel(process)}
              </Typography>
              {(process === PROCESS.PF_KAP || process === PROCESS.PF_PGV || process === PROCESS.FACTSHEET) && (
                <Typography variant="body2" sx={{ color: muiTheme.palette.text.disabled }}>
                  {getMessage('label.funding.round')}:{' '}
                  {fundingRoundName ? fundingRoundName : `${getMessage('label.not.available')}`}
                </Typography>
              )}
            </Box>

            {process !== 'SUCCESS_FACTORS' && (
              <Chip
                label={`${getMessage('label.project.status')}: ${
                  status ? getMessage(`label.project.status.${status}`) : `${getMessage('label.not.available')}`
                }`}
                size="small"
                variant="outlined"
              />
            )}
          </DetailedInfoWrapper>
          <Divider />
        </>
      )}

      {routes.map((routeGroup) =>
        routeGroup.list.filter(({ routePath }) => isPathAllowedForRole(routePath, userRoles)).length > 0 ? (
          <SideBarList
            key={routeGroup.groupMessageKey}
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheaderStyled>
                <Typography variant="caption">{getMessage(routeGroup.groupMessageKey)?.toUpperCase()}</Typography>
              </ListSubheaderStyled>
            }
          >
            <Stack spacing={1}>
              {routeGroup.list.map(({ href, routePath, listMessageKey }) =>
                isPathAllowedForRole(routePath, userRoles) ? (
                  <ListItemLink key={listMessageKey} to={href} primary={getMessage(listMessageKey)} />
                ) : null,
              )}
            </Stack>
          </SideBarList>
        ) : null,
      )}
    </BoxStyled>
  )
}
