import { ListItemText } from '@mui/material'
import { useRef, useState } from 'react'
import { useParams } from 'react-router'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { EXPORT_FORMAT, PROCESS_TYPE } from 'src/shared/constants/constants'
import { ExportMenu } from 'src/shared/menu/ExportMenu'
import { S } from 'src/shared/styled/S'
import { useUserLocale } from 'src/user/UserContext'
import { LoadingMenuItem } from 'src/shared/menu/item/LoadingMenuItem'
import { downloadFileByUrl } from 'src/shared/utils/BlobUtils'

interface Props {
  process: PROCESS_TYPE | undefined
}

export const RoundManagementExportMenu = ({ process }: Props) => {
  const { getMessage } = useMessageSource()
  const language = useUserLocale()
  const { roundId } = useParams()

  const exportMenuRef = useRef<{ closeMenu: () => void }>({ closeMenu: () => {} })

  const [loadingExport, setLoadingExport] = useState(false)

  const handleTriageListExport = (processParam: PROCESS_TYPE) => {
    setLoadingExport(true)
    downloadFileByUrl(`api/reporting/triage/${roundId}/export?lang=${language}&process=${processParam}`)
      .then(() => exportMenuRef.current.closeMenu())
      .finally(() => setLoadingExport(false))
  }

  return (
    process && (
      <ExportMenu ref={exportMenuRef}>
        <LoadingMenuItem
          loading={loadingExport}
          disabled={loadingExport}
          onClick={() => handleTriageListExport(process)}
        >
          <ListItemText>{getMessage('label.export.round.management.triage.list')}</ListItemText>
          <S.Typography.ExportFormat>({EXPORT_FORMAT.EXCEL})</S.Typography.ExportFormat>
        </LoadingMenuItem>
      </ExportMenu>
    )
  )
}
