import { DialogActions, DialogContent, Stack } from '@mui/material'
import { GridRowSelectionModel, useGridApiRef } from '@mui/x-data-grid-pro'
import { SyntheticEvent, useState } from 'react'
import { Mutation_Root } from 'src/@types/graphql'
import { FactsheetFilterModalCard } from 'src/screens/shared/factsheet-relation/FactsheetFilterModalCard'
import { updateProjectBaseFactsheetId } from 'src/screens/pf-kap/project/details/basic-information/basicInformationQueries'
import { RelatedFactsheetsGrid } from 'src/screens/shared/factsheet-relation/RelatedFactsheetsGrid'
import { useFetchRelatedFactsheets } from 'src/screens/shared/factsheet-relation/useFetchRelatedFactsheets'
import { PrimaryButton, SecondaryButton } from 'src/shared/button/Buttons'
import { LEVELS, MODULE_TYPES } from 'src/shared/constants/constants'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useClient } from 'urql'

interface Props {
  onCancel: () => void
  onSuccess: () => void
  projectBaseId: number | null
  modules: MODULE_TYPES[]
}

export const PfKapLinkRelatedFactsheetModalDialog = ({ onSuccess, onCancel, projectBaseId, modules }: Props) => {
  const gridRef = useGridApiRef()
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([])
  const urqlClient = useClient()
  const notificationService = useNotificationService()

  const { filteredFactsheets, fetchData } = useFetchRelatedFactsheets({ modules, levels: LEVELS })

  const onSubmit = async (event: SyntheticEvent) => {
    event.preventDefault()
    const rows = gridRef.current.getSelectedRows()
    const selectedFactsheet = rows.get(selectionModel[0])

    const { data, error } = await urqlClient
      .mutation<{
        update_project_base: Mutation_Root['update_project_base']
      }>(updateProjectBaseFactsheetId, {
        projectBaseId,
        factsheetId: selectedFactsheet?.id,
      })
      .toPromise()

    if (error || data?.update_project_base?.affected_rows !== 1) {
      notificationService.operationFailed()
    } else {
      notificationService.changesSaved()
      onSuccess()
    }
  }

  return (
    <>
      <DialogContent>
        {filteredFactsheets && (
          <>
            <HelpAndInstructions labelKey="label.help.link.related.factsheet" defaultExpansion />
            <Stack spacing={2}>
              <FactsheetFilterModalCard
                modules={modules as unknown as MODULE_TYPES[]}
                levels={LEVELS}
                fetchData={fetchData}
              />
              <RelatedFactsheetsGrid
                factsheets={filteredFactsheets}
                selectionModel={selectionModel}
                setSelectionModel={setSelectionModel}
                gridRef={gridRef}
              />
            </Stack>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <SecondaryButton messageKey={'button.cancel'} onClick={onCancel} />
        <PrimaryButton messageKey={'button.link.factsheet'} disabled={selectionModel.length === 0} onClick={onSubmit} />
      </DialogActions>
    </>
  )
}
