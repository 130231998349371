import { GLOBAL_USER_ROLES, PROCESS_TYPE, PROCESS, USER_STATUS } from 'src/shared/constants/constants'
import { User_Roles_Bool_Exp } from 'src/@types/graphql'

export const ASSESSMENT_TYPE = {
  INTERNAL: 'INTERNAL',
  EXTERNAL: 'EXTERNAL',
} as const

export type ASSESSMENT_TYPE_TYPE = keyof typeof ASSESSMENT_TYPE

export const ASSESSMENT_STATUS = {
  OPEN: 'OPEN',
  DONE: 'DONE',
} as const

export type ASSESSMENT_STATUS_TYPE = keyof typeof ASSESSMENT_STATUS

export const RECOMMENDATION_OR_DECISION = {
  EXCLUSION: 'EXCLUSION',
  REJECT: 'REJECT',
  ACCEPT: 'ACCEPT',
} as const

export const CRITERIA_TYPE = {
  QUALITY: 'QUALITY',
  EXCLUSION: 'EXCLUSION',
} as const

export type CRITERIA_TYPE_TYPE = keyof typeof CRITERIA_TYPE

export interface AddProjectAssessmentType {
  project_base_id: number
  assessor_type: ASSESSMENT_TYPE_TYPE
  assessor_id: number
  instance_id: number
  status: ASSESSMENT_STATUS_TYPE
}

export const internalGfchAssessorsWhereClause = (process: PROCESS_TYPE): User_Roles_Bool_Exp => {
  const pfKapGfchRolesWhereClause: User_Roles_Bool_Exp = {
    _or: [
      { role: { _eq: GLOBAL_USER_ROLES['PD-GFCH_PF-KAP_CONTRIBUTOR'] } },
      { role: { _eq: GLOBAL_USER_ROLES['PD-GFCH_PF-KAP_READER'] } },
    ],
    user: { status: { _eq: USER_STATUS.ACTIVE } },
  }

  const pfPgvGfchRolesWhereClause: User_Roles_Bool_Exp = {
    _or: [
      { role: { _eq: GLOBAL_USER_ROLES['PD-GFCH_PF-PGV_CONTRIBUTOR'] } },
      { role: { _eq: GLOBAL_USER_ROLES['PD-GFCH_PF-PGV_READER'] } },
    ],
    user: { status: { _eq: USER_STATUS.ACTIVE } },
  }
  return process === PROCESS.PF_KAP ? pfKapGfchRolesWhereClause : pfPgvGfchRolesWhereClause
}
