import { Button, Card, CardContent, Divider, Stack } from '@mui/material'
import { FormApi } from 'final-form'
import { ReactElement, useContext, useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { FactsheetRelatedSearchInput, FeaturesFilterInput } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { FilterCardErrorText } from 'src/screens/shared/common/filter-card/FilterCardErrorText'
import { FeaturesFilter } from 'src/screens/shared/common/filter-card/filters/features/FeaturesFilter'
import { SearchFilter } from 'src/screens/shared/common/filter-card/filters/SearchFilter'
import { SearchButton } from 'src/shared/button/Buttons'
import { LEVEL_TYPE, MODULE_TYPES } from 'src/shared/constants/constants'
import { FeaturesFilterModel } from 'src/shared/constants/filter-constants'
import { FEATURE_OPERATOR } from 'src/shared/constants/reporting-constants'
import { ResetIcon } from 'src/shared/icons/Icons'
import { S } from 'src/shared/styled/S'
import { UserContext } from 'src/user/UserContext'

const FACTSHEET_SEARCH_FIELDS = {
  SEARCH: 'search',
  MODULES: 'modules',
  LEVEL: 'level',
  FEATURES: 'features',
  LANGUAGE: 'language',
}

interface FactsheetsFilterForm {
  search: string
  modules: MODULE_TYPES[]
  levels: LEVEL_TYPE[]
  features: FeaturesFilterModel
}

interface Props {
  levels: LEVEL_TYPE[]
  modules: MODULE_TYPES[]
  fetchData: (search: FactsheetRelatedSearchInput) => void
}

export const FactsheetFilterModalCard = ({ levels, modules, fetchData }: Props): ReactElement => {
  const { getMessage } = useMessageSource()
  const { user } = useContext(UserContext)

  const [beingSearched, setBeingSearched] = useState(false)
  const [featuresInput, setFeaturesInput] = useState<FeaturesFilterModel>()
  const [resetFilterState, setResetFilterState] = useState<boolean>(false)

  const defaultFormValue: FactsheetsFilterForm = useMemo(
    () => ({
      search: '',
      modules: modules,
      levels: levels as LEVEL_TYPE[],
      features: {
        betweenFeatureTypesOperator: FEATURE_OPERATOR.AND,
        selectedFeatures: [],
        withinFeatureTypeOperator: FEATURE_OPERATOR.AND,
      },
    }),
    [modules, levels],
  )

  const [initialValues, setInitialValues] = useState<FactsheetsFilterForm>()

  let formValid = false

  const featureConfigWhere = useMemo(
    () => ({
      levels: { _neq: [] },
    }),
    [],
  )

  const mapFormValuesToSearchInput = (values: FactsheetsFilterForm): FactsheetRelatedSearchInput => {
    return {
      search: values.search ?? null,
      modules: values.modules,
      levels: values.levels,
      features: values.features as FeaturesFilterInput,
      published: true,
      language: user.language,
    }
  }

  useEffect(() => {
    setInitialValues(defaultFormValue)
  }, [defaultFormValue])

  const handleSubmitSearch = async (values: FactsheetsFilterForm) => {
    setBeingSearched(true)

    const copiedValues: FactsheetsFilterForm = {
      ...values,
      features: featuresInput as FeaturesFilterModel,
    }

    const search = mapFormValuesToSearchInput(copiedValues)
    await fetchData(search)

    setBeingSearched(false)
  }

  const onResetFilters = async (form: FormApi<FactsheetsFilterForm>) => {
    form.restart()
    // Trigger the function that renders UI interaction states of individual filters
    setInitialValues(defaultFormValue)
    await setResetFilterState(true)
    setResetFilterState(false)
    await fetchData(mapFormValuesToSearchInput(defaultFormValue))
  }

  return (
    <>
      {initialValues && (
        <Card>
          <Form<FactsheetsFilterForm>
            initialValues={initialValues}
            onSubmit={handleSubmitSearch}
            render={({ form, handleSubmit, errors, valid, initialValues }) => {
              formValid = valid

              const searchError = !!errors?.[FACTSHEET_SEARCH_FIELDS.SEARCH]

              const fields = {
                search: {
                  name: FACTSHEET_SEARCH_FIELDS.SEARCH,
                  label: 'label.search',
                  error: searchError,
                },
              }

              return (
                <form id="factsheets-filter-form" onSubmit={handleSubmit} noValidate>
                  <CardContent>
                    <Stack spacing={1} divider={<Divider />}>
                      <SearchFilter
                        name={FACTSHEET_SEARCH_FIELDS.SEARCH}
                        error={searchError}
                        placeholder={getMessage('label.factsheets.filter.search.placeholder')}
                      />
                      <FeaturesFilter
                        featureConfigWhere={featureConfigWhere}
                        setFeaturesInput={setFeaturesInput}
                        isReset={resetFilterState}
                        initialState={initialValues.features}
                      />
                    </Stack>
                  </CardContent>
                  <S.FilterCard.Actions>
                    <FilterCardErrorText fields={fields} formErrors={errors} />
                    <Button startIcon={<ResetIcon />} onClick={() => onResetFilters(form)} disabled={beingSearched}>
                      {getMessage('button.reporting.reset.filters')}
                    </Button>
                    <SearchButton type="submit" disabled={!formValid} loading={beingSearched} />
                  </S.FilterCard.Actions>
                </form>
              )
            }}
          />
        </Card>
      )}
    </>
  )
}
