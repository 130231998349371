import {
  Committee_Config,
  Factsheet_Application_Decision,
  Factsheet_Committee_Recommendation,
  Query_Root,
} from 'src/@types/graphql'
import { isEqual, sortBy, unionWith } from 'lodash'
import { ApplicationCommitteeUtils } from 'src/screens/shared/application/committee/ApplicationCommiteeUtils'

/**
 * Utility class for handling values used in Factsheet Application Committee page.
 * Based on shared `ApplicationCommitteeUtils.ts` file.
 * Uses some utils from `ApplicationCommitteeUtils.ts` file.
 */
export interface CommitteeConfigValues {
  committeeConfigId: number
  code: string
  label: string
  description: string | null
  recommendation: string | null
}

export interface FactsheetApplicationDecisionValues {
  id: number | null
  pros: string | null
  cons: string | null
  finalDecision: string | null
  exclusionCriteria: string[]
  qualityCriteria: string[]
  exclusionCriteriaIds: string[]
  qualityCriteriaIds: string[]
  decisionLetter: string | null
  gfchResponsibleId: number | null
  gfchResponsibleValue: string | null
}

export interface FactsheetCommitteeValues {
  factsheetApplicationDecision: FactsheetApplicationDecisionValues
  committees: Array<CommitteeConfigValues>
  factsheetId?: number | null
  version: number | null
}

const htmlEditorFieldInitialValue = '<p><br></p>'

const readHtmlEditorFieldValue = (html: string | null) => {
  if (html === '<p><br></p>') {
    return null
  }
  return html
}

const adaptServerDataAsFormValues = (
  serverData:
    | {
        committee_config: Committee_Config[]
        factsheet_committee_recommendation: Factsheet_Committee_Recommendation[]
        factsheet_application_decision: Factsheet_Application_Decision[]
        criteria_config: Query_Root['criteria_config']
        user: Query_Root['user']
      }
    | undefined,
  factsheetId?: number,
): FactsheetCommitteeValues => {
  const allActiveCommitteeConfigs = serverData?.committee_config ?? []
  const allEnteredCommitteeConfigRecommendations = serverData?.factsheet_committee_recommendation ?? []

  // prepare initial committee config form values
  const committeeConfigsData = getAllActiveAndEnteredCommitteeConfigs(
    allActiveCommitteeConfigs,
    allEnteredCommitteeConfigRecommendations,
  )

  const committeeConfigFormValues: Array<CommitteeConfigValues> = committeeConfigsData.map((committeeConfigData) => ({
    committeeConfigId: committeeConfigData.committeeConfig.id,
    code: committeeConfigData.committeeConfig.code,
    label: committeeConfigData.committeeConfig.descriptions,
    description: committeeConfigData.committeeConfigRecommendation[0]?.description || htmlEditorFieldInitialValue,
    recommendation: committeeConfigData.committeeConfigRecommendation[0]?.recommendation || null,
  }))

  const applicationDecision = serverData?.factsheet_application_decision[0]

  let qcCriteria = []
  const qcCodeIds = applicationDecision?.quality_criteria
  if (qcCodeIds) {
    qcCriteria = sortBy(
      qcCodeIds.flatMap((qcCodeId: string) => serverData?.criteria_config.filter((cc) => cc.code === qcCodeId)),
      ['sort_number'],
    ).map((cc) => cc.criteria_id)
  }

  let ecCriteria = []
  const ecCodeIds = applicationDecision?.exclusion_criteria
  if (ecCodeIds) {
    ecCriteria = sortBy(
      ecCodeIds.flatMap((ecCodeId: string) => serverData?.criteria_config.filter((cc) => cc.code === ecCodeId)),
      ['sort_number'],
    ).map((cc: any) => cc.criteria_id)
  }

  const gfchResponsibleValue = applicationDecision?.factsheet.gfch_responsible?.first_name
    ? `${applicationDecision.factsheet.gfch_responsible.first_name} ${applicationDecision.factsheet.gfch_responsible.last_name}`
    : applicationDecision?.factsheet.gfch_responsible?.email

  const applicationDecisionFormValues: FactsheetApplicationDecisionValues = {
    id: applicationDecision?.id || null,
    pros: applicationDecision?.pros || htmlEditorFieldInitialValue,
    cons: applicationDecision?.cons || htmlEditorFieldInitialValue,
    finalDecision: applicationDecision?.final_decision || null,
    exclusionCriteria: applicationDecision?.exclusion_criteria || [],
    qualityCriteria: applicationDecision?.quality_criteria || [],
    exclusionCriteriaIds: ecCriteria || [],
    qualityCriteriaIds: qcCriteria || [],
    decisionLetter: applicationDecision?.decision_letter_text || htmlEditorFieldInitialValue,
    gfchResponsibleId: applicationDecision?.factsheet.gfch_responsible?.id || null,
    gfchResponsibleValue: gfchResponsibleValue || null,
  }

  const version = applicationDecision?.version || 0

  return {
    committees: committeeConfigFormValues,
    factsheetApplicationDecision: applicationDecisionFormValues,
    version: version,
    factsheetId: factsheetId,
  }
}

const getAllActiveAndEnteredCommitteeConfigs = (
  allActiveCommitteeConfigs: Committee_Config[],
  allEnteredCommitteeConfigRecommendations: Factsheet_Committee_Recommendation[],
): {
  committeeConfig: Committee_Config
  committeeConfigRecommendation: Factsheet_Committee_Recommendation[]
  sortNumber: number
}[] => {
  const allFilteredCommitteeConfigs =
    unionWith(
      allActiveCommitteeConfigs,
      allEnteredCommitteeConfigRecommendations.map((x) => x.committee_config),
      isEqual,
    ) ?? []

  const uniqueCommitteeConfigs = allFilteredCommitteeConfigs.map((committeeConfig) => {
    const committeeConfigRecommendation = allEnteredCommitteeConfigRecommendations.filter(
      (committeeConfigRecommendation) => committeeConfigRecommendation.committee_config_id === committeeConfig.id,
    )
    return {
      committeeConfig: committeeConfig,
      committeeConfigRecommendation: committeeConfigRecommendation,
      sortNumber: committeeConfig.sort_number,
    }
  })

  return sortBy(uniqueCommitteeConfigs, 'sortNumber')
}

const readFormValues = (formValues: FactsheetCommitteeValues) => {
  const factsheetCommitteeRecommendations = formValues.committees.map((committeeConfig) => ({
    description: readHtmlEditorFieldValue(committeeConfig.description),
    recommendation: committeeConfig.recommendation,
    factsheet_id: formValues.factsheetId,
    committee_config_id: committeeConfig.committeeConfigId,
  }))

  const factsheetApplicationDecision = {
    cons: readHtmlEditorFieldValue(formValues.factsheetApplicationDecision.cons),
    pros: readHtmlEditorFieldValue(formValues.factsheetApplicationDecision.pros),
    final_decision: formValues.factsheetApplicationDecision.finalDecision,
    quality_criteria: formValues.factsheetApplicationDecision.qualityCriteria,
    exclusion_criteria: formValues.factsheetApplicationDecision.exclusionCriteria,
    decision_letter_text: readHtmlEditorFieldValue(formValues.factsheetApplicationDecision.decisionLetter),
    factsheet_id: formValues.factsheetId,
    version: formValues.version,
  }

  const gfchResponsibleId = formValues.factsheetApplicationDecision.gfchResponsibleId || null

  return {
    factsheetCommitteeRecommendations: factsheetCommitteeRecommendations,
    applicationDecision: factsheetApplicationDecision,
    gfchResponsibleId: gfchResponsibleId,
  }
}

export const FactsheetApplicationCommitteeUtils = {
  adaptAsFormValues: adaptServerDataAsFormValues,
  getCommitteeConfigData: getAllActiveAndEnteredCommitteeConfigs,
  getCriteriaOptions: ApplicationCommitteeUtils.getCriteriaOptions,
  getGFCHResponsibleOptions: ApplicationCommitteeUtils.getGFCHResponsibleOptions,
  readFormValues,
}
