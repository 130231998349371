import { Box, DialogActions, DialogContent } from '@mui/material'
import { FC, ReactElement, useMemo } from 'react'
import { Form } from 'react-final-form'
import { Application_Type, Mutation_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { updateApplicationTypePublicMutation } from 'src/screens/administration/application-type-management/applicationTypeManagementQueries'
import { PrimaryButton, SecondaryButton } from 'src/shared/button/Buttons'
import { AutoCompleteField } from 'src/shared/form/control/AutoCompleteField'
import { required } from 'src/shared/form/validation/validators'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useClient } from 'urql'

interface ApplicationTypeFormValues {
  public: boolean
}

interface Props {
  applicationType: Application_Type
  onSuccess: () => void
  onCancel: () => void
}

export const EditApplicationTypeModal: FC<Props> = ({ applicationType, onSuccess, onCancel }): ReactElement => {
  const { getMessage } = useMessageSource()
  const urqlClient = useClient()
  const notificationService = useNotificationService()

  const initialValues: ApplicationTypeFormValues = useMemo(
    () => ({
      public: applicationType.public,
    }),
    [applicationType],
  )

  const editApplicationType = async (values: ApplicationTypeFormValues) => {
    const { data } = await urqlClient
      .mutation<
        {
          update_application_type: Mutation_Root['update_application_type']
        },
        { id: number; public: boolean }
      >(updateApplicationTypePublicMutation, { id: applicationType.id, public: values.public })
      .toPromise()

    if (data?.update_application_type && data?.update_application_type.affected_rows > 0) {
      notificationService.changesSaved()
      onSuccess()
    } else {
      notificationService.operationFailed()
    }
  }

  return (
    <Box>
      <Form<ApplicationTypeFormValues>
        initialValues={initialValues}
        onSubmit={editApplicationType}
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit} noValidate>
              <DialogContent>
                <AutoCompleteField
                  name="public"
                  label={getMessage('label.application-type.public')}
                  options={[
                    {
                      label: getMessage('label.application-type.public.true'),
                      value: true,
                    },
                    {
                      label: getMessage('label.application-type.public.false'),
                      value: false,
                    },
                  ]}
                  validate={required()}
                />
              </DialogContent>
              <DialogActions>
                <SecondaryButton messageKey={'button.cancel'} onClick={onCancel} />
                <PrimaryButton messageKey="button.save" type="submit" />
              </DialogActions>
            </form>
          )
        }}
      />
    </Box>
  )
}
