import combinedQuery from 'graphql-combine-query'
import { ReactElement, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { resolveFinalReportMilestoneRoot, resolveImplementationDetailsRoot } from 'src/routing/routing-utils'
import {
  getApplicationTypeIdFromProjects,
  getCriteriaConfigForMilestoneAssessmentQuery,
} from 'src/screens/shared/assessment-criteria/assessmentQueries'
import { BaseAssessmentCriteria } from 'src/screens/shared/assessment-criteria/BaseAssessmentCriteria'
import {
  createGroupsAndInitialData,
  CriteriaFormValues,
  CriteriaGroups,
  QUALITY_PREFIX,
} from 'src/screens/shared/assessment-criteria/utils/AssessmentUtils'
import { MilestoneAssessmentCommentSection } from 'src/screens/shared/implementation/details/milestone/details/final/comment/MilestoneAssessmentCommentSection'
import { queryMilestoneById } from 'src/screens/shared/implementation/details/milestoneQueries'
import { usePermissionsForFinalMilestone } from 'src/service/security/PermissionHook'
import { ASSESSMENT_TYPE, ASSESSMENT_TYPE_TYPE, CRITERIA_TYPE } from 'src/shared/constants/assessment-constants'
import { PROJECT } from 'src/shared/constants/constants'
import {
  MILESTONE_RESPONSIBLE_TYPE_TYPE,
  MILESTONE_STATUS_TYPE,
  MILESTONE_TYPE,
} from 'src/shared/constants/milestone-constants'
import { FinalProjectReportExportMenu } from 'src/shared/menu/FinalProjectReportExportMenu'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useClient, useQuery } from 'urql'
import { MilestoneFinalReportChart } from 'src/shared/charts/milestone-final-report/MilestoneFinalReportChart'

interface Props {
  baseUrl: '/pf-kap' | '/pf-pgv'
  assessorType: ASSESSMENT_TYPE_TYPE
}

export const MilestoneAssessmentCriteriaPage = ({ baseUrl, assessorType }: Props): ReactElement => {
  const { milestoneId, projectId } = useParams()
  const { getMessage } = useMessageSource()
  const milestone_id = parseInt(milestoneId as string)
  const project_id = parseInt(projectId as string)

  const notificationService = useNotificationService()
  const urqlClient = useClient()
  const navigate = useDelayedNavigate()
  const projectType = Utils.resolveProcess(baseUrl)

  const [criteriaGroups, setCriteriaGroups] = useState<CriteriaGroups | undefined>()
  const [initialValues, setInitialValues] = useState<CriteriaFormValues | undefined>()
  const [chartBase64Image, setChartBase64Image] = useState<string>()

  const assessmentLabels = {
    sectionLayoutTitle: `label.assessment.type.${assessorType.toLowerCase()}_assessment`,
    helpText: `label.help.milestone.assessment.${assessorType.toLowerCase()}.${projectType.toLowerCase()}`,
  }

  const isPfKap = projectType === PROJECT.PF_KAP
  const isPfPgv = projectType === PROJECT.PF_PGV

  const { document } = combinedQuery('fetchMilestoneAndAppCodeId')
    .add<{ milestone_by_pk: Query_Root['milestone_by_pk'] }, { id: number }>(queryMilestoneById, {
      id: milestone_id,
    })
    .add<
      {
        pf_kap_project: Query_Root['pf_kap_project']
        pf_pgv_project: Query_Root['pf_pgv_project']
      },
      { isPfKap: boolean; isPfPgv: boolean; projectId: number }
    >(getApplicationTypeIdFromProjects, { isPfKap: isPfKap, isPfPgv: isPfPgv, projectId: project_id })

  const [{ data, error }] = useQuery<
    {
      milestone_by_pk: Query_Root['milestone_by_pk']
      pf_kap_project: Query_Root['pf_kap_project']
      pf_pgv_project: Query_Root['pf_pgv_project']
    },
    { id: number; isPfKap: boolean; isPfPgv: boolean; projectId: number }
  >({
    query: document,
    variables: { id: milestone_id, isPfKap: isPfKap, isPfPgv: isPfPgv, projectId: project_id },
  })

  const applicationTypeId = isPfKap
    ? data?.pf_kap_project?.[0]?.application_type_id
    : data?.pf_pgv_project?.[0]?.application_type_id

  if (error) {
    notificationService.operationFailed()
  }

  const milestone = data?.milestone_by_pk

  const { loading, canEdit, canEditInternalAssessment } = usePermissionsForFinalMilestone(
    projectType,
    project_id,
    milestone?.status as MILESTONE_STATUS_TYPE,
    milestone?.responsible_type as MILESTONE_RESPONSIBLE_TYPE_TYPE,
  )

  useEffect(() => {
    const initData = async () => {
      const { data } = await urqlClient
        .query<{
          milestone_assessment_criteria_selection: Query_Root['milestone_assessment_criteria_selection']
          criteria_group_config: Query_Root['criteria_group_config']
        }>(getCriteriaConfigForMilestoneAssessmentQuery, {
          milestoneId: milestoneId,
          process: projectType,
          criteriaType: CRITERIA_TYPE.QUALITY,
          assessorType: assessorType,
          applicationTypeId: applicationTypeId,
        })
        .toPromise()

      const criteriaGroupedConfigs = data?.criteria_group_config
      const selectedQualities = data?.milestone_assessment_criteria_selection

      const { criteriaGroups, initialValues } = createGroupsAndInitialData(
        selectedQualities,
        criteriaGroupedConfigs,
        QUALITY_PREFIX,
        applicationTypeId,
      )

      setCriteriaGroups(criteriaGroups)
      setInitialValues(initialValues)
    }

    initData()
  }, [urqlClient, assessorType, projectType, milestoneId, applicationTypeId])

  const onEditHandler = () => {
    const parameters = {
      projectId: projectId,
      milestoneId: milestoneId,
      milestonePath: Utils.resolveMilestonePath(MILESTONE_TYPE.FINAL_REPORT),
    }

    if (assessorType === ASSESSMENT_TYPE.EXTERNAL) {
      navigate(resolveFinalReportMilestoneRoot(baseUrl).nested.ExternalAssessmentEdit.params(parameters))
    } else {
      navigate(resolveFinalReportMilestoneRoot(baseUrl).nested.InternalAssessmentEdit.params(parameters))
    }
  }

  const onBackHandler = () => {
    navigate(resolveImplementationDetailsRoot(baseUrl).params({ projectId }))
  }

  const canEditAssessment = useMemo(
    () => (assessorType === ASSESSMENT_TYPE.INTERNAL ? canEditInternalAssessment : canEdit),
    [assessorType, canEdit, canEditInternalAssessment],
  )

  return (
    <>
      <BaseAssessmentCriteria
        screenLayoutTitle={getMessage(`label.milestone.type.${MILESTONE_TYPE.FINAL_REPORT}`)}
        onBackHandler={onBackHandler}
        sectionLayoutTitle={getMessage(assessmentLabels.sectionLayoutTitle)}
        helpText={assessmentLabels.helpText}
        criteriaGroups={criteriaGroups}
        initialValues={initialValues}
        canEdit={canEditAssessment}
        onEditHandler={onEditHandler}
        loading={loading}
        showRating={projectType === PROJECT.PF_KAP}
        optional={!(projectType === PROJECT.PF_KAP && assessorType === ASSESSMENT_TYPE.EXTERNAL)}
        commentSection={
          <MilestoneAssessmentCommentSection baseUrl={baseUrl} type={assessorType} canEdit={canEditAssessment} />
        }
        actions={<FinalProjectReportExportMenu process={projectType} chartBase64Image={chartBase64Image} />}
      />

      <MilestoneFinalReportChart
        setChartBase64Image={setChartBase64Image}
        style={{ visibility: 'hidden', display: 'none' }}
      />
    </>
  )
}
