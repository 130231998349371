import { DialogActions, DialogContent, Stack } from '@mui/material'
import { Box } from '@mui/system'
import { GridRowSelectionModel } from '@mui/x-data-grid-pro'
import combinedQuery from 'graphql-combine-query'
import { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import { AssessorInput, Mutation_Root, Project_Assessment, Query_Root, SupportedLocale } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { AssessmentFormValues } from 'src/screens/shared/application/assessment/add-assessment/AddAssessmentModal'
import {
  getAssessmentTypesQuery,
  getUsersQuery,
  insertMultipleAssessmentsMutation,
  notifyAssessorsQuery,
} from 'src/screens/shared/assessment-criteria/assessmentQueries'
import { PrimaryButton, SecondaryButton } from 'src/shared/button/Buttons'
import {
  AddProjectAssessmentType,
  ASSESSMENT_STATUS,
  ASSESSMENT_TYPE,
  internalGfchAssessorsWhereClause,
} from 'src/shared/constants/assessment-constants'
import { GLOBAL_USER_ROLES, PROCESS_TYPE, PROJECT_USER_ROLES, USER_STATUS } from 'src/shared/constants/constants'
import { Option } from 'src/shared/form/control'
import { AutoCompleteField } from 'src/shared/form/control/AutoCompleteField'
import { MultiSelectField } from 'src/shared/form/control/MultiSelectField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { createDecorators } from 'src/shared/form/utils/decorators'
import { required } from 'src/shared/form/validation/validators'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useClient } from 'urql'

interface Props {
  onCancel: () => void
  onSuccess: () => void
  process: PROCESS_TYPE
  selections: GridRowSelectionModel
}

const decorators = createDecorators()

export const AssessorManagementModal = ({ onCancel, onSuccess, process, selections }: Props) => {
  const { getMessage } = useMessageSource()
  const urqlClient = useClient()
  const notificationService = useNotificationService()

  const [initialValues] = useState<AssessmentFormValues>({
    instanceId: undefined,
    internalAssessmentsIds: [],
    externalAssessmentsIds: [],
  })
  const [assessmentInstances, setAssessmentInstances] = useState<Option[] | undefined>()
  const [internalUsers, setInternalUsers] = useState<Option[] | undefined>()
  const [externalUsers, setExternalUsers] = useState<Option[] | undefined>()

  useEffect(() => {
    const initData = async () => {
      const { document, variables } = combinedQuery('AssessmentInstancesAndUsers')
        .add(getAssessmentTypesQuery, { process: process })
        .add(getUsersQuery, {
          role: GLOBAL_USER_ROLES['PD-EXT_EXPERT'],
          userRoleWhere: internalGfchAssessorsWhereClause(process),
          status: USER_STATUS.ACTIVE,
        })

      const { data } = await urqlClient
        .query<{
          assessment_instance_type: Query_Root['assessment_instance_type']
          external_users: Query_Root['user_roles']
          internal_users: Query_Root['user_roles']
        }>(document, variables)
        .toPromise()

      if (data?.assessment_instance_type && data?.external_users && data?.internal_users) {
        const assessmentInstances = data?.assessment_instance_type
        const externalUsers = data?.external_users
        const gfchUsers = data?.internal_users

        const assessmentInstancesOptions = assessmentInstances
          ?.sort((a, b) => a.sort_number - b.sort_number)
          .map((item) => ({ label: getMessage(item.key), value: item.id })) as Option[]

        const externalUsersOptions = externalUsers?.map((item, index) => ({
          label: item.user.first_name
            ? `${item.user.first_name} ${item.user.last_name} (${item.user.email})`
            : item.user.email,
          value: item.user.id,
          sortNumber: index,
        })) as Option[]

        const gfchUsersOptions = gfchUsers?.map((item, index) => ({
          label: item.user.first_name
            ? `${item.user.first_name} ${item.user.last_name} (${item.user.email})`
            : item.user.email,
          value: item.user.id,
          sortNumber: index,
        })) as Option[]

        setAssessmentInstances(assessmentInstancesOptions)
        setExternalUsers(externalUsersOptions)
        setInternalUsers(gfchUsersOptions)
      }
    }
    initData()
  }, [urqlClient, process, getMessage])

  const mapValuesToProjectAssessmentType = (
    userIds: number[],
    assessorType: string,
    instanceId: number,
    projectBaseId: number,
  ): AddProjectAssessmentType[] =>
    userIds.map((userId) => ({
      project_base_id: projectBaseId,
      assessor_type: assessorType,
      assessor_id: userId,
      instance_id: instanceId,
      status: ASSESSMENT_STATUS.OPEN,
    })) as AddProjectAssessmentType[]

  const handleSubmitLocal = async (values: AssessmentFormValues) => {
    const { externalAssessmentsIds, internalAssessmentsIds, instanceId } = values

    const assessmentList = selections.flatMap((projectBaseId) => [
      ...mapValuesToProjectAssessmentType(
        externalAssessmentsIds,
        ASSESSMENT_TYPE.EXTERNAL,
        instanceId as number,
        projectBaseId as number,
      ),
      ...mapValuesToProjectAssessmentType(
        internalAssessmentsIds,
        ASSESSMENT_TYPE.INTERNAL,
        instanceId as number,
        projectBaseId as number,
      ),
    ])

    const projectUsersList = selections.flatMap((projectBaseId) => [
      ...externalAssessmentsIds.map((item) => ({
        project_base_id: projectBaseId,
        user_id: item,
        type: PROJECT_USER_ROLES.EXPERT,
      })),
    ])

    const queryVariables = {
      objects: assessmentList,
      users: projectUsersList,
    }

    const { data } = await urqlClient
      .mutation<{
        insert_project_assessment: Mutation_Root['insert_project_assessment']
        insert_project_user: Mutation_Root['insert_project_user']
      }>(insertMultipleAssessmentsMutation, queryVariables)
      .toPromise()

    if (data) {
      const returningList = data?.insert_project_assessment?.returning as Project_Assessment[]
      sendNotificationToAssessors(returningList)
      notificationService.changesSaved()
      onSuccess()
    } else {
      notificationService.operationFailed()
    }
  }

  const sendNotificationToAssessors = async (returningProjectAssessment: Project_Assessment[]) => {
    const assessors: AssessorInput[] = returningProjectAssessment
      .map((projectAssessment) => ({
        firstName: projectAssessment.user.first_name ?? '',
        lastName: projectAssessment.user.last_name ?? '',
        email: projectAssessment.user.email ?? '',
        language: projectAssessment.user.language as SupportedLocale,
      }))
      .filter((_, index, self) => self.findIndex((o: any) => o.email === self[index].email) === index)

    const { data, error } = await urqlClient
      .mutation<{
        notifyAssessors: Mutation_Root['notifyAssessors']
      }>(notifyAssessorsQuery, { assessors })
      .toPromise()

    if (error || (data && data.notifyAssessors.status === 'FAILURE')) {
      notificationService.operationFailed()
    }
  }

  return (
    <>
      <Box>
        {assessmentInstances && internalUsers && externalUsers && (
          <Form<AssessmentFormValues>
            initialValues={initialValues}
            onSubmit={handleSubmitLocal}
            decorators={decorators}
            mutators={{
              clearInternalUsers: (_, state, utils) => {
                utils.changeValue(state, 'internalAssessmentsIds', () => [])
              },
              clearExternalUsers: (_, state, utils) => {
                utils.changeValue(state, 'externalAssessmentsIds', () => [])
              },
            }}
            render={({ handleSubmit, form }) => {
              return (
                <form onSubmit={handleSubmit} noValidate>
                  <DialogContent>
                    <Stack spacing={2}>
                      <AutoCompleteField
                        required
                        label={getMessage('label.instance')}
                        name="instanceId"
                        options={assessmentInstances}
                        validate={required()}
                      />
                      <OnChange name="instanceId">
                        {() => {
                          form.mutators.clearInternalUsers()
                          form.mutators.clearExternalUsers()
                        }}
                      </OnChange>
                      <MultiSelectField
                        options={internalUsers}
                        name="internalAssessmentsIds"
                        label={getMessage('label.users.internal')}
                        unavailableOptionsMessage={getMessage('label.assessment.no.available.users')}
                      />
                      <MultiSelectField
                        options={externalUsers}
                        name="externalAssessmentsIds"
                        label={getMessage('label.users.external')}
                        unavailableOptionsMessage={getMessage('label.assessment.no.available.users')}
                      />
                      <DirtyFormSpy />
                    </Stack>
                  </DialogContent>
                  <DialogActions>
                    <SecondaryButton messageKey={'button.cancel'} onClick={onCancel} />
                    <PrimaryButton messageKey={'button.create'} type="submit" />
                  </DialogActions>
                </form>
              )
            }}
          />
        )}
      </Box>
    </>
  )
}
