import { Chip, IconButton, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { ReactElement, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { SupportedLocale } from 'src/@types'
import { Mutation_Root, Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import {
  queryPfKapProjectById,
  unlinkFactsheetIdFromProjectBase,
} from 'src/screens/pf-kap/project/details/basic-information/basicInformationQueries'
import { PfKapLinkRelatedFactsheetModalDialog } from 'src/screens/pf-kap/project/details/basic-information/link-related-factsheet/PfKapLinkRelatedFactsheetModalDialog'
import { ProjectResponsibleAndPartnerOrganizations } from 'src/screens/shared/project/details/basic-information/organization/ProjectResponsibleAndPartnerOrganizations'
import { usePermissionsForProject } from 'src/service/security/PermissionHook'
import { BaseButton, EditButton } from 'src/shared/button/Buttons'
import { PROJECT, USER_STATUS } from 'src/shared/constants/constants'
import { TYPOGRAPHY_MIXIN } from 'src/shared/constants/styling-constants'
import { DeleteIcon, LinkIcon } from 'src/shared/icons/Icons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { ProjectExportMenu } from 'src/shared/menu/ProjectExportMenu'
import { ConfirmationModalDialog } from 'src/shared/modal-dialog/ConfirmationModalDialog'
import { ModalDialog } from 'src/shared/modal-dialog/ModalDialog'
import { DefaultSectionTypography } from 'src/shared/presentation/DefaultSectionTypography'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { HtmlRenderer } from 'src/shared/presentation/HtmlRenderer'
import { ReadOnlySelection, ReadOnlyTextField } from 'src/shared/presentation/ReadOnly'
import { Section } from 'src/shared/presentation/Section'
import { S } from 'src/shared/styled/S'
import { DateUtils } from 'src/shared/utils/DateUtils'
import { useModalCancel } from 'src/shared/utils/hooks/modal-hooks'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { useUserLocale } from 'src/user/UserContext'
import styled from 'styled-components/macro'
import { useClient, useQuery } from 'urql'

const IdChip = styled(Chip)`
  cursor: pointer;
  min-width: 3rem;
  &.MuiChip-filledDefault {
    background-color: ${({ theme }) => theme.colors.action.hover};
    color: ${({ theme }) => theme.colors.text.disabled};
  }
  & .MuiChip-label {
    ${TYPOGRAPHY_MIXIN.subtitle2};
  }
`

export const PfKapBasicInformationPage = (): ReactElement => {
  const { projectId } = useParams()
  const pf_kap_id = parseInt(projectId as string)
  const locale = useUserLocale()
  const { getMessage } = useMessageSource()
  const [{ data, error }, refetch] = useQuery<
    { pf_kap_project_by_pk: Query_Root['pf_kap_project_by_pk'] },
    { pf_kap_id: number; locale: SupportedLocale }
  >({
    query: queryPfKapProjectById,
    variables: { pf_kap_id, locale },
  })
  const pf_kap_project_data = data?.pf_kap_project_by_pk
  const modulesContentChanges = pf_kap_project_data?.modules.join('')
  const modules = useMemo(() => pf_kap_project_data?.modules ?? [], [modulesContentChanges])
  const projectType = PROJECT.PF_KAP
  const {
    loading,
    canEdit,
    metadata: { projectBaseId },
  } = usePermissionsForProject(projectType, pf_kap_id)
  const navigate = useDelayedNavigate()
  const notificationService = useNotificationService()
  const urqlClient = useClient()
  const [linkFactsheetsModalOpen, setLinkFactsheetsModalOpen] = useState(false)
  const [factsheetDeleteConfirmationOpen, setFactsheetDeleteConfirmationOpen] = useState(false)

  const handleEditButtonClick = () => {
    navigate(ROUTES.PfKapDetailsRoot.nested.BasicInformationEdit.params({ projectId }))
  }

  const openLinkFactsheetsModalHandler = () => {
    setLinkFactsheetsModalOpen(true)
  }

  const onLinkFactsheetSuccess = () => {
    refetch()
    setLinkFactsheetsModalOpen(false)
  }

  const onLinkFactsheetCancel = useModalCancel(() => setLinkFactsheetsModalOpen(false))

  const relatedFactsheetClickHandler = (factsheetId: number) => () => {
    window.open(ROUTES.FactsheetDescriptionRoot.nested.FactsheetDescription.params({ factsheetId }))
  }

  const handleDeleteFactsheet = () => {
    setFactsheetDeleteConfirmationOpen(true)
  }

  const handleCancelDeleteFactsheet = () => {
    setFactsheetDeleteConfirmationOpen(false)
  }

  const handleConfirmDeleteFactsheet = async () => {
    setFactsheetDeleteConfirmationOpen(false)
    const { data, error } = await urqlClient
      .mutation<{
        update_project_base: Mutation_Root['update_project_base']
      }>(unlinkFactsheetIdFromProjectBase, {
        projectBaseId,
      })
      .toPromise()
    if (error || data?.update_project_base?.affected_rows !== 1) {
      notificationService.operationFailed()
    } else {
      notificationService.changesSaved()
      refetch()
    }
  }

  if (error) {
    notificationService.operationFailed()
  }

  return (
    <ScreenLayout
      title={getMessage('label.navigation.project.details')}
      actions={<ProjectExportMenu process={PROJECT.PF_KAP} entityId={projectBaseId as number} />}
    >
      <PageLayout>
        <>
          {!loading && pf_kap_project_data && (
            <>
              <Section
                id="project-information"
                title={getMessage('label.project.information')}
                actionButton={
                  <EditButton origin="content" color="primary" onClick={handleEditButtonClick} hidden={!canEdit} />
                }
                helpAndInstructions={
                  <HelpAndInstructions labelKey="label.help.basic.information.project.information.pf_kap" />
                }
              >
                <Card>
                  <CardContent>
                    <Box>
                      <ReadOnlyTextField text={getMessage('label.project.id')}>
                        {`${getMessage('label.project.id.character.pf_kap')}${pf_kap_project_data.id}`}
                      </ReadOnlyTextField>
                      <ReadOnlyTextField text={getMessage('label.title')}>
                        {pf_kap_project_data.project_base.dossier.title}
                      </ReadOnlyTextField>
                      <ReadOnlyTextField text={getMessage('label.short.title')}>
                        {pf_kap_project_data.project_base.dossier.short_title}
                      </ReadOnlyTextField>
                      <ReadOnlyTextField text={getMessage('label.description')}>
                        {pf_kap_project_data?.project_base?.dossier?.description && (
                          <HtmlRenderer html={pf_kap_project_data?.project_base?.dossier?.description} />
                        )}
                      </ReadOnlyTextField>
                      <ReadOnlySelection text={getMessage('label.application_type')}>
                        {getMessage(pf_kap_project_data.application_type.key)}
                      </ReadOnlySelection>
                      <ReadOnlySelection text={getMessage('label.modules')}>
                        {Utils.sortModules(modules).map((module: any) => (
                          <Typography key={module}>{getMessage(`label.module.description.${module}`)}</Typography>
                        ))}
                      </ReadOnlySelection>
                      <ReadOnlySelection text={getMessage('label.project.start.date')}>
                        {pf_kap_project_data.project_base?.dossier?.start_date &&
                          DateUtils.parseAndFormatDate(pf_kap_project_data!.project_base.dossier.start_date)}
                      </ReadOnlySelection>
                      <ReadOnlySelection text={getMessage('label.project.end.date')}>
                        {pf_kap_project_data?.project_base?.dossier?.end_date &&
                          DateUtils.parseAndFormatDate(pf_kap_project_data!.project_base.dossier.end_date)}
                      </ReadOnlySelection>
                      <ReadOnlySelection text={getMessage('label.project.leader')} isLast={true}>
                        {/* if a GFCH Contributor creates the project, there won't be a project leader initially */}
                        {pf_kap_project_data?.project_base?.user &&
                          (pf_kap_project_data?.project_base?.user?.status === USER_STATUS.ACTIVE
                            ? `${pf_kap_project_data?.project_base?.user.first_name} ${pf_kap_project_data?.project_base?.user.last_name}, ${pf_kap_project_data?.project_base?.user.email}`
                            : `${getMessage('label.user.unknown')}, ${pf_kap_project_data?.project_base?.user.email}`)}
                      </ReadOnlySelection>
                    </Box>
                  </CardContent>
                </Card>
              </Section>

              <Section
                title={getMessage('label.related.factsheet')}
                helpAndInstructions={<HelpAndInstructions labelKey="label.help.related.factsheet" />}
                actionButton={
                  <BaseButton
                    messageKey="button.link.factsheet"
                    variant="text"
                    startIcon={<LinkIcon />}
                    onClick={openLinkFactsheetsModalHandler}
                    hidden={!canEdit}
                    disabled={!!pf_kap_project_data.project_base.factsheet}
                  />
                }
              >
                {pf_kap_project_data.project_base.factsheet ? (
                  <S.Card.Container>
                    <S.Card.Content
                      onClick={relatedFactsheetClickHandler(pf_kap_project_data.project_base.factsheet.id)}
                      tabIndex={0}
                      onKeyDown={(event: { key: string }) => {
                        if (['Enter', ' '].includes(event.key)) {
                          relatedFactsheetClickHandler(pf_kap_project_data.project_base.factsheet!.id)()
                        }
                      }}
                    >
                      <IdChip
                        size="small"
                        label={`${getMessage('label.factsheet.id.character')}${pf_kap_project_data.project_base
                          ?.factsheet.id}`}
                      />
                      <Typography variant="subtitle2">{pf_kap_project_data.project_base.factsheet.title}</Typography>
                    </S.Card.Content>
                    {canEdit && (
                      <S.Card.Actions>
                        <IconButton color="primary" size="large" onClick={handleDeleteFactsheet}>
                          <DeleteIcon />
                        </IconButton>
                      </S.Card.Actions>
                    )}
                  </S.Card.Container>
                ) : (
                  <S.Card.Container $nonClickable={true}>
                    <S.Card.Content>
                      <DefaultSectionTypography
                        noEntriesMessageKey={getMessage('label.no.linked.factsheets')}
                        $standAlone={true}
                      />
                    </S.Card.Content>
                  </S.Card.Container>
                )}
              </Section>
              <ProjectResponsibleAndPartnerOrganizations
                projectId={pf_kap_project_data.id}
                baseUrl="/pf-kap"
                projectBase={pf_kap_project_data.project_base}
                refetch={refetch}
                canEdit={canEdit}
              />
            </>
          )}
        </>
        <ModalDialog
          open={linkFactsheetsModalOpen}
          onClose={onLinkFactsheetCancel}
          title={getMessage('label.link.related.factsheet')}
          maxWidth="md"
        >
          <PfKapLinkRelatedFactsheetModalDialog
            onCancel={onLinkFactsheetCancel}
            onSuccess={onLinkFactsheetSuccess}
            projectBaseId={projectBaseId}
            modules={modules}
          />
        </ModalDialog>
        <ConfirmationModalDialog
          open={factsheetDeleteConfirmationOpen}
          onCancel={handleCancelDeleteFactsheet}
          onConfirm={handleConfirmDeleteFactsheet}
        >
          {getMessage('label.unlink.factsheet.confirm')}
        </ConfirmationModalDialog>
      </PageLayout>
    </ScreenLayout>
  )
}
