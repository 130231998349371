import { Criteria_Config, Factsheet_Assessment_Criteria_Selection_Insert_Input, Query_Root } from 'src/@types/graphql'
import { Dictionary, groupBy } from 'lodash'
import { InternationalizedObject } from 'src/@types'
import { CRITERIA_TYPE } from 'src/shared/constants/assessment-constants'

export const CRITERIA_CONFIG_PREFIX = 'cc_'

export interface CriteriaFormValues {
  [key: string]: boolean | string | undefined
}

export type CriteriaConfigs = Array<Criteria_Config & { cgCode: string; cgNames: InternationalizedObject }>
export type CriteriaGroups = Dictionary<CriteriaConfigs>

interface CriteriaGroupsAndData {
  criteriaGroups: CriteriaGroups
  initialValues: CriteriaFormValues
}

export const createGroupsAndInitialData = (
  criteriaSelections: Query_Root['factsheet_assessment_criteria_selection'],
  criteriaGroups: Query_Root['criteria_group_config'],
): CriteriaGroupsAndData => {
  // assign group code to each criteria config
  const criteriaConfig: CriteriaConfigs = criteriaGroups.flatMap((cg) =>
    cg.criteria_configs.map((cc) => ({
      ...cc,
      cgCode: cg.code as string,
      cgNames: cg.names as InternationalizedObject,
    })),
  )

  const filterInactive = criteriaConfig.filter(
    (c) => c.active || criteriaSelections?.some((s) => s.criteria_config_id === c.id),
  )

  // group criteria configs by group code
  const groups: CriteriaGroups = groupBy(filterInactive, (cc) => cc.cgCode)

  // prepare initial values from selected criteria configs
  const initialValues: CriteriaFormValues = {}
  criteriaSelections.forEach((criteriaSelection) => {
    const selectedCriteriaConfigType = criteriaSelection.criteria_config.criteria_type

    // EXCLUSION cc are added with boolean values (initially True)
    if (selectedCriteriaConfigType === CRITERIA_TYPE.EXCLUSION.toString()) {
      initialValues[CRITERIA_CONFIG_PREFIX + criteriaSelection.criteria_config_id] = true
    }

    // QUALITY cc are added with string || undefined values based on rating stored in db
    if (selectedCriteriaConfigType === CRITERIA_TYPE.QUALITY.toString()) {
      initialValues[CRITERIA_CONFIG_PREFIX + criteriaSelection.criteria_config_id] =
        criteriaSelection.rating || undefined
    }
  })

  return {
    criteriaGroups: groups,
    initialValues,
  }
}

/**
 * Filters out unselected or invalid values from an array of selections.
 *
 * This function takes an array of key-value pairs and removes any pair where:
 * - The value is `undefined`.
 * - The value is a boolean and evaluates to `false` -- for EXCLUSION cc.
 * - The value is a string and is empty or contains only whitespace  -- for QUALITY cc.
 *
 * @param {Array<[string, string | boolean | undefined]>} selections -
 * An array of key-value pairs where the key is a string and the value can be a string, boolean, or undefined.
 *
 * @returns {Array<[string, string | boolean ]>} -
 * A new array containing only the pairs where the value is valid.
 */
export const filterOutUnselects = (
  selections: [string, string | boolean | undefined][],
): [string, string | boolean][] => {
  const filterOutUnselectsRes = selections.filter(([_, value]) => {
    if (typeof value === 'boolean') {
      return value
    } else if (typeof value === 'string') {
      return value.trim() !== ''
    }

    return false
  }) as [string, string | boolean][]

  return filterOutUnselectsRes
}

/**
 * Maps an array of selections to an array of input objects for factsheet assessment criteria selection.
 *
 * This function transforms an array of key-value pairs into objects formatted for insertion into
 * the `Factsheet_Assessment_Criteria_Selection` table. Each object contains:
 * - `factsheet_assessment_id`: The provided assessment ID.
 * - `criteria_config_id`: Extracted and parsed from the key, which includes the prefix `CRITERIA_CONFIG_PREFIX`.
 * - `rating`: The value for rating. If it's a string return the value of the pair for QUALITY cc,
 * or if boolean return `undefined` because we dont store rating for EXCLUSION cc.
 *
 * @param {number} assessmentId - The ID of the factsheet assessment.
 * @param {Array<[string, string | boolean]>} selections - selections
 *
 * @returns {Factsheet_Assessment_Criteria_Selection_Insert_Input[]} - inserts.
 */
export const mapSelectionsToAssessmentCriteriaSelectionsInputs = (
  assessmentId: number,
  selections: [string, string | boolean][],
): Factsheet_Assessment_Criteria_Selection_Insert_Input[] => {
  const factsheetAssessmentInserts: Factsheet_Assessment_Criteria_Selection_Insert_Input[] = selections.map(
    ([key, value]) => ({
      factsheet_assessment_id: assessmentId,
      criteria_config_id: parseInt(key.split(CRITERIA_CONFIG_PREFIX)[1]),
      rating: typeof value === 'string' ? value : undefined,
    }),
  )

  return factsheetAssessmentInserts
}
