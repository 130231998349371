import { isAfter, isValid, parse } from 'date-fns'
import { TextLength, TEXT_LENGTH } from 'src/shared/constants/constants'
import { DateUtils } from 'src/shared/utils/DateUtils'

const VALID = undefined

type ValidationSuccess = typeof VALID

export type ValidationError = {
  errorKey: string
  params?: (string | number)[]
}

export type ValidationOutput = ValidationError | ValidationSuccess

type ValidatorFunc = (value: string & number) => ValidationOutput
type ValidatorArgs = Array<string | number>
type ComposeValidatorsFuncReturn = (value: string) => ValidatorFunc

export const composeValidators =
  (...validators: Array<ValidatorFunc>): ComposeValidatorsFuncReturn =>
  (...args: ValidatorArgs) =>
    validators.reduce((error: any, validator: any) => error || validator(...args), undefined)

export const required =
  (errorKey = 'validation.required') =>
  (value: any): ValidationOutput => {
    const isArray = Array.isArray(value)
    const isBoolean = typeof value === 'boolean'

    if (value && !isArray && !isBoolean) {
      return VALID
    }

    if (value && isArray) {
      return value.length > 0 ? VALID : { errorKey }
    }

    if (isBoolean) {
      return VALID
    }

    return { errorKey }
  }

/**
 * A regular expression that matches valid e-mail addresses.
 *
 * At a high level, this regexp matches e-mail addresses of the format `local-part@tld`, where:
 * - `local-part` consists of one or more of the allowed characters (alphanumeric and some
 *   punctuation symbols).
 * - `local-part` cannot begin or end with a period (`.`).
 * - `local-part` cannot be longer than 64 characters.
 * - `tld` consists of one or more `labels` separated by periods (`.`). For example `localhost` or
 *   `foo.com`.
 * - A `label` consists of one or more of the allowed characters (alphanumeric, dashes (`-`) and
 *   periods (`.`)).
 * - A `label` cannot begin or end with a dash (`-`) or a period (`.`).
 * - A `label` cannot be longer than 63 characters.
 * - The whole address cannot be longer than 254 characters.
 *
 * ## Implementation background
 *
 * This regexp was ported over from AngularJS (see there for git history):
 * https://github.com/angular/angular.js/blob/c133ef836/src/ng/directive/input.js#L27
 * It is based on the
 * [WHATWG version](https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address) with
 * some enhancements to incorporate more RFC rules (such as rules related to domain names and the
 * lengths of different parts of the address). The main differences from the WHATWG version are:
 *   - Disallow `local-part` to begin or end with a period (`.`).
 *   - Disallow `local-part` length to exceed 64 characters.
 *   - Disallow total address length to exceed 254 characters.
 *
 * See [this commit](https://github.com/angular/angular.js/commit/f3f5cf72e) for more details.
 */
const emailRegex =
  /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

export const validEmail =
  (errorKey = 'validation.email') =>
  (value: string): ValidationOutput => {
    if (emailRegex.test(value)) {
      return VALID
    }

    return {
      errorKey,
    }
  }

const chPhoneRegex = /(\+41)\s(\d{2})\s(\d{3})\s(\d{2})\s(\d{2})/

export const validPhoneNumber =
  (errorKey = 'validation.valid.number') =>
  (value: string): ValidationOutput => {
    if (value.startsWith('+41')) {
      if (chPhoneRegex.test(value)) {
        return VALID
      }
    } else {
      return VALID
    }

    return {
      errorKey,
    }
  }

export const validDate =
  (errorKey = 'validation.date.invalid') =>
  (value: string): ValidationOutput => {
    if (!value) {
      return VALID
    }

    const valueAsDate = parse(value, DateUtils.DATE_FORMAT, 0)
    // by default Material UI picker has a default `minDate` and `maxDate` that it will use to both
    // control which years are available in the picker, but also color the input with error color if it is invalid
    // that's why we need to additionally validate that the 'year' is in acceptable range
    // https://github.com/mui-org/material-ui/blob/a0cccf538eab4ea50b0601e7296ac583f16004f9/packages/material-ui-lab/src/LocalizationProvider/LocalizationProvider.tsx#L51
    if (
      isValid(valueAsDate) &&
      (DateUtils.before(valueAsDate, DateUtils.MIN_DATE) || DateUtils.after(valueAsDate, DateUtils.MAX_DATE))
    ) {
      return {
        errorKey: 'validation.date.invalid.range',
        params: [DateUtils.formatDate(DateUtils.MIN_DATE), DateUtils.formatDate(DateUtils.MAX_DATE)],
      }
    }
    return isValid(valueAsDate) ? VALID : { errorKey }
  }

export const dateIsAfterToday =
  (errorKey = 'validation.date.after.today') =>
  (value: string): ValidationOutput => {
    if (!value) {
      return VALID
    }
    const valueAsDate = parse(value, DateUtils.DATE_FORMAT, 0)
    const today = new Date()
    return isAfter(valueAsDate, today) ? VALID : { errorKey }
  }

export const validTime =
  (errorKey = 'validation.time.valid') =>
  (value: string): ValidationOutput => {
    if (!value) {
      return VALID
    }
    const valueAsDate = parse(value, 'dd.MM.yyyy HH:mm', 0)
    return isValid(valueAsDate) ? VALID : { errorKey }
  }

export const maxChar =
  (maxLength: TextLength = TEXT_LENGTH.M) =>
  (value: string): ValidationOutput => {
    if (!value) {
      return VALID
    }
    const errorKey = `validation.max.char`
    const allowedChars = maxLength.value
    const messageValue = maxLength.messageValue ? maxLength.messageValue : allowedChars

    if (value.length > allowedChars) {
      return { errorKey, params: [messageValue] }
    } else {
      return VALID
    }
  }

export const minChar =
  (minLength = 0) =>
  (value: string): ValidationOutput => {
    if (!value) {
      return VALID
    }
    const errorKey = `validation.min.char`

    if (value.length < minLength) {
      return { errorKey, params: [minLength] }
    } else {
      return VALID
    }
  }

export const validPattern =
  (pattern: RegExp, errorMessageKey?: string) =>
  (value: string): ValidationOutput => {
    if (!value) {
      return VALID
    }
    const defaultErrorKey = `validation.pattern`

    const isValid = pattern.test(value)
    if (!isValid) {
      if (errorMessageKey) {
        return { errorKey: errorMessageKey }
      } else {
        return { errorKey: defaultErrorKey, params: [`${pattern}`] }
      }
    } else {
      return VALID
    }
  }

export const validYear =
  (errorKey = 'validation.reporting.filter.year.invalid.range') =>
  (value: string): ValidationOutput => {
    if (!value) {
      return VALID
    }
    const valueAsDate = parse(value, DateUtils.DATE_FORMAT, 0)

    if (
      !isValid(valueAsDate) ||
      (isValid(valueAsDate) &&
        (DateUtils.before(valueAsDate, DateUtils.MIN_DATE) || DateUtils.after(valueAsDate, DateUtils.MAX_DATE)))
    ) {
      return {
        errorKey: errorKey,
        params: [DateUtils.MIN_DATE.getFullYear(), DateUtils.MAX_DATE.getFullYear()],
      }
    }
    return VALID
  }

export const requiredSelection =
  (errorKey = 'validation.reporting.filter.required.selection') =>
  (value: []): ValidationOutput => {
    if (value) {
      return value.length > 0 ? VALID : { errorKey }
    }
    return { errorKey }
  }
