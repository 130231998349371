import { useEffect, useState } from 'react'
import { Query_Root } from 'src/@types/graphql'
import { queryFactsheetApplicationInformationByFactsheetId } from 'src/screens/factsheets/application/details/application-information/factsheetApplicationInformationQueries'
import { useClient } from 'urql'

export interface FactsheetApplicationInformationValues {
  factsheetStatus: string
  applicationDecision: string | null
  fundingRoundId: number | null
  fundingRoundName: string | null
  fundingRoundDecisionDate: string | null
  version: number
}

export const useResolveFactsheetApplicationInformation = (factsheetId: number | null) => {
  const urqlClient = useClient()

  const [applicationInformation, setApplicationInformation] = useState<FactsheetApplicationInformationValues>()

  useEffect(() => {
    const initData = async () => {
      const { data } = await urqlClient
        .query<{
          factsheet_application_decision: Query_Root['factsheet_application_decision']
          factsheet: Query_Root['factsheet']
        }>(queryFactsheetApplicationInformationByFactsheetId, { factsheetId: factsheetId })
        .toPromise()
      if (data) {
        const applicationInformation: FactsheetApplicationInformationValues = {
          factsheetStatus: data.factsheet[0]?.workflow_status,
          applicationDecision: data.factsheet_application_decision[0]?.application_decision ?? null,
          version: data.factsheet_application_decision[0]?.version ?? null,
          fundingRoundId: data.factsheet[0]?.funding_round_id ?? null,
          fundingRoundName: data.factsheet[0]?.funding_round?.name ?? null,
          fundingRoundDecisionDate: data.factsheet[0]?.funding_round?.decision_date,
        }
        setApplicationInformation(applicationInformation)
      }
    }
    initData()
  }, [factsheetId, urqlClient])

  return {
    applicationInformation,
    setApplicationInformation,
  }
}
