import { LoadingButton } from '@mui/lab'
import { Alert, Button, DialogActions, DialogContent, Stack } from '@mui/material'
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { useParams } from 'react-router'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { SummarySection } from 'src/screens/shared/application/common/SummarySection'
import { ValidationListItemProps } from 'src/screens/shared/common/SummaryValidationUtils'
import { FactsheetWorkflowService } from 'src/service/axios/FactsheetWorkflowService'
import { usePermissionsForFactsheetApplication } from 'src/service/security/PermissionHook'
import { EditButton, SecondaryButton } from 'src/shared/button/Buttons'
import { FACTSHEET_WORKFLOW_STATUS, FACTSHEET_WORKFLOW_STATUS_TYPE } from 'src/shared/constants/factsheet-constants'
import { NextStateFactsheet } from 'src/shared/constants/workflow-constants'
import { AutoCompleteField } from 'src/shared/form/control/AutoCompleteField'
import { required } from 'src/shared/form/validation/validators'
import { ErrorOutlineIcon } from 'src/shared/icons/Icons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { FactsheetExportMenu } from 'src/shared/menu/FactsheetExportMenu'
import { ModalDialog } from 'src/shared/modal-dialog/ModalDialog'
import { SecondaryConfirmationModalDialog } from 'src/shared/modal-dialog/SecondaryConfirmationModalDialog'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { useSidebarAPI } from 'src/shared/sidebar/SidebarAwareContext'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { NextStateFactsheetIf } from 'src/shared/workflow/NextStateFactsheetIf'
import styled from 'styled-components/macro'
import { useClient } from 'urql'

const SpanStyled = styled.span<{ $isCanceled: boolean }>`
  color: ${({ $isCanceled, theme }) => ($isCanceled ? theme.colors.error.dark : 'inherit')};
  line-height: 2.25rem;
`

interface FinalDecisionForm {
  decision: FACTSHEET_WORKFLOW_STATUS_TYPE | ''
}

export const FactsheetApplicationSummaryPage = (): ReactElement => {
  const navigate = useDelayedNavigate()
  const { factsheetId } = useParams()
  const factsheet_id = parseInt(factsheetId as string)

  const { getMessage } = useMessageSource()
  const urqlClient = useClient()
  const sidebarAPI = useSidebarAPI()
  const notificationService = useNotificationService()

  const [nextStates, setNextStates] = useState<NextStateFactsheet[] | null>(null)
  const [transitionToStateLoading, setTransitionToStateLoading] = useState<boolean>(false)

  const [withdrawnConfirmationOpen, setWithdrawnConfirmationOpen] = useState<boolean>(false)
  const [decideModalOpen, setDecideModalOpen] = useState<boolean>(false)
  const [revisionConfirmationOpen, setRevisionConfirmationOpen] = useState<boolean>(false)

  const {
    canView,
    canEdit,
    loading,
    refetch,
    metadata: {
      factsheet_workflow_id,
      userFactsheetRoles,
      workflowStatus,
      factsheetApplicationDecision,
      userGlobalRoles,
    },
  } = usePermissionsForFactsheetApplication(factsheet_id)

  const decisionOptions = useMemo(
    () =>
      [FACTSHEET_WORKFLOW_STATUS.ACCEPTED, FACTSHEET_WORKFLOW_STATUS.REJECTED, FACTSHEET_WORKFLOW_STATUS.EXCLUDED].map(
        (status) => ({
          label: getMessage(`button.set.to.${status.toLowerCase()}`),
          value: status,
        }),
      ),
    [getMessage],
  )

  const validateApplication = useCallback(
    (
      applicationDecision: string | null | undefined,
      workflowStatus: FACTSHEET_WORKFLOW_STATUS_TYPE | null,
    ): ValidationListItemProps[] => {
      const validationViolations: ValidationListItemProps[] = []
      if (workflowStatus === FACTSHEET_WORKFLOW_STATUS.CANCELED) {
        return validationViolations
      } else {
        if (!applicationDecision) {
          const violation = {
            message: getMessage('validation.fill.out.section', [
              getMessage('label.navigation.application.information'),
              getMessage('label.application.decision'),
            ]),
            tab: 'application-information',
            section: 'information',
          }
          validationViolations.push(violation)
        }
        return validationViolations
      }
    },
    [getMessage],
  )

  const validationViolations = useMemo(
    () => validateApplication(factsheetApplicationDecision?.application_decision, workflowStatus),
    [factsheetApplicationDecision?.application_decision, workflowStatus, validateApplication],
  )

  const fetchData = useCallback(async () => {
    if (factsheet_workflow_id !== null) {
      const nextStates = await FactsheetWorkflowService.nextStates(factsheet_workflow_id)

      setNextStates(nextStates)
    }
  }, [urqlClient, notificationService, factsheet_workflow_id])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const refetchPageData = () => {
    refetch()
    fetchData()
  }

  const handleViolationClick = (validationItem: ValidationListItemProps) => {
    const route = validationItem.root
      ? validationItem.root
      : ROUTES.FactsheetApplicationDetailsRoot.nested.Information.params({ factsheetId })

    navigate(
      `${route}${validationItem.tab ? '/' + validationItem.tab : ''}${
        validationItem.section ? `#${validationItem.section}` : ''
      }`,
    )
  }

  const summaryCommentsEditHandler = () => {
    navigate(ROUTES.FactsheetApplicationDetailsRoot.nested.SummaryCommentEdit.params({ factsheetId }))
  }

  const getValidationCompleteMessage = (): string => {
    if (workflowStatus === FACTSHEET_WORKFLOW_STATUS.CANCELED) {
      return getMessage('label.project.is.canceled')
    } else {
      return getMessage('label.application.summary.check.complete')
    }
  }

  const openRevisionConfirmationModalDialog = () => {
    setRevisionConfirmationOpen(true)
  }

  const cancelRevisionConfirmationModalDialog = () => {
    setRevisionConfirmationOpen(false)
  }

  const confirmRevisionHandler = async () => {
    try {
      setTransitionToStateLoading(true)
      await FactsheetWorkflowService.transition(factsheet_workflow_id as string, FACTSHEET_WORKFLOW_STATUS.REVISION)
      refetchPageData()
      sidebarAPI.refetchSidebarData()
      setRevisionConfirmationOpen(false)
      notificationService.changesSaved()
    } catch (e) {
      notificationService.operationFailed()
    } finally {
      setTransitionToStateLoading(false)
    }
  }

  const openWithdrawnConfirmationModalDialog = () => {
    setWithdrawnConfirmationOpen(true)
  }

  const cancelWithdrawnConfirmationModalDialog = () => {
    setWithdrawnConfirmationOpen(false)
  }

  const confirmWithdrawnHandler = async () => {
    try {
      setTransitionToStateLoading(true)
      await FactsheetWorkflowService.transition(factsheet_workflow_id as string, FACTSHEET_WORKFLOW_STATUS.WITHDRAWN)
      refetchPageData()
      sidebarAPI.refetchSidebarData()
      setWithdrawnConfirmationOpen(false)
      notificationService.changesSaved()
    } catch (e) {
      notificationService.operationFailed()
    } finally {
      setTransitionToStateLoading(false)
    }
  }

  const openDecideModalDialog = () => {
    setDecideModalOpen(true)
  }

  const cancelDecideModalDialog = () => {
    setDecideModalOpen(false)
  }

  const confirmDecisionHandler = async (decision: FACTSHEET_WORKFLOW_STATUS_TYPE) => {
    try {
      setTransitionToStateLoading(true)
      await FactsheetWorkflowService.transition(factsheet_workflow_id as string, decision)
      refetchPageData()
      sidebarAPI.refetchSidebarData()
      setDecideModalOpen(false)
      notificationService.changesSaved()
    } catch (e) {
      notificationService.operationFailed()
    } finally {
      setTransitionToStateLoading(false)
    }
  }

  const onSubmitDecision = (values: FinalDecisionForm) => {
    const { decision } = values
    if (decision !== '') {
      confirmDecisionHandler(decision)
    }
  }

  if (!loading && !canView) {
    return <NotAuthorized />
  }

  return (
    <ScreenLayout title={getMessage('label.navigation.factsheet.summary')} actions={<FactsheetExportMenu />}>
      <PageLayout>
        <>
          {!loading && nextStates !== null && (
            <>
              <SummarySection
                title={getMessage('label.application.summary.title')}
                actionsHelpLabel={`label.help.application.summary.factsheet`}
                status={
                  <>
                    {`${getMessage('label.factsheet.status')}: `}
                    <SpanStyled $isCanceled={workflowStatus === FACTSHEET_WORKFLOW_STATUS.CANCELED}>
                      {getMessage(`label.factsheet.status.${workflowStatus}`)}
                    </SpanStyled>
                  </>
                }
                violations={validationViolations}
                violationClickHandler={handleViolationClick}
                actionsCompleteMessage={getValidationCompleteMessage()}
                commentsHelpLabel={`label.help.application.summary.comments.factsheet`}
                commentActions={<EditButton onClick={summaryCommentsEditHandler} hidden={!canEdit} />}
                comment={factsheetApplicationDecision?.application_comment}
                statusActions={
                  <>
                    {(workflowStatus === FACTSHEET_WORKFLOW_STATUS.APPLICATION ||
                      workflowStatus === FACTSHEET_WORKFLOW_STATUS.ACCEPTED) && (
                      <NextStateFactsheetIf
                        forState={FACTSHEET_WORKFLOW_STATUS.WITHDRAWN}
                        nextStates={nextStates}
                        userGlobalRoles={userGlobalRoles}
                        userFactsheetRoles={userFactsheetRoles}
                      >
                        <Button
                          variant="outlined"
                          color="secondary"
                          sx={{
                            display: !canEdit ? 'none' : 'inline-block',
                          }}
                          onClick={openWithdrawnConfirmationModalDialog}
                        >
                          {getMessage('button.set.to.withdrawn')}
                        </Button>
                      </NextStateFactsheetIf>
                    )}

                    {workflowStatus === FACTSHEET_WORKFLOW_STATUS.APPLICATION && (
                      <NextStateFactsheetIf
                        forState={FACTSHEET_WORKFLOW_STATUS.REVISION}
                        nextStates={nextStates}
                        userGlobalRoles={userGlobalRoles}
                        userFactsheetRoles={userFactsheetRoles}
                      >
                        <Button
                          variant="outlined"
                          color="secondary"
                          sx={{
                            display: !canEdit ? 'none' : 'inline-block',
                          }}
                          onClick={openRevisionConfirmationModalDialog}
                        >
                          {getMessage('button.set.to.revision')}
                        </Button>
                      </NextStateFactsheetIf>
                    )}

                    {workflowStatus === FACTSHEET_WORKFLOW_STATUS.APPLICATION && (
                      <NextStateFactsheetIf
                        forState={[
                          FACTSHEET_WORKFLOW_STATUS.ACCEPTED,
                          FACTSHEET_WORKFLOW_STATUS.REJECTED,
                          FACTSHEET_WORKFLOW_STATUS.EXCLUDED,
                        ]}
                        nextStates={nextStates}
                        userGlobalRoles={userGlobalRoles}
                        userFactsheetRoles={userFactsheetRoles}
                      >
                        <Button
                          variant="contained"
                          color="secondary"
                          disabled={validationViolations.length > 0}
                          sx={{
                            display: !canEdit ? 'none' : 'inline-block',
                          }}
                          onClick={openDecideModalDialog}
                        >
                          {getMessage('button.decide')}
                        </Button>
                      </NextStateFactsheetIf>
                    )}
                  </>
                }
              />

              <SecondaryConfirmationModalDialog
                open={withdrawnConfirmationOpen}
                onCancel={cancelWithdrawnConfirmationModalDialog}
                onConfirm={confirmWithdrawnHandler}
                titleKey={'label.factsheet.confirm.withdrawn.title'}
                confirmButtonKey={'button.confirm'}
                loading={transitionToStateLoading}
              >
                {getMessage('label.factsheet.confirm.withdrawn.body')}
              </SecondaryConfirmationModalDialog>

              <SecondaryConfirmationModalDialog
                open={revisionConfirmationOpen}
                onCancel={cancelRevisionConfirmationModalDialog}
                onConfirm={confirmRevisionHandler}
                titleKey={'label.factsheet.confirm.revision.title'}
                confirmButtonKey={'button.confirm'}
                loading={transitionToStateLoading}
              >
                {getMessage('label.factsheet.confirm.revision.body')}
              </SecondaryConfirmationModalDialog>

              <ModalDialog
                title={getMessage('label.final.decision')}
                onClose={cancelDecideModalDialog}
                open={decideModalOpen}
                withCloseIcon
                maxWidth="sm"
              >
                <Form<FinalDecisionForm>
                  initialValues={{ decision: '' }}
                  onSubmit={onSubmitDecision}
                  render={({ handleSubmit, valid }) => {
                    return (
                      <form noValidate onSubmit={handleSubmit}>
                        <DialogContent>
                          <Stack spacing={2}>
                            <AutoCompleteField
                              required
                              label={getMessage('label.decision')}
                              name="decision"
                              options={decisionOptions}
                              validate={required()}
                            />
                            <Alert severity="warning" icon={<ErrorOutlineIcon />}>
                              {getMessage('label.action.cannot.be.undone')}
                            </Alert>
                          </Stack>
                        </DialogContent>
                        <DialogActions>
                          <SecondaryButton messageKey={'button.cancel'} onClick={cancelDecideModalDialog} />
                          <LoadingButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            loading={transitionToStateLoading}
                            disabled={!valid}
                          >
                            {getMessage('button.publish')}
                          </LoadingButton>
                        </DialogActions>
                      </form>
                    )
                  }}
                />
              </ModalDialog>
            </>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
