import {
  Card,
  CardContent,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import { ReactElement, useState } from 'react'
import { useParams } from 'react-router'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import { useResolveFactsheetApplicationInformation } from 'src/screens/factsheets/application/details/application-information/FactsheetApplicationInformationService'
import { usePermissionsForFactsheetApplication } from 'src/service/security/PermissionHook'
import { EditButton } from 'src/shared/button/Buttons'
import { DownloadIcon } from 'src/shared/icons/Icons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { FactsheetExportMenu } from 'src/shared/menu/FactsheetExportMenu'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { ReadOnlySelection } from 'src/shared/presentation/ReadOnly'
import { Section } from 'src/shared/presentation/Section'
import { downloadFileByUrl } from 'src/shared/utils/BlobUtils'
import { DateUtils } from 'src/shared/utils/DateUtils'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useUserLocale } from 'src/user/UserContext'
import styled from 'styled-components/macro'
import { FACTSHEET_WORKFLOW_STATUS } from 'src/shared/constants/factsheet-constants'

const ListItemButtonStyled = styled(ListItemButton)`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.action.hover};

  &:hover {
    background-color: ${({ theme }) => theme.colors.action.focus};

    & > .MuiListItemIcon-root {
      color: ${({ theme }) => theme.colors.text.primary};
    }
  }
`

export const FactsheetApplicationInformationPage = (): ReactElement => {
  const { getMessage } = useMessageSource()
  const language = useUserLocale()

  const navigate = useDelayedNavigate()
  const { factsheetId } = useParams()
  const factsheet_id = parseInt(factsheetId as string)

  const {
    loading,
    canEdit,
    canView,
    metadata: { workflowStatus },
  } = usePermissionsForFactsheetApplication(factsheet_id)

  const { applicationInformation } = useResolveFactsheetApplicationInformation(factsheet_id)

  const [loadingExport, setLoadingExport] = useState<boolean>(false)

  const handleEdit = () => {
    navigate(ROUTES.FactsheetApplicationDetailsRoot.nested.InformationEdit.params({ factsheetId }))
  }

  const handleFactsheetExport = () => {
    setLoadingExport(true)
    downloadFileByUrl(`api/factsheet/${factsheetId}/export/pdf?lang=${language}`).finally(() => setLoadingExport(false))
  }

  if (!loading && !canView) {
    return <NotAuthorized />
  }

  return (
    <ScreenLayout
      title={getMessage('label.navigation.factsheets.application.details')}
      actions={<FactsheetExportMenu />}
    >
      <PageLayout>
        <>
          {!loading && applicationInformation && (
            <Section
              id="information"
              title={getMessage('label.application.factsheet.info')}
              actionButton={<EditButton origin="content" color="primary" hidden={!canEdit} onClick={handleEdit} />}
              helpAndInstructions={
                <HelpAndInstructions labelKey={`label.help.basic.information.application.information.factsheet`} />
              }
            >
              <Card>
                <CardContent>
                  <ReadOnlySelection text={getMessage('label.funding.round')}>
                    {applicationInformation.fundingRoundId &&
                      `${getMessage('label.round.id.character')}${applicationInformation.fundingRoundId} ${
                        applicationInformation.fundingRoundName
                      }`}
                  </ReadOnlySelection>
                  <ReadOnlySelection text={getMessage('label.latest.decision.date')}>
                    {applicationInformation.fundingRoundDecisionDate &&
                      DateUtils.parseAndFormatDate(applicationInformation.fundingRoundDecisionDate)}
                  </ReadOnlySelection>
                  <ReadOnlySelection text={getMessage('label.application.decision')}>
                    {applicationInformation.applicationDecision &&
                      getMessage(`label.recommendation.${applicationInformation.applicationDecision}`)}
                  </ReadOnlySelection>
                  <List disablePadding>
                    <ListItem disablePadding>
                      <ListItemButtonStyled
                        disabled={loadingExport || workflowStatus === FACTSHEET_WORKFLOW_STATUS.CONCEPT}
                        onClick={handleFactsheetExport}
                      >
                        <ListItemIcon sx={{ minWidth: '40px' }}>
                          <DownloadIcon />
                        </ListItemIcon>
                        <ListItemText sx={{ flexGrow: 'initial' }}>
                          {getMessage('button.download.application')}
                        </ListItemText>
                        <Typography color="textSecondary">&nbsp;(pdf)</Typography>
                      </ListItemButtonStyled>
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Section>
          )}
        </>
      </PageLayout>
    </ScreenLayout>
  )
}
