import { ReactElement, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { AddFactsheetAssessmentModal } from 'src/screens/factsheets/application/assessment/AddFactsheetAssessmentModal'
import { FactsheetAssessmentGrid } from 'src/screens/factsheets/application/assessment/FactsheetAssessmentGrid'
import { getFactsheetAssessmentsQuery } from 'src/screens/factsheets/application/assessment/factsheetAssessmentQueries'
import { usePermissionsForFactsheetAssessmentIndexPage } from 'src/service/security/PermissionHook'
import { AddButton } from 'src/shared/button/Buttons'
import { PageLayout } from 'src/shared/layout/PageLayout'
import { ScreenLayout } from 'src/shared/layout/ScreenLayout'
import { FactsheetExportMenu } from 'src/shared/menu/FactsheetExportMenu'
import { ModalDialog } from 'src/shared/modal-dialog/ModalDialog'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { Section } from 'src/shared/presentation/Section'
import { useModalCancel } from 'src/shared/utils/hooks/modal-hooks'
import { useQuery } from 'urql'
import { NotAuthorized } from 'src/shared/not-authorized/NotAuthorized'

export const FactsheetApplicationAssessmentIndexPage = (): ReactElement => {
  const { factsheetId } = useParams()
  const factsheetIdNumber = parseInt(factsheetId as string)
  const { canAdd, canDelete, loading, canView } = usePermissionsForFactsheetAssessmentIndexPage(factsheetIdNumber)
  const { getMessage } = useMessageSource()

  const [openModal, setOpenModal] = useState<boolean>(false)

  const onClose = useModalCancel(() => setOpenModal(false))

  const [{ data }, refetch] = useQuery<{
    factsheet_assessment: Query_Root['factsheet_assessment']
  }>({
    query: getFactsheetAssessmentsQuery,
    variables: { factsheetId: factsheetIdNumber },
  })

  const onSuccess = () => {
    refetch()
    setOpenModal(false)
  }

  if (!loading && !canView) {
    return <NotAuthorized />
  }

  return (
    <ScreenLayout
      title={getMessage('label.navigation.factsheets.application.assessment')}
      hasSecondLevelNavigation={false}
      actions={<FactsheetExportMenu />}
    >
      <PageLayout>
        {!loading && (
          <Section
            id="information"
            title={getMessage('label.factsheet.personal.assessment')}
            actionButton={
              <AddButton
                messageKey="button.factsheet.assessor.add"
                onClick={() => setOpenModal(true)}
                hidden={!canAdd}
              />
            }
            helpAndInstructions={<HelpAndInstructions labelKey={`label.help.personal.assessment.factsheet`} />}
          >
            <FactsheetAssessmentGrid
              factsheetId={factsheetIdNumber}
              assessments={data?.factsheet_assessment ?? []}
              refetch={refetch}
              canDeleteAssessment={canDelete}
            />
          </Section>
        )}
      </PageLayout>

      <ModalDialog title={getMessage('label.title.add.factsheet.assessment')} open={openModal} onClose={onClose}>
        <AddFactsheetAssessmentModal
          onCancel={onClose}
          onSuccess={onSuccess}
          assessments={data?.factsheet_assessment}
          factsheetId={factsheetIdNumber}
          onDiscard={() => setOpenModal(false)}
        />
      </ModalDialog>
    </ScreenLayout>
  )
}
