import { SecondLevelRoute } from 'src/routing/routing'
import { ComposePaths, ExtractRouteParams, withParams } from 'src/routing/routing-utils'
import { FactsheetAssessmentDetailsIndexPage } from 'src/screens/factsheets/application/assessment/details/FactsheetAssessmentDetailsIndexPage'
import { FactsheetAssessmentDetailsLevelsEditPage } from 'src/screens/factsheets/application/assessment/details/levels/edit/FactsheetAssessmentDetailsLevelsEditPage'
import { FactsheetAssessmentLevelsCommentEditPage } from 'src/screens/factsheets/application/assessment/details/levels/edit/FactsheetAssessmentLevelsCommentEditPage'
import { FactsheetAssessmentDetailsLevelsPage } from 'src/screens/factsheets/application/assessment/details/levels/FactsheetAssessmentDetailsLevelsPage'
import { FactsheetAssessmentDetailsRecommendationEditPage } from 'src/screens/factsheets/application/assessment/details/recommendation/edit/FactsheetAssessmentDetailsRecommendationEditPage'
import { FactsheetAssessmentDetailsRecommendationPage } from 'src/screens/factsheets/application/assessment/details/recommendation/FactsheetAssessmentDetailsRecommendationPage'
import { FactsheetAssessmentSummaryCommentEditPage } from 'src/screens/factsheets/application/assessment/details/summary/edit/FactsheetAssessmentSummaryCommentEditPage'
import { FactsheetAssessmentDetailsSummaryPage } from 'src/screens/factsheets/application/assessment/details/summary/FactsheetAssessmentDetailsSummaryPage'
import { FactsheetApplicationAssessmentIndexPage } from 'src/screens/factsheets/application/assessment/FactsheetApplicationAssessmentIndexPage'
import { FactsheetApplicationCommitteeEditPage } from 'src/screens/factsheets/application/committee/edit/FactsheetApplicationCommitteeEditPage'
import { FactsheetApplicationCommitteePage } from 'src/screens/factsheets/application/committee/FactsheetApplicationCommitteePage'
import { FactsheetApplicationInformationEditPage } from 'src/screens/factsheets/application/details/application-information/edit/FactsheetApplicationInformationEditPage'
import { FactsheetApplicationInformationPage } from 'src/screens/factsheets/application/details/application-information/FactsheetApplicationInformationPage'
import { FactsheetApplicationDetailsIndexPage } from 'src/screens/factsheets/application/details/FactsheetApplicationDetailsIndexPage'
import { FactsheetApplicationSummaryCommentEditPage } from 'src/screens/factsheets/application/details/summary/edit/FactsheetApplicationSummaryCommentEditPage'
import { FactsheetApplicationSummaryPage } from 'src/screens/factsheets/application/details/summary/FactsheetApplicationSummaryPage'
import { FactsheetBasicInformationEditPage } from 'src/screens/factsheets/factsheet/description/description/edit/FactsheetBasicInformationEditPage'
import { FactsheetDescriptionEditPage } from 'src/screens/factsheets/factsheet/description/description/edit/FactsheetDescriptionEditPage'
import { FactsheetTitleAndDescriptionEditPage } from 'src/screens/factsheets/factsheet/description/description/edit/FactsheetTitleAndDescriptionEditPage'
import { FactsheetDescriptionPage } from 'src/screens/factsheets/factsheet/description/description/FactsheetDescriptionPage'
import { FactsheetDescriptionIndexPage } from 'src/screens/factsheets/factsheet/description/FactsheetDescriptionIndexPage'
import { FactsheetFeaturesEditPage } from 'src/screens/factsheets/factsheet/description/features/edit/FactsheetFeaturesEditPage'
import { FactsheetFeaturesPage } from 'src/screens/factsheets/factsheet/description/features/FactsheetFeaturesPage'
import { FactsheetSummaryPage } from 'src/screens/factsheets/factsheet/description/summary/FactsheetSummaryPage'
import { FactsheetPermissionsPage } from 'src/screens/factsheets/factsheet/permissions/FactsheetPermissionsPage'
import { FactsheetRelatedProjectsAndMeasuresPage } from 'src/screens/factsheets/factsheet/related-projects-and-measures/FactsheetRelatedProjectsAndMeasuresPage'
import { FactsheetsIndexPage } from 'src/screens/factsheets/index/FactsheetsIndexPage'
import { LEVEL, LEVEL_TYPE } from 'src/shared/constants/constants'
import { FACTSHEET_DESCRIPTION_EDIT_OPTION } from 'src/shared/constants/factsheet-constants'
import { FactsheetSummaryCommentEditPage } from 'src/screens/factsheets/factsheet/description/summary/edit/FactsheetSummaryCommentEditPage'

const FactsheetsIndex = {
  path: 'factsheets',
  Component: FactsheetsIndexPage,
  navigationKey: 'label.navigation.factsheets',
} as const

const FactsheetDescriptionRoot = {
  path: 'factsheets/:factsheetId/description',
  Component: FactsheetDescriptionIndexPage,
  roles: [
    'PD-EXT_USER',
    'PD-EXT_EXPERT',
    'PD-GFCH_FACTSHEET_COORDINATOR',
    'PD-GFCH_PF-KAP_CONTRIBUTOR',
    'PD-GFCH_PF-KAP_COORDINATOR',
    'PD-GFCH_PF-KAP_READER',
    'PD-GFCH_PF-PGV_CONTRIBUTOR',
    'PD-GFCH_PF-PGV_COORDINATOR',
    'PD-GFCH_PF-PGV_READER',
    'PD-GFCH_KAP_CONTRIBUTOR',
    'PD-GFCH_KAP_COORDINATOR',
    'PD-GFCH_KAP_READER',
    'PD-CANTON_KAP_CONTRIBUTOR',
    'PD-CANTON_KAP_READER',
    'PD-SUCCESS_FACTOR_CONTRIBUTOR',
    'PD-GFCH_SUCCESS_FACTOR_COORDINATOR',
  ],
} as const

const FactsheetDescription = {
  path: 'factsheet-description',
  Component: FactsheetDescriptionPage,
  navigationKey: 'label.navigation.factsheet.description',
  primary: true,
} as const

const FactsheetBasicInformationEdit = {
  path: FACTSHEET_DESCRIPTION_EDIT_OPTION.BASIC_INFO + '/edit',
  Component: FactsheetBasicInformationEditPage,
  hideNavigation: true,
} as const

const FactsheetTitleAndDescriptionEdit = {
  path: FACTSHEET_DESCRIPTION_EDIT_OPTION.TITLE_AND_DESCRIPTION + '/edit',
  Component: FactsheetTitleAndDescriptionEditPage,
  hideNavigation: true,
} as const

const FactsheetImportantInsightsEdit = {
  path: FACTSHEET_DESCRIPTION_EDIT_OPTION.IMPORTANT_INSIGHTS + '/edit',
  Component: () => (
    <FactsheetDescriptionEditPage
      field={'important_insights'}
      sectionLabel="label.factsheet.edit.important_insights"
      helpLabel="label.help.factsheet.important_insights"
    />
  ),
  hideNavigation: true,
} as const

const FactsheetStrengthEdit = {
  path: FACTSHEET_DESCRIPTION_EDIT_OPTION.STRENGTH + '/edit',
  Component: () => (
    <FactsheetDescriptionEditPage
      field={'strength'}
      sectionLabel="label.factsheet.edit.strength"
      helpLabel="label.help.factsheet.strength"
    />
  ),
  hideNavigation: true,
} as const

const FactsheetFurtherInformationEdit = {
  path: FACTSHEET_DESCRIPTION_EDIT_OPTION.FURTHER_INFORMATION + '/edit',
  Component: () => (
    <FactsheetDescriptionEditPage
      field={'further_information'}
      sectionLabel="label.factsheet.edit.further_information"
      helpLabel="label.help.factsheet.further_information"
    />
  ),
  hideNavigation: true,
} as const

const FactsheetEvidenceOfEffectivenessEdit = {
  path: FACTSHEET_DESCRIPTION_EDIT_OPTION.EVIDENCE_OF_EFFECTIVENESS + '/edit',
  Component: () => (
    <FactsheetDescriptionEditPage
      field={'evidence_of_effectiveness'}
      sectionLabel="label.factsheet.edit.evidence_of_effectiveness"
      helpLabel="label.help.factsheet.evidence_of_effectiveness"
    />
  ),
  hideNavigation: true,
} as const

const FactsheetFeatures = {
  path: 'factsheet-features',
  Component: FactsheetFeaturesPage,
  navigationKey: 'label.navigation.factsheet.features',
  primary: false,
} as const

const FactsheetFeaturesEdit = {
  path: 'factsheet-features/:featureTypeId/edit',
  Component: FactsheetFeaturesEditPage,
  hideNavigation: true,
} as const

const FactsheetSummary = {
  path: 'factsheet-summary',
  Component: FactsheetSummaryPage,
  navigationKey: 'label.navigation.factsheet.summary',
  hideNavigation: false,
} as const

const FactsheetSummaryCommentEdit = {
  path: 'factsheet-summary/edit',
  Component: FactsheetSummaryCommentEditPage,
  hideNavigation: true,
} as const

const FactsheetRelatedProjects = {
  path: 'factsheets/:factsheetId/related-projects-and-measures',
  Component: FactsheetRelatedProjectsAndMeasuresPage,
  roles: [
    'PD-EXT_USER',
    'PD-EXT_EXPERT',
    'PD-GFCH_FACTSHEET_COORDINATOR',
    'PD-GFCH_PF-KAP_CONTRIBUTOR',
    'PD-GFCH_PF-KAP_COORDINATOR',
    'PD-GFCH_PF-KAP_READER',
    'PD-GFCH_KAP_CONTRIBUTOR',
    'PD-GFCH_KAP_COORDINATOR',
    'PD-GFCH_KAP_READER',
    'PD-CANTON_KAP_CONTRIBUTOR',
    'PD-CANTON_KAP_READER',
  ],
} as const

const FactsheetPermissions = {
  path: 'factsheets/:factsheetId/permissions',
  Component: FactsheetPermissionsPage,
  roles: [
    'PD-EXT_USER',
    'PD-EXT_EXPERT',
    'PD-GFCH_FACTSHEET_COORDINATOR',
    'PD-GFCH_PF-KAP_CONTRIBUTOR',
    'PD-GFCH_PF-KAP_COORDINATOR',
    'PD-GFCH_PF-KAP_READER',
    'PD-GFCH_KAP_CONTRIBUTOR',
    'PD-GFCH_KAP_COORDINATOR',
    'PD-GFCH_KAP_READER',
    'PD-CANTON_KAP_CONTRIBUTOR',
    'PD-CANTON_KAP_READER',
  ],
} as const

const FactsheetApplicationDetailsRoot = {
  path: 'factsheets/:factsheetId/application/details',
  Component: FactsheetApplicationDetailsIndexPage,
  roles: ['PD-GFCH_FACTSHEET_COORDINATOR', 'PD-GFCH_PF-KAP_CONTRIBUTOR'],
} as const

const FactsheetApplicationInformation = {
  path: 'information',
  Component: FactsheetApplicationInformationPage,
  navigationKey: 'label.navigation.factsheets.application.information',
  primary: true,
} as const

const FactsheetApplicationInformationEdit = {
  path: 'information/edit',
  Component: FactsheetApplicationInformationEditPage,
  hideNavigation: true,
} as const

const FactsheetApplicationSummary = {
  path: 'summary',
  Component: FactsheetApplicationSummaryPage,
  navigationKey: 'label.navigation.factsheets.summary',
} as const

const FactsheetApplicationSummaryCommentEdit = {
  path: 'summary/edit',
  Component: FactsheetApplicationSummaryCommentEditPage,
  hideNavigation: true,
} as const

const FactsheetApplicationCommittee = {
  path: 'factsheets/:factsheetId/application/committee',
  Component: FactsheetApplicationCommitteePage,
  roles: ['PD-GFCH_FACTSHEET_COORDINATOR', 'PD-GFCH_PF-KAP_CONTRIBUTOR'],
} as const

const FactsheetApplicationCommitteeEdit = {
  path: 'factsheets/:factsheetId/application/committee/edit',
  Component: () => <FactsheetApplicationCommitteeEditPage />,
  roles: ['PD-GFCH_FACTSHEET_COORDINATOR', 'PD-GFCH_PF-KAP_CONTRIBUTOR'],
} as const

const FactsheetApplicationAssessment = {
  path: 'factsheets/:factsheetId/application/assessment',
  Component: FactsheetApplicationAssessmentIndexPage,
  roles: ['PD-GFCH_FACTSHEET_COORDINATOR', 'PD-GFCH_PF-KAP_CONTRIBUTOR'],
} as const

const FactsheetApplicationAssessmentDetailsRoot = {
  path: 'factsheets/:factsheetId/application/assessment/:assessmentId/details',
  Component: FactsheetAssessmentDetailsIndexPage,
  roles: ['PD-GFCH_FACTSHEET_COORDINATOR', 'PD-GFCH_PF-KAP_CONTRIBUTOR'],
} as const

const LEVEL_TABS: LEVEL_TYPE[] = [LEVEL.INTERVENTION, LEVEL.COMMUNICATION, LEVEL.NETWORK, LEVEL.POLICY]

const FactsheetApplicationAssessmentDetailsLevels = LEVEL_TABS.map((level) => ({
  path: level,
  Component: () => <FactsheetAssessmentDetailsLevelsPage level={level} />,
  navigationKey: 'label.navigation.assessment.' + level.toLowerCase(),
  primary: level === LEVEL.INTERVENTION,
}))

export type LevelTabsEdit = `${LEVEL_TYPE}Edit`
const FactsheetApplicationAssessmentDetailsLevelsEdit = LEVEL_TABS.map((level) => ({
  path: level,
  Component: () => <FactsheetAssessmentDetailsLevelsEditPage level={level} />,
  hideNavigation: true,
}))

export type LevelTabsCommentEdit = `${LEVEL_TYPE}CommentEdit`
const FactsheetApplicationAssessmentDetailsLevelsCommentEdit = LEVEL_TABS.map((level) => ({
  path: level,
  Component: () => <FactsheetAssessmentLevelsCommentEditPage level={level} />,
  hideNavigation: true,
}))

const FactsheetApplicationAssessmentDetailsRecommendation = {
  path: 'recommendation',
  Component: FactsheetAssessmentDetailsRecommendationPage,
  navigationKey: 'label.navigation.assessment.recommendation',
} as const

const FactsheetApplicationAssessmentDetailsRecommendationEdit = {
  path: 'recommendation/edit',
  Component: FactsheetAssessmentDetailsRecommendationEditPage,
  hideNavigation: true,
} as const

const FactsheetApplicationAssessmentDetailsSummary = {
  path: 'summary',
  Component: FactsheetAssessmentDetailsSummaryPage,
  navigationKey: 'label.navigation.project.summary',
} as const

const FactsheetApplicationAssessmentDetailsSummaryCommentEdit = {
  path: 'summary/comment/edit',
  Component: FactsheetAssessmentSummaryCommentEditPage,
  hideNavigation: true,
} as const

export const FactsheetRoutes = {
  FactsheetsIndex: FactsheetsIndex,
  FactsheetDescriptionRoot: {
    ...FactsheetDescriptionRoot,
    params: (props: ExtractRouteParams<typeof FactsheetDescriptionRoot.path>) =>
      withParams(`/${FactsheetDescriptionRoot.path}`)(props),
    nested: {
      FactsheetDescription: {
        ...FactsheetDescription,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof FactsheetDescriptionRoot.path, typeof FactsheetDescription.path>
          >,
        ) => withParams(`/${FactsheetDescriptionRoot.path}/${FactsheetDescription.path}`)(props),
      },
      FactsheetBasicInformationEdit: {
        ...FactsheetBasicInformationEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof FactsheetDescriptionRoot.path, typeof FactsheetBasicInformationEdit.path>
          >,
        ) => withParams(`/${FactsheetDescriptionRoot.path}/${FactsheetBasicInformationEdit.path}`)(props),
      },
      FactsheetTitleAndDescriptionEdit: {
        ...FactsheetTitleAndDescriptionEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof FactsheetDescriptionRoot.path, typeof FactsheetTitleAndDescriptionEdit.path>
          >,
        ) => withParams(`/${FactsheetDescriptionRoot.path}/${FactsheetTitleAndDescriptionEdit.path}`)(props),
      },
      FactsheetImportantInsightsEdit: {
        ...FactsheetImportantInsightsEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof FactsheetDescriptionRoot.path, typeof FactsheetImportantInsightsEdit.path>
          >,
        ) => withParams(`/${FactsheetDescriptionRoot.path}/${FactsheetImportantInsightsEdit.path}`)(props),
      },
      FactsheetStrengthEdit: {
        ...FactsheetStrengthEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof FactsheetDescriptionRoot.path, typeof FactsheetStrengthEdit.path>
          >,
        ) => withParams(`/${FactsheetDescriptionRoot.path}/${FactsheetStrengthEdit.path}`)(props),
      },
      FactsheetFurtherInformationEdit: {
        ...FactsheetFurtherInformationEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof FactsheetDescriptionRoot.path, typeof FactsheetFurtherInformationEdit.path>
          >,
        ) => withParams(`/${FactsheetDescriptionRoot.path}/${FactsheetFurtherInformationEdit.path}`)(props),
      },
      FactsheetEvidenceOfEffectivenessEdit: {
        ...FactsheetEvidenceOfEffectivenessEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof FactsheetDescriptionRoot.path, typeof FactsheetEvidenceOfEffectivenessEdit.path>
          >,
        ) => withParams(`/${FactsheetDescriptionRoot.path}/${FactsheetEvidenceOfEffectivenessEdit.path}`)(props),
      },
      FactsheetFeatures: {
        ...FactsheetFeatures,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof FactsheetDescriptionRoot.path, typeof FactsheetFeatures.path>>,
        ) => withParams(`/${FactsheetDescriptionRoot.path}/${FactsheetFeatures.path}`)(props),
      },
      FactsheetFeaturesEdit: {
        ...FactsheetFeaturesEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof FactsheetDescriptionRoot.path, typeof FactsheetFeaturesEdit.path>
          >,
        ) => withParams(`/${FactsheetDescriptionRoot.path}/${FactsheetFeaturesEdit.path}`)(props),
      },
      FactsheetSummary: {
        ...FactsheetSummary,
        params: (
          props: ExtractRouteParams<ComposePaths<typeof FactsheetDescriptionRoot.path, typeof FactsheetSummary.path>>,
        ) => withParams(`/${FactsheetDescriptionRoot.path}/${FactsheetSummary.path}`)(props),
      },
      FactsheetSummaryCommentEdit: {
        ...FactsheetSummaryCommentEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof FactsheetDescriptionRoot.path, typeof FactsheetSummaryCommentEdit.path>
          >,
        ) => withParams(`/${FactsheetDescriptionRoot.path}/${FactsheetSummaryCommentEdit.path}`)(props),
      },
    },
  },
  FactsheetPermissions: {
    ...FactsheetPermissions,
    params: (props: ExtractRouteParams<typeof FactsheetPermissions.path>) =>
      withParams(`/${FactsheetPermissions.path}`)(props),
  },
  FactsheetRelatedProjects: {
    ...FactsheetRelatedProjects,
    params: (props: ExtractRouteParams<typeof FactsheetRelatedProjects.path>) =>
      withParams(`/${FactsheetRelatedProjects.path}`)(props),
  },
  FactsheetApplicationDetailsRoot: {
    ...FactsheetApplicationDetailsRoot,
    params: (props: ExtractRouteParams<typeof FactsheetApplicationDetailsRoot.path>) =>
      withParams(`/${FactsheetApplicationDetailsRoot.path}`)(props),
    nested: {
      Information: {
        ...FactsheetApplicationInformation,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof FactsheetApplicationDetailsRoot.path, typeof FactsheetApplicationInformation.path>
          >,
        ) => withParams(`/${FactsheetApplicationDetailsRoot.path}/${FactsheetApplicationInformation.path}`)(props),
      },
      InformationEdit: {
        ...FactsheetApplicationInformationEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof FactsheetApplicationDetailsRoot.path, typeof FactsheetApplicationInformationEdit.path>
          >,
        ) => withParams(`/${FactsheetApplicationDetailsRoot.path}/${FactsheetApplicationInformationEdit.path}`)(props),
      },
      Summary: {
        ...FactsheetApplicationSummary,
        params: (
          props: ExtractRouteParams<
            ComposePaths<typeof FactsheetApplicationDetailsRoot.path, typeof FactsheetApplicationSummary.path>
          >,
        ) => withParams(`/${FactsheetApplicationDetailsRoot.path}/${FactsheetApplicationSummary.path}`)(props),
      },
      SummaryCommentEdit: {
        ...FactsheetApplicationSummaryCommentEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof FactsheetApplicationDetailsRoot.path,
              typeof FactsheetApplicationSummaryCommentEdit.path
            >
          >,
        ) =>
          withParams(`/${FactsheetApplicationDetailsRoot.path}/${FactsheetApplicationSummaryCommentEdit.path}`)(props),
      },
    },
  },
  FactsheetApplicationCommittee: {
    ...FactsheetApplicationCommittee,
    params: (props: ExtractRouteParams<typeof FactsheetApplicationCommittee.path>) =>
      withParams(`/${FactsheetApplicationCommittee.path}`)(props),
  },
  FactsheetApplicationCommitteeEdit: {
    ...FactsheetApplicationCommitteeEdit,
    params: (props: ExtractRouteParams<typeof FactsheetApplicationCommitteeEdit.path>) =>
      withParams(`/${FactsheetApplicationCommitteeEdit.path}`)(props),
  },
  FactsheetApplicationAssessment: {
    ...FactsheetApplicationAssessment,
    params: (props: ExtractRouteParams<typeof FactsheetApplicationAssessment.path>) =>
      withParams(`/${FactsheetApplicationAssessment.path}`)(props),
  },
  FactsheetApplicationAssessmentDetailsRoot: {
    ...FactsheetApplicationAssessmentDetailsRoot,
    params: (props: ExtractRouteParams<typeof FactsheetApplicationAssessmentDetailsRoot.path>) =>
      withParams(`/${FactsheetApplicationAssessmentDetailsRoot.path}`)(props),
    nested: {
      ...(Object.fromEntries(
        FactsheetApplicationAssessmentDetailsLevels.map((detailsLevel) => [
          detailsLevel.path as LEVEL_TYPE,
          {
            ...detailsLevel,
            path: detailsLevel.path.toLowerCase(),
            params: (
              props: ExtractRouteParams<
                ComposePaths<typeof FactsheetApplicationAssessmentDetailsRoot.path, typeof detailsLevel.path>
              >,
            ) =>
              withParams(`/${FactsheetApplicationAssessmentDetailsRoot.path}/${detailsLevel.path.toLowerCase()}`)(
                props,
              ),
          } as SecondLevelRoute,
        ]),
      ) as Record<LEVEL_TYPE, SecondLevelRoute>),

      ...(Object.fromEntries(
        FactsheetApplicationAssessmentDetailsLevelsEdit.map((detailsLevel) => [
          `${detailsLevel.path}Edit`,
          {
            ...detailsLevel,
            path: detailsLevel.path.toLowerCase() + '/edit',
            params: (
              props: ExtractRouteParams<
                ComposePaths<typeof FactsheetApplicationAssessmentDetailsRoot.path, typeof detailsLevel.path>
              >,
            ) =>
              withParams(
                `/${FactsheetApplicationAssessmentDetailsRoot.path}/${detailsLevel.path.toLowerCase() + '/edit'}`,
              )(props),
          } as SecondLevelRoute,
        ]),
      ) as Record<LevelTabsEdit, SecondLevelRoute>),

      ...(Object.fromEntries(
        FactsheetApplicationAssessmentDetailsLevelsCommentEdit.map((detailsLevel) => [
          `${detailsLevel.path}CommentEdit`,
          {
            ...detailsLevel,
            path: detailsLevel.path.toLowerCase() + '/comment/edit',
            params: (
              props: ExtractRouteParams<
                ComposePaths<typeof FactsheetApplicationAssessmentDetailsRoot.path, typeof detailsLevel.path>
              >,
            ) =>
              withParams(
                `/${FactsheetApplicationAssessmentDetailsRoot.path}/${
                  detailsLevel.path.toLowerCase() + '/comment/edit'
                }`,
              )(props),
          } as SecondLevelRoute,
        ]),
      ) as Record<LevelTabsCommentEdit, SecondLevelRoute>),

      Recommendation: {
        ...FactsheetApplicationAssessmentDetailsRecommendation,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof FactsheetApplicationAssessmentDetailsRoot.path,
              typeof FactsheetApplicationAssessmentDetailsRecommendation.path
            >
          >,
        ) =>
          withParams(
            `/${FactsheetApplicationAssessmentDetailsRoot.path}/${FactsheetApplicationAssessmentDetailsRecommendation.path}`,
          )(props),
      },

      RecommendationEdit: {
        ...FactsheetApplicationAssessmentDetailsRecommendationEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof FactsheetApplicationAssessmentDetailsRoot.path,
              typeof FactsheetApplicationAssessmentDetailsRecommendationEdit.path
            >
          >,
        ) =>
          withParams(
            `/${FactsheetApplicationAssessmentDetailsRoot.path}/${FactsheetApplicationAssessmentDetailsRecommendationEdit.path}`,
          )(props),
      },

      Summary: {
        ...FactsheetApplicationAssessmentDetailsSummary,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof FactsheetApplicationAssessmentDetailsRoot.path,
              typeof FactsheetApplicationAssessmentDetailsSummary.path
            >
          >,
        ) =>
          withParams(
            `/${FactsheetApplicationAssessmentDetailsRoot.path}/${FactsheetApplicationAssessmentDetailsSummary.path}`,
          )(props),
      },

      SummaryCommentEdit: {
        ...FactsheetApplicationAssessmentDetailsSummaryCommentEdit,
        params: (
          props: ExtractRouteParams<
            ComposePaths<
              typeof FactsheetApplicationAssessmentDetailsRoot.path,
              typeof FactsheetApplicationAssessmentDetailsSummaryCommentEdit.path
            >
          >,
        ) =>
          withParams(
            `/${FactsheetApplicationAssessmentDetailsRoot.path}/${FactsheetApplicationAssessmentDetailsSummaryCommentEdit.path}`,
          )(props),
      },
    },
  },
}
